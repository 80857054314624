import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import PageTitle from "../../common/PageTitle";
import FAQCard from "../../components/faq/FAQCard";
import Loader from "../../common/Loader";

export default function FAQPage() {
  const [loader, setLoader] = useState(false);
  const [allowedQuestions, setAllowedQuestions] = useState([]);

  useEffect(() => {
    const permissionList = JSON.parse(localStorage.getItem('permisionlist'));
    
    if (permissionList && permissionList[0]?.submenuPermissions.length > 0) {
      const submenuPermissions = permissionList[0].submenuPermissions.map(item => item.submenu);

      // Filter questions based on submenu permissions
      const filteredQuestions = questions.filter(question =>
        submenuPermissions.includes(question.title)
      );

      setAllowedQuestions(filteredQuestions);
    } else {

      setAllowedQuestions(questions);
    }
  }, []);

  const questions = [
    {
      id: 1,
      title: "Frequently Asked Questions",
      url : "/faq/frequently-asked-questions"
    },
    {
      id: 4,
      title: "Terms & Condition",
      url : "/faq/t&c"
    },
    {
      id: 2,
      title: "Privacy Policy",
      url : "/faq/privacy-policy"
    },
    {
      id: 3,
      title: "Help Center",
      url : "/faq/user-guide"
    },
  ];

  return (
    <Box sx={{ height: "inherit" }}>
      <Loader loader={loader} />
      <PageTitle title="FAQ's" subTitle="Online Reference Documents" />
      <Box display="flex">
        <Grid
          container
          rowSpacing={7}
          columnSpacing={{ xs: 1 }}
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          mt="1%"
        >
          {allowedQuestions.map((question) => (
            <FAQCard large title={question.title} key={question.id} url={question.url} />
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
