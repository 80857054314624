import React, { useEffect, useState } from 'react'
import SiteDetailCard from '../../../components/sites/DetailCard'
import "./Property.css"
// import { Box, Button, Modal, Paper, Table, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core'
import { checkAuthority } from '../../../utils'
// import EmptyTable from '../../../common/EmptyTable'
// import { Handler } from 'leaflet'

export const tableHeader = {
    fontSize: '0.9rem !important',
    fontWeight: 500,
    color: '#45445F',
    px: 3,
    paddingLeft: '5px',
    paddingRight: '5px',
    padding: '12px'
}

const Properties = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        height: 300,
        bgcolor: 'background.paper',
        border: '2px solid white',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        borderRadius: "2px"
    };
    const segments = window.location?.pathname;
    const [properyDetail, setPropertyDetail] = useState([])

    const handleClick = (e, i) => {
        console.log(i, "----i");
        e.preventDefault()
        setIsModalOpen(true)
    }

    useEffect(() => {
        getProperties()
    }, [segments])

    const getProperties = () => {
        var arr = []
        let url = segments?.split("/")[1] === 'company' ? '/company' : ''

        if (checkAuthority('VIEW_ADD_PROPERTY')) {
            arr.push({
                id: 1,
                title: "Add Property",
                url: `${url}/maintainance-report/property/add-property`
            })
        }

        if (checkAuthority('VIEW_PROPERTY')) {
            arr.push({
                id: 2,
                title: "View Property",
                url: `${url}/maintainance-report/property/view-property`
            })
        }

        setPropertyDetail(arr)

    }

    return (
        <div>
            <div>
                <h1>Property Portfolio</h1>
            </div>
            <div className='datacard' onClick={() => setIsModalOpen(true)}>
                {
                    properyDetail?.map((it, i) => {
                        return <div key={i}><SiteDetailCard title={it?.title} url={it?.url} /></div>
                    })
                }
            </div>
            {/* <div>
                <Modal
                    open={isModalOpen}
                >
                    <Box sx={style}>
                   
                        <div className='box-title'>
                            <h2>Add Property Type</h2>
                        </div>
                        <div>
                            <TextField variant='outlined' label="Property Name" className='box-input' />
                        </div>
                        <div className='box-button-group'>
                            <Button variant='contained'>Submit</Button>
                            <Button variant='outlined' onClick={() => setIsModalOpen(false)} >Cancel</Button>
                        </div>
                    </Box>
                </Modal>
            </div> */}

        </div>
    )
}

export default Properties