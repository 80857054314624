import React, { useEffect, useState } from "react";
import PageTitle from "../../../../common/PageTitle";

import AddCircleIcon from "@mui/icons-material/AddCircle";
// import EmptyTable from '../../../../common/EmptyTable';
import { tableData, tableHeader } from "../../../../utils";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { deleteAPI, getAPI, postAPI, putAPI } from "../../../../network";
import EmptyTable from "../../../../common/EmptyTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const AddBuildingSpaceId = () => {
  let companyId = localStorage.getItem("companyIdLS");
  if (companyId === null) {
    companyId = undefined;
  }
  const [modalOpen, setModalOpen] = useState(false);
  const [buildingSpaceIdList, setBuildingSpaceIdList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [inputs, setInputs] = useState({
    areaId: "",
    buildingTypes: "",
    buildingName: "",
    buildingFloor: "",
    buildingSpaceType: "",
    companyId: companyId,
  });

  const [buildingInputs, setBuildingInputs] = useState({
    _id: "",
    areaId: "",
    buildingTypes: "",
    buildingName: "",
    buildingFloor: "",
    buildingSpaceType: "",
  });
  const [editModal, setEditModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [buildingTypeList, setBuildingTypeList] = useState([]);
  const [buildingNameList, setBuildingNameList] = useState([]);
  const [buildingFloorList, setBuildingFloorList] = useState([]);
  const [buildingSpaceList, setBuildingSpaceList] = useState([]);

  console.log(buildingTypeList);

  const [permissionList, setPermissionList] = useState([]);

  // Load the permission list from localStorage
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permisionlist")) || [];
    setPermissionList(permissions);
  }, []);

  const subActionPermissions = permissionList[0]?.subactionPermissions || [];

  const checkSubActionPermission = (subAction, actionName, permissionList) => {
    if (!permissionList || permissionList.length === 0) {
      return false; // Return false if permissionList is empty or not provided
    }

    const subactionPermissions = permissionList[0]?.subactionPermissions || [];
    const actionPermissions = permissionList[0]?.actionPermissions || [];

    // Find the action ID(s) matching the given actionName
    const matchingActions = actionPermissions.filter(
      (actionPerm) => actionPerm.action === actionName
    );

    if (matchingActions.length === 0) {
      return false; // If no matching actions are found, return false
    }

    // Check if any subAction is linked to the matching actions
    return subactionPermissions.some((subPerm) => {
      return (
        subPerm.subAction === subAction &&
        matchingActions.some((actionPerm) => actionPerm._id === subPerm.action)
      );
    });
  };

  // If subActionPermissions is empty, show both buttons
  const showBothButtons = subActionPermissions.length === 0;

  // -------------------------------------------- Table States and Static Data Manipulation Start --------------------------------------

  const [page, setPage] = useState(0);
  const [perPages, setPerPages] = useState([10, 25, 50]);
  const [perPage, setPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value);
    setPerPage(event.target.value);
    setPage(0);
  };

  // -------------------------------------------- Table States and Static Data Manipulation Ends --------------------------------------

  // ---------------------------------------------- Event Handlers Start --------------------------------------------------------------

  const onChange = async (e) => {
    e.preventDefault();

    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const editChange = async (e) => {
    e.preventDefault();

    setBuildingInputs({ ...buildingInputs, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (editMode) {
      editBuildingTypee();
    } else {
      postBuildingType();
      setModalOpen(false);
    }
  };

  const editBuildingType = async (e, _id) => {
    e.preventDefault();

    setEditModal(true);
    setEditMode(true);

    let data = buildingSpaceIdList?.filter((item) => item?._id === _id)[0];

    setBuildingInputs(data);
    setBuildingInputs({
      ...data,
      buildingTypes: data.buildingTypes._id,
      buildingName: data.buildingName._id,
      buildingFloor: data.buildingFloor._id,
      buildingSpaceType: data?.buildingSpaceType?._id,
    });
  };

  // ---------------------------------------------- Event Handlers Ends --------------------------------------------------------------

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  // ------------------------------------------------- Get Building Type Start  ----------------------------------------------------

  const getBuildingType = async () => {
    console.log("called");

    setLoader(true);
    const response = await getAPI(`/building/type?companyId=${companyId}`);
    if (response) {
      console.log(response);

      setBuildingTypeList(response);
    }
    setLoader(false);
  };

  const getBuildingName = async () => {
    setLoader(true);
    const response = await getAPI(`/building/name?companyId=${companyId}`);
    if (response) {
      setBuildingNameList(response);
    }
    setLoader(false);
  };

  const getBuildingFloor = async () => {
    setLoader(true);
    const response = await getAPI(`/building/floor?companyId=${companyId}`);
    if (response) {
      setBuildingFloorList(response);
    }
    setLoader(false);
  };

  const getBuildingSpace = async () => {
    setLoader(true);
    const response = await getAPI("/building/SpaceType");
    if (response) {
      setBuildingSpaceList(response);
    }
    setLoader(false);
  };

  // ------------------------------------------------- Get Building Type End  ------------------------------------------------------

  // ---------------------------------------------- Building-Floor CRUD Start ------------------------------------------------------

  const postBuildingType = async () => {
    setLoader(true);
    const response = await postAPI("/building/SpaceId", inputs);
    if (response) {
      getBuildingSpaceId();
      setModalOpen(false);
      setEditMode(false);
      setInputs({
        areaId: "",
        buildingTypes: "",
        buildingName: "",
        buildingFloor: "",
        buildingSpaceType: "",
        companyId: companyId,
      });
    }
    setLoader(false);
  };

  const getBuildingSpaceId = async () => {
    setLoader(true);
    const response = await getAPI("/building/SpaceId");
    if (response) {
      setBuildingSpaceIdList(response);
    }
    setLoader(false);
  };

  const editBuildingTypee = async () => {
    setLoader(true);
    const response = await putAPI(
      `/building/SpaceId/${buildingInputs?._id}`,
      buildingInputs
    );
    if (response) {
      getBuildingSpaceId();
      setEditModal(false);
      setInputs({
        _id: "",
        areaId: "",
        buildingTypes: "",
        buildingName: "",
        buildingFloor: "",
        buildingSpaceType: "",
        companyId: companyId,
      });
    }
    setLoader(false);
  };

  const deleteBuildingType = async (_id) => {
    console.log(_id);
    setLoader(true);
    const response = await deleteAPI(`/building/SpaceId/${_id}`);
    if (response) {
      getBuildingSpaceId();
    }
    setLoader(false);
  };

  // ---------------------------------------------- Building-Floor CRUD Ends -------------------------------------------------------

  useEffect(() => {
    getBuildingSpaceId();
    getBuildingSpace();
    getBuildingFloor();
    getBuildingName();
    getBuildingType();
  }, []);
  return (
    <Grid>
      <PageTitle title={"Building Space Id"} />

      <Grid>
        {(showBothButtons ||
          checkSubActionPermission("Add", "Space Id", permissionList)) && (
          <Button
            onClick={() => setModalOpen(true)}
            variant="contained"
            style={{ backgroundColor: "#75859D", float: "right" }}
            sx={{ height: 52, mx: 2 }}
          >
            <AddCircleIcon /> &nbsp; &nbsp; Add Building Space Id
          </Button>
        )}
      </Grid>

      <Grid style={{ marginTop: "50px" }}>
        <TableContainer
          component={Paper}
          sx={{ mx: "0.8rem", width: "60%", my: "6rem" }}
        >
          {/* <Grid style={{width: 'auto', overflowX: 'scroll'}}> */}

          <Table
            sx={{ minWidth: "auto" }}
            aria-label="custom pagination table"
            className="responsive-table"
          >
            <TableHead>
              <TableRow className="table-header">
                <TableCell align="left" component="th" sx={tableHeader}>
                  Building Type
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Building Name
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Building Floor
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Building Space
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Building Space Id
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...buildingSpaceIdList]
                ?.reverse()
                .slice(page * perPage, page * perPage + perPage)
                .map((item, index) => (
                  <TableRow key={index}>
                    {/* <TableCell align="left" sx={tableData}>
                        {fullName(item)}
                        </TableCell> */}
                    <TableCell align="left" sx={tableData}>
                      {item?.buildingTypes?.title}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item?.buildingName?.title}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item?.buildingFloor?.title}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item?.buildingSpaceType?.title}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item?.areaId}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={tableData}
                      style={{
                        display: "flex",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {(showBothButtons ||
                        checkSubActionPermission(
                          "Edit",
                          "Space Id",
                          permissionList
                        )) && (
                        <Button
                          size="small"
                          className="btn-Grid"
                          variant="outlined"
                          color="info"
                          onClick={(e) => editBuildingType(e, item?._id)}
                        >
                          <EditIcon />
                        </Button>
                      )}

                      {/* Render Delete button */}
                      {(showBothButtons ||
                        checkSubActionPermission(
                          "Delete",
                          "Space Id",
                          permissionList
                        )) && (
                        <Button
                          size="small"
                          className="btn-Grid"
                          variant="outlined"
                          color="error"
                          onClick={() => deleteBuildingType(item?._id)}
                        >
                          <DeleteIcon />
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              {!buildingFloorList && <EmptyTable colSpan={9} />}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  align="right"
                  rowsPerPageOptions={perPages}
                  colSpan={9}
                  count={1}
                  rowsPerPage={perPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  // ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
          {/* </Grid> */}
        </TableContainer>
      </Grid>

      <Grid>
        <Modal open={modalOpen}>
          <Box sx={style}>
            <PageTitle title={"Add Building Space Id"} />

            <Grid
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Building Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="buildingTypes"
                  value={inputs?.buildingTypes}
                  label="Select Building Type"
                  onChange={onChange}
                >
                  {buildingTypeList?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item?._id}>
                        {item?.title}
                      </MenuItem>
                    );
                  })}
                  {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Building Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="buildingName"
                  value={inputs?.buildingName}
                  label="Select Building Name"
                  onChange={onChange}
                >
                  {buildingNameList
                    ?.filter(
                      (name) =>
                        name?.buildingTypes?._id === inputs?.buildingTypes
                    )
                    ?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?._id}>
                          {item?.title}
                        </MenuItem>
                      );
                    })}
                  {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Building Floor
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="buildingFloor"
                  value={inputs?.buildingFloor?._id}
                  label="Select Building Floor"
                  onChange={onChange}
                >
                  {buildingFloorList
                    ?.filter(
                      (floor) =>
                        floor?.buildingName?._id === inputs?.buildingName
                    )
                    ?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?._id}>
                          {item?.title}
                        </MenuItem>
                      );
                    })}
                  {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Building Space
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="buildingSpaceType"
                  value={inputs?.buildingSpaceType?._id}
                  label="Select Building Space"
                  onChange={onChange}
                >
                  {buildingSpaceList
                    ?.filter(
                      (space) =>
                        space?.buildingFloor?._id === inputs?.buildingFloor
                    )
                    ?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?._id}>
                          {item?.title}
                        </MenuItem>
                      );
                    })}
                  {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>

              <TextField
                label="Enter Building Space Id"
                value={inputs?.title}
                name="areaId"
                id="title"
                variant="outlined"
                style={{ width: "100%", marginLeft: "10px" }}
                onChange={onChange}
              />
            </Grid>

            <Grid
              style={{
                marginTop: "15px",
                width: "100%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "10px",
                marginLeft: "10px",
              }}
            >
              <Button
                variant="contained"
                onClick={onSubmit}
                style={{ float: "right" }}
                sx={{ height: 40 }}
              >
                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                Submit
              </Button>

              <Button
                onClick={() => {
                  setModalOpen(false);
                  setEditMode(false);
                }}
                variant="outlined"
                style={{ float: "right" }}
                sx={{ height: 40 }}
              >
                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                Cancel
              </Button>
            </Grid>

            <Grid></Grid>
          </Box>
        </Modal>
      </Grid>

      <Grid>
        <Modal open={editModal}>
          <Box sx={style}>
            <PageTitle title={"Update Building Space Id"} />

            <Grid
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Building Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="buildingTypes"
                  value={buildingInputs?.buildingTypes}
                  label="Select Building Type"
                  onChange={(e) =>
                    setBuildingInputs({
                      ...buildingInputs,
                      buildingTypes: e.target.value,
                    })
                  }
                >
                  {buildingTypeList?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item?._id}>
                        {item?.title}
                      </MenuItem>
                    );
                  })}
                  {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Building Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="buildingName"
                  value={buildingInputs?.buildingName}
                  label="Select Building Name"
                  onChange={(e) =>
                    setBuildingInputs({
                      ...buildingInputs,
                      buildingName: e.target.value,
                    })
                  }
                >
                  {buildingNameList
                    ?.filter(
                      (name) =>
                        name?.buildingTypes?._id ===
                        buildingInputs?.buildingTypes
                    )
                    ?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?._id}>
                          {item?.title}
                        </MenuItem>
                      );
                    })}
                  {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Building Floor
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="buildingFloor"
                  value={buildingInputs?.buildingFloor}
                  label="Select Building Floor"
                  onChange={(e) =>
                    setBuildingInputs({
                      ...buildingInputs,
                      buildingFloor: e.target.value,
                    })
                  }
                >
                  {buildingFloorList
                    ?.filter(
                      (floor) =>
                        floor?.buildingName?._id ===
                        buildingInputs?.buildingName
                    )
                    ?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?._id}>
                          {item?.title}
                        </MenuItem>
                      );
                    })}
                  {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Building Space
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="buildingSpaceType"
                  value={buildingInputs?.buildingSpaceType}
                  label="Select Building Space"
                  onChange={(e) =>
                    setBuildingInputs({
                      ...buildingInputs,
                      buildingSpaceType: e.target.value,
                    })
                  }
                >
                  {buildingSpaceList
                    ?.filter(
                      (space) =>
                        space?.buildingFloor?._id ===
                        buildingInputs?.buildingFloor
                    )
                    ?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?._id}>
                          {item?.title}
                        </MenuItem>
                      );
                    })}
                  {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>

              <TextField
                label="Enter Area"
                value={buildingInputs?.areaId}
                name="areaId"
                id="title"
                variant="outlined"
                style={{ width: "100%", marginLeft: "10px" }}
                onChange={editChange}
              />
            </Grid>

            <Grid
              style={{
                marginTop: "15px",
                width: "100%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "10px",
                marginLeft: "10px",
              }}
            >
              <Button
                variant="contained"
                onClick={onSubmit}
                style={{ float: "right" }}
                sx={{ height: 40 }}
              >
                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                Update
              </Button>

              <Button
                onClick={() => {
                  setEditModal(false);
                  setEditMode(false);
                }}
                variant="outlined"
                style={{ float: "right" }}
                sx={{ height: 40 }}
              >
                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                Cancel
              </Button>
            </Grid>

            <Grid></Grid>
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default AddBuildingSpaceId;
