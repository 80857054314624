import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import PageTitle from "../../common/PageTitle";
import FAQCard from "../../components/faq/FAQCard";

export default function SchedulerPage() {
  const segments = window.location.pathname;
  const [urlType, setUrlType] = useState('');
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    scheduleList();
    if (segments.split('/')[1] === 'company') {
      setUrlType('/company');
    }
  }, [segments]);

  const scheduleList = () => {
    // Retrieve permission list from local storage
    const permissionList = JSON.parse(localStorage.getItem('permisionlist')) || [];
    const submenuPermissions = permissionList[0]?.submenuPermissions || [];

    // Function to check if a title has permission in submenuPermissions
    const hasPermission = (title) => {
      // Show all items if permissionList is empty or submenuPermissions is empty
      if (permissionList.length === 0 || submenuPermissions.length === 0) {
        return true;
      }
      return submenuPermissions.some((permission) => permission.submenu === title);
    };

    // Build schedule array with conditional permission checks
    const arr = [];
    let url = segments.split('/')[1] === 'company' ? '/company' : '';

    if (hasPermission("Alarm Response")) {
      arr.push({
        id: 1,
        title: "Alarm Response",
        url: `${url}/scheduler/alarmResponseNavigation`
      });
    }

    if (hasPermission("Casual Shifts")) {
      arr.push({
        id: 2,
        title: "Casual Shifts",
        url: `${url}/scheduler/CasualShiftNavigation`
      });
    }

    // Set filtered schedule list based on permissions
    setSchedule(arr);
  };

  return (
    <Box sx={{ height: "inherit" }}>
      <PageTitle title="Scheduler" />
      <Box display="flex">
        <Grid
          container
          rowSpacing={7}
          columnSpacing={{ xs: 1 }}
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          mt="5%"
        >
          {schedule.map((report) => (
            <FAQCard large title={report.title} key={report.id} url={report.url} />
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
