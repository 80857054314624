import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import PageTitle from "../../common/PageTitle";
import FAQCard from "../../components/faq/FAQCard";

export default function AlarmResponseNavigation() {
  const segments = window.location.pathname;
  const [urlType, setUrlType] = useState('');
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    scheduleList();
    if (segments.split('/')[1] === 'company') {
      setUrlType('/company');
    }
  }, [segments]);

  const checkAuthority = (action) => {
    const permissionList = JSON.parse(localStorage.getItem('permisionlist'));

    if (!permissionList) return true;

    const actionPermissions = permissionList[0]?.actionPermissions || [];
   
    return actionPermissions.some((permission) => permission.action === action);
  };

  const scheduleList = () => {
    const arr = [];
    const url = segments.split('/')[1] === 'company' ? '/company' : '';

    if (checkAuthority('Create Alarm Response')) {
      arr.push({
        id: 1,
        title: "Create Alarm Response",
        url: `${url}/scheduler/create-alarm-response`,
        data: true
      });
    }

    if (checkAuthority('View Alarm Response')) {
      arr.push({
        id: 2,
        title: "View Alarm Response",
        url: `${url}/scheduler/view-alarm-response`,
        data: false
      });
    }

    setSchedule(arr);
  };

  return (
    <Box sx={{ height: "inherit" }}>
      <PageTitle title="Alarm Response" />
      <Box display="flex">
        <Grid
          container
          rowSpacing={7}
          columnSpacing={{ xs: 1 }}
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          mt="5%"
        >
          {schedule.map((report) => (
            <FAQCard large title={report.title} key={report.id} url={report.url}  />
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
