/* eslint-disable react/jsx-filename-extension */
import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SupportAgentSharpIcon from '@mui/icons-material/SupportAgentSharp';
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { Badge } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOutUser, selectAuth } from "../../features/auth/authSlices";
import { useState } from "react";
import { useEffect } from "react";
import { getAPI } from "../network";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigateTo = useNavigate();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))
  const [userType, setUserType] = useState('admin')
  const [notificationCount, setNotificationCount] = useState(0)
  const [alertCount, setAlertCount] = useState(0)

  const dispatch = useDispatch();
  const { data } = useSelector(selectAuth);
  useEffect(() => {
    let newType = localStorage.getItem('userType')
    setUserType(newType)
    getNotificationCount()
    getAlertCount()
  })

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getNotificationCount = async () => {

    let data = await getAPI('/notifications')
    if (data) {
      setNotificationCount(data.length)
    }
    else {
      setNotificationCount(0)
    }

  }
  function getFormattedDate() {
    const currentDate = new Date();

    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();

    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    return year + '-' + formattedMonth + '-' + formattedDay;
  }
  const getAlertCount = async () => {

    let data = await getAPI('/alerts')
    if (data) {
      setAlertCount(data.length)
      let currentDate = getFormattedDate();
      let filteredArray = data.filter(obj => obj.expiryDate >= currentDate);
      setAlertCount(filteredArray.length)
    }
    else {
      setAlertCount(0)
    }

  }
  const handleEmail = () => {
    const email = 'info@secuber.io';
    // const email = 'wishallmaurya@gmail.com';
    const subject = 'Support request';
    const body = `Issue title:

Issue Description:

Support Type:

      Please Select Support Type from the following- 
      Admin Support
      Invoicing Support
      Tech support
      Integration support
      App Support
      Portal Support
      Hardware Support
      User Support
      Other`;

    window.location.href = `mailto:${email}?subject=${subject}&body=${encodeURIComponent(body)}`;
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(logOutUser());
    navigateTo("/login");
  };
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Notifications">
          <IconButton color="inherit" onClick={() => {

            console.log('User Type', userType)
            if (userType === 'company') {
              navigateTo('/company/notifications')
            }
            else {
              navigateTo('/notifications')
            }
          }}>
            <Badge badgeContent={notificationCount} color="secondary">
              <IconButton >
                <NotificationsIcon />
              </IconButton>
            </Badge>
          </IconButton>
        </Tooltip>
        <Tooltip title="Alerts">
          <IconButton color="inherit" onClick={() => {

            // console.log('User Type', userType)
            if (userType === 'company') {
              navigateTo('/company/alert-information')
            }
            else {
              navigateTo('/alert-information')
            }
          }}>
            <Badge badgeContent={alertCount} color="secondary">
              <IconButton >
                <ReportProblemIcon />
              </IconButton>
            </Badge>
          </IconButton>
        </Tooltip>
        <Tooltip title="Support">
          <IconButton color="inherit" onClick={() => handleEmail()}>
            <Badge color="secondary">
              <IconButton >
                <SupportAgentSharpIcon />
              </IconButton>
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Avatar /> {`${user?.firstname} ${user?.lastname}`}
        </MenuItem>
        <Divider />
        {/* <MenuItem>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem> */}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

