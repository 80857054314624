/* eslint-disable no-unused-vars */
import { Typography } from '@mui/material';
import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
  Grid,
  FormControl,
  Skeleton,
  Button,
  Alert,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormLabel
} from "@mui/material";
import { red } from "@mui/material/colors";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TableFooter from "@mui/material/TableFooter";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PlaceIcon from '@mui/icons-material/Place';
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { FilterList } from "@mui/icons-material";
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { BASE_URL } from '../../../../../constant';
import { axiosInstance } from '../../../../../utils/axiosSetup';
import Loader from '../../../../common/Loader';
import PageTitle from '../../../../common/PageTitle';
import BasicSelector from '../../../../common/Selector';
import LocalDateSelector from '../../../../common/LocalDateSelector';
import LocalTimeSelector from '../../../../common/LocalTimeSelector';
import { checkAuthority, differenceOf2DatesInMinutes, formatDatePost, formatDateUTCtoLocal, getDateFromStringDateAndTime, tableData, tableHeader, validation } from '../../../../utils';
import { deleteAPI, getAPI, getExportAPI, patchAPI, postAPI, putAPI } from '../../../../network';
import EmptyTable from '../../../../common/EmptyTable';
import { MapContainer, Marker, Popup, TileLayer, Polyline } from 'react-leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';


export default function TimeAndAttendanceRecords() {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  // const [companyId] = useContext(CompanyContext)
  let companyId = localStorage.getItem('companyIdLS')
  if (companyId === null) {
    companyId = undefined
  }

  let siteId = window.location.pathname.split('/')[2];
  if (siteId === 'sites') {
    siteId = window.location.pathname.split('/')[3]
  }
  const [deleteId, setDeleteId] = useState('');
  const [open, setOpen] = useState(false)
  const [loader, setLoader] = useState(false)
  const [tasks, setTasks] = useState([])
  const [sites, setSites] = useState([])
  const [distance, setDistance] = useState(
    Array.from({ length: 10 }, (_, i) => ({
      label: `${(i + 1) * 50} meters`,
      value: (i + 1) * 50,
    }))
  );
  const [time, setTime] = useState(
    Array.from({ length: (240 / 15) }, (_, i) => {
      const minutes = (i + 1) * 15;
      return {
        label: `${minutes} minutes`,
        value: minutes
      };
    })
  );


  const [distanceSelected, setDistanceSelected] = useState(50)
  const [timeSelected, setTimeSelected] = useState(15)


  const [companies, setCompanies] = useState([])
  const [emailList, setEmailList] = useState([])
  const [company, setCompany] = useState('')
  const [urlF, setUrlF] = useState('')

  const [page, setPage] = useState(0);
  const [perPages, setPerPages] = useState([5, 10, 25, 50]);
  const [perPage, setPerPage] = useState(10)

  const [filter, setFilter] = useState({
    companyId: '',
    siteId: '',
    startDate: null,
    endDate: null,
    email: '',
  })

  const { handleSubmit, control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      company: "",
      site: "",
      date: "",
      checkIn: null,
      checkOut: null,
      lat: "",
      lng: "",
      purpose: "",

    },
  });

  const handleClose = () => {
    setOpenMap(false);
  };

  // const taskData = []
  // for (let j = 0; j < tasks.length; j++) {
  //   taskData.push(tasks[j].tasks)
  // }


  // const taskD = []
  // for (let i = 0; i < taskData.length; i++) {
  //   taskD.push(taskData[i][0])
  // }
  const getSiteSettings = async () => {
    const sitesSettings = await getAPI(`/sites/details/${siteId}`)
    setDistanceSelected(sitesSettings?.clockOutDistanceRange ? sitesSettings?.clockOutDistanceRange : 50)
    setTimeSelected(sitesSettings?.forceCheckOutLimit ? sitesSettings?.forceCheckOutLimit : 15)
  }
  const updateSiteSettings = async (dist, time) => {
    setLoader(true)

    setDistanceSelected(dist ? dist : distanceSelected)
    setTimeSelected(time ? time : timeSelected)
    const payload = {
      "clockOutDistanceRange": dist ? dist : distanceSelected,
      "forceCheckOutLimit": time ? time : timeSelected
    }
    await patchAPI(`/sites/${siteId}`, payload)
    setLoader(false)

  }
  useEffect(() => {
    if (companyId) {
      getVisitorList(companyId, null)
    } else {
      getVisitorList(null, null)
    }
    getSites()
    getCompanies()
    getSiteSettings()
  }, []);


  const userType = localStorage.getItem('userType')


  const handleChangeRowsPerPage = (event) => {
    // console.log(event.target.value)
    setPerPage(event.target.value)
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  // {console.log( checkAuthority('ADD_TASK') ? 'TRUE ---------' : "FALSE --------")}
  const getTasks = async (sId = null, sDate = null, eDate = null) => {

    sId = sId !== null ? sId : filter.siteId;
    eDate = eDate !== null ? eDate : filter.endDate;
    sDate = sDate !== null ? sDate : filter.startDate;

    var url = ''
    var start = true;
    if (sId !== '' && sId !== null) {
      url += (start ? '?' : '&') + `siteId=${sId?.id ? sId?.id : sId}`
      start = false;
    }
    if (eDate !== '' && eDate !== null) {
      url += (start ? '?' : '&') + `taskDate=${formatDatePost(eDate)}`
      start = false;
    }
    if (sDate !== '' && sDate !== null) {
      url += (start ? '?' : '&') + `startDate=${formatDatePost(sDate)}`
      start = false;
    }
    setLoader(true)
    if (companyId) {
      let data = await getAPI(`/timeAndAttendance?companyId=${companyId}` + url)
      if (data) {
        setTasks(data)
      }
    }
    else {
      let data = await getAPI(`/timeAndAttendance` + url)
      if (data) {
        setTasks(data)
      }
    }

    setLoader(false)
  }
  const getVisitorList = async (cId = null, sId = null, sDate = null, eDate = null, email = null) => {

    cId = cId !== null ? cId : filter.companyId;
    sId = sId !== null ? sId : filter.siteId;
    eDate = eDate !== null ? eDate : filter.endDate;
    sDate = sDate !== null ? sDate : filter.startDate;
    email = email !== null ? email : filter.email;

    var url = ''
    var start = true;
    if (cId !== '' && cId !== null) {
      url += (start ? '?' : '&') + `company=${cId}`
      start = false;
    }
    if (sId !== '' && sId !== null) {
      url += (start ? '?' : '&') + `siteId=${sId?.id ? sId?.id : sId}`
      start = false;
    }
    if (eDate !== '' && eDate !== null) {
      url += (start ? '?' : '&') + `endDate=${formatDatePost(eDate)}`
      start = false;
    }
    if (sDate !== '' && sDate !== null) {
      url += (start ? '?' : '&') + `startDate=${formatDatePost(sDate)}`
      start = false;
    }
    if (email !== '' && email !== null) {
      url += (start ? '?' : '&') + `user=${email}`
      start = false;
    }
    setLoader(true)
    setUrlF(url)
    let data = await getAPI(`/timeAndAttendance` + url)
    if (data) {
      setTasks(data)
    }

    setLoader(false)
  }

  const getSites = async (sId) => {
    setLoader(true)
    let data = await getAPI(`/sites?companyId=${sId ? sId : companyId}`)
    if (data) {
      var arr = []
      for (var i = 0; i < data.length; i++) {
        var obj = data[i]
        obj['label'] = obj.name
        obj['value'] = obj._id
        arr.push(obj)
      }
      setSites(arr)
    }
    setLoader(false)
  }

  const getCompanies = async () => {
    setLoader(true)
    const data = await getAPI(`/companies?companyId=${companyId}`);
    if (data) {
      var arr = []
      for (var i = 0; i < data.length; i++) {
        var obj = data[i]
        obj['label'] = obj?.name
        obj['value'] = obj?.id
        arr.push(obj)
      }
      setCompanies(arr)
    }
    setLoader(false)
  }

  const deleteClick = (id) => {
    setDeleteId(id);
    setOpen(true)
  }

  const handleDelete = async (siteId = null) => {

    setLoader(true);
    let empty = await deleteAPI(`/tasks/${deleteId}`);
    if (empty) {
      setOpen(false);
      if (companyId) {
        getVisitorList(companyId)
      } else {
        getVisitorList()
      }

    }
    setLoader(false);
  }
  const AssessExp = async (id) => {
    setLoader(true)
    let data = await getExportAPI(`${BASE_URL}/visitor_management?visitorId=${id}`, `Time and Attendance Records`)
    setLoader(false)
  }
  const AssessExp2 = async (id) => {
    setLoader(true)
    let data = await getExportAPI(`${BASE_URL}/visitor/book/export${urlF}`, `Time and Attendance Records`)
    setLoader(false)
  }

  const [openMap, setOpenMap] = useState(false)
  const [markers, setMarkers] = useState([]); // Custom markers
  const [siteMarkers, setSiteMarkers] = useState([]); // Custom markers
  const [position1, setPosition1] = useState([-33.93493568576685, 151.06907095273533]);
  const [position2, setPosition2] = useState([-33.935, 151.07]);
  const handleMapData = (task, status) => {
    if (status === 'in') {
      setMarkers([
        {
          "coordinates": [
            task.clockInLocationLat,
            task.clockInLocationLng
          ],
          "name": task.assignedUser.firstname + ' ' + task.assignedUser.lastname,
          "email": task.assignedUser.email
        }
      ]);
      setPosition1([task.clockInLocationLat, task.clockInLocationLng])
    } else {
      setMarkers([
        {
          "coordinates": [
            task.clockOutLocationLat,
            task.clockOutLocationLng
          ],
          "name": task.assignedUser.firstname + ' ' + task.assignedUser.lastname,
          "email": task.assignedUser.email
        }
      ]);
      setPosition1([task.clockOutLocationLat, task.clockOutLocationLng])
    }


    setSiteMarkers([
      {
        "coordinates": [
          task.siteId.latitude,
          task.siteId.longitude
        ],
        "name": task.siteId.name,
        "email": task.siteId.address
      }
    ]);


    setPosition2([task.siteId.latitude, task.siteId.longitude])

    setOpenMap(true);

  }


  return (
    <Box>
      <Loader loader={loader} />
      <PageTitle title="Time and Attendance Records" />

      <Box ml={2}>
        <FormControl
          sx={{
            width: "100%",
          }}
        >



          <Grid sx={{ my: "1rem" }} spacing={3} width={"100%"} display="flex" gap="10px">


            {
              <>
                <Grid item xs={6} md={6} lg={2} width="25%">

                  <BasicSelector
                    disableAll={true}
                    options={sites}
                    selectorHight={"61px"}
                    style={{ display: "flex", justifyContent: "center", items: "center" }}
                    value={filter.siteId}
                    name={"Select Site"}
                    selectorWidth={"100%"}
                    onChange={(e) => {

                      setFilter({
                        ...filter,
                        siteId: e.target.value
                      })
                      getVisitorList(null, e.target.value)
                    }}
                  />
                </Grid>
                {/* <Grid item xs={6} md={6} lg={2}>

                  <BasicSelector
                    disableAll={true}
                    options={emailList}
                    selectorHight={"53px"}
                    value={filter.email}
                    name={"Visitor Email"}
                    selectorWidth={"100%"}
                    onChange={(e) => {

                      setFilter({
                        ...filter,
                        email: e.target.value
                      })
                      getVisitorList(null, null, null, null, e.target.value)
                    }}
                  />
                </Grid> */}
                <Grid item xs={6} md={6} lg={3}>
                  <LocalDateSelector
                    title="Start Date "
                    value={filter.startDate}

                    onChange={(date) => {
                      setFilter({
                        ...filter,
                        startDate: date
                      })
                      getVisitorList(null, null, date, null)
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={3}>
                  <LocalDateSelector
                    title="End Date "
                    value={filter.endDate}

                    onChange={(date) => {
                      setFilter({
                        ...filter,
                        endDate: date
                      })
                      getVisitorList(null, null, null, date)
                    }}
                  />
                </Grid>


              </>
            }

          </Grid>
          <Grid sx={{ my: "1rem" }} spacing={3} width={"100%"} display="flex" gap="10px">
            <Grid item xs={6} md={6} lg={2} width="25%">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Set Clock in Distance Range</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={distanceSelected}
                  label="Set Clock in Distance Range"
                  onChange={(e) => updateSiteSettings(e.target.value)}
                >
                  {
                    distance.map((i, index) =>
                      <MenuItem value={i.value} key={index}>{i?.label}</MenuItem>
                    )
                  }

                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6} lg={2} width="25%">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Set Force Check Out Limit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={timeSelected}
                  label="Set Force Check Out Limit"
                  onChange={(e) => updateSiteSettings(null, e.target.value)}
                >
                  {
                    time.map((i, index) =>
                      <MenuItem value={i.value} key={index}>{i?.label}</MenuItem>
                    )
                  }

                </Select>
              </FormControl>
            </Grid>
          </Grid>

        </FormControl>
        {
          checkAuthority('VIEW_TASKS') &&
          <Box display="flex" sx={{ my: "3rem" }}>
            <TableContainer component={Paper}>

              <Table
                sx={{ minWidth: "auto" }}
                aria-label="custom pagination table"
                className="responsive-table"
              >
                <TableHead>
                  <TableRow className="table-header">
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Date
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Site
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Shift Type
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Resources Name
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Shift Code
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Start Time
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      End Time
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Clocked In
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Late Clocked In
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Missed Clock In
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Incorrect Location Clock In
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Incorrect Location Clock Out
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Clocked Out
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Auto Clock Out
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>


                  {tasks.slice(page * perPage, page * perPage + perPage).map((task, index) => (
                    <TableRow key={index}>
                      <TableCell align="left" sx={tableData}>
                        {task?.shiftDate?.split("T")[0]}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.siteId?.name}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.shiftType === 'Fixed' ? "Roster" : "Casual"}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {`${task?.assignedUser?.firstname} ${task?.assignedUser?.lastname}`}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.shiftCode}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.startTime}
                        {/* {formatDateUTCtoLocal(getDateFromStringDateAndTime(task?.shiftDate?.split("T")[0], task?.startTime))} */}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.endTime}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {formatDateUTCtoLocal(task?.clockInTime)}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {differenceOf2DatesInMinutes(task?.clockInTime, getDateFromStringDateAndTime(task?.shiftDate?.split("T")[0], task?.startTime)) > 0 ? `${differenceOf2DatesInMinutes(task?.clockInTime, getDateFromStringDateAndTime(task?.shiftDate?.split("T")[0], task?.startTime))} minutes late` : `${+differenceOf2DatesInMinutes(task?.clockInTime, getDateFromStringDateAndTime(task?.shiftDate?.split("T")[0], task?.startTime))} minutes early`}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>

                      </TableCell>

                      <TableCell align="left" sx={tableData}>

                        {task?.isMatch === true ?
                          <Button style={{ color: 'white', backgroundColor: 'green' }}> Matched</Button> :
                          <Button style={{ color: 'white', backgroundColor: 'red' }} onClick={() => handleMapData(task, "in")}>Mismatch</Button>
                        }
                        <i>
                          {task?.isMatch ? null : `${task?.distance} km away`}
                        </i>
                      </TableCell>
                      {
                        task?.autoClockOut ? <TableCell>Not available</TableCell> :
                          <TableCell align="left" sx={tableData}>
                            {task?.isMatch2 === true ?
                              <Button style={{ color: 'white', backgroundColor: 'green' }}> Matched</Button> :
                              <Button style={{ color: 'white', backgroundColor: 'red' }} onClick={() => handleMapData(task, "out")}>Mismatch</Button>
                            }
                            <i>
                              {task?.isMatch2 ? null : `${task?.distance2} km away`}
                            </i>
                          </TableCell>
                      }

                      <TableCell align="left" sx={tableData}>
                        {formatDateUTCtoLocal(task?.clockOutTime)}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.autoClockOut ? 'Yes' : "No"}
                      </TableCell>



                    </TableRow>

                  ))}

                  {
                    tasks.length === 0 &&
                    <EmptyTable colSpan={6} />
                  }
                </TableBody>
                <TableFooter>
                  <TableRow >

                    <TablePagination
                      align="right"
                      rowsPerPageOptions={perPages}
                      colSpan={7}
                      count={tasks.length}
                      rowsPerPage={perPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    // ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
        }

      </Box>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true}>
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>Delete Task</DialogTitle>

        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { my: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >

            <h3 style={{ textAlign: 'left', fontWeight: 'bold' }}>Do you want's to delete this task</h3>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={handleDelete} variant="contained" color="error">Delete</Button>
          <Button onClick={() => setOpen(false)} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openMap} onClose={handleClose}>
        {/* <DialogTitle align="center" >Visitor Location</DialogTitle> */}

        <MapContainer center={position2} zoom={22} style={{ height: '80vh', width: '120vh', marginButton: '10px' }}>
          <TileLayer
            // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          />
          {markers.map((marker, index) => (
            <Marker key={index} position={marker?.coordinates}>
              <Popup>{marker.name}
                <br />
                {marker?.email}
              </Popup>
            </Marker>
          ))}
          {siteMarkers.map((marker, index) => (
            <Marker key={index} position={marker?.coordinates}>
              <Popup>{marker.name}
                <br />
                {marker?.email}
              </Popup>
            </Marker>
          ))}
          {/* <Polyline positions={[siteMarkers.coordinates, markers.coordinates]} color="red" /> */}
          <Polyline positions={[position1, position2]} color="red" />

        </MapContainer>

      </Dialog>
    </Box>
  );
}
