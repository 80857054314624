import React, { useEffect, useState, useContext } from "react";

import { Box, Grid, TextareaAutosize, Typography, Button, InputLabel } from "@mui/material";
import PageTitle from "../../common/PageTitle";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import Loader from '../../common/Loader'
import { getAPI, postAPI, patchAPI, deleteAPI } from '../../network'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from "@mui/x-date-pickers";
// import { duration } from "../../utils/data";
// import Header from "../../layout/Header";
// import { CompanyContext } from '../../../context';
import AccountMenu from '../../layout/AccountMenu'

import "./style.css";
import { formatDate, formatDatePost, getSetTime, timeFormat, getTimeFromTimestamp2, validation, fullName, checkAuthority, tableHeader, tableData, durationCalc } from "../../utils";
import LocalDateSelector from "../../common/LocalDateSelector";

export default function NotificationPage() {
    const [companyF, setCompanyF] = useState("")
    const [siteF, setSiteF] = useState("")
    const [vendorF, setVendorF] = useState("")
    const [userF, setUserF] = useState("")
    const [startDateF, setStartDateF] = useState("")
    const [endDateF, setEndDateF] = useState("")

    // const [companyId] = useContext(CompanyContext)
    let companyId = localStorage.getItem('companyIdLS')
    if (companyId === null) {
        companyId = undefined
    }
    const [selectedStartTime, setSelectedStartTime] = useState("");
    const [id, setId] = useState('');
    const [loader, setLoader] = useState(false);
    const [notifications, setNotifications] = useState([])
    const [action, setAction] = useState('add');
    const [open, setOpen] = useState(false)
    const [endBy, setEndBy] = useState(false);
    const [endAfter, setendAfter] = useState(false);
    const [noend, setNoend] = useState(false);
    const [show, setShow] = useState(false)
    const [sites, setSites] = useState([])
    const [company, setCompany] = useState([])
    const [vendor, setVendor] = useState([])
    const [recurrences, setRecurrences] = useState([])
    const [users, setUsers] = useState([])
    const [roles, setRoles] = useState([])
    const [client, setClient] = useState("");
    const [cmpId, setCmpId] = useState("");
    const [selectedStatus, setSelectedStatus] = useState({
        shiftStatus: "",
    });
    const [notification, setNotification] = useState({
        notes: '',
        startTime: null,
        endTime: null,
        startDate: null,
        endDate: null,
        shiftRecurrence: null,
        siteId: '',
        vendor: '',
        role: '',
        userId: '',
        company: '',
        duration: '',
        endType: '',
        recurrenceDay: [],
        durationError: false,
        notesError: false,
        startTimeError: false,
        endTimeError: false,
        startDateError: false,
        endDateError: false,
        shiftRecurrenceError: false,
        siteIdError: false,
        userIdError: false,
        companyIdError: false,
        vendorIdError: false,
    })
    const checkListWeek = [{
        "name": "Mon",
        val: 0
    },
    {
        "name": "Tue",
        val: 1
    },
    {
        "name": "Wed",
        val: 2
    },
    {
        "name": "Thus",
        val: 3
    },
    {
        "name": "Fri",
        val: 4
    },
    {
        "name": "Sat",
        val: 5
    },
    {
        "name": "Sun",
        value: 6
    }];
    const [checked, setChecked] = useState([]);


    useEffect(() => {
        // console.log(notification, 'notification')
        getNotifications()
        getSites()
        getRecurrences()
        getUsers()
        getCompany()
        getVendor()
        getRoles()
    }, [])

    const clearAll = () => {
        setNotification({
            notes: '',
            startTime: null,
            endTime: null,
            startDate: null,
            endDate: null,
            shiftRecurrence: null,
            siteId: '',
            userId: '',
            company: '',
            duration: '',
            endType: '',
            recurrenceDay: [],
            checked: [],
            durationError: false,
            notesError: false,
            startTimeError: false,
            endTimeError: false,
            startDateError: false,
            endDateError: false,
            shiftRecurrenceError: false,
            siteIdError: false,
            userIdError: false,
            companyIdError: false,
            vendorIdError: false,

        })

    }

    const getNotifications = async () => {
        setLoader(true);
        if (companyId) {
            var url = `/notifications?company=${companyId}`
        } else {
            var url = `/notifications`
        }
        let data = await getAPI(url)
        if (data) {
            setNotifications(data)
        }
        setLoader(false);
    }
    const getNotificationsF = async (cId = null, v, siteId = null, u, sDate = null, eDate = null) => {
        setLoader(true);
        const queryParams = {}
        if (companyId) queryParams.company = companyId
        if (siteId) queryParams.siteId = siteId
        if (!queryParams.siteId) queryParams.siteId = siteF
        if (!queryParams.siteId) delete queryParams["siteId"];
        if (sDate) queryParams.startDate = formatDatePost(sDate)
        if (!queryParams.startDate) queryParams.startDate = formatDatePost(startDateF)
        if (!queryParams.startDate) delete queryParams["startDate"];
        if (eDate) queryParams.endDate = formatDatePost(eDate)
        if (!queryParams.endDate) queryParams.endDate = formatDatePost(endDateF)
        if (!queryParams.endDate) delete queryParams["endDate"];



        const queryString = new URLSearchParams(queryParams).toString();

        const res = await getAPI(`/notifications?${queryString}`);
        setNotifications(res);

        setLoader(false);

    }


    const getCompany = async () => {
        setLoader(true);
        let data = await getAPI(`/companies?companyId=${companyId}`)
        if (data) {
            setCompany(data)
        }
        setLoader(false);
        getSites()
    }
    const getVendor = async (cId) => {
        setLoader(true);
        if (cId) {
            let data = await getAPI(`/vendors?companyId=${cId}`)
            if (data) {
                setVendor(data)
            }
        } else {
            let data = await getAPI(`/vendors`)
            if (data) {
                setVendor(data)
            }
        }


        setLoader(false);
    }

    const getSites = async (cIdd) => {
        if (cIdd) {
            setLoader(true)
            let data = await getAPI(`/sites?companyId=${cIdd}`);
            if (data) {
                setSites(data)
            }
            setLoader(false)
        } else {
            setLoader(true)
            let data = await getAPI(`/sites?companyId=${companyId}`);
            if (data) {
                setSites(data)
            }
            setLoader(false)
        }

    }
    const getRoles = async (sId) => {
        if (sId) {
            setLoader(true)
            let data = await getAPI(`/roles?siteId=${sId}`);
            if (data) {
                setRoles(data)
                console.log(data, 1122)
            }
            setLoader(false)
        } else {
            setLoader(true)
            let data = await getAPI(`/roles`);
            if (data) {
                setRoles(data)
            }
            setLoader(false)
        }

    }
    // const getUsers = async () => {
    //   setLoader(true);
    //   let data = await getAPI('/users')
    //   if (data) {
    //     setUsers(data)
    //   }
    //   setLoader(false);
    // }
    const getUsers = async (idd, cidd) => {
        if (cidd) {
            setLoader(true)
            let data = await getAPI(`/company/sites/team?companyId=${cidd}`);
            if (data) {
                let emails = data.map((item) => ({
                    id: item._id ? item._id : item.id,
                    label: fullName(item) + `(${item.email})`,
                    value: item.email
                }))
                setUsers(emails)

            }
            console.log(data, 1122)
            setLoader(false)
        }
        if (idd) {
            setLoader(true)
            let data = await getAPI(`/sites-user/${idd}`);
            if (data) {
                let emails = data.map((item) => ({
                    id: item._id ? item._id : item.id,
                    label: fullName(item) + `(${item.email})`,
                    value: item.email
                }))
                setUsers(emails)
            }
            setLoader(false)
        }

        if (!idd && !cidd) {
            setLoader(true);
            let data = await getAPI(`/users?companyId=${companyId}`);
            if (data) {
                let emails = data.map((item) => ({
                    id: item._id ? item._id : item.id,
                    label: fullName(item) + `(${item.email})`,
                    value: item.email
                }))
                setUsers(emails)
            }
            setLoader(false);
        }

    }

    // console.log(sites);

    const getRecurrences = async () => {
        setLoader(true)
        let data = await getAPI('/recurrence');
        if (data) {
            var arr = [];
            for (var i = 0; i < data.length; i++) {
                arr.push({ id: data[i].id, name: data[i].name, checked: false })
            }
            setRecurrences(arr)
        }
        setLoader(false)
    }

    const changeStartTime = (event) => {
        setSelectedStartTime(event.target.value);
    };

    const editClick = (id) => {
        setId(id);
        setAction('edit');
        let note = notifications.filter((item) => item?._id === id)[0]
        console.log("NOTE_______", note)
        setNotification(prevState => ({
            ...prevState,
            notes: note.notes,
            startTime: note?.startTime,
            endTime: note?.endTime,
            startDate: new Date(note?.startDate),
            endDate: new Date(note?.endDate),
            endType: note?.endType,
            shiftRecurrence: note?.shiftRecurrence,
            recurrenceDay: note?.recurrenceDay,
            siteId: note.siteId?._id,
            vendor: note?.vendor,
            userId: note.userId?._id,
            company: note.company._id,
        }))

        setOpen(true)
    }

    const deleteClick = (id) => {
        setId(id);
        setShow(true)
    }

    const handleShowClose = () => {
        setShow(false)
    }
    const onChangeDuration = (event) => {
        setNotification(prevState => ({
            ...prevState,
            duration: event.target.value,
        }))
    }


    const allClear = () => {
        setNotification(prevState => ({
            ...prevState,
            notes: '',
            startTime: null,
            endTime: null,
            startDate: null,
            endDate: null,
            shiftRecurrence: '',
            siteId: '',
            vendor: '',
            userId: '',
            company: '',
            endType: '',
            recurrenceDay: [],
            setChecked: [],
            duration: null,
            durationError: false,
            notesError: false,
            startTimeError: false,
            endTimeError: false,
            startDateError: false,
            endDateError: false,
            shiftRecurrenceError: false,
            siteIdError: false,
            userIdError: false,
            companyIdError: false,
            vendorIdError: false

        }))
    }

    const handleClose = () => {
        setOpen(false)
        setNotification(prevState => ({
            ...prevState,
            notes: '',
            startTime: null,
            endTime: null,
            startDate: null,
            endDate: null,
            shiftRecurrence: '',
            siteId: '',
            vendor: '',
            userId: '',
            company: '',
            endType: '',
            duration: null,
            durationError: false,
            notesError: false,
            startTimeError: false,
            endTimeError: false,
            startDateError: false,
            endDateError: false,
            shiftRecurrenceError: false,
            siteIdError: false,
            userIdError: false,
            companyIdError: false
        }))
    }

    const handleSubmit = async (action) => {

        setNotification(prevState => ({
            ...prevState,
            notesError: false,
            startTimeError: false,
            endTimeError: false,
            startDateError: false,
            endTimeError: false,
            shiftRecurrenceError: false,
            recurranceError: false,
            siteIdError: false,
            durationError: false,
            userIdError: false,
            endTypeError: false,
        }))



        // if (validation('empty', 'Site', notification.siteId)) {
        //   setNotification(prevState => ({
        //     ...prevState,
        //     siteIdError: true
        //   }))
        //   return;
        // }
        if (validation('empty', 'Company', notification.company)) {
            setNotification(prevState => ({
                ...prevState,
                CompanyIdError: true
            }))
            return;
        }

        // if (validation('empty', 'User', notification.userId)) {
        //   setNotification(prevState => ({
        //     ...prevState,
        //     userIdError: false
        //   }))
        //   return;
        // }
        if (validation('long', 'Notification', notification.notes)) {
            setNotification(prevState => ({
                ...prevState,
                notesError: true
            }))
            return;
        }

        else if (validation('time', 'Start Time', notification.startTime)) {
            setNotification(prevState => ({
                ...prevState,
                startTime: true
            }))
            return;
        }
        else if (validation('time', 'End Time', notification.endTime)) {
            setNotification(prevState => ({
                ...prevState,
                endTime: true
            }))
            return;
        }
        else if (validation('date', 'Start Date', notification.startDate)) {
            setNotification(prevState => ({
                ...prevState,
                startDate: true
            }))
            return;
        }
        else if (validation('date', 'End Date', notification.endDate)) {
            setNotification(prevState => ({
                ...prevState,
                endDate: true
            }))
            return;
        }
        else if (validation('empty', 'RecurranceDay', checked)) {
            setNotification(prevState => ({
                ...prevState,
                recurrenceDay: true
            }))
            return;
        }
        // else if(validation('time', 'Shift Recurrence',notification.shiftRecurrence) ){
        //   setNotification(prevState => ({
        //     ...prevState,
        //     shiftRecurrence : true
        //   }))
        //   return;
        // }

        let checkedval = []
        if (checked.length > 0) {
            for (let i = 0; i < checked.length; i++) {
                checkedval.push(parseInt(checked[i]))
            }
        } else {
            for (let i = 0; i <= 6; i++) {
                checkedval.push(parseInt(i))
            }
        }

        // console.log("checked value#####################",checkedval)


        let payload = {
            notification: notification.notes,
            notes: notification.notes,
            startTime: notification.startTime,
            endTime: notification.endTime,
            startDate: formatDatePost(notification.startDate),
            endDate: formatDatePost(notification.endDate),
            shiftRecurrence: "Weekly",
            siteId: notification.siteId ? notification.siteId : null,
            role: notification.role ? notification.role : null,
            vendor: notification.vendor ? notification.vendor : null,
            duration: notification.duration,
            userId: notification.userId ? notification.userId : null,
            company: notification.company,
            endType: notification.endType,
            recurrenceDay: checkedval
        }

        // console.log("------PAYLOAD-----", payload)
        if (action === 'add') {
            setLoader(true)
            let data = await postAPI('/notifications', payload)
            if (data) {
                setOpen(false)
                getNotifications()
                setNotification(prevState => ({
                    ...prevState,
                    notes: '',
                    startTime: null,
                    endTime: null,
                    startDate: null,
                    endDate: null,
                    shiftRecurrence: '',
                    siteId: '',
                    vendor: '',
                    userId: '',
                    company: '',
                    duration: '',
                    endType: '',
                    recurrenceDay: [],
                    checked: [],
                    durationError: false,
                    notesError: false,
                    startTimeError: false,
                    endTimeError: false,
                    startDateError: false,
                    endDateError: false,
                    shiftRecurrenceError: false,
                    siteIdError: false,
                    companyIdError: false,
                    vendorIdError: false,
                }))
            }
            setLoader(false)
            clearAll()
        }
        else {
            setLoader(true)
            let data = await patchAPI(`/notifications/${id}`, payload)
            if (data) {
                setOpen(false)
                getNotifications()
                setNotification(prevState => ({
                    ...prevState,
                    notes: '',
                    startTime: null,
                    endTime: null,
                    startDate: null,
                    endDate: null,
                    shiftRecurrence: '',
                    siteId: '',
                    vendor: '',
                    userId: '',
                    company: '',
                    duration: '',
                    endType: '',
                    recurrenceDay: [],
                    durationError: false,
                    notesError: false,
                    startTimeError: false,
                    endTimeError: false,
                    startDateError: false,
                    endDateError: false,
                    shiftRecurrenceError: false,
                    siteIdError: false,
                    companyIdError: false,
                    vendorIdError: false,
                }))
            }
            setLoader(false)
        }
        clearAll()


    }

    const handleDelete = async () => {
        setLoader(true)

        let data = await deleteAPI(`/notifications/${id}`);
        if (data) {
            getNotifications()
            setShow(false)
            AccountMenu()
        }
        setLoader(false)

    }

    const handleRecurrenceChange = (event) => {
        setNotification(prevState => ({
            ...prevState,
            shiftRecurrence: event.target.value,
        }))
    }



    const handleChange = (event) => {
        setNotification(prevState => ({
            ...prevState,
            siteId: event.target.value,
        }))
        getUsers(event.target.value, null)
        getRoles(event.target.value)
    };
    const handleCompChange = (event) => {
        setNotification(prevState => ({
            ...prevState,
            company: event.target.value,
        }))
        setCmpId(event.target.value)
        getVendor(event.target.value)
        getSites(event.target.value)
        getUsers(null, event.target.value)

    };
    const handleVendorChange = (event) => {
        setNotification(prevState => ({
            ...prevState,
            vendor: event.target.value,
        }))
    };
    const handleUserChange = (event) => {
        setNotification(prevState => ({
            ...prevState,
            userId: event.target.value,
        }))
    };
    const handleRoleChange = (event) => {
        setNotification(prevState => ({
            ...prevState,
            role: event.target.value,
        }))
    };
    const handleCompanyChangeF = (event) => {
        setCompanyF(event.target.value)
        getSites(event.target.value)
        setSiteF('')
        getNotificationsF(event.target.value)
    };
    const handleVendorChangeF = (event) => {
        setVendorF(event.target.value)
        getNotificationsF(null, event.target.value, null, null)
    };
    const handleSiteChangeF = (event) => {
        setSiteF(event.target.value)
        getNotificationsF(null, null, event.target.value, null)
    };
    const handleStartDateChangeF = (date) => {
        setStartDateF(date)
        getNotificationsF(null, null, null, null, date)
    };
    const handleEndDateChangeF = (date) => {
        setEndDateF(date)
        getNotificationsF(null, null, null, null, null, date)
    };
    const handleUserChangeF = (event) => {
        setUserF(event.target.value)
        getNotificationsF(null, null, null, event.target.value)
    };

    const setChoice = (id) => {
        var data = recurrences.map((item) => {
            if (item.id === id) {
                return { id: item.id, name: item.name, checked: true }
            }
            else {
                return { id: item.id, name: item.name, checked: false }
            }
        })
        setNotification(prevState => ({
            ...prevState,
            shiftRecurrence: id,
        }))

        setRecurrences(data)
    }
    const endByset = (value) => {
        // console.log("ENDBYE selected", value)
        setNotification(prevState => ({
            ...prevState,
            endType: value,
        }))

    }
    const setEndByy = () => {
        setEndBy(current1 => !current1);
        setNoend(false)
        setendAfter(false)
    }
    const setNoendd = () => {
        setNoend(current3 => !current3);
        setEndBy(false)
        setendAfter(false)
    }
    const setendAfterr = () => {
        setendAfter(current2 => !current2);
        setEndBy(false)
        setNoend(false)

    }
    const findDuration = (fromDate = null, toDate = null) => {
        let newFromDate = fromDate !== null ? fromDate : notification.startTime;
        let newToDate = toDate !== null ? toDate : notification.endTime;

        if (newFromDate !== null && newToDate !== null) {
            return durationCalc(newFromDate, newToDate);
            // let duration = durationCalc(newFromDate, newToDate)
            // setNotification({
            //   ...notification,
            //   duration : duration
            // })
        }
        else {
            return null;
        }

    }


    const onStartDateChange = (data) => {

        setNotification({
            ...notification,
            startTime: data,
            duration: findDuration(data, null)
        })

        // findDuration(data, null)
    }



    const onEndDateChange = (data) => {

        setNotification({
            ...notification,
            endTime: data,
            duration: findDuration(data, null)
        })

        // findDuration(null,data)
    }

    // Return classes based on whether item is checked
    var isChecked = (item) =>
        checked.includes(item) ? "checked-item" : "not-checked-item";

    const onChange = (event) => {
        if (event.target.name === "client") {
            setClient(event.target.value);
        } else
            setSelectedStatus({
                [event.target.name]: event.target.value,
                ...selectedStatus,
            });
    };

    const handleCheck = (event) => {
        var updatedList = [...checked];
        // console.log(event.target.checked, "event.target.checked")
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
    };

    const [permissionList, setPermissionList] = useState([]);

    // Load the permission list from localStorage
    useEffect(() => {
      const permissions = JSON.parse(localStorage.getItem("permisionlist")) || [];
      setPermissionList(permissions);
    }, []);
  
    const actionPermissions = permissionList[0]?.actionPermissions || [];
  
    // Check if 'Delete' and 'Edit' subActions exist
    
    const canDelete = actionPermissions.some(
      (permission) => permission.action === "Delete"
    );
    const canEdit = actionPermissions.some(
      (permission) => permission.action === "Edit"
    );
  
  
    // If actionPermissions is empty, show both buttons
    const showBothButtons = actionPermissions.length === 0;

    return (
        <Box sx={{ height: "inherit" }}>
            <Loader loader={loader} />
            <PageTitle title="View Notification" />


            <Grid container className="grid-row">


                <Grid item xs={3} sx={{ my: 2 }}>
                    <FormControl sx={{ width: "100%" }}  >
                        <InputLabel id="site-label">Site</InputLabel>
                        <Select
                            labelId="site-label"
                            id="site-select"
                            value={siteF}
                            label="Site"
                            onChange={handleSiteChangeF}
                        >
                            {
                                sites.map((item, index) => (
                                    <MenuItem value={item?._id} key={index}>{item?.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={3} sx={{ my: 2, width: "100%" }}>
                    <LocalDateSelector
                        title="Start Date "
                        value={startDateF}
                        onChange={handleStartDateChangeF}

                    />
                </Grid>
                <Grid item xs={3} sx={{ my: 2, width: "100%" }}>
                    <LocalDateSelector
                        title="End Date "
                        value={endDateF}
                        onChange={handleEndDateChangeF}
                    />
                </Grid>

            </Grid>
            {
                checkAuthority('VIEW_NOTIFICATIONS') &&
                <Box display="flex" sx={{ my: "3rem" }}>
                    <TableContainer component={Paper} sx={{ mx: "0.8rem", mb: "2rem" }}>
                        <div style={{ width: "auto", overflowX: "scroll" }}>
                            <Table
                                sx={{ minWidth: "auto" }}
                                aria-label="custom pagination table"
                                className="responsive-table"
                            >
                                <TableHead>
                                    <TableRow className="table-header">
                                        <TableCell align="center" component="th" sx={tableHeader}>
                                            Notification
                                        </TableCell>
                                        <TableCell align="center" component="th" sx={tableHeader}>
                                            Site
                                        </TableCell>
                                        <TableCell align="center" component="th" sx={tableHeader}>
                                            Start Date
                                        </TableCell>
                                        <TableCell align="center" component="th" sx={tableHeader}>
                                            End Date
                                        </TableCell>
                                        <TableCell align="center" component="th" sx={tableHeader}>
                                            Start Time
                                        </TableCell>
                                        <TableCell align="center" component="th" sx={tableHeader}>
                                            End Time
                                        </TableCell>
                                        <TableCell align="left" component="th" sx={tableHeader}>
                                            Site Notes
                                        </TableCell>
                                        <TableCell align="center" component="th" sx={tableHeader}>
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        notifications.length === 0 || !notifications ?
                                            'No data, Try to change filter'
                                            :
                                            notifications?.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="center" sx={tableData}>
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell align="center" sx={tableData}>
                                                        {item?.siteId?.name}
                                                    </TableCell>
                                                    <TableCell align="center" sx={tableData}>
                                                        {formatDate(item?.startDate)}
                                                    </TableCell>
                                                    <TableCell align="center" sx={tableData}>
                                                        {formatDate(item?.endDate)}
                                                    </TableCell>
                                                    <TableCell align="center" sx={tableData}>
                                                        {getTimeFromTimestamp2(item?.startTime)}
                                                    </TableCell>
                                                    <TableCell align="center" sx={tableData}>
                                                        {getTimeFromTimestamp2(item?.endTime)}
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        {item?.notes}
                                                    </TableCell>
                                                    <TableCell align="center" sx={tableData}>
                                                        {
                                                           (showBothButtons || canEdit) && checkAuthority('EDIT_NOTIFICATION') &&
                                                            <Button className="btn-div" color="primary" variant="outlined" sx={{ mx: 2 }} onClick={() => editClick(item?._id)}>
                                                                <EditIcon className="" />
                                                            </Button>
                                                        }
                                                        {
                                                          (showBothButtons || canDelete) &&  checkAuthority('DELETE_NOTIFICATION') &&
                                                            <Button className="btn-div" color="error" variant="outlined" onClick={() => deleteClick(item?._id)}>
                                                                <DeleteIcon className="" />
                                                            </Button>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                </TableBody>
                            </Table>
                        </div>
                    </TableContainer>
                </Box>
            }


            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle sx={{ textAlign: 'center', mb: 8 }}>Edit Notification</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="site-label">Site</InputLabel>
                        <Select
                            labelId="site-label"
                            id="site-select"
                            value={notification.siteId}
                            label="Site"
                            onChange={handleChange}

                        >
                            {
                                sites.map((item, index) => (
                                    <MenuItem value={item._id} key={index}>{item.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    {/* <FormControl fullWidth sx={{ my: 2 }}>
                        <InputLabel id="site-label" >Vendor</InputLabel>
                        <Select
                            labelId="site-label"
                            id="site-select"
                            value={notification.vendor}
                            label="Company"
                            onChange={handleVendorChange}
                        >
                            {
                                vendor.map((item, index) => (
                                    <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl> */}

                    {/* <FormControl fullWidth sx={{ my: 2 }}>
                        <InputLabel id="user-label">User</InputLabel>
                        <Select
                            labelId="user-label"
                            id="user-select"
                            value={notification.userId}
                            label="User"
                            onChange={handleUserChange}
                        >
                            {
                                users.map((item, index) => (
                                    <MenuItem value={item.id} key={index}>{item?.label}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl> */}

                    <FormControl fullWidth>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="notification"
                            label="Notification"
                            type="text"
                            rows={2}
                            multiline
                            defaultValue={notification.notes}
                            onChange={(event) => {
                                setNotification(prevState => ({
                                    ...prevState,
                                    notes: event.target.value,
                                }))
                            }}
                            fullWidth
                            error={notification.notesError ? true : false}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ my: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                                className={notification.startTimeError ? "error" : null}
                                label="Start Time"
                                value={notification.startTime}
                                onChange={(data) => {
                                    setNotification(prevState => ({
                                        ...prevState,
                                        startTime: data,
                                    }))
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>


                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                                className={notification.endTimeError ? "error" : null}
                                label="End Time"
                                value={notification.endTime}
                                onChange={(data) => {
                                    setNotification(prevState => ({
                                        ...prevState,
                                        endTime: data,
                                    }))
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                className={notification.startDateError ? "error" : null}
                                label="Start Date"
                                value={notification.startDate}
                                onChange={(data) => {
                                    setNotification(prevState => ({
                                        ...prevState,
                                        startDate: data,
                                    }))
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                className={notification.endDateError ? "error" : null}
                                label="End Date"
                                value={notification.endDate}
                                onChange={(data) => {
                                    setNotification(prevState => ({
                                        ...prevState,
                                        endDate: data,
                                    }))
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <Grid item xs={12} >

                        <Button variant="outlined" className={'row-btn-alt'}
                        >
                            Weekly
                        </Button>
                        {/* {isShow && */}
                        <Typography>
                            <h4>Please Select Recurrence Day</h4>
                            <Grid className="mt-2 mb-2">
                                <div className="h-10" >
                                    {/* {console.log("checkList==========", checkListWeek)} */}
                                    {checkListWeek.map((item, index) => (
                                        <div key={index} style={{ display: "inline" }}>
                                            <input sx={{ fontSize: "12px" }} value={item.val} type="checkbox" onChange={handleCheck}
                                            />
                                            <span className={isChecked(item)} key={item} value={item.val}>{item.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </Grid>
                        </Typography>

                    </Grid>


                    {/* <FormControl fullWidth>
                         <InputLabel id="shift-label">Shift Recurrence</InputLabel>
                          <Select
                            labelId="shift-label"
                            id="shift-select"
                            value={notification.shiftRecurrence}
                            label="Shift Recurrence"
                            onChange={(event) => {handleRecurrenceChange(event)}}
                          >
                            {
                              recurrences.map((item, index) => (
                                <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                              ))
                            }
                          </Select>
                    </FormControl>   */}
                </DialogContent>
                <DialogActions sx={{ mx: 2, mb: 4 }}>
                    <Button onClick={() => handleSubmit('edit')} variant="contained" color="primary">Update</Button>
                    <Button onClick={handleClose} variant="outlined">Cancel</Button>
                </DialogActions>
            </Dialog>


            <Dialog open={show} onClose={handleShowClose} fullWidth={true}>
                <DialogTitle sx={{ mb: 4, textAlign: "center" }}>Delete Notification</DialogTitle>

                <DialogContent>
                    <Box
                        component="form"
                        sx={{ '& .MuiTextField-root': { my: 2, width: '100%' }, }}
                        noValidate
                        autoComplete="off"
                    >
                        <h4 style={{ textAlign: 'left', fontWeight: 'bold' }}>Do you want's to delete this notification</h4>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ mb: 2, mx: 4 }}>
                    <Button onClick={handleDelete} variant="contained" color="error">Delete</Button>
                    <Button onClick={handleShowClose} variant="outlined">Cancel</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
