import { Box, Button, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import { deleteV2API, getV2API, getV2ExportAPI, postV2API, putV2API } from '../../../network/index.v2';
import { tableData } from '../../../utils';
import EmptyTable from '../../../common/EmptyTable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PageTitle from '../../../common/PageTitle';




export const tableHeader = {
    fontSize: '0.9rem !important',
    fontWeight: 500,
    color: '#45445F',
    px: 3,
    paddingLeft: '5px',
    paddingRight: '5px',
    padding: '12px'
}

const ContractorAdminModal = () => {
    const [adminContractorList, setAdminContractorList] = useState([])
    const [ContractorList, setContractorList] = useState([])


    const [modalOpen, setModalOpen] = useState(false)
    const [editModal, setEditModal] = useState(false)

    const [contractorId, setContractorId] = useState("")
    const [adminName, setAdminName] = useState("")
    const [_id, setId] = useState("")
    const [adminEmail, setAdminEmail] = useState("")
    const [adminPhoneNumber, setAdminPhoneNumber] = useState("")
    const [selectedSites, setSelectedSites] = useState("")
    const [selectedStates, setSelectedState] = useState("")
    // const [suburb, setSuburb] = useState("")
    // const [email, setEmail] = useState("")
    // const [contactName, setContactName] = useState("")
    // const [postcode, setPostCode] = useState("")

    const [inputs, setInputs] = useState({
        contractorId: "",
        adminName: "",
        adminEmail: "",
        adminPhoneNumber: "",
        selectedStates: "",
        selectedSites: ""
    })

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        // height: 300,
        bgcolor: 'background.paper',
        border: '2px solid white',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        borderRadius: "2px"
    };

    // -------------------------------------------- Table States and Static Data Manipulation Start --------------------------------------


    const [page, setPage] = useState(0)
    const [perPages, setPerPages] = useState([10, 25, 50]);
    const [perPage, setPerPage] = useState(10)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        console.log(event.target.value)
        setPerPage(event.target.value)
        setPage(0);
    };

    const editContactor = (e, _id) => {
        e.preventDefault()

        const filteredId = adminContractorList?.filter(item => item?._id === _id)
        setId(filteredId[0]?._id)
        setAdminName(filteredId[0]?.adminName)
        setAdminEmail(filteredId[0]?.adminEmail)
        setAdminPhoneNumber(filteredId[0]?.adminPhoneNumber)
        setContractorId(filteredId[0]?.contractorId?._id)
        // setAddress(filteredId[0]?.address)
        // setPhoneNumber(filteredId[0]?.phoneNumber)
        setSelectedState(filteredId[0]?.selectedStates)
        setSelectedSites(filteredId[0]?.selectedSites)
        // setSuburb(filteredId[0]?.suburb)
        // setContactName(filteredId[0]?.contactName)
        setEditModal(true)

    }


    // -------------------------------------------- Table States and Static Data Manipulation Ends --------------------------------------

    const handleChange = (e) => {
        e.preventDefault()

        setInputs({ ...inputs, [e.target.name]: e.target.value })

    }

    const createContractor = async (e) => {
        e.preventDefault()

        const fD = new FormData()
        fD.append("contractorId", inputs?.contractorId)
        fD.append("adminName", inputs?.adminName)
        fD.append("adminEmail", inputs?.adminEmail)
        // fD.append("email", inputs?.email)
        fD.append("adminPhoneNumber", inputs?.adminPhoneNumber)
        fD.append("selectedStates", inputs?.selectedStates)
        fD.append("selectedSites", inputs?.selectedSites)
        // fD.append("state", inputs?.state)
        // fD.append("suburb", inputs?.suburb)
        // fD.append("contactName", inputs?.contactName)

        const response = await postV2API("/maintenance/contractor/admin", fD)
        setModalOpen(false)
        clearInputs()
        getContractorList()
    }

    const updateContractor = async (e) => {
        e.preventDefault()

        const fD = new FormData()
        fD.append("contractorId", contractorId)
        fD.append("adminName", adminName)
        fD.append("adminEmail", adminEmail)
        // fD.append("email", inputs?.email)
        fD.append("adminPhoneNumber",adminPhoneNumber)
        fD.append("selectedStates", selectedStates)
        fD.append("selectedSites", selectedSites)
        // fD.append("state", inputs?.state)
        // fD.append("suburb", inputs?.suburb)
        // fD.append("contactName", inputs?.contactName)

        const response = await putV2API(`/maintenance/contractor/admin/${_id}`, fD)
        setEditModal(false)
        // clearInputs()
        getContractorList()
    }

    const getContractorList = async () => {
        try {
            const response = await getV2API("/maintenance/contractor/admin")
            if (response) {
                setAdminContractorList(response)
            }
        } catch (error) {
            console.log(error, "error from api");
        }
    }

    const getContractorLists = async () => {
        try {
            const response = await getV2API("/maintenance/contractor")
            if (response) {
                setContractorList(response)
            }
        } catch (error) {
            console.log(error, "error from api");
        }
    }

    const deleteAdminContract = async (id) => {
        try {
          const response = await deleteV2API(`/maintenance/contractor/admin/${id}`)
          if (response) {
            getContractorList()
          }
        } catch (error) {
          console.log(error, "error in api");
        }
      }

    const clearInputs = () => {
        setInputs((prev) => ({
            ...prev,
            contractorId: "",
            adminName: "",
            adminEmail: "",
            adminPhoneNumber: "",
            selectedStates: "",
            selectedSites: ""
        }))
    }


    useEffect(() => {
        getContractorList()
        getContractorLists()
    }, [])
    return (
        <div>
            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>

                <div>
                    <Button onClick={() => setModalOpen(true)} variant="contained" style={{ backgroundColor: "#75859D", float: "right" }} sx={{ height: 52, mx: 2 }}>
                        <AddCircleIcon /> &nbsp; &nbsp;
                        Add Admin Contractor
                    </Button>
                </div>
                <Box display="flex" sx={{ my: "1rem" }}>

                    <TableContainer component={Paper}>

                        <Table
                            sx={{ minWidth: "auto" }}
                            aria-label="custom pagination table"
                            className="responsive-table"
                        >
                            <TableHead>
                                <TableRow className="table-header">
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Contractor Id
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Contractor Name
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Admin Name
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Admin Email
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Admin Phone
                                    </TableCell>

                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Status
                                    </TableCell>

                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Action
                                    </TableCell>
                                    {/* <TableCell align="center" component="th" sx={tableHeader}>
                        Task Status
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                        Action
                    </TableCell> */}

                                </TableRow>
                            </TableHead>
                            <TableBody>


                                {adminContractorList?.slice(page * perPage, page * perPage + perPage)?.map((task, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.contractorId?._id}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.siteId?.name}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.adminName}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.adminEmail}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.adminPhoneNumber}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.adminPhoneNumber}
                                        </TableCell>
                                        <TableCell align="left" sx={{ display: "flex" }} >
                                            <Button size="small" className="btn-div" variant="outlined" color="info"
                                                onClick={(e) => editContactor(e, task?._id)}
                                            >
                                                <EditIcon className="" />
                                            </Button>
                                            <Button size="small" className="btn-div" variant="outlined" color="error"
                                            onClick={() => deleteAdminContract(task?._id)}
                                            >
                                                <DeleteIcon className="" />
                                            </Button>
                                        </TableCell>
                                        {/* <TableCell align="left" sx={tableData}>
            {task?.user?.phone}
        </TableCell> */}
                                        {/* <TableCell align="left" sx={tableData}>
            {task?.checkIn?.split('T')[0]}
        </TableCell>
        <TableCell align="left" sx={tableData}>
            {task?.checkOut?.split('T')[0]}
        </TableCell>
        <TableCell align="left" sx={tableData}>
            {task?.purpose}
        </TableCell> */}
                                        {/* <TableCell align="left" sx={tableData}>

            {task?.isMatch === true ?
                <Button style={{ color: 'white', backgroundColor: 'green' }}> Matched</Button> :
                <Button style={{ color: 'white', backgroundColor: 'red' }} onClick={() => handleMapData(task)}>Mismatch</Button>
            }
            <i>
                {task?.isMatch ? null : `${task?.distance} km away`}
            </i>
        </TableCell> */}
                                        {/* <TableCell align="left" sx={tableData}>
            No
        </TableCell>

        <TableCell align="left" sx={tableData}>
            Non Critical
        </TableCell>
        <TableCell align="left" sx={tableData}>
            Pending
        </TableCell> */}
                                        {/* <TableCell align="left" sx={tableData}>
<Button variant="outlined" style={{ color: 'white', backgroundColor: 'green' }} onClick={() => setOpenCheckout(true)}>Checked Out</Button>

<Button variant="outlined" color="error" onClick={() => setOpenCheckout(true)}>Forced Check Out</Button>

<Button variant="outlined" style={{ color: 'white', backgroundColor: 'orange' }} onClick={() => setOpenCheckout(true)}>Check Out</Button>
</TableCell> */}

                                    </TableRow>

                                ))}

                                {
                                    adminContractorList.length === 0 &&
                                    <EmptyTable colSpan={6} />
                                }
                            </TableBody>
                            <TableFooter>
                                <TableRow >

                                    <TablePagination
                                        align="right"
                                        rowsPerPageOptions={perPages}
                                        colSpan={7}
                                        count={adminContractorList.length}
                                        rowsPerPage={perPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    // ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Box>
            </div>

            <div>
                <Modal
                    open={modalOpen}
                >
                    <Box sx={style}>
                        {/* <h6>Close</h6> */}
                        <div className='box-title'>
                            <h2>Add Property</h2>
                        </div>
                        <form onSubmit={createContractor}>
                            <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "10px" }}>
                                <TextField variant='outlined' label="Admin Name" className='box-input' name='adminName' onChange={handleChange} />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Contractor</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={inputs?.propertytypeId}
                                        name='contractorId'
                                        label="Property Type"
                                        onChange={handleChange}
                                    >
                                        {
                                            ContractorList?.map((property) => {
                                                return <MenuItem key={property?._id} value={property?._id}>{property?.supplierName}</MenuItem>
                                            })
                                        }

                                    </Select>
                                </FormControl>
                                {/* <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Property Category</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={inputs?.propertycategoryId}
                                        name='propertycategoryId'
                                        label="Property Type"
                                        onChange={handleChange}
                                    >
                                        {
                                            PropertyCategoryList?.map((property) => {
                                                return <MenuItem key={property?._id} value={property?._id}>{property?.title}</MenuItem>
                                            })
                                        }
                                     
                                    </Select>
                                </FormControl> */}
                                <TextField variant='outlined' label="Admin Email" className='box-input' name='adminEmail' onChange={handleChange} />
                                <TextField variant='outlined' label="Admin Phone Number" className='box-input' name='adminPhoneNumber' onChange={handleChange} />
                                <TextField variant='outlined' label="Selected States" className='box-input' name='selectedStates' onChange={handleChange} />
                                <TextField variant='outlined' label="Selected Sites" className='box-input' name='selectedSites' onChange={handleChange} />

                            </div>
                            <div className='box-button-group'>
                                <Button variant='contained' type='submit'>Submit</Button>
                                <Button variant='outlined' onClick={() => setModalOpen(false)} >Cancel</Button>
                            </div>
                        </form>
                    </Box>
                </Modal>
            </div>


            <div>
                <Modal
                    open={editModal}

                >
                    <Box sx={style}>
                        <PageTitle title={"Update Contractor"} />

                        <form onSubmit={updateContractor}>
                            <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "10px" }}>
                                <TextField variant='outlined' label="Admin Name" className='box-input' name='adminName' value={adminName} onChange={(e) =>setAdminName(e.target.value)} />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Contractor</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={contractorId}
                                        name='contractorId'
                                        label="Property Type"
                                        onChange={(e) => setContractorId(e.target.value)}
                                    >
                                        {
                                            ContractorList?.map((property) => {
                                                return <MenuItem key={property?._id} value={property?._id}>{property?.supplierName}</MenuItem>
                                            })
                                        }

                                    </Select>
                                </FormControl>
                                {/* <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Property Category</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={inputs?.propertycategoryId}
                                        name='propertycategoryId'
                                        label="Property Type"
                                        onChange={handleChange}
                                    >
                                        {
                                            PropertyCategoryList?.map((property) => {
                                                return <MenuItem key={property?._id} value={property?._id}>{property?.title}</MenuItem>
                                            })
                                        }
                                     
                                    </Select>
                                </FormControl> */}
                                <TextField variant='outlined' label="Admin Email" className='box-input' value={adminEmail} name='adminEmail' onChange={(e) => setAdminEmail(e.target.value)} />
                                <TextField variant='outlined' label="Admin Phone Number" className='box-input' value={adminPhoneNumber} name='adminPhoneNumber' onChange={(e) => setAdminPhoneNumber(e.target.value)} />
                                <TextField variant='outlined' label="Selected States" className='box-input' value={selectedStates} name='selectedStates' onChange={(e)=>setSelectedState(e.target.value)} />
                                <TextField variant='outlined' label="Selected Sites" className='box-input' value={selectedSites} name='selectedSites' onChange={(e)=>setSelectedSites(e.target.value)} />

                            </div>
                            <div className='box-button-group'>
                                <Button variant='contained' type='submit'>Submit</Button>
                                <Button variant='outlined' onClick={() => setEditModal(false)} >Cancel</Button>
                            </div>
                        </form>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}

export default ContractorAdminModal