export const currency = [
    { currency: 'Afghan Afghani', code: 'AFN' },
    { currency: 'Albanian Lek', code: 'ALL' },
    { currency: 'Algerian Dinar', code: 'DZD' },
    { currency: 'Angolan Kwanza', code: 'AOA' },
    { currency: 'Argentine Peso', code: 'ARS' },
    { currency: 'Armenian Dram', code: 'AMD' },
    { currency: 'Aruban Florin', code: 'AWG' },
    { currency: 'Australian Dollar', code: 'AUD' },
    { currency: 'Azerbaijani Manat', code: 'AZN' },
    { currency: 'Bahamian Dollar', code: 'BSD' },
    { currency: 'Bangladeshi Taka', code: 'BDT' },
    { currency: 'Barbadian Dollar', code: 'BBD' },
    { currency: 'Belize Dollar', code: 'BZD' },
    { currency: 'Bermudian Dollar', code: 'BMD' },
    { currency: 'Bolivian Boliviano', code: 'BOB' },
    { currency: 'Bosnia & Herzegovina Convertible Mark', code: 'BAM' },
    { currency: 'Botswana Pula', code: 'BWP' },
    { currency: 'Brazilian Real', code: 'BRL' },
    { currency: 'British Pound', code: 'GBP' },
    { currency: 'Brunei Dollar', code: 'BND' },
    { currency: 'Bulgarian Lev', code: 'BGN' },
    { currency: 'Burundian Franc', code: 'BIF' },
    { currency: 'Cambodian Riel', code: 'KHR' },
    { currency: 'Canadian Dollar', code: 'CAD' },
    { currency: 'Cape Verdean Escudo', code: 'CVE' },
    { currency: 'Cayman Islands Dollar', code: 'KYD' },
    { currency: 'Central African Cfa Franc', code: 'XAF' },
    { currency: 'Cfp Franc', code: 'XPF' },
    { currency: 'Chilean Peso', code: 'CLP' },
    { currency: 'Chinese Renminbi Yuan', code: 'CNY' },
    { currency: 'Colombian Peso', code: 'COP' },
    { currency: 'Comorian Franc', code: 'KMF' },
    { currency: 'Congolese Franc', code: 'CDF' },
    { currency: 'Costa Rican Colón', code: 'CRC' },
    { currency: 'Croatian Kuna', code: 'HRK' },
    { currency: 'Czech Koruna', code: 'CZK' },
    { currency: 'Danish Krone', code: 'DKK' },
    { currency: 'Djiboutian Franc', code: 'DJF' },
    { currency: 'Dominican Peso', code: 'DOP' },
    { currency: 'East Caribbean Dollar', code: 'XCD' },
    { currency: 'Egyptian Pound', code: 'EGP' },
    { currency: 'Ethiopian Birr', code: 'ETB' },
    { currency: 'Euro', code: 'EUR' },
    { currency: 'Falkland Islands Pound', code: 'FKP' },
    { currency: 'Fijian Dollar', code: 'FJD' },
    { currency: 'Gambian Dalasi', code: 'GMD' },
    { currency: 'Georgian Lari', code: 'GEL' },
    { currency: 'Gibraltar Pound', code: 'GIP' },
    { currency: 'Guatemalan Quetzal', code: 'GTQ' },
    { currency: 'Guinean Franc', code: 'GNF' },
    { currency: 'Guyanese Dollar', code: 'GYD' },
    { currency: 'Haitian Gourde', code: 'HTG' },
    { currency: 'Honduran Lempira', code: 'HNL' },
    { currency: 'Hong Kong Dollar', code: 'HKD' },
    { currency: 'Hungarian Forint', code: 'HUF' },
    { currency: 'Icelandic Króna', code: 'ISK' },
    { currency: 'Indian Rupee', code: 'INR' },
    { currency: 'Indonesian Rupiah', code: 'IDR' },
    { currency: 'Israeli New Sheqel', code: 'ILS' },
    { currency: 'Jamaican Dollar', code: 'JMD' },
    { currency: 'Japanese Yen', code: 'JPY' },
    { currency: 'Kazakhstani Tenge', code: 'KZT' },
    { currency: 'Kenyan Shilling', code: 'KES' },
    { currency: 'Kyrgyzstani Som', code: 'KGS' },
    { currency: 'Lao Kip', code: 'LAK' },
    { currency: 'Lebanese Pound', code: 'LBP' },
    { currency: 'Lesotho Loti', code: 'LSL' },
    { currency: 'Liberian Dollar', code: 'LRD' },
    { currency: 'Macanese Pataca', code: 'MOP' },
    { currency: 'Macedonian Denar', code: 'MKD' },
    { currency: 'Malagasy Ariary', code: 'MGA' },
    { currency: 'Malawian Kwacha', code: 'MWK' },
    { currency: 'Malaysian Ringgit', code: 'MYR' },
    { currency: 'Maldivian Rufiyaa', code: 'MVR' },
    { currency: 'Mauritanian Ouguiya', code: 'MRO' },
    { currency: 'Mauritian Rupee', code: 'MUR' },
    { currency: 'Mexican Peso', code: 'MXN' },
    { currency: 'Moldovan Leu', code: 'MDL' },
    { currency: 'Mongolian Tögrög', code: 'MNT' },
    { currency: 'Moroccan Dirham', code: 'MAD' },
    { currency: 'Mozambican Metical', code: 'MZN' },
    { currency: 'Myanmar Kyat', code: 'MMK' },
    { currency: 'Namibian Dollar', code: 'NAD' },
    { currency: 'Nepalese Rupee', code: 'NPR' },
    { currency: 'Netherlands Antillean Gulden', code: 'ANG' },
    { currency: 'New Taiwan Dollar', code: 'TWD' },
    { currency: 'New Zealand Dollar', code: 'NZD' },
    { currency: 'Nicaraguan Córdoba', code: 'NIO' },
    { currency: 'Nigerian Naira', code: 'NGN' },
    { currency: 'Norwegian Krone', code: 'NOK' },
    { currency: 'Pakistani Rupee', code: 'PKR' },
    { currency: 'Panamanian Balboa', code: 'PAB' },
    { currency: 'Papua New Guinean Kina', code: 'PGK' },
    { currency: 'Paraguayan Guaraní', code: 'PYG' },
    { currency: 'Peruvian Nuevo Sol', code: 'PEN' },
    { currency: 'Philippine Peso', code: 'PHP' },
    { currency: 'Polish Złoty', code: 'PLN' },
    { currency: 'Qatari Riyal', code: 'QAR' },
    { currency: 'Romanian Leu', code: 'RON' },
    { currency: 'Russian Ruble', code: 'RUB' },
    { currency: 'Rwandan Franc', code: 'RWF' },
    { currency: 'São Tomé and Príncipe Dobra', code: 'STD' },
    { currency: 'Saint Helenian Pound', code: 'SHP' },
    { currency: 'Salvadoran Colón', code: 'SVC' },
    { currency: 'Samoan Tala', code: 'WST' },
    { currency: 'Saudi Riyal', code: 'SAR' },
    { currency: 'Serbian Dinar', code: 'RSD' },
    { currency: 'Seychellois Rupee', code: 'SCR' },
    { currency: 'Sierra Leonean Leone', code: 'SLL' },
    { currency: 'Singapore Dollar', code: 'SGD' },
    { currency: 'Solomon Islands Dollar', code: 'SBD' },
    { currency: 'Somali Shilling', code: 'SOS' },
    { currency: 'South African Rand', code: 'ZAR' },
    { currency: 'South Korean Won', code: 'KRW' },
    { currency: 'Sri Lankan Rupee', code: 'LKR' },
    { currency: 'Surinamese Dollar', code: 'SRD' },
    { currency: 'Swazi Lilangeni', code: 'SZL' },
    { currency: 'Swedish Krona', code: 'SEK' },
    { currency: 'Swiss Franc', code: 'CHF' },
    { currency: 'Tajikistani Somoni', code: 'TJS' },
    { currency: 'Tanzanian Shilling', code: 'TZS' },
    { currency: 'Thai Baht', code: 'THB' },
    { currency: 'Tongan Paʻanga', code: 'TOP' },
    { currency: 'Trinidad and Tobago Dollar', code: 'TTD' },
    { currency: 'Turkish Lira', code: 'TRY' },
    { currency: 'Ugandan Shilling', code: 'UGX' },
    { currency: 'Ukrainian Hryvnia', code: 'UAH' },
    { currency: 'United Arab Emirates Dirham', code: 'AED' },
    { currency: 'United States Dollar', code: 'USD' },
    { currency: 'Uruguayan Peso', code: 'UYU' },
    { currency: 'Uzbekistani Som', code: 'UZS' },
    { currency: 'Vanuatu Vatu', code: 'VUV' },
    { currency: 'Vietnamese Đồng', code: 'VND' },
    { currency: 'West African Cfa Franc', code: 'XOF' },
    { currency: 'Yemeni Rial', code: 'YER' },
    { currency: 'Zambian Kwacha', code: 'ZMW' },
]