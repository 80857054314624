import React, { useEffect, useState } from 'react'
import PageTitle from '../../../common/PageTitle'
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField } from '@material-ui/core'
import { checkAuthority, tableData, tableHeader } from '../../../utils'
import { deleteV2API, getV2API, postV2API, putV2API } from '../../../network/index.v2'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from '../../../common/Loader'
import { useNavigate } from 'react-router-dom'

const WorkPermit = () => {
    let companyId = localStorage.getItem('companyIdLS')
    if (companyId === null) {
        companyId = undefined
    }
    const [workPermitList, setWorkPermitList] = useState([])
    const [loader, setLoader] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [inputs, setInputs] = useState({
        category: "",
        siteId: "645ca78a181a1d27147fdee8",
        name: "",
        description: ""
    })
    const navigate = useNavigate()



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        border: '2px solid #fff',
        borderRadius: "5px",
        boxShadow: 24,
        p: 4,
    };

    const [responseInputs, setResponseInputs] = useState({
        _id: "",
        category: "",
        siteId: "",
        name: "",
        description: ""
    })

    const onChange = async (e) => {
        e.preventDefault()

        setInputs({ ...inputs, [e.target.name]: e.target.value })

    }

    const editChange = async (e) => {
        e.preventDefault()

        setResponseInputs({ ...responseInputs, [e.target.name]: e.target.value })
    }

    const editPermit = async (e, _id) => {
        e.preventDefault()

        setEditModal(true)
        setEditMode(true)

        // console.log(w);


        let data = workPermitList?.filter(item => item?._id === _id)[0]

        setResponseInputs(data)


    }

    const postWorkPermit = async () => {
        setLoader(true)
        const response = await postV2API("/maintenance/workpermit", inputs)
        if (response) {
            getPermitList()
            setModalOpen(false)
            setEditMode(false)
        }
        setLoader(false)
    }

    const getPermitList = async () => {
        // if (companyId) {
        //     var url = `/sites?companyId=${companyId}`
        // } else {
        //     var url = `/sites`
        // }
        setLoader(true)
        let response = await getV2API("/maintenance/workpermit/");
        if (response) {
            setWorkPermitList(response)
        }
        setLoader(false)
    }
    // console.log(workPermitList);

    const editWorkPermit = async () => {
        setLoader(true)
        const response = await putV2API(`/maintenance/workpermit/${responseInputs?._id}`, responseInputs)
        if (response) {
            getPermitList()
            setEditModal(false)
        }
        setLoader(false)

    }



    const deleteWorkPermit = async (_id) => {
        // console.log(_id);
        setLoader(true)
        const response = await deleteV2API(`/maintenance/workpermit/${_id}`)
        if (response) {
            getPermitList()
        }
        setLoader(false)
    }

    useEffect(() => {
        getPermitList()
    }, [])
    return (
        <div>
            <Grid sx={{ display: "flex", justifyContent: "space-between", width: "100%", height: "45px", marginBottom: "10px" }}>
                <PageTitle title="Work Permits" />
                <Button onClick={() => setModalOpen(true)} variant='contained'>Add WorkPermit</Button>
            </Grid>
            {
                loader ? <Loader /> : <Grid>
                    <TableContainer component={Paper} sx={{ mx: "0.8rem" }} >
                        <Table sx={{ minWidth: 'auto' }} aria-label="custom pagination table" className="responsive-table">
                            <TableHead >
                                <TableRow className="table-header">
                                    <TableCell align="left" component="th" sx={tableHeader}>Category</TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>Site Id</TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>Name</TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>Description</TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>Create Form</TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>Submit Form</TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>


                                {
                                    workPermitList.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="left" sx={tableData}>
                                                {item?.category}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                {item?.siteId?.siteId}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                {item?.name}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                {item?.description}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData} style={{ fontWeight: "bold", textAlign: "center", textTransform: "capitalize", cursor:"pointer" }} onClick={() => navigate(`/maintainance_report/work-permits/question/${item?._id}`)}>
                                                Create Form
                                            </TableCell>
                                            <TableCell align="left" sx={tableData} style={{ fontWeight: "bold", textAlign: "center", textTransform: "capitalize", cursor:"pointer" }} onClick={() => navigate(`/maintainance_report/Work-Permits/answer/${item?._id}`)}>
                                                Submit Form
                                            </TableCell>
                                            {/* {
                                            checkAuthority('ADD_INSPECTION_FORM') ?
                                                <TableCell align="center" className="t-body-cell font-bold formBtn" onClick={() => handleNavigateForm(item.siteId[0]._id, item?._id, item?._id)}>
                                                    {'Create Form'}
                                                </TableCell>
                                                :
                                                <TableCell align="center" className="t-body-cell font-bold">
                                                    <span className="formBtn"></span>
                                                </TableCell>
                                        } */}

                                            {/* {
                                            checkAuthority('SUBMIT_INSPECTION_FORM') ?
                                                <TableCell align="center" className="t-body-cell font-bold" onClick={() => handleNavigateSubmitForm(item?.siteId[0]._id, item?._id, item?._id)}>
                                                    <span className="formBtn">{'Submit Form'}</span>
                                                </TableCell>
                                                :
                                                <TableCell align="center" className="t-body-cell font-bold">
                                                    <span className="formBtn"></span>
                                                </TableCell>
                                        } */}

                                            <TableCell align="center" sx={tableData}>
                                                {
                                                    checkAuthority('EDIT_ALERT') &&
                                                    <Button
                                                        className="btn-div" color="primary"
                                                        variant="outlined" sx={{ mx: 2 }}
                                                        onClick={(e) => editPermit(e, item?._id)}
                                                    >
                                                        <EditIcon className="" />
                                                    </Button>
                                                }
                                                {
                                                    checkAuthority('DELETE_ALERT') &&
                                                    <Button
                                                        className="btn-div" color="error"
                                                        variant="outlined"
                                                        onClick={() => deleteWorkPermit(item?._id)}
                                                    >
                                                        <DeleteIcon className="" />
                                                    </Button>
                                                }
                                            </TableCell>

                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                            {
                                checkAuthority('ADD_SITE_INSPECTION') &&
                                <TableFooter>
                                    <TableRow>
                                        {/* <TableCell sx={tableData} direction="column" justifyContent="center" onClick={handleClickOpen}>
                                        <Link href="#" underline="none" >
                                            <div className="custom-table-cell">
                                                <span className="add-title">Add Report</span>
                                                <AddCircleIcon className="add-icon" fontSize="medium" />
                                            </div>
                                        </Link>
                                    </TableCell> */}
                                        <TableCell align="left" sx={tableData}>
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            }

                        </Table>
                    </TableContainer>
                </Grid>
            }

            <div>
                <Modal
                    open={modalOpen}

                >
                    <Box sx={style}>
                        <PageTitle title={"Add Work Permit "} />

                        <Grid sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
                            <TextField label="Enter Category" value={inputs?.category} name='category' id='category' variant='outlined' style={{ width: "100%", marginLeft: "10px" }} onChange={onChange} />
                            <FormControl fullWidth style={{ marginLeft: "10px" }}>
                                <InputLabel id="demo-simple-select-label">Site Id</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={inputs?.siteId}
                                    label="Site Id"
                                    name='siteId'
                                    onChange={onChange}
                                >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField label="Enter  Name" value={inputs?.name} name='name' id='name' variant='outlined' style={{ width: "100%", marginLeft: "10px" }} onChange={onChange} />
                            <TextField label="Enter  Description" value={inputs?.description} name='description' id='description' variant='outlined' style={{ width: "100%", marginLeft: "10px" }} onChange={onChange} />
                        </Grid>

                        <Grid style={{ marginTop: "15px", width: "100%", display: "flex", justifyContent: "start", alignItems: "center", gap: "10px", marginLeft: "10px" }}>
                            <Button variant="contained" onClick={postWorkPermit} style={{ float: "right" }} sx={{ height: 40 }}>
                                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                                Submit
                            </Button>

                            <Button onClick={() => {
                                setModalOpen(false)
                                setEditMode(false)
                            }} variant="outlined" style={{ float: "right" }} sx={{ height: 40 }}>
                                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                                Cancel
                            </Button>
                        </Grid>

                        <div></div>
                    </Box>
                </Modal>
            </div>


            <div>
                <Modal
                    open={editModal}

                >
                    <Box sx={style}>
                        <PageTitle title={"Update Building Floor"} />

                        <Grid sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
                            <TextField label="Enter Category" value={responseInputs?.category} name='category' id='category' variant='outlined' style={{ width: "100%", marginLeft: "10px" }} onChange={editChange} />
                            <FormControl fullWidth style={{ marginLeft: "10px" }}>
                                <InputLabel id="demo-simple-select-label">Site Id</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={"645ca78a181a1d27147fdee8"}
                                    label="Site Id"
                                    name='siteId'
                                    onChange={editChange}
                                >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField label="Enter  Name" value={responseInputs?.name} name='name' id='name' variant='outlined' style={{ width: "100%", marginLeft: "10px" }} onChange={editChange} />
                            <TextField label="Enter  Description" value={responseInputs?.description} name='description' id='description' variant='outlined' style={{ width: "100%", marginLeft: "10px" }} onChange={editChange} />
                        </Grid>

                        <Grid style={{ marginTop: "15px", width: "100%", display: "flex", justifyContent: "start", alignItems: "center", gap: "10px", marginLeft: "10px" }}>
                            <Button variant="contained" onClick={editWorkPermit} style={{ float: "right" }} sx={{ height: 40 }}>
                                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                                Update
                            </Button>

                            <Button onClick={() => {
                                setEditModal(false)
                                setEditMode(false)
                            }} variant="outlined" style={{ float: "right" }} sx={{ height: 40 }}>
                                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                                Cancel
                            </Button>
                        </Grid>

                        <div></div>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}

export default WorkPermit