import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import PageTitle from "../../common/PageTitle";
import SiteDetailCard from "../../components/sites/DetailCard";

export default function ReportsPage() {
  const segments = window.location.pathname;
  const [urlType, setUrlType] = useState('');
  const [filteredReports, setFilteredReports] = useState([]);

  useEffect(() => {
    if (segments.split('/')[1] === 'company') {
      setUrlType('/company');
    }
    filterReports();
  }, [urlType]);

  const filterReports = () => {
    const permissionList = JSON.parse(localStorage.getItem('permisionlist'));
    console.log("permissionList:", permissionList); // Debugging statement

    const reports = [
      { id: 1, title: "Custom Reports", url: `${urlType}/custom/reports` },
      { id: 2, title: "Portfolio Reports", url: `${urlType}/portfolio/reports` },
      // { id: 3, title: "Shift Reports", url: `${urlType}/shift/reports` },
      // { id: 4, title: "Incident Reports", url: `${urlType}/incident/reports` },
    ];

    // Display all reports if permissionList is missing or submenuPermissions is empty
    if (!permissionList || permissionList[0]?.submenuPermissions.length === 0) {
      console.log("No submenu permissions found, displaying all reports"); // Debugging statement
      setFilteredReports(reports);
      return;
    }

    // Extract submenu permissions if available
    const submenuPermissions = permissionList[0].submenuPermissions || [];
    const allowedTitles = submenuPermissions.map(item => item.submenu);
    console.log("Allowed Titles:", allowedTitles); // Debugging statement

    // Filter reports based on allowed titles
    const filtered = reports.filter(report =>
      allowedTitles.includes(report.title)
    );
    setFilteredReports(filtered);
  };

  return (
    <Box sx={{ height: "inherit" }}>
      <PageTitle title="Reports" />
      <Box display="flex">
        <Grid
          container
          rowSpacing={7}
          columnSpacing={{ xs: 1 }}
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          mt="1%"
        >
          {filteredReports.map((report) => (
            <SiteDetailCard large title={report.title} key={report.id} url={report.url} />
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
