import React, { useEffect, useState } from "react";
import PageTitle from "../../../../common/PageTitle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteAPI, getAPI, postAPI, putAPI } from "../../../../network";

const AddIssueType = () => {
  let companyId = localStorage.getItem("companyIdLS");
  if (companyId === null) {
    companyId = undefined;
  }

  const [modalOpen, setModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [issueTypeList, setIssueTypeList] = useState([]);
  const [inputs, setInputs] = useState({ title: "", companyId });
  const [issueInputs, setIssueInputs] = useState({ _id: "", title: "" });

  // Fetch Issue Types
  const getIssueType = async () => {
    try {
      const response = await getAPI(
        `/building/get-issuetype?companyId=${companyId}`
      );
      if (response) {
        setIssueTypeList(response);
      }
    } catch (error) {
      console.error("Error fetching issue types:", error);
    }
  };

  // Add Issue Type
  const postIssueType = async () => {
    try {
      const response = await postAPI("/building/create-issuetype", inputs);
      if (response) {
        getIssueType();
        setModalOpen(false);
        setInputs({ title: "", companyId });
        setPage(0);
      }
    } catch (error) {
      console.error("Error creating issue type:", error);
    }
  };

  // Open Edit Modal and Populate Inputs
  const openEditModal = (issueType) => {
    setEditModal(true);
    setIssueInputs(issueType);
  };

  // Update Issue Type
  const updateIssueType = async () => {
    try {
      const response = await putAPI(
        `/building/update-issuetype/${issueInputs._id}`,
        issueInputs
      );
      if (response) {
        getIssueType();
        setEditModal(false);
        setIssueInputs({ _id: "", title: "" });
      }
    } catch (error) {
      console.error("Error updating issue type:", error);
    }
  };

  // Delete Issue Type
  const deleteIssueType = async (id) => {
    try {
      const response = await deleteAPI(`/building/delete-issuetype/${id}`);
      if (response) {
        getIssueType();
      }
    } catch (error) {
      console.error("Error deleting issue type:", error);
    }
  };

  // Handle Input Changes for Adding
  const handleAddChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  // Handle Input Changes for Editing
  const handleEditChange = (e) => {
    setIssueInputs({ ...issueInputs, [e.target.name]: e.target.value });
  };

  const [page, setPage] = useState(0);
  const [perPages, setPerPages] = useState([10, 25, 50]);
  const [perPage, setPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    getIssueType();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Grid>
      <PageTitle title={"Issue Type"} />
      <Grid>
        <Button
          onClick={() => setModalOpen(true)}
          variant="contained"
          style={{ backgroundColor: "#75859D", float: "right" }}
          sx={{ height: 52, mx: 2 }}
        >
          <AddCircleIcon /> &nbsp; Add Issue Type
        </Button>
      </Grid>

      <Grid style={{ marginTop: "50px" }}>
        <TableContainer
          component={Paper}
          sx={{ mx: "0.8rem", width: "60%", my: "6rem" }}
        >
          <Table
            sx={{ minWidth: "auto" }}
            aria-label="custom pagination table"
            className="responsive-table"
          >
            <TableHead>
              <TableRow className="table-header">
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {issueTypeList
                .slice(page * perPage, page * perPage + perPage)
                .map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">{item.title}</TableCell>
                    <TableCell align="left">
                      <Button
                        size="small"
                        variant="outlined"
                        color="info"
                        onClick={() => openEditModal(item)}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        color="error"
                        style={{ marginLeft: "8px" }}
                        onClick={() => deleteIssueType(item._id)}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={perPages}
                  count={issueTypeList.length} // Total number of items
                  rowsPerPage={perPage} // Number of rows per page
                  page={page} // Current page
                  onPageChange={handleChangePage} // Change page
                  onRowsPerPageChange={handleChangeRowsPerPage} // Change rows per page
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>

      {/* Add Modal */}
      <Modal open={modalOpen}>
        <Box sx={style}>
          <PageTitle title={"Add Issue Type"} />
          <TextField
            label="Enter Issue Type Name"
            variant="outlined"
            fullWidth
            name="title"
            value={inputs.title}
            onChange={handleAddChange}
            style={{ marginBottom: "15px" }}
          />
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                onClick={postIssueType}
                sx={{ height: 40 }}
              >
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => setModalOpen(false)}
                sx={{ height: 40 }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* Edit Modal */}
      <Modal open={editModal}>
        <Box sx={style}>
          <PageTitle title={"Update Issue Type"} />
          <TextField
            label="Edit Issue Type Name"
            variant="outlined"
            fullWidth
            name="title"
            value={issueInputs.title}
            onChange={handleEditChange}
            style={{ marginBottom: "15px" }}
          />
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                onClick={updateIssueType}
                sx={{ height: 40 }}
              >
                Update
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => setEditModal(false)}
                sx={{ height: 40 }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
};

export default AddIssueType;
