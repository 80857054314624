import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { adminLogin, adminV2Login, submitOtp, submitV2Otp } from "./loginApi";
import { useNavigate } from "react-router-dom";
import "./login.css"; // Assuming you are adding the CSS here
import { companyLogin } from "../../utils/companyAuth";
import { postAPI } from "../../network";
import { successMessage } from "../../../utils/Alert";

const Login = () => {
  const [typeOfUser, setTypeOfUser] = useState(true);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpState, setOtpState] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedLoginType, setSelectedLoginType] = useState({
    otpLogin: false,
    ssoLogin: false,
  });

  const handleSelectedLoginType = (e, type) => {
    e.preventDefault();
    if (type === "otpLogin") {
      setSelectedLoginType({ otpLogin: true, ssoLogin: false });
    } else if (type === "ssoLogin") {
      setSelectedLoginType({ otpLogin: false, ssoLogin: true });
    }
  };

  const getOtp = async () => {
    setLoader(true)
    const payload = {
      email: email
    }
    if (typeOfUser) {
      try {
        const response = await adminLogin(payload)
        if (response?.status === 200) {
          successMessage("Email Verified")
          setOtpState(true)
          // const checkV2Api = await adminV2Login(payload)
          // if (checkV2Api.status === 200) {
          //   setOtpState(true)
          // } else {
          //   throw response
          // }
        } else {
          throw response
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        let data = await postAPI('/company/auth/login/otp', payload);
        if (data) {
          console.log(data)
          setEmail(email)
          // setOtpForm(true)
        }
      } catch (error) {
        console.error(error);
      }
    }
    setLoader(false)

  }
  const handleSubmitOtp = async () => {
    setLoader(true)

    const payload = {
      email: email,
      otp: otp
    }
    if (typeOfUser) {
      try {
        const response = await submitOtp(payload)
        if (response?.status === 200) {
          localStorage.setItem("token", response?.data.data?.accessToken);
          localStorage.setItem("user", JSON.stringify(response?.data?.data));
          localStorage.setItem("role", response.data.data.role)
          localStorage.setItem("userType", "admin");
          localStorage.setItem("permissions", JSON.stringify([]));
          const convArray = !Array.isArray(response?.data?.data?.roleId) ? [response?.data?.data?.roleId] : response?.data?.data?.roleId
          if (response?.data?.data?.roleId)
            localStorage.setItem(
              "permisionlist",
              JSON.stringify(convArray)
            );
            console.log(response?.data?.data?.roleId);
            
          localStorage.setItem("userType", "admin");
          if (response?.data?.data?.roleId)
            localStorage.setItem(
              "permissions",
              JSON.stringify(convArray)
            );
          if (response?.data?.data?.company?._id)
            localStorage.setItem("companyIdLS", response?.data?.data?.company?._id);
          navigate("/")
          window.location.reload()
          setOtpState(false)
          // const callNewAdminUserFromV2 = await submitV2Otp(payload)
          // if (callNewAdminUserFromV2?.status === 200) {
          //     localStorage.setItem("role-oldPanel", JSON.stringify(response?.data?.data?.role))
          //     localStorage.setItem("token-oldPanel", JSON.stringify(response?.data?.data?.accessToken))
          //     localStorage.setItem("user-oldPanel", JSON.stringify(response?.data?.data))
          //     localStorage.setItem("role-newPanel", JSON.stringify(callNewAdminUserFromV2?.data?.user?.role?.name))
          //     localStorage.setItem("token-newPanel", JSON.stringify(callNewAdminUserFromV2?.data?.token))
          //     localStorage.setItem("user-newPanel", JSON.stringify(callNewAdminUserFromV2?.data?.user))
          //     localStorage.setItem("permissions", JSON.stringify(callNewAdminUserFromV2?.data?.user?.role))
          //     localStorage.setItem("companyIdLs", JSON.stringify(callNewAdminUserFromV2.data.user.companyId))
          //     const { buildingType, buildingName, floor, zone, deskType } = callNewAdminUserFromV2.data.user
          //     const deskSetting = {}
          //     if (buildingType?._id) deskSetting.buildingType = buildingType?._id
          //     if (buildingName?._id) deskSetting.buildingName = buildingName?._id
          //     if (floor?._id) deskSetting.floor = floor?._id
          //     if (zone?._id) deskSetting.zone = zone?._id
          //     if (deskType?._id) deskSetting.deskType = deskType?._id
          //     localStorage.setItem("deskSetting", JSON.stringify(deskSetting))
          //     navigate("/")
          //     window.location.reload()
          //     setOtpState(false)
          // } else {
          //     throw callNewAdminUserFromV2
          // }
        } else {
          throw response
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        let data = await companyLogin('/company/auth/login', payload);
        if (data) {
          window.token = data.data.accessToken
          localStorage.setItem('token', data.data.accessToken);
          localStorage.setItem('user', JSON.stringify(data.data));
          localStorage.setItem('userType', 'company');
          localStorage.setItem('userType', 'admin');
          if (payload?.data?.roleId) localStorage.setItem("permissions", JSON.stringify(data?.data?.roleId));
          if (payload?.data?.company?._id) localStorage.setItem("companyIdLS", data?.data?.company?._id);
          window.location = window.location.origin + '/company'
          // return navigateTo('/company')
        }
      } catch (error) {
        console.error(error);
      }
    }
    setLoader(false)

  }

  return (
    <div className="login-container">
      <div className="login-left">
        <img src="/Admin Log In.png" alt="login-banner" className="login-banner" />
      </div>
      <div className="login-right">
        <Box className="logo-container">
          <img src="/Logo.png" alt="logo" className="logo" />
        </Box>
        <h1 className="brand-name">
          Secuber
        </h1>
        {!selectedLoginType.otpLogin && !selectedLoginType.ssoLogin && (
          <Box className="login-buttons">
            <Button
              onClick={(e) => handleSelectedLoginType(e, "ssoLogin")}
              className="login-button"
              variant="contained"
            >
              SSO Login
            </Button>
            <Button
              onClick={(e) => handleSelectedLoginType(e, "otpLogin")}
              className="login-button"
              variant="contained"

            >
              OTP Login
            </Button>
          </Box>
        )}
        {selectedLoginType.otpLogin && (
          <Box className="otp-login-container">
            <Typography variant="h5" className="login-title">
              {!typeOfUser ? "Company" : "Admin"} Login
            </Typography>
            {!otpState && (
              <TextField
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                variant="standard"
                className="login-input"
              />
            )}
            {otpState && (
              <TextField
                label="Otp"
                onChange={(e) => setOtp(e.target.value)}
                fullWidth
                variant="standard"
                className="login-input"
              />
            )}
            <Typography className="otp-info">
              We will send you a One Time Password on your email
            </Typography>
            {!otpState && (
              <Button
                onClick={getOtp}
                disabled={loader}
                className="submit-button"
                variant="contained"

              >
                {loader ? <CircularProgress size="30px" /> : "Login"}
              </Button>
            )}
            {otpState && (
              <Button
                onClick={handleSubmitOtp}
                disabled={loader}
                className="submit-button"
                variant="contained"

              >
                {loader ? <CircularProgress size="30px" /> : "Submit OTP"}
              </Button>
            )}
            <Button
              onClick={() => setTypeOfUser(!typeOfUser)}
              className="toggle-login-type"
            >
              {!typeOfUser ? "Admin" : "Company"} Login
            </Button>
          </Box>
        )}
      </div>
    </div>
  );
};

export default Login;
