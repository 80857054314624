import React, { useState, useEffect } from "react";
import PageTitle from "../../../../common/PageTitle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from "@mui/material";

import { getAPI, deleteAPI, postAPI, putAPI } from "../../../../network"; // Assuming you have these functions to make API requests.

const AddFaultType = () => {
  let companyId = localStorage.getItem("companyIdLS");
  if (companyId === null) {
    companyId = undefined;
  }
  const [modalOpen, setModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [faultTypeList, setFaultTypeList] = useState([]);
  const [inputs, setInputs] = useState({ title: "", issueType: "", companyId });
  const [issueTypes, setIssueTypes] = useState([]);
  const [editInputs, setEditInputs] = useState({
    _id: "",
    title: "",
    issueType: "",
    companyId,
  });

  // Fetch Fault Types
  const getFaultTypes = async () => {
    try {
      const response = await getAPI(
        `/building/get-fault?companyId=${companyId}`
      );

      if (response) {
        setFaultTypeList(response);
      }
    } catch (error) {
      console.error("Error fetching fault types:", error);
    }
  };

  const getIssueType = async () => {
    try {
      const response = await getAPI(
        `/building/get-issuetype?companyId=${companyId}`
      );
      if (response) {
        setIssueTypes(response);
      }
    } catch (error) {
      console.error("Error fetching issue types:", error);
    }
  };

  // Delete Fault Type
  const deleteFaultType = async (id) => {
    try {
      const response = await deleteAPI(`/building/delete-fault/${id}`);
      if (response) {
        getFaultTypes(); // Refresh the list after deletion
      }
    } catch (error) {
      console.error("Error deleting fault type:", error);
    }
  };

  // Handle Add Fault Type
  const handleAddFaultType = async () => {
    // Basic validation
    // if (!inputs.title || !inputs.issueType) {
    //   alert("Please fill in all required fields.");
    //   return;
    // }

    try {
      // API call to create a new fault type
      const response = await postAPI("/building/create-fault", {
        title: inputs.title,
        issueType: inputs.issueType,
        companyId: inputs.companyId,
      });

      if (response) {
        setModalOpen(false); // Close the modal
        getFaultTypes(); // Refresh fault types list
      }
    } catch (error) {
      console.error("Error adding fault type:", error);
    }
  };

  // Handle Edit Fault Type
  const handleEditFaultType = async () => {
    try {
      // API call to create a new fault type
      const response = await putAPI(`/building/update-fault/${editInputs._id}`, {
        title: editInputs.title,
        issueType: editInputs.issueType,
        faultType: editInputs.faultType,
        companyId: editInputs.companyId,
      });

      if (response) {
        setModalOpen(false); // Close the modal
        getFaultTypes(); // Refresh fault types list
      }
    } catch (error) {
      console.error("Error adding fault type:", error);
    }
    setEditModal(false);
  };

  // Handle input changes for Add Fault Type
  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  // Handle input changes for Edit Fault Type
  const handleEditChange = (e) => {
    setEditInputs({ ...editInputs, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getFaultTypes();
    getIssueType();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  console.log(faultTypeList); // Check the data structure

  return (
    <Grid>
      <PageTitle title={"Fault Type"} />
      <Grid>
        <Button
          onClick={() => setModalOpen(true)}
          variant="contained"
          style={{ backgroundColor: "#75859D", float: "right" }}
          sx={{ height: 52, mx: 2 }}
        >
          <AddCircleIcon /> &nbsp; Add Fault Type
        </Button>
      </Grid>

      <Grid style={{ marginTop: "50px" }}>
        <TableContainer
          component={Paper}
          sx={{ mx: "0.8rem", width: "60%", my: "6rem" }}
        >
          <Table
            sx={{ minWidth: "auto" }}
            aria-label="custom pagination table"
            className="responsive-table"
          >
            <TableHead>
              <TableRow className="table-header">
                <TableCell align="left">Issue Type</TableCell>
                <TableCell align="left">Fault Type</TableCell>
                <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {faultTypeList.map((item) => (
                <TableRow key={item._id}>
                  {/* Ensure nested issueType and faultType properties are correctly accessed */}
                  <TableCell align="left">{item.issueType?.title}</TableCell>
                  <TableCell align="left">{item.title}</TableCell>
                  <TableCell align="left">
                    <Button
                      size="small"
                      variant="outlined"
                      color="info"
                      onClick={() => {
                        setEditModal(true);
                        setEditInputs({
                          title: item.title,
                          issueType: item.issueType?._id,
                          _id: item._id,
                        });
                      }}
                    >
                      <EditIcon />
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      color="error"
                      style={{ marginLeft: "8px" }}
                      onClick={() => deleteFaultType(item._id)}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Add Modal */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box sx={style}>
          <PageTitle title={"Add Fault Type"} />
          <FormControl fullWidth style={{ marginBottom: "15px" }}>
            <InputLabel>Issue Type</InputLabel>
            <Select
              name="issueType"
              value={inputs.issueType}
              onChange={handleInputChange}
              label="Issue Type"
            >
              {issueTypes.map((issueType) => (
                <MenuItem key={issueType._id} value={issueType._id}>
                  {issueType.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Enter Fault Type Name"
            variant="outlined"
            fullWidth
            name="title"
            value={inputs.title}
            onChange={handleInputChange}
            style={{ marginBottom: "15px" }}
          />

          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                onClick={handleAddFaultType}
                sx={{ height: 40 }}
              >
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => setModalOpen(false)}
                sx={{ height: 40 }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* Edit Modal */}
      <Modal open={editModal} onClose={() => setEditModal(false)}>
        <Box sx={style}>
          <PageTitle title={"Update Fault Type"} />
          <FormControl fullWidth style={{ marginBottom: "15px" }}>
            <InputLabel>Issue Type</InputLabel>
            <Select
              name="issueType"
              value={editInputs.issueType}
              onChange={handleEditChange}
              label="Issue Type"
            >
              {issueTypes.map((issueType) => (
                <MenuItem key={issueType._id} value={issueType._id}>
                  {issueType.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Edit Fault Type Name"
            variant="outlined"
            fullWidth
            name="title"
            value={editInputs.title}
            onChange={handleEditChange}
            style={{ marginBottom: "15px" }}
          />


          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                onClick={handleEditFaultType}
                sx={{ height: 40 }}
              >
                Update
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => setEditModal(false)}
                sx={{ height: 40 }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
};

export default AddFaultType;
