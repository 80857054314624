import { axiosInstance } from "../../../utils/axiosSetup";
// import { axiosV2Instance } from "../../../utils/axiosSetUp.v2";
import { axiosInstanceV3 } from "../../../utils/dbs.axiosSetUp";



export const adminLogin = async (payload) => {
    try {
        const response = await axiosInstance.post("/auth/login", payload)
        if (response?.status === 200) {
            return response
        } else {
            throw response
        }
    } catch (error) {
        console.error(error?.response);
        return error?.response

    }
}
export const submitOtp = async (payload) => {
    try {
        const response = await axiosInstance.post("/auth/login/otp", payload)
        if (response?.status === 200) {
            return response
        } else {
            throw response
        }
    } catch (error) {
        console.error(error?.response);
        return error?.response

    }
}
export const adminV2Login = async (payload) => {
    try {
        const response = await axiosInstanceV3.post("/user/login", payload)
        if (response?.status === 200) {
            return response
        } else {
            throw response
        }
    } catch (error) {
        console.error(error?.response);
        return error?.response

    }
}
export const submitV2Otp = async (payload) => {
    try {
        const response = await axiosInstanceV3.post("/user/verify-otp", payload)
        if (response?.status === 200) {
            return response
        } else {
            throw response
        }
    } catch (error) {
        console.error(error?.response);
        return error?.response

    }
}

