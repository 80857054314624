import React, { useEffect, useState } from "react";
import PageTitle from "../../../../common/PageTitle";

import AddCircleIcon from "@mui/icons-material/AddCircle";
// import EmptyTable from '../../../../common/EmptyTable';
import { tableData, tableHeader } from "../../../../utils";
import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { deleteAPI, getAPI, postAPI, putAPI } from "../../../../network";
import EmptyTable from "../../../../common/EmptyTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const AddBuildingType = () => {
  let companyId = localStorage.getItem("companyIdLS");
  if (companyId === null) {
    companyId = undefined;
  }
  const [modalOpen, setModalOpen] = useState(false);
  const [buildingTypeList, setBuildingTypeList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [inputs, setInputs] = useState({ title: "", companyId: companyId });
  const [buildingInputs, setBuildingInputs] = useState({
    _id: "",
    title: "",
  });
  const [editModal, setEditModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [permissionList, setPermissionList] = useState([]);

  // Load the permission list from localStorage
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permisionlist")) || [];
    setPermissionList(permissions);
  }, []);

  const subActionPermissions = permissionList[0]?.subactionPermissions || [];

  //new Code
  const checkSubActionPermission = (subAction, actionName, permissionList) => {
    if (!permissionList || permissionList.length === 0) {
      return false; // Return false if permissionList is empty or not provided
    }

    const subactionPermissions = permissionList[0]?.subactionPermissions || [];
    const actionPermissions = permissionList[0]?.actionPermissions || [];

    // Find the action ID(s) matching the given actionName
    const matchingActions = actionPermissions.filter(
      (actionPerm) => actionPerm.action === actionName
    );

    if (matchingActions.length === 0) {
      return false; // If no matching actions are found, return false
    }

    // Check if any subAction is linked to the matching actions
    return subactionPermissions.some((subPerm) => {
      return (
        subPerm.subAction === subAction &&
        matchingActions.some((actionPerm) => actionPerm._id === subPerm.action)
      );
    });
  };

  // const res = checkSubActionPermission("Edit", "Building Type", permissionList);
  // console.log("RES>>>" + res);
  // Check if 'Delete' and 'Edit' subActions exist
  const canDelete = subActionPermissions.some(
    (permission) => permission.subAction === "Delete"
  );
  const canEdit = subActionPermissions.some(
    (permission) => permission.subAction === "Edit"
  );
  const canAdd = subActionPermissions.some(
    (permission) => permission.subAction === "Add"
  );

  // If subActionPermissions is empty, show both buttons
  const showBothButtons = subActionPermissions.length === 0;
  // -------------------------------------------- Table States and Static Data Manipulation Start --------------------------------------

  const [page, setPage] = useState(0);
  const [perPages, setPerPages] = useState([10, 25, 50]);
  const [perPage, setPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value);
    setPerPage(event.target.value);
    setPage(0);
  };

  // -------------------------------------------- Table States and Static Data Manipulation Ends --------------------------------------

  // ---------------------------------------------- Event Handlers Start --------------------------------------------------------------

  const onChange = async (e) => {
    e.preventDefault();

    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const editChange = async (e) => {
    e.preventDefault();

    setBuildingInputs({ ...buildingInputs, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (editMode) {
      console.log("---edit mode");
      editBuildingTypee();
    } else {
      console.log("----create mode");
      postBuildingType();
    }
  };

  const editBuildingType = async (e, _id) => {
    e.preventDefault();

    setEditModal(true);
    setEditMode(true);

    let data = buildingTypeList?.filter((item) => item?._id === _id)[0];

    setBuildingInputs(data);
  };

  // ---------------------------------------------- Event Handlers Ends --------------------------------------------------------------

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  // ---------------------------------------------- Building-Type CRUD Start ------------------------------------------------------

  const postBuildingType = async () => {
    setLoader(true);

    const response = await postAPI("/building/type", inputs);
    if (response) {
      getBuildingType();
      setModalOpen(false);
    }
    setLoader(false);
  };

  const getBuildingType = async () => {
    setLoader(true);
    const response = await getAPI(`/building/type?companyId=${companyId}`);
    if (response) {
      setBuildingTypeList(response);
    }
    setLoader(false);
  };

  const editBuildingTypee = async () => {
    setLoader(true);
    const response = await putAPI(
      `/building/type/${buildingInputs?._id}`,
      buildingInputs
    );
    if (response) {
      getBuildingType();
      setEditModal(false);
      setEditMode(false);
    }
    setLoader(false);
  };

  const deleteBuildingType = async (_id) => {
    console.log(_id);
    setLoader(true);
    const response = await deleteAPI(`/building/type/${_id}`);
    if (response) {
      getBuildingType();
    }
    setLoader(false);
  };

  // ---------------------------------------------- Building-Type CRUD Ends -------------------------------------------------------

  console.log(buildingTypeList);
  useEffect(() => {
    getBuildingType();
  }, []);
  return (
    <Grid>
      <PageTitle title={"Building Type"} />

      <Grid>
        {(showBothButtons ||
          checkSubActionPermission("Add", "Building Type", permissionList)) && (
          <Button
            onClick={() => setModalOpen(true)}
            variant="contained"
            style={{ backgroundColor: "#75859D", float: "right" }}
            sx={{ height: 52, mx: 2 }}
          >
            <AddCircleIcon /> &nbsp; &nbsp; Add Building Type
          </Button>
        )}
      </Grid>

      <Grid style={{ marginTop: "50px" }}>
        <TableContainer
          component={Paper}
          sx={{ mx: "0.8rem", width: "60%", my: "6rem" }}
        >
          {/* <Grid style={{width: 'auto', overflowX: 'scroll'}}> */}

          <Table
            sx={{ minWidth: "auto" }}
            aria-label="custom pagination table"
            className="responsive-table"
          >
            <TableHead>
              <TableRow className="table-header">
                <TableCell align="left" component="th" sx={tableHeader}>
                  Type
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {/* {console.log("Users---",users)} */}
            <TableBody>
              {[...buildingTypeList]
                ?.reverse()
                .slice(page * perPage, page * perPage + perPage)
                .map((item, index) => (
                  <TableRow key={index}>
                    {/* <TableCell align="left" sx={tableData}>
                        {fullName(item)}
                      </TableCell> */}
                    <TableCell align="left" sx={tableData}>
                      {item?.title}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {(showBothButtons ||
                        checkSubActionPermission(
                          "Edit",
                          "Building Type",
                          permissionList
                        )) && (
                        <Button
                          size="small"
                          className="btn-Grid"
                          variant="outlined"
                          color="info"
                          onClick={(e) => editBuildingType(e, item?._id)}
                        >
                          <EditIcon />
                        </Button>
                      )}

                      {/* Render Delete button */}
                      {(showBothButtons ||
                        checkSubActionPermission(
                          "Delete",
                          "Building Type",
                          permissionList
                        )) && (
                        <Button
                          size="small"
                          className="btn-Grid"
                          variant="outlined"
                          color="error"
                          onClick={() => deleteBuildingType(item?._id)}
                        >
                          <DeleteIcon />
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              {!buildingTypeList && <EmptyTable colSpan={9} />}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  align="right"
                  rowsPerPageOptions={perPages}
                  colSpan={9}
                  count={1}
                  rowsPerPage={perPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  // ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
          {/* </Grid> */}
        </TableContainer>
      </Grid>

      <Grid>
        <Modal open={modalOpen}>
          <Box sx={style}>
            <PageTitle title={"Add Building"} />

            <TextField
              label="Enter Building Name"
              value={inputs?.title}
              name="title"
              id="title"
              variant="outlined"
              style={{ width: "100%", marginLeft: "10px" }}
              onChange={onChange}
            />

            <Grid
              style={{
                marginTop: "15px",
                width: "100%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "10px",
                marginLeft: "10px",
              }}
            >
              <Button
                variant="contained"
                onClick={onSubmit}
                style={{ float: "right" }}
                sx={{ height: 40 }}
              >
                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                Submit
              </Button>

              <Button
                onClick={() => {
                  setModalOpen(false);
                  setEditMode(false);
                }}
                variant="outlined"
                style={{ float: "right" }}
                sx={{ height: 40 }}
              >
                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                Cancel
              </Button>
            </Grid>

            <Grid></Grid>
          </Box>
        </Modal>
      </Grid>

      <Grid>
        <Modal open={editModal}>
          <Box sx={style}>
            <PageTitle title={"Update Building"} />

            <TextField
              label="Enter Building Name"
              value={buildingInputs?.title}
              name="title"
              id="title"
              variant="outlined"
              style={{ width: "100%", marginLeft: "10px" }}
              onChange={editChange}
            />

            <Grid
              style={{
                marginTop: "15px",
                width: "100%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "10px",
                marginLeft: "10px",
              }}
            >
              <Button
                variant="contained"
                onClick={onSubmit}
                style={{ float: "right" }}
                sx={{ height: 40 }}
              >
                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                Update
              </Button>

              <Button
                onClick={() => {
                  setEditModal(false);
                  setEditMode(false);
                }}
                variant="outlined"
                style={{ float: "right" }}
                sx={{ height: 40 }}
              >
                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                Cancel
              </Button>
            </Grid>

            <Grid></Grid>
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default AddBuildingType;
