import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import PageTitle from "../../common/PageTitle";
import SiteDetailCard from "../../components/sites/DetailCard";

const Visitor = () => {
  const [hasVisitorManagementPermission, setHasVisitorManagementPermission] =
    useState(false);
  const [hasVisitorTaskPermission, setHasVisitorTaskPermission] =
    useState(false);

  useEffect(() => {
    // Get permissionList from local storage
    const permissionList =
      JSON.parse(localStorage.getItem("permisionlist")) || [];

    // Check for submenu permissions in permissionList
    if (permissionList.length > 0) {
      const submenuPermissions = permissionList[0]?.submenuPermissions || [];

      // Set permission flags based on submenu titles
      setHasVisitorManagementPermission(
        submenuPermissions.some(
          (permission) => permission.submenu === "Visitor Management"
        )
      );
      setHasVisitorTaskPermission(
        submenuPermissions.some(
          (permission) => permission.submenu === "Visitor Task Management"
        )
      );
    } else {
      // If permissionList is empty or undefined, show both cards by default
      setHasVisitorManagementPermission(true);
      setHasVisitorTaskPermission(true);
    }
  }, []);

  return (
    <Box sx={{ height: "inherit" }}>
      <PageTitle title="Visitor" />
      <Box display="flex">
        <Grid
          container
          rowSpacing={7}
          columnSpacing={{ xs: 1 }}
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          mt="1%"
        >
          {hasVisitorManagementPermission && (
            <SiteDetailCard
              large
              title="Visitor Management"
              url="/visitor_management"
            />
          )}
          {hasVisitorTaskPermission && (
            <SiteDetailCard
              large
              title="Visitor Task Management"
              url="/taskManagement"
            />
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default Visitor;
