import React, { useEffect, useState } from 'react';
import { Box, Grid } from "@mui/material";
import PageTitle from '../../common/PageTitle';
import SiteDetailCard from '../../components/sites/DetailCard';

const RiskAssessmentNav = () => {
    const [allowedCards, setAllowedCards] = useState([]);

    useEffect(() => {
        const permissionList = JSON.parse(localStorage.getItem('permisionlist'));
        const cards = [
            { title: 'Forms', url: '/riskAssessment/forms' },
            { title: 'Options', url: '/riskAssessment/options' },
            { title: 'Reports', url: '/riskAssessment/reports' },
        ];

        if (!permissionList || permissionList[0]?.submenuPermissions.length === 0) {
            // If no permissions are defined, show all cards
            setAllowedCards(cards);
        } else {
            // Filter cards based on submenuPermissions
            const submenuPermissions = permissionList[0].submenuPermissions.map(item => item.submenu);
            const filteredCards = cards.filter(card => submenuPermissions.includes(card.title));
            setAllowedCards(filteredCards);
        }
    }, []);

    return (
        <Box sx={{ height: "inherit" }}>
            <PageTitle title="Risk Assessment " />
            <Box display="flex">
                <Grid
                    container
                    rowSpacing={7}
                    columnSpacing={{ xs: 1 }}
                    alignContent="center"
                    justifyContent="center"
                    alignItems="center"
                    mt="1%"
                >
                    {allowedCards.map((card, index) => (
                        <SiteDetailCard key={index} large title={card.title} url={card.url} />
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default RiskAssessmentNav;
