import React, { useEffect, useState } from 'react'
import { checkAuthority } from '../../../utils';
import SiteDetailCard from '../../../components/sites/DetailCard';
import "../ContractProfile.css"

const AddAdminContract = () => {
  const segments = window.location?.pathname;
  console.log(segments, "----------maintainsegment");

  const [contract, setContract] = useState([])

  useEffect(() => {
    getContractDetails()
  }, [segments])

  const getContractDetails = () => {
    var arr = []
    let url = segments?.split("/")[1] === 'company' ? '/company' : ''

    if (checkAuthority('ADD_CONTRACT')) {
      arr.push({
        id: 1,
        title: "Add Admin Contract",
        url: `${url}/maintainance-report/contractors/admin-contract/create`
      })
    }

    if (checkAuthority('VIEW_CONTRACT')) {
      arr.push({
        id: 2,
        title: "View Admin Contract",
        url: `${url}/maintainance-report/contractors/view-admin-contract`
      })
    }

    setContract(arr)
  }
  return (
    <div className='datacard'>
      {
        contract?.map((contract) => {
          return <SiteDetailCard title={contract?.title} url={contract?.url} />
        })
      }
    </div>
  )
}

export default AddAdminContract