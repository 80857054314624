import React, { useEffect, useState } from "react";
import { checkAuthority } from "../../../utils";
import PageTitle from "../../../common/PageTitle";
import SiteDetailCard from "../../../components/sites/DetailCard";
import { Grid } from "@material-ui/core";

const Settings = () => {
  const [fsrList, setFsrList] = useState([]);
  const segments = window.location?.pathname;

  useEffect(() => {
    getManagementDetails();
  }, [segments]);

  const getManagementDetails = () => {
    let arr = [];
    let url = segments?.split("/")[1] === "company" ? "/company" : "";

    // Fetch permission list from localStorage
    const permissionList =
      JSON.parse(localStorage.getItem("permisionlist")) || [];
    console.log("PERMISSION_LIST" + JSON.stringify(permissionList)); // Debug: Check the permissionList

    // If permissionList is empty, show all titles
    if (permissionList.length === 0) {
      console.log("No permissions available. Displaying all titles.");

      // Add all titles to the list if no permissions are found
      arr.push({
        id: 1,
        title: "Building Type",
        url: `${url}/fsr/settings/building-type/add`,
      });
      arr.push({
        id: 2,
        title: "Building Name",
        url: `${url}/fsr/settings/building-name/add`,
      });
      arr.push({
        id: 3,
        title: "Building Floor",
        url: `${url}/fsr/settings/building-floor/add`,
      });
      arr.push({
        id: 4,
        title: "Space Type",
        url: `${url}/fsr/settings/building-space/add`,
      });
      arr.push({
        id: 5,
        title: "Space Id",
        url: `${url}/fsr/settings/building-space-id/add`,
      });
      arr.push({
        id: 6,
        title: "Asset Id",
        url: `${url}/fsr/settings/building-asset-id/add`,
      });
      arr.push({
        id: 7,
        title: "Assignee",
        url: `${url}/fsr/settings/building-assignee/add`,
      });
      arr.push({
        id: 8,
        title: "Vendor",
        url: `${url}/fsr/settings/building-vendor/add`,
      });
      arr.push({
        id: 9,
        title: "Issue Type",
        url: `${url}/fsr/settings/issue-type/add`,
      });
      arr.push({
        id: 10,
        title: "Fault Type",
        url: `${url}/fsr/settings/fault-type/add`,
      });
      setFsrList(arr);
      return; // Stop further execution when no permissions are available
    }

    // Extract actionPermissions from the permission list
    const actionPermissions = permissionList.reduce((acc, perm) => {
      if (perm?.actionPermissions) {
        acc = acc.concat(
          perm.actionPermissions.map((permission) => permission.action)
        );
      }
      return acc;
    }, []);

    console.log("SubAction Permissions:", actionPermissions); // Debug: Check the actionPermissions

    // Function to check if a title exists in actionPermissions
    const checkActionPermission = (title) => {
      return actionPermissions.includes(title);
    };

    // Conditionally add items to the list based on authority and action permissions
    if (
      checkAuthority("VIEW_ADD_BUILDING_TYPE") &&
      checkActionPermission("Building Type")
    ) {
      arr.push({
        id: 1,
        title: "Building Type",
        url: `${url}/fsr/settings/building-type/add`,
      });
    }

    if (
      checkAuthority("VIEW_ADD_BUILDING_NAME") &&
      checkActionPermission("Building Name")
    ) {
      arr.push({
        id: 2,
        title: "Building Name",
        url: `${url}/fsr/settings/building-name/add`,
      });
    }

    if (
      checkAuthority("VIEW_ADD_BUILDING_FLOOR") &&
      checkActionPermission("Building Floor")
    ) {
      arr.push({
        id: 3,
        title: "Building Floor",
        url: `${url}/fsr/settings/building-floor/add`,
      });
    }

    if (
      checkAuthority("VIEW_ADD_BUILDING_SPACE_TYPE") &&
      checkActionPermission("Space Type")
    ) {
      arr.push({
        id: 4,
        title: "Space Type",
        url: `${url}/fsr/settings/building-space/add`,
      });
    }

    if (
      checkAuthority("VIEW_ADD_BUILDING_SPACE_ID") &&
      checkActionPermission("Space Id")
    ) {
      arr.push({
        id: 5,
        title: "Space Id",
        url: `${url}/fsr/settings/building-space-id/add`,
      });
    }

    if (
      checkAuthority("VIEW_ADD_BUILDING_ASSET_ID") &&
      checkActionPermission("Asset Id")
    ) {
      arr.push({
        id: 6,
        title: "Asset Id",
        url: `${url}/fsr/settings/building-asset-id/add`,
      });
    }

    if (
      checkAuthority("VIEW_ADD_BUILDING_ASSIGNEE") &&
      checkActionPermission("Assignee")
    ) {
      arr.push({
        id: 7,
        title: "Assignee",
        url: `${url}/fsr/settings/building-assignee/add`,
      });
    }

    if (
      checkAuthority("VIEW_ADD_BUILDING_VENDOR") &&
      checkActionPermission("Vendor")
    ) {
      arr.push({
        id: 8,
        title: "Vendor",
        url: `${url}/fsr/settings/building-vendor/add`,
      });
    }

    // Set the filtered list to state
    setFsrList(arr);
  };

  return (
    <Grid>
      <PageTitle title={"Setting List"} />

      {/* Render a message when there are no items */}
      {fsrList.length === 0 ? (
        <div>No settings available based on your permissions.</div>
      ) : (
        <Grid className="datacard">
          {fsrList.map((fsr) => (
            <SiteDetailCard key={fsr.id} title={fsr.title} url={fsr.url} />
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default Settings;
