import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import DashboardIcon from "@mui/icons-material/Dashboard";
// import PeopleIcon from "@mui/icons-material/People";
import { Box, ListSubheader, Divider, Tooltip } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Map as MapIcon,
  ListAlt,
  Schedule,
  Report,
  Security,
  HealthAndSafety,
  Pages,
  Book,
  Notifications,
  Quiz,
  SupportAgent,
  Logout,
  House,
  Apartment,
  PeopleAlt,
} from "@mui/icons-material";
import SafetyCheckIcon from "@mui/icons-material/SafetyCheck";
import AddTaskIcon from "@mui/icons-material/AddTask";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import InsightsIcon from "@mui/icons-material/Insights";
import GroupsIcon from "@mui/icons-material/Groups";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import ListItemLink from "../common/ListItemLink";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CoPresentSharpIcon from "@mui/icons-material/CoPresentSharp";
import ListAltSharpIcon from "@mui/icons-material/ListAltSharp";
import PeopleIcon from "@mui/icons-material/People";
import Group from "@mui/icons-material/People";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { useDispatch, useSelector } from "react-redux";
import { logOutUser, selectAuth } from "../../features/auth/authSlices";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
// import {adminMenus, companyMenus} from '../utils/data'

function SideMenu() {
  const [dashboardMenus, setDashboardMenus] = useState([]);
  const userType = localStorage.getItem("userType");
  const permissions = JSON.parse(localStorage.getItem("permisionlist") || 0);
  const [slice, setSlice] = useState(4);
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const [dynamicCompanyMenu, setDynamicCompanyMenu] = useState([]);
  const companyId = localStorage.getItem("companyIdLS");
  useEffect(() => {
    getCompanyMenu();
    getDashboardMenus();
  }, [userType]);

  const logOut = () => {
    return userType === "admin"
      ? navigateTo("/login")
      : navigateTo("/company/login");
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(logOutUser());
    navigateTo("/login");
  };

  const adminMenus = [
    {
      primary: "Dashboard",
      to: "/",
      icon: <DashboardIcon />,
    },
    {
      primary: "Companies",
      to: "/companies",
      icon: <Apartment />,
    },
    {
      primary: "Sites",
      to: "/sites",
      icon: <ViewModuleIcon />,
    },
    {
      primary: "DBS",
      externalTo: "https://dbs.secuber.myappsdevelopment.co.in/",
      to: "https://dbs.secuber.myappsdevelopment.co.in/",
      icon: <AirlineSeatReclineNormalIcon />,
    },
    {
      primary: "FSR",
      to: "/fsr",
      icon: <EngineeringIcon />,
    },
    {
      primary: "Maintainance Report",
      to: "/maintainance_report",
      icon: <AssessmentIcon />,
    },
    // {
    //   primary: "Properties",
    //   to: "/properties",
    //   icon: <AssessmentIcon />,
    // },
    // {
    //   primary: "Contractor profile",
    //   to: "/contractorprofile",
    //   icon: <AssessmentIcon />,
    // },
    {
      primary: "VMS",
      to: "/visitor",
      icon: <CoPresentSharpIcon />,
    },
    // {
    //   primary: "Add Visitor Task",
    //   to: "/visitor_task",
    //   icon: <ListAltSharpIcon />,
    // },
    // {
    //   primary: "Visitor Task Management",
    //   to: "/taskManagement",
    //   icon: <Book />,
    // },

    {
      primary: "Vendors",
      to: "/vendors",
      icon: <AccessibilityIcon />,
    },
    // {
    //   primary: "Client",
    //   to: "/client",
    //   icon: <PeopleIcon />,
    // },

    {
      primary: "Admin Users",
      to: "/company/users",
      icon: <PeopleIcon />,
    },
    // {
    //   primary: "Clients",
    //   to: "/vendor/:vendorId/clients",
    //   icon: <PeopleOutlineIcon />,
    // },
    {
      primary: "Admin Roles",
      to: "/roles",
      icon: <VerifiedUserIcon />,
    },
    // {
    //   primary: "Guards",
    //   to: "/guards",
    //   icon: <Security />,
    // },
    {
      primary: "Client Resource's",
      to: "/users",
      icon: <PeopleOutlineIcon />,
    },
    {
      primary: "All App Users",
      to: "/app-users",
      icon: <GroupsIcon />,
    },
    {
      primary: "GPS Trackers",
      to: "/trackers",
      icon: <MapIcon />,
    },
    {
      primary: "Timesheets",
      to: "/timesheets",
      icon: <ListAlt />,
    },
    {
      primary: "Scheduler",
      to: "/scheduler",
      icon: <Schedule />,
    },
    {
      primary: "Reports",
      to: "/reports",
      icon: <InsightsIcon />,
    },

    {
      primary: "Risk Assessment",
      to: "/riskAssessment",
      icon: <Pages />,
    },
    {
      primary: "Alert Information",
      to: "/alert-information",
      icon: <ReportProblemIcon />,
    },
    {
      primary: "Safety Tips",
      to: "/safety-tips",
      icon: <HealthAndSafety />,
    },

    {
      primary: "License Type",
      to: "/licensetype",
      icon: <AssignmentIcon />,
    },
    {
      primary: "Shift Type",
      to: "/shifttype",
      icon: <DragIndicatorIcon />,
    },
    {
      primary: "Notification",
      to: "/notifications",
      icon: <Notifications />,
    },
    {
      primary: "FAQ's",
      to: "/faq",
      icon: <Quiz />,
    },
    {
      primary: "Support",
      to: "/supports",
      icon: <SupportAgent />,
    },
    {
      primary: "Task",
      to: "/tasks/list",
      icon: <AddTaskIcon />,
    },
    {
      primary: "Welfare Check",
      to: "/welfare",
      icon: <SafetyCheckIcon />,
    },
  ];
  const filterMenus = () => {
    if (!permissions || permissions.length === 0) {
      setDashboardMenus(adminMenus);
      return;
    }

    const allowedMenus = adminMenus.filter((menu) => {
      const hasPermission = permissions?.some((role) =>
        role?.menuPermissions?.some(
          (permission) => permission.menu === menu.primary
        )
      );
      return hasPermission;
    });

    setDashboardMenus(allowedMenus.length > 0 ? allowedMenus : adminMenus);
  };

  useEffect(() => {
    filterMenus();
  }, []);

  const getCompanyMenu = () => {
    var arr = [];
    let find = [
      "VIEW_DASHBOARD",
      "VIEW_SITES",
      "VIEW_MAINTAINANCE_REPORT",
      // 'VIEW_PROPERTIES',
      // 'VIEW_CONTRACTOR',
      "VIEW_FSR",
      "VIEW_RESOURCE",
      "VIEW_TRACKER",
      "VIEW_TIMESHEETS",
      "VIEW_SCHEDULER",
      "VIEW_SITE_REPORTS",
      "VIEW_ALERTS",
      "VIEW_TIPS",
      "VIEW_SITE_OVERVIEWS",
      "VIEW_NOTIFICATIONS",
      "VIEW_TASKS",
      "ADD_TASKS",
      "VIEW_USERS",
      "ADD_ASSESSMENT_CATEGORY",
    ];

    for (var i = 0; i < find.length; i++) {
      for (var j = 0; j < permissions.length; j++) {
        if (permissions[j]?.name === find[i]) {
          if (find[i] === "VIEW_DASHBOARD") {
            arr.push({
              primary: "Dashboard",
              to: "/company",
              icon: <DashboardIcon />,
            });
          } else if (find[i] === "VIEW_SITES") {
            arr.push({
              primary: "Sites",
              to: "/company/sites",
              icon: <ViewModuleIcon />,
            });
          } else if (find[i] === "VIEW_FSR") {
            arr.push({
              primary: "FSR",
              to: "/fsr",
              icon: <EngineeringIcon />,
            });
          } else if (find[i] === "VIEW_MAINTAINANCE_REPORT") {
            arr.push({
              primary: "Sites",
              to: "/company/maintainance_report",
              icon: <AssessmentIcon />,
            });
          }
          // else if (find[i] === 'VIEW_PROPERTIES') {
          //   arr.push({
          //     primary: "Sites",
          //     to: "/company/properties",
          //     icon: <AssessmentIcon />,
          //   })
          // }
          // else if (find[i] === 'VIEW_CONTRACTOR') {
          //   arr.push({
          //     primary: "Contractor",
          //     to: "/company/contractorprofile",
          //     icon: <AssessmentIcon />,
          //   })
          // }
          else if (find[i] === "VIEW_RESOURCE") {
            arr.push({
              primary: "Guards",
              to: "/company/guards",
              icon: <Security />,
            });
          } else if (find[i] === "VIEW_TRACKER") {
            arr.push({
              primary: "Trackers",
              to: "/company/trackers",
              icon: <MapIcon />,
            });
          } else if (find[i] === "VIEW_TIMESHEETS") {
            arr.push({
              primary: "Timesheets",
              to: "/company/timesheets",
              icon: <ListAlt />,
            });
          } else if (find[i] === "VIEW_SCHEDULER") {
            arr.push({
              primary: "Scheduler",
              to: "/company/scheduler",
              icon: <Schedule />,
            });
          } else if (find[i] === "VIEW_SITE_REPORTS") {
            arr.push({
              primary: "Reports",
              to: "/company/reports",
              icon: <InsightsIcon />,
            });
          } else if (find[i] === "VIEW_ALERTS") {
            arr.push({
              primary: "Alert Information",
              to: "/company/alert-information",
              icon: <ReportProblemIcon />,
            });
          } else if (find[i] === "VIEW_TIPS") {
            arr.push({
              primary: "Safety Tips",
              to: "/company/safety-tips",
              icon: <HealthAndSafety />,
            });
          } else if (find[i] === "ADD_ASSESSMENT_CATEGORY") {
            arr.push({
              primary: "Assessment Category",
              to: "/company/addRiskCategory",
              icon: <Pages />,
            });
          } else if (find[i] === "VIEW_NOTIFICATIONS") {
            arr.push({
              primary: "Notification",
              to: "/company/notifications",
              icon: <Notifications />,
            });
          } else if (find[i] === "VIEW_TASKS") {
            arr.push({
              primary: "Task",
              to: "/company/tasks/list",
              icon: <AddTaskIcon />,
            });
          } else if (find[i] === "VIEW_TASKS") {
            arr.push({
              primary: "Task",
              to: "/tasks/details",
              icon: <AddTaskIcon />,
            });
          }
        }
      }
    }
    setDynamicCompanyMenu(arr.length > 0 ? arr : adminMenus);
  };

  const companyMenus = [
    {
      primary: "Dashboard",
      to: "/company",
      icon: <DashboardIcon />,
    },
    {
      primary: "Sites",
      to: "/company/sites",
      icon: <ViewModuleIcon />,
    },
    {
      primary: "FSR",
      to: "fsr",
      icon: <EngineeringIcon />,
    },
    {
      primary: "Maintainance Report",
      to: "/company/mainatainance_report",
      icon: <AssessmentIcon />,
    },
    {
      primary: "Properties",
      to: "/company/properties",
      icon: <AssessmentIcon />,
    },

    {
      primary: "Contractor profile",
      to: "/contractorprofile",
      icon: <AssessmentIcon />,
    },
    {
      primary: "Guards",
      to: "/company/guards",
      icon: <Security />,
    },

    {
      primary: "Trackers",
      to: "/company/trackers",
      icon: <MapIcon />,
    },
    {
      primary: "Timesheets",
      to: "/company/timesheets",
      icon: <ListAlt />,
    },
    {
      primary: "Scheduler",
      to: "/company/scheduler",
      icon: <Schedule />,
    },
    {
      primary: "Reports",
      to: "/company/reports",
      icon: <InsightsIcon />,
    },
    {
      primary: "Alert Information",
      to: "/company/alert-information",
      icon: <ReportProblemIcon />,
    },
    {
      primary: "Safety Tips",
      to: "/company/safety-tips",
      icon: <HealthAndSafety />,
    },
    {
      primary: "App Page",
      to: "#",
      icon: <Pages />,
    },
    {
      primary: "Notification",
      to: "/company/notifications",
      icon: <Notifications />,
    },
    // {
    //   primary: "FAQ's",
    //   to: "/company/faq",
    //   icon: <Quiz />,
    // },
    {
      primary: "Task",
      to: "/company/tasks/list",
      icon: <AddTaskIcon />,
    },
    {
      primary: "Welfare Check",
      to: "/welfare",
      icon: <SafetyCheckIcon />,
    },
  ];

  const getDashboardMenus = () => {
    if (userType === "admin") {
      setDashboardMenus(adminMenus);
      setSlice(3);
    } else {
      setDashboardMenus(dynamicCompanyMenu);
      setSlice(2);
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        overflowY: "auto",
        pb: 1,
      }}
    >
      <List
        component="nav"
        subheader={
          <ListSubheader
            sx={{
              fontSize: "larger",
              fontWeight: "700",
              lineHeight: "normal",
              height: "40px",
              my: 1,
            }}
            component="p"
            id="nested-list-subheader"
          >
            {userType === "admin" ? "Admin" : "Company"}
          </ListSubheader>
        }
      >
        {dashboardMenus.slice(0, slice).map(({ primary, to, icon }) => (
          // Wrap each ListItemButton with Tooltip
          <Tooltip key={primary} title={!companyId ? "Please select a company first." : ""} disableInteractive>
            <span>
              <ListItemButton
                component="a"
                href={to}
                // target="_blank"
                // rel="noopener noreferrer"  // This is added for security purposes when using target="_blank"
                disabled={!companyId} // Disable the button if no companyId is present
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText sx={{ fontWeight: "500" }} primary={primary} />
              </ListItemButton>
            </span>
          </Tooltip>
        ))}
      </List>

      <List>
        {dashboardMenus.slice(slice).map(({ primary, to, icon }) => (
          // Wrap each ListItemButton with Tooltip
          <Tooltip key={primary} title={!companyId ? "Please select a company first." : ""} disableInteractive>
            <span>
              <ListItemButton
                component="a"
                href={to}
                target="_blank"
                rel="noopener noreferrer"
                disabled={!companyId} // Disable the button if no companyId is present
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText sx={{ fontWeight: "500" }} primary={primary} />
              </ListItemButton>
            </span>
          </Tooltip>
        ))}

        <li>
          <ListItemButton
            pt={0}
            onClick={(e) => {
              e.preventDefault();
              handleLogout();
            }}
          >
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText sx={{ fontWeight: "500" }} primary={"Log Out"} />
          </ListItemButton>
        </li>
      </List>
    </Box>
  );
}

export default SideMenu;
