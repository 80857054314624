import React, { useEffect, useState } from 'react';
import { Box, Grid } from "@mui/material";
import PageTitle from '../../common/PageTitle';
import SiteDetailCard from '../../components/sites/DetailCard';

const NotificationPage = () => {
  const [showCreate, setShowCreate] = useState(true);
  const [showView, setShowView] = useState(true);

  useEffect(() => {
    const permissionList = JSON.parse(localStorage.getItem('permisionlist'));
    if (permissionList && permissionList[0]?.submenuPermissions.length > 0) {
      const submenuPermissions = permissionList[0].submenuPermissions.map(item => item.submenu);

      // Set states based on submenu permissions
      setShowCreate(submenuPermissions.includes("Create"));
      setShowView(submenuPermissions.includes("View"));
    }
  }, []);

  return (
    <Box sx={{ height: "inherit" }}>
      <PageTitle title="Notification" />
      <Box display="flex">
        <Grid
          container
          rowSpacing={7}
          columnSpacing={{ xs: 1 }}
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          mt="1%"
        >
          {showCreate && <SiteDetailCard large title={'Create Notification'} url={'/notifications/create'} />}
          {showView && <SiteDetailCard large title={'View Notification'} url={'/notifications/view'} />}
        </Grid>
      </Box>
    </Box>
  );
};

export default NotificationPage;
