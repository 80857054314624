import React, { useEffect ,useState} from "react";
import { Box, Grid, Button, Typography, Divider } from "@mui/material";
import PageTitle from "../../../common/PageTitle";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableFooter from '@mui/material/TableFooter';
// import TablePagination from '@mui/material/TablePagination'; 
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
// import { blue } from '@mui/material/colors';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import InputLabel from '@mui/material/InputLabel';
// import FormHelperText from '@mui/material/FormHelperText';
// import FormControl from '@mui/material/FormControl';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate, useLocation } from "react-router-dom";
import { tableHeader, tableData, getTimeFromDate } from "../../../utils";
import { getAPI } from "../../../network";
import "./style.css";
import { axiosInstance } from "../../../../utils/axiosSetup";
import moment from "moment";



export default function TimesheetResultPage(props) {
  const location = useLocation();
  const [siteId, setSiteId] = React.useState("");
  const [timeSheets, setTimeSheets] = React.useState({});
  const navigateTo = useNavigate();
  const Id = location.state.id

  if (location != null) {
    if (location?.state?.Item) {
      setTimeSheets(location.state.task)
    }
  }

  useEffect(() => {
    setSiteId(siteId);
    getTimeSheetData();

  }, []);
  const getTimeSheetData = async () => {
    try {
      const res = await getAPI(`/admin/timesheet/${Id}`);

      setTimeSheets(res);
      return res;
    } catch (error) {
      return error.message || error.msg;
    }
  };
  console.log("TimeSheet---+++++++++++++++++++++++++++", timeSheets)
  // const handleTimeSheetApproved = async (id) => {
  //   try {
  //     const res = await axiosInstance.patch(`/admin/timesheet/change-status/${id}`, {
  //       status: "62ad8408ae2a0f2b4881335f",
  //        notes: "Notes by approver"
  //     });
  //     navigateTo(-1);
  //     return res.data;
  //   } catch (error) {
  //     return error.message || error.msg;
  //   }
  // }

  const handleTimeSheet = async (id, action) => {
    try {
      if (action === 'reject') {
        const res = await axiosInstance.patch(`/admin/timesheet/change-status/${id}`, {
          statusId: "66a9d41eb9dfdf15f8fc2f29",

        });
        navigateTo(-1);
        return res.data;
      }
      else {
        const res = await axiosInstance.patch(`/admin/timesheet/change-status/${id}`, {
          statusId: "66a9d413b9dfdf15f8fc2f26",

        });
        navigateTo(-1);
        return res.data;
      }


    } catch (error) {
      return error.message || error.msg;
    }
  }

  const [permissionList, setPermissionList] = useState([]);

  // Load the permission list from localStorage
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permisionlist")) || [];
    setPermissionList(permissions);
  }, []);

  const actionPermissions = permissionList[0]?.actionPermissions || [];

  // Check if 'Delete' and 'Edit' subActions exist
  
  const canAdd = actionPermissions.some(
    (permission) => permission.action === "Approve"
  );
  const canReject = actionPermissions.some(
    (permission) => permission.action === "Reject"
  );
  

  // If actionPermissions is empty, show both buttons
  const showBothButtons = actionPermissions.length === 0;

  return (
    <Box sx={{ height: "inherit" }}>
      <PageTitle title="Timesheets" />
      <Grid className="sort-box" container direction={"row"} sx={{ mr: "0.8rem", mt: "3rem" }} >
        {/* {console.log("timeSheets?.statusId?.name============",timeSheets)} */}

        <Grid item xs={8} textAlign='center' >


          <Box sx={{ height: "inherit", mr: 1.5, my: 4, width: "inherit" }} className="small-box"  >
            <Typography variant="h6" component="h5" className="box-heading">
              Timesheet Duration {moment(timeSheets?.startTime).format("MMM Do YYYY")} - {moment(timeSheets?.endTime).format("MMM Do YYYY")}
            </Typography>
            <Divider />
            <Box sx={{ height: "inherit" }} className="hybrid-box" textAlign='left'>
              <div>
                <span className="hybrid-box-heading">
                  Guard Name :
                </span>
                <span className="hybrid-box-value">
                  {timeSheets?.userId?.firstname + ' ' + timeSheets?.userId?.lastname}
                </span>
              </div>
              <div>
                <span className="hybrid-box-heading">
                  Client :
                </span>
                <span className="hybrid-box-value">
                  {timeSheets?.clientId?.name}

                </span>
              </div>
              <div>
                <span className="hybrid-box-heading">
                  Vendor :
                </span>
                <span className="hybrid-box-value">
                  {timeSheets?.vendorId?.name}

                </span>
              </div>
              <div>
                <span className="hybrid-box-heading">
                  Site :
                </span>
                <span className="hybrid-box-value">
                  {timeSheets?.siteId?.name}

                </span>
              </div>
              {
                timeSheets?.statusId?.name === "Rejected" &&
                <div>
                  <span className="hybrid-box-heading">
                    Rejected By :
                  </span>
                  <span className="hybrid-box-value">
                    {timeSheets?.siteId?.email}

                  </span>
                </div>
              }
              {
                timeSheets?.statusId?.name === "Approved" &&
                <div>
                  <span className="hybrid-box-heading">
                    Approved By :
                  </span>
                  <span className="hybrid-box-value">
                    {timeSheets?.siteId?.email}

                  </span>
                </div>
              }


            </Box>

            <TableContainer component={Paper}  >
              <Table sx={{ minWidth: 'auto' }} aria-label="custom pagination table" className="responsive-table">
                <TableHead >
                  <TableRow className="table-header">
                    <TableCell align="center" component="th" sx={tableHeader}>Date</TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>Start time</TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>Finish time</TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>Break time (mins)</TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>Total (hrs)</TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>Actual Start time</TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>Actual Finish time</TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>Actual Break time (mins)</TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>Actual Total (hrs)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {console.log("TIMESHIT clock----", timeSheets?.clock)} */}

                  {timeSheets?.clock?.map((item, index) => (
                    <TableRow>
                      {/* {console.log(new Date(item?.endDateTime).toLocaleTimeString(), "items------*****************************")} */}
                      <TableCell align="center" sx={tableData}>
                        {moment(timeSheets?.createdAt).format("MMM Do YY")}
                      </TableCell>
                      <TableCell align="center" sx={tableData}>
                        {item?.shiftStartTime}
                        {/* {new Date(item?.shiftStartTime).toLocaleTimeString()} */}
                        {/* {moment(item?.shiftStartTime).format("MMM Do YY")} */}
                      </TableCell>
                      <TableCell align="center" sx={tableData}>
                        {/* {new Date(item?.shiftEndTime).toLocaleTimeString()} */}
                        {item?.shiftEndTime}
                        {/* {moment(item?.endDateTime).format("hh")} */}
                      </TableCell>
                      <TableCell align="center" sx={tableData} >
                        {item?.break}
                      </TableCell>
                      <TableCell align="center" sx={tableData}>
                        {item?.shiftTotal?.toFixed(2)}
                      </TableCell>
                      <TableCell align="center" sx={tableData} >
                        {new Date(item?.start).toLocaleTimeString()}
                      </TableCell>
                      <TableCell align="center" sx={tableData} >
                        {new Date(item?.finish).toLocaleTimeString()}
                      </TableCell>
                      <TableCell align="center" sx={tableData} >
                        {item?.break}
                      </TableCell>

                      <TableCell align="center" sx={tableData}>
                        {item?.total?.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <div style={{ margin: 5 }}></div>
                <TableHead >
                  <TableRow className="table-header table-bottom">
                    {/* <TableCell align="center" component="th" className="t-cell-bottom">Total</TableCell > */}
                    <TableCell align="right" component="th" className="t-cell-bottom" colSpan={5}>{timeSheets?.clock?.reduce((acc, curr) => acc + curr?.shiftTotal, 0)?.toFixed(2)}</TableCell>

                    <TableCell align="right" component="th" className="t-cell-bottom" colSpan={8}>{timeSheets?.clock?.reduce((acc, curr) => acc + curr?.total, 0)?.toFixed(2)
                    }</TableCell>
                  </TableRow>
                </TableHead>
                <TableHead >
                  <TableRow className="table-header table-bottom">
                    {/* <TableCell align="center" component="th" className="t-cell-bottom">Actual Total</TableCell > */}
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>

            <Box sx={{ height: "inherit" }} className="hybrid-bottom-box" textAlign='left'>
              <Typography className="hybrid-box-heading">
                Workspace injuries :
              </Typography>
              {
                timeSheets?.isInjured === true ?
                  <Typography className="hybrid-box-value" mx={{ mt: 2 }}>
                    I did sustain a reportable workplace injury during this period.
                  </Typography>
                  :
                  <Typography className="hybrid-box-value" mx={{ mt: 2 }}>
                    I did not sustain a reportable workplace injury during this period.
                  </Typography>
              }

            </Box>
 
            {
              timeSheets?.statusId?.name === "Submitted"
              &&
              <Box sx={{ height: "inherit", mx: 5 }} className="hybrid-bottom-box" display="flex" justifyContent="space-around">
                {(showBothButtons || canAdd) && (
                <Button variant='outlined' className="btn-input-dark" onClick={() => {
                  handleTimeSheet(timeSheets?.id, 'approve')


                }}>
                  Approve
                </Button>
                )}
                {(showBothButtons || canReject) && (
                <Button variant='outlined' className="btn-input-dark" onClick={() => {
                  handleTimeSheet(timeSheets?.id, 'reject')

                }}>
                  Reject
                </Button>
                )}
              </Box>

            }

          </Box>

        </Grid>





      </Grid>
    </Box>
  );
}


