import React, { useEffect, useState } from 'react'
import PageTitle from '../../../../common/PageTitle'
import { Box, Button, Grid, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { tableHeader } from '../Addproperty';
import { tableData } from '../../../../utils';
import { deleteV2API, getV2API, postV2API, putV2API } from '../../../../network/index.v2';
import EmptyTable from '../../../../common/EmptyTable';



const PropertyType = () => {


  const [modalOpen, setModalOpen] = useState(false)
  const [editModal, setEditModal] = useState(false)

  const [propertyList, setPropertyList] = useState([])
  const [inputs, setInputs] = useState({ title: "" })
  const [title, setTitle] = useState("")
  const [_id, setId] = useState("")


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 300,
    bgcolor: 'background.paper',
    border: '2px solid white',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    borderRadius: "2px"
  };

  // -------------------------------------------- Table States and Static Data Manipulation Start --------------------------------------


  const [page, setPage] = useState(0)
  const [perPages, setPerPages] = useState([10, 25, 50]);
  const [perPage, setPerPage] = useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value)
    setPerPage(event.target.value)
    setPage(0);
  };


  // -------------------------------------------- Table States and Static Data Manipulation Ends --------------------------------------

  const handleChange = (e) => {
    e.preventDefault()

    setInputs({ ...inputs, [e.target.name]: e.target.value })


  }



  const editPropertyType = async (e, _id) => {
    e.preventDefault()

    const filterdId = propertyList?.filter(item => item?._id === _id)

    if (filterdId) {
      // setInputs({ ...inputs, title: filterdId[0]?.title })
      setTitle(filterdId[0].title)
      setId(filterdId[0]?._id)
      setEditModal(true)

    }
  }
  // -------------------------------------------- Property Type CRUD Start ------------------------------------------------------------

  const postForm = async (e) => {
    e.preventDefault()
    const fD = new FormData()
    fD.append("title", inputs?.title)
    try {
      const response = await postV2API("/maintenance/property/type", fD)
      getPropertyTypes()
      clearInputs()
      setModalOpen(false)

    } catch (error) {
      console.log(error, "error")
    }
  }

  const updatePropertyType = async (e) => {
    e.preventDefault()
    const fD = new FormData()
    fD.append("title", title)
    try {
      const response = await putV2API(`/maintenance/property/type/${_id}`, fD)
      setEditModal(false)
      setTitle("")
    } catch (error) {
      console.log(error);
    }
  }

  const getPropertyTypes = async () => {
    try {
      const response = await getV2API("/maintenance/property/type")
      if (response) {
        setPropertyList(response)
      }
    } catch (error) {
      console.log(error, "error from api");
    }
  }



  const deletePropertyType = async (id) => {
    try {
      const response = await deleteV2API(`/maintenance/property/type/${id}`)
      if (response) {
        getPropertyTypes()
      }
    } catch (error) {
      console.log(error, "error in api");
    }
  }
  // -------------------------------------------- Property Type CRUD Ends -------------------------------------------------------------

  useEffect(() => {
    getPropertyTypes()
  }, [])

  const clearInputs = () => {
    setInputs((prev) => ({
      ...prev,
      title: ""
    }))
  }

  return (
    <div>

      <PageTitle title={"Property Type"} />


      <div>
        <Button onClick={() => setModalOpen(true)} variant="contained" style={{ backgroundColor: "#75859D", float: "right" }} sx={{ height: 52, mx: 2 }}>
          <AddCircleIcon /> &nbsp; &nbsp;
          Add Property Type
        </Button>
      </div>

      <div style={{ marginTop: "50px" }}>

        <TableContainer component={Paper} sx={{ mx: "0.8rem", width: "60%", my: "6rem" }}>
          {/* <div style={{width: 'auto', overflowX: 'scroll'}}> */}

          <Table
            sx={{ minWidth: "auto" }}
            aria-label="custom pagination table"
            className="responsive-table"
          >
            <TableHead>
              <TableRow className="table-header">
                <TableCell align="left" component="th" sx={tableHeader}>
                  Name
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Action
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {
                [...propertyList]?.reverse().slice(page * perPage, page * perPage + perPage).map((item, index) => (
                  <TableRow key={index}>
                    {/* <TableCell align="left" sx={tableData}>
                      {fullName(item)}
                    </TableCell> */}
                    <TableCell align="left" sx={tableData}>
                      {item?.title}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      <Button size="small" className="btn-div" variant="outlined" color="info"
                        onClick={(e) => editPropertyType(e, item?._id)}
                      >
                        <EditIcon className="" />
                      </Button>
                      <Button size="small" className="btn-div" variant="outlined" color="error"
                        onClick={() => deletePropertyType(item?._id)}
                      >
                        <DeleteIcon className="" />
                      </Button>
                    </TableCell>

                  </TableRow>
                ))}
              {
                !propertyList &&
                <EmptyTable colSpan={9} />
              }
            </TableBody>
            <TableFooter>
              <TableRow>

                <TablePagination
                  align="right"
                  rowsPerPageOptions={perPages}
                  colSpan={9}
                  count={1}
                  rowsPerPage={perPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                // ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
          {/* </div> */}
        </TableContainer>

      </div>


      <div>
        <Modal
          open={modalOpen}
        >
          <Box sx={style}>
            {/* <h6>Close</h6> */}
            <div className='box-title'>
              <h2>Add Property Type</h2>
            </div>
            <form onSubmit={postForm}>
              <div>
                <TextField variant='outlined' label="Property Name" name='title' className='box-input' value={inputs?.title} onChange={handleChange} />
              </div>
              <div className='box-button-group'>
                <Button variant='contained' type='submit'>Submit</Button>
                <Button variant='outlined' onClick={() => setModalOpen(false)} >Cancel</Button>
              </div>
            </form>
          </Box>
        </Modal>
      </div>

      <div>
        <Modal
          open={editModal}
        >
          <Box sx={style}>
            {/* <h6>Close</h6> */}
            <div className='box-title'>
              <h2>Update Property Type</h2>
            </div>
            <form onSubmit={updatePropertyType}>
              <div>
                <TextField variant='outlined' label="Property Name" className='box-input' name='title' value={title} onChange={(e) => setTitle(e.target.value)} />
              </div>
              <div className='box-button-group'>
                <Button variant='contained' type='submit'>Update</Button>
                <Button variant='outlined' onClick={() => setEditModal(false)} >Cancel</Button>
              </div>
            </form>
          </Box>
        </Modal>
      </div>

      {/* 
    <div>
      <Modal
        open={editModal}

      >
        <Box sx={style}>
          <PageTitle title={"Update Building Name"} />

          <TextField label="Enter Building Name" value={buildingInputs?.title} name='title' id='title' variant='outlined' style={{ width: "100%", marginLeft: "10px" }} onChange={editChange} />

          <Grid style={{ marginTop: "15px", width: "100%", display: "flex", justifyContent: "start", alignItems: "center", gap: "10px", marginLeft: "10px" }}>
            <Button variant="contained" onClick={onSubmit} style={{ float: "right" }} sx={{ height: 40 }}>
             
              Update
            </Button>

            <Button onClick={() => {
              setEditModal(false)
              setEditMode(false)
            }} variant="outlined" style={{ float: "right" }} sx={{ height: 40 }}>
       
              Cancel
            </Button>
          </Grid>

          <div></div>
        </Box>
      </Modal>
    </div> */}



    </div>
  )
}

export default PropertyType