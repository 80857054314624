import React, { useEffect, useState } from "react";
import { Box, FormControl, Grid, Link, Modal, Typography, Button } from "@mui/material";
import PageTitle from "../../common/PageTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import { getAPI, postAPI, patchAPI, deleteAPI } from "../../network";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { checkAuthority, formatDate, validation, tableHeader, tableData, tablebtn, fullName, formatDateUTCtoLocal } from "../../utils";
import Loader from "../../common/Loader";
import EmptyTable from "../../common/EmptyTable";



const Wellness = () => {
    let companyId = localStorage.getItem('companyIdLS')
    if (companyId === null) {
        companyId = undefined
    }
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState('');

    const [editId, setEditId] = useState('');
    const [action, setAction] = useState('');
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [wellnessNotification, setWellnessNotification] = useState([]);
    const [person, setPerson] = useState({
        message: 'Are you okay?',
        site: '',
        user: '',
        messageError: false,
        userError: false,
        siteError: false
    })
    const [filter, setFilter] = useState({

        site: '',
        type: '',

    })
    const [page, setPage] = useState(0)
    const [perPages, setPerPages] = useState([10, 25, 50]);
    const [perPage, setPerPage] = useState(10)
    const [sites, setSites] = useState([])
    const [users, setUsers] = useState([])


    useEffect(() => {
        getShifts();
        getSites()
    }, []);

    const getShifts = async () => {
        setLoader(true)
        let data = await getAPI(`/wellness`);
        setWellnessNotification(data)
        setLoader(false)
        console.log(wellnessNotification)
    }
    const handleMapNavigate = (latitude, longitude) => {
        const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
        window.open(googleMapsUrl, '_blank');
    };
    const getSites = async () => {
        setLoader(true)
        const data = await getAPI(`/sites?companyId=${companyId}`);
        if (data) {
            setSites(data)
        }
        setLoader(false)
    }

    const getUsers = async (id) => {
        setLoader(true)
        let data = await getAPI(`/sites-user/${id}`);
        if (data) {
            let emails = data.map((item) => ({
                id: item.id ? item.id : item._id,
                label: fullName(item) + `(${item.email})`,
                value: item.id ? item.id : item._id
            }))
            console.table(emails, 124)
            setUsers(emails)
        }
        setLoader(false)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        console.log(event.target.value)
        setPerPage(event.target.value)
        setPage(0);
    };


    const addLicense = (e) => {
        e.preventDefault();
        setAction('add');
        clearAll();
        setOpen(true)
    }

    const editShift = (e, id) => {
        e.preventDefault();
        setEditId(id)
        setAction('edit');
        clearAll();
        let data = wellnessNotification.filter(item => item.id === id)[0];

        setPerson({
            ...person,
            name: data?.name,
            type: data?.type,
            site: data?.site,
            nameError: false
        })


        setOpen(true)

    }

    const deleteshift = (id) => {

        setEditId(id);
        clearAll();
        setShow(true);
    }

    const clearAll = () => {
        setPerson({
            name: '',
            type: '',
            nameError: false
        })
    }

    const handleClose = () => {
        setOpen(false)
    }
    const handleShowClose = () => {
        setShow(false);
    }



    const handleSubmit = async (e) => {
        e.preventDefault();
        setPerson(prevState => ({
            ...prevState,
            nameError: false,
        }))

        if (validation(null, 'Site', person.site)) {
            setPerson(prevState => ({
                siteError: true,
            }))
            return;
        }
        if (validation(null, 'User', person.user)) {
            setPerson(prevState => ({
                userError: true,
            }))
            return;
        }



        let payload = {
            message: person.message,
            siteId: person.site,
            userId: person.user,
        }
        if (action === 'add') {
            setLoader(true)
            let data = await postAPI('/wellness', payload)
            if (data) {
                getShifts()
                setOpen(false)
            }
            setLoader(false)
        }
        else {
            setLoader(true)
            let data = await patchAPI(`/wellness/${editId}`, payload)
            if (data) {
                getShifts()
                setOpen(false)
            }
            setLoader(false)
        }
    }

    const handleDelete = async () => {
        setLoader(true);
        let process = await deleteAPI(`/wellness/${editId}`);
        setLoader(false);
        if (process) {
            getShifts();
            setShow(false)
        }
    }


    const [permissionList, setPermissionList] = useState([]);

    // Load the permission list from localStorage
    useEffect(() => {
      const permissions = JSON.parse(localStorage.getItem("permisionlist")) || [];
      setPermissionList(permissions);
    }, []);
  
    const submenuPermissions = permissionList[0]?.submenuPermissions || [];
  
    // Check if 'Delete' and 'Edit' subActions exist
    
    const canAdd = submenuPermissions.some(
      (permission) => permission.submenu === "Create"
    );
   
    
  
    // If submenuPermissions is empty, show both buttons
    const showBothButtons = permissionList.length === 0;
    return (
        <Box sx={{ height: "inherit" }}>
            <Loader loader={loader} />
            <PageTitle title="Welfare Notification" subTitle="" />
            {
               (showBothButtons || canAdd) &&  checkAuthority('ADD_SHIFT') &&
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    sx={{ mx: 2 }}
                    gap='2'
                >
                    
                    <Button variant="contained" style={{ backgroundColor: "#75859D" }} sx={{ height: 50 }} onClick={addLicense}>
                        <AddCircleIcon /> &nbsp; &nbsp;
                        Create Welfare Check
                    </Button>
                    
                </Box>
            }
            <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{ mx: 2 }}
            >
                {/* <FormControl sx={{ minWidth: '20%', mt: 1, mr: 4 }}>
                    <InputLabel id="site">Site</InputLabel>
                    <Select
                        labelId="site"
                        id="site"
                        label="Site"
                        // error={person.countryError}
                        value={filter.site}
                        onChange={(data) => {
                            setFilter(prevState => ({
                                ...prevState,
                                site: data.target.value,
                            }))
                            getShifts(data.target.value)
                        }}
                    >
                        {
                            sites.map((item, index) => (
                                <MenuItem value={item._id} key={index}>{item.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl> */}

            </Box>
            {
                checkAuthority('VIEW_SHIFTS') &&
                <Box display="flex" sx={{ my: "2rem" }}>
                    <TableContainer component={Paper} sx={{ mx: "1rem" }}>
                        <Table
                            sx={{ minWidth: "auto" }}
                            aria-label="custom pagination table"
                            className="responsive-table"
                        >
                            <TableHead>
                                <TableRow className="table-header" align="center">
                                    <TableCell align="left" component="th" sx={{ width: '25rem', tableHeader }}>
                                        Date
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={{ width: '25rem', tableHeader }}>
                                        Response Time
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={{ width: '25rem', tableHeader }}>
                                        Site
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={{ width: '25rem', tableHeader }}>
                                        Employ Name
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={{ width: '25rem', tableHeader }}>
                                        Response
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader} >
                                        Location
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {wellnessNotification.slice(page * perPage, page * perPage + perPage).map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="left" sx={tableData}>
                                            {item?.sentAt.split("T")[0]}
                                        </TableCell>

                                        <TableCell align="left" sx={tableData}>
                                            {item?.response ? formatDateUTCtoLocal(item?.updatedAt) : null}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {item?.siteId.name}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {item?.userId?.firstname}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {item?.response}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            Latitude: {item?.latitude} <br />
                                            Longitude {item?.longitude}  <br />
                                            <button onClick={() => handleMapNavigate(item?.latitude, item?.longitude)}>
                                                Navigate to Location
                                            </button>
                                        </TableCell>
 
                                    </TableRow>
                                ))}
                                {
                                    wellnessNotification.length === 0 &&
                                    <EmptyTable colSpan={7} />
                                }


                            </TableBody>
                            <TableFooter>
                                <TableRow>

                                    <TablePagination
                                        align="right"
                                        rowsPerPageOptions={perPages}
                                        colSpan={9}
                                        count={wellnessNotification.length}
                                        rowsPerPage={perPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    // ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Box>
            }
            <Dialog open={open} onClose={handleClose} fullWidth={true}>
                <DialogTitle sx={{ mb: 4, textAlign: "center" }}>{action === 'add' ? 'Add' : 'Edit'} Welfare check</DialogTitle>
                <DialogContent>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { my: 2, width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >

                        {/* <FormControl sx={{ minWidth: '100%', mx: 0, px: 0 }}>
                            <TextField
                                id="message"
                                label="Message"
                                variant="outlined"
                                type="text"
                                value={person.message}
                                error={person.messageError}
                                onChange={(data) => {
                                    setPerson(prevState => ({
                                        ...prevState,
                                        message: data.target.value,
                                    }))
                                }}
                                fullWidth
                                sx={{ m: 0 }}
                            />
                        </FormControl> */}
                        <FormControl sx={{ minWidth: '100%', mt: 1 }}>
                            <InputLabel id="site">Site</InputLabel>
                            <Select
                                labelId="site"
                                id="site"
                                value={person.site}
                                label="Site"
                                error={person.countryError}
                                onChange={(data) => {
                                    setPerson(prevState => ({
                                        ...prevState,
                                        site: data.target.value,
                                    }))
                                    getUsers(data.target.value)
                                }}
                            >
                                {
                                    sites.map((item, index) => (
                                        <MenuItem value={item._id} key={index}>{item.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: '100%', mt: 1 }}>
                            <InputLabel id="user">User</InputLabel>
                            <Select
                                labelId="user"
                                id="user"
                                value={person.user}
                                label="User"
                                error={person.countryError}
                                onChange={(data) => {
                                    setPerson(prevState => ({
                                        ...prevState,
                                        user: data.target.value,
                                    }))

                                }}
                            >
                                {
                                    users.map((item, index) => (
                                        <MenuItem value={item.id} key={index}>{item.label}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>


                    </Box>
                </DialogContent>
                <DialogActions sx={{ mb: 2, mx: 4 }}>
                    <Button onClick={handleSubmit} variant="contained">{action === 'add' ? 'Submit' : 'Update'}</Button>
                    <Button onClick={handleClose} variant="outlined">Cancel</Button>
                </DialogActions>
            </Dialog>


            {/* delete Modal */}
            <Dialog open={show} onClose={handleShowClose} fullWidth={true}>
                <DialogTitle sx={{ mb: 4, textAlign: "center" }}>Delete Shift Type</DialogTitle>

                <DialogContent>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { my: 2, width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >

                        <h3 style={{ textAlign: 'left', fontWeight: 'bold' }}>Do you want's to delete this shift-type </h3>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ mb: 2, mx: 4 }}>
                    <Button onClick={handleDelete} variant="contained" color="error">Delete</Button>
                    <Button onClick={handleShowClose} variant="outlined">Cancel</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default Wellness