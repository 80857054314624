import React, { useEffect, useState, useContext } from "react";
import { Box, Button, FormControl, Select, InputLabel, MenuItem } from "@mui/material";
import PageTitle from "../../common/PageTitle";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Loader from "../../common/Loader";
import "./style.css";
import { deleteAPI, getAPI, patchAPI, postAPI } from "../../network";
import { checkAuthority, validation, tableData, tableHeader, formatDatePost } from "../../utils";
import EmptyTable from "../../common/EmptyTable";
import { CompanyContext } from "../../../context";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
export default function AlertPage() {
  // const [companyId] = useContext(CompanyContext)
  let companyId = localStorage.getItem('companyIdLS')
  if (companyId === null) {
    companyId = undefined
  }
  const [alerts, setAlerts] = useState([])
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = useState('add');
  const [btnTxt, setBtnTxt] = useState('Add');
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [site, setSite] = useState([]);
  const [sites, setSites] = useState([]);
  const [expiryDate, setExpiryDate] = useState(null);
  const [resource, setResource] = useState([]);
  const [resources, setResources] = useState([]);
  const [titleError, setTitleError] = useState(false);
  const [descError, setDescError] = useState(false);
  const [show, setShow] = useState(false)
  const [alertStatus, setAlertStatus] = useState('')

  const [page, setPage] = useState(0);
  const [perPages, setPerPages] = useState([5, 10, 25, 50]);
  const [perPage, setPerPage] = useState(10)

  useEffect(() => {
    getAlerts();
    getSite()
    getResource()
  }, [])

  function getFormattedDate() {
    const currentDate = new Date();

    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();

    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    return year + '-' + formattedMonth + '-' + formattedDay;
  }

  const getSite = async () => {
    setLoader(true);
    let data = await getAPI(`/sites?companyId=${companyId}`);
    if (data) {
      var sites = [];
      for (var i = 0; i < data.length; i++) {
        sites.push({ label: data[i].name, id: data[i]._id })
      }
      setSites(sites);

    }
    setLoader(false);
  }
  const getResource = async () => {
    setLoader(true);
    let data = await getAPI(`/admin/guards?companyId=${companyId}`);

    if (data) {
      var resources1 = [];
      for (var i = 0; i < data.length; i++) {
        resources1.push({
          label: `${data[i].firstname} ( ${data[i].email})`,
          id: data[i]._id
        })
      }
      console.log(resources1)
      setResources(resources1);

    }
    setLoader(false);
  }

  const getAlerts = async (status) => {
    if (!status) {
      status = alertStatus
    }
    setLoader(true);
    let data = await getAPI(`/alerts?companyId=${companyId}`);
    if (data) {
      if (status === 'Expired') {
        let currentDate = getFormattedDate()
        let filteredArray = data.filter(obj => obj.expiryDate <= currentDate);
        setAlerts(filteredArray)
      }
      if (status === 'Active') {
        let currentDate = getFormattedDate();
        let filteredArray = data.filter(obj => obj.expiryDate >= currentDate);
        setAlerts(filteredArray)
      }
      if (!status) {
        setAlerts(data)
      }
    }
    setLoader(false);
  }
  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value)
    setPerPage(event.target.value)
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleClickOpen = () => {
    setTitleError(false);
    setDescError(false);
    setTitle('');
    setDesc('')
    setSite([])
    setResource([])
    setExpiryDate(null)
    setBtnTxt('Add')
    setAction('add')
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addAction = () => {

  }

  const handleSubmit = async () => {
    setTitleError(false);
    setDescError(false);

    if (validation(null, 'Title', title)) {
      setTitleError(true);
      return;
    }
    else if (validation('long', 'Description', desc)) {
      setDescError(true);
      return;
    }

    //   if(title === '' || title.length < 3){
    //     toast.warning('Title is required! at least 3 character long.');
    //     setTitleError(true);
    //     return;
    //   }
    //   else if(desc === '' || desc.length < 10){
    //     toast.warning('Description is required! at least 10 character long.');
    //     setDescError(true);
    //     return;
    //   }

    let payload = {
      site,
      resource,
      title: title,
      description: desc,
      expiryDate: formatDatePost(expiryDate),

    };


    if (action === 'add') {
      setLoader(true);
      let alerts = await postAPI('/alerts', payload);
      if (alerts) {
        getAlerts();
        setOpen(false)
      }
      setLoader(false);
    }
    else if (action === 'edit') {
      setLoader(true);
      let alerts = await patchAPI(`/alerts/${id}`, payload);
      if (alerts) {
        getAlerts();
        setOpen(false)
      }
      setLoader(false);
    }
    setExpiryDate(null)
    setSite([])
    setResource([])

  }

  const editClick = (id) => {
    setTitleError(false);
    setDescError(false);
    let alert = alerts.filter(item => item.id === id)[0];
    console.log(alert)
    setId(id)
    setTitle(alert.title)
    setDesc(alert.description);
    setSite(alert?.site)
    setResource(alert?.resource)
    setExpiryDate(alert.expiryDate);
    setBtnTxt('Update');
    setAction('edit');
    setOpen(true);
  }

  const handleShowClose = () => {
    setShow(false)
  }

  const deleteClick = (id) => {
    setId(id);
    setShow(true)
  }

  const handleDelete = async () => {
    setLoader(true);
    let tip = await deleteAPI(`/alerts/${id}`);
    if (tip) {
      getAlerts()
      setShow(false)
    }
    setLoader(false);
  }

  const [permissionList, setPermissionList] = useState([]);

  // Load the permission list from localStorage
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permisionlist")) || [];
    setPermissionList(permissions);
  }, []);

  const submenuPermissions = permissionList[0]?.submenuPermissions || [];

  // Check if 'Delete' and 'Edit' subActions exist
  
  const canAdd = submenuPermissions.some(
    (permission) => permission.submenu === "Add"
  );
  const canEdit = submenuPermissions.some(
    (permission) => permission.submenu === "Edit"
  );
  const canDelete = submenuPermissions.some(
    (permission) => permission.submenu === "Delete"
  );

  // If submenuPermissions is empty, show both buttons
  const showBothButtons = submenuPermissions.length === 0;
  return (
    <Box sx={{ height: "inherit" }}>
      <Loader loader={loader} />
      <PageTitle title="Alert Information" />
      <FormControl sx={{ my: 1, minWidth: "25%", marginLeft: "10px" }}>
        <InputLabel id="shiftType-label">Alert Status </InputLabel>
        <Select
          value={alertStatus}
          onChange={(e) => {
            setAlertStatus(e.target.value)
            getAlerts(e.target.value)
          }}
          displayEmpty
          labelId="shiftType-label"
          id="shiftType"
          label="Alert Status"
          sx={{
            borderRadius: "10px",
            borderColor: "#707070",
            pl: 2,
          }}
        >
          <MenuItem value='Active'>Active</MenuItem>
          <MenuItem value='Expired'>Expired</MenuItem>
        </Select>
      </FormControl>
      {
        checkAuthority('ADD_ALERT') &&
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          sx={{ mx: 2 }}
        >
           {(showBothButtons || canAdd) && (
          <Button variant="contained" style={{ backgroundColor: "#75859D" }} sx={{ height: 50 }} onClick={handleClickOpen}>
            <AddCircleIcon /> &nbsp;&nbsp;
            Add Alert
          </Button>
           )}
        </Box>
      }

      {
        checkAuthority('VIEW_ALERTS') &&
        <Box display="flex" sx={{ my: "3rem" }}>
          <TableContainer component={Paper} sx={{ mx: "0.8rem" }} >
            {/* <div style={{width: 'auto', overflowX: 'scroll'}}> */}
            <Table sx={{ minWidth: 'auto' }} aria-label="custom pagination table" className="responsive-table">
              <TableHead >
                <TableRow className="table-header">
                  <TableCell align="left" component="th" sx={tableHeader}>Title</TableCell>
                  <TableCell align="left" component="th" sx={tableHeader}>Description</TableCell>
                  <TableCell align="left" component="th" sx={tableHeader}>Expiry Date</TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  alerts.slice(page * perPage, page * perPage + perPage).map((item, index) => (
                    <TableRow key={index}>
                      <TableCell align="left" sx={tableData}>
                        {item.title}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item.description}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item.expiryDate ? item.expiryDate : ` Not declared`}
                      </TableCell>
                      
                      <TableCell align="center" sx={tableData}>
                        
                      {(showBothButtons || canEdit) && (
                          
                          <Button className="btn-div" color="primary" variant="outlined" sx={{ mx: 2 }} onClick={() => editClick(item.id)}>
                            <EditIcon className="" />
                          </Button>
                       ) }
                        {(showBothButtons || canDelete) && (
                          <Button className="btn-div" color="error" variant="outlined" onClick={() => deleteClick(item.id)}>
                            <DeleteIcon className="" />
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                }
                {
                  alerts.length === 0 &&
                  <EmptyTable colSpan={3} />
                }
              </TableBody>
              <TableFooter>
                <TableRow>

                  <TablePagination
                    align="right"
                    rowsPerPageOptions={perPages}
                    colSpan={7}
                    count={alerts.length}
                    rowsPerPage={perPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  // ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
            {/* </div> */}
          </TableContainer>
        </Box>
      }


      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ textAlign: 'center', mb: 8 }}>{action === "add" ? 'Add' : 'Update'} Alert</DialogTitle>
        <DialogContent>
          <FormControl sx={{ my: 1, minWidth: "100%" }}>
            <InputLabel id="demo-multiple-name-label">Site</InputLabel>
            <Select
              multiple
              value={site}
              onChange={(e) => setSite(typeof value === 'string' ? e.target.value.split(',') : e.target.value,)}
              displayEmpty
              labelId="company-label"
              id="company"
              label="Select Site"
            //   error={shift.companyError}
            //   InputProps={{
            //     classes: {
            //       root: classes.inputRoot,
            //     },
            //   }}
            >

              {
                sites?.map((item, index) => (
                  <MenuItem value={item.id} key={index} multiple>
                    <div className="select_item" value={item.id}>{item?.label}</div>
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <FormControl sx={{ my: 1, minWidth: "100%" }}>
            <InputLabel id="demo-multiple-name-label">Resource</InputLabel>
            <Select
              multiple
              value={resource}
              onChange={(e) => setResource(e.target.value)}
              displayEmpty
              labelId="company-label"
              id="company"
              label="Select Resource"
            //   error={shift.companyError}
            //   InputProps={{
            //     classes: {
            //       root: classes.inputRoot,
            //     },
            //   }}
            >

              {
                resources?.map((item, index) => (
                  <MenuItem value={item.id} key={index} multiple>
                    <div className="select_item" value={item.id}>{item?.label}</div>
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Alert title"
            type="text"
            defaultValue={title}
            onChange={(event) => { setTitle(event.target.value) }}
            fullWidth
            error={titleError}
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Description for alert"
            type="text"
            defaultValue={desc}
            onChange={(event) => { setDesc(event.target.value) }}
            fullWidth
            multiline
            rows={4}
            error={descError}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                // className={notification.endDateError ? "error" : null}
                label="Expiry Date"
                value={expiryDate}
                onChange={(data) => {
                  setExpiryDate(data)
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ mx: 2, mb: 4 }}>
          <Button onClick={handleSubmit} variant="contained" color="primary">{btnTxt}</Button>
          <Button onClick={handleClose} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={show} onClose={handleClose} fullWidth={true}>
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>Delete Alert</DialogTitle>

        <DialogContent>
          <Box
            component="form"
            sx={{ '& .MuiTextField-root': { my: 2, width: '100%' }, }}
            noValidate
            autoComplete="off"
          >
            <h4 style={{ textAlign: 'left', fontWeight: 'bold' }}>Do you want's to delete this alert</h4>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={handleDelete} variant="contained" color="error">Delete</Button>
          <Button onClick={handleShowClose} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}


