import React, { useEffect, useState,useContext } from "react";
import { Box, Link, Button, MenuItem, Grid, FormControl, Select, InputLabel } from "@mui/material";
import PageTitle from "../../common/PageTitle";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { deleteAPI, getAPI, patchAPI, postAPI } from "../../network";
import Loader from "../../common/Loader";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { RED, GREY } from "../../../constant"
import { toast } from "react-toastify";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import "./style.css";
import EmptyTable from "../../common/EmptyTable";
import { checkAuthority, tableHeader, tableData } from "../../utils";
import { CompanyContext } from "../../../context";
export default function TipPage() {
    // const [companyId] = useContext(CompanyContext)
    let companyId = localStorage.getItem('companyIdLS')
    if (companyId === null) {
      companyId = undefined
    }
    const [tips, setTips] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [sites, setSites] = useState([]);
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [action, setAction] = useState('add');
    const [btnTxt, setBtnTxt] = useState('Add');
    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [company, setCompany] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [site, setSite] = useState([]);
    const [desc, setDesc] = useState('');
    const [titleError, setTitleError] = useState(false);
    const [descError, setDescError] = useState(false);
    const [show, setShow] = useState(false)

    useEffect(() => {
        getTips();
        getCompany()
        getVendor()
        getSite()
    }, [])

    const [page, setPage] = useState(0)
    const [perPages, setPerPages] = useState([5, 10, 25, 50]);
    const [perPage, setPerPage] = useState(10)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
      
        setPerPage(event.target.value)
        setPage(0);
    };

    const getTips = async () => {
        setLoader(true);
        let tips = await getAPI(`/safety-tips?companyId=${companyId}`);
        if (tips) {
            setTips(tips);
        }
        setLoader(false);
    }
    const getCompany = async () => {
        setLoader(true);
        let data = await getAPI(`/companies?companyId=${companyId}`);
        if (data) {
            var companies = [];
            for (var i = 0; i < data.length; i++) {
                companies.push({ label: data[i].name, id: data[i].id })
            }
            setCompanies(companies);
        }
        setLoader(false);
    }
    const getVendor = async () => {
        setLoader(true);
        let data = await getAPI(`/vendors?companyId=${companyId}`);
        if (data) {
            var vendors = [];
            for (var i = 0; i < data.length; i++) {
                vendors.push({ label: data[i].name, id: data[i].id })
            }
            setVendors(vendors);
            
        }
        setLoader(false);
    }
    const getSite = async () => {
        setLoader(true);
        let data = await getAPI(`/sites?companyId=${companyId}`);
        if (data) {
            var sites = [];
            for (var i = 0; i < data.length; i++) {
                sites.push({ label: data[i].name, id: data[i]._id })
            }
            setSites(sites);
           
        }
        setLoader(false);
    }

    const handleClickOpen = () => {
        setTitleError(false);
        setDescError(false);
        setTitle('');
        setDesc('')
        setBtnTxt('Add')
        setAction('add')
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const addAction = () => {

    }

    const handleSubmit = async () => {
        setTitleError(false);
        setDescError(false);

        if (title === '' || title.length < 3) {
            toast.warning('Title is required! at least 3 character long.');
            setTitleError(true);
            return;
        }
        if (title.length > 50) {
            toast.warning('Title should be less then 50 character');
            setTitleError(true);
            return;
        }
        else if (desc === '' || desc.length < 10) {
            toast.warning('Description is required! at least 10 character long');
            setDescError(true);
            return;
        }
        else if (desc.length > 500) {
            toast.warning('Description should be less then 500 character');
            setDescError(true);
            return;
        }

        let payload = {
            company,
            vendor,
            site,
            title: title,
            description: desc
        };


        if (action === 'add') {
            setLoader(true);
            let tips = await postAPI('/safety-tips', payload);
            if (tips) {
                getTips();
                setOpen(false)
            }
            setLoader(false);
        }
        else if (action === 'edit') {
            setLoader(true);
            let tips = await patchAPI(`/safety-tips/${id}`, payload);
            if (tips) {
                getTips();
                setOpen(false)
            }
            setLoader(false);
        }
    }

    const editClick = (id) => {
        setTitleError(false);
        setDescError(false);
        let tip = tips.filter(item => item.id === id)[0];
        setId(id)
        setTitle(tip.title)
        setDesc(tip.description);
        setCompany(tip?.company);
        setVendor(tip?.vendor);
        setSite(tip?.site);
        setBtnTxt('Update');
        setAction('edit');
        setOpen(true);
    }

    const handleShowClose = () => {
        setShow(false)
    }

    const deleteClick = (id) => {
        setId(id);
        setShow(true)
    }

    const handleDelete = async () => {
        setLoader(true);
        let tip = await deleteAPI(`/safety-tips/${id}`);
        if (tip) {
            getTips()
            setShow(false)
        }
        setLoader(false);
    }
    const [permissionList, setPermissionList] = useState([]);

    // Load the permission list from localStorage
    useEffect(() => {
      const permissions = JSON.parse(localStorage.getItem("permisionlist")) || [];
      setPermissionList(permissions);
    }, []);
  
    const submenuPermissions = permissionList[0]?.submenuPermissions || [];
  
    // Check if 'Delete' and 'Edit' subActions exist
    
    const canDelete = submenuPermissions.some(
      (permission) => permission.submenu === "Delete"
    );
    const canEdit = submenuPermissions.some(
      (permission) => permission.submenu === "Edit"
    );
    
  
    // If submenuPermissions is empty, show both buttons
    const showBothButtons = submenuPermissions.length === 0;

    return (
        <Box sx={{ height: "inherit" }}>
            <Loader loader={loader} />
            <PageTitle title="Safety Tips" />
            {
                checkAuthority('VIEW_TIPS') &&
                <Box display="flex" sx={{ my: "3rem" }}>
                    <TableContainer component={Paper} sx={{ mx: "0.8rem" }} >
                        <Table sx={{ minWidth: 'auto' }} aria-label="custom pagination table" className="responsive-table">
                            <TableHead >
                                <TableRow className="table-header">
                                    <TableCell align="left" component="th" sx={tableHeader}>Title</TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>Description</TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    tips.slice(page * perPage, page * perPage + perPage).map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="left" sx={tableData}>
                                                {item.title}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                {item.description}
                                            </TableCell>
                                            <TableCell align="center" sx={tableData}>
                                                {
                                                  (showBothButtons || canEdit) &&  checkAuthority('EDIT_TIP') &&
                                                    <Button className="btn-div" color="primary" variant="outlined" sx={{ mx: 2 }} onClick={() => editClick(item.id)}>
                                                        <EditIcon className="" />
                                                    </Button>
                                                }
                                                {
                                                   (showBothButtons || canDelete) && checkAuthority('DELETE_TIP') &&
                                                    <Button className="btn-div" color="error" variant="outlined" onClick={() => deleteClick(item.id)}>
                                                        <DeleteIcon className="" />
                                                    </Button>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                                {
                                    tips.length === 0 &&
                                    <EmptyTable colSpan={3} />
                                }
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell sx={tableData} direction="column" justifyContent="center">
                                        <Link href="#" underline="none" >
                                            {/* Add Guard <AddCircleOutlineIcon  className="add-icon"/> */}
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                                justifyContent: 'space-between'

                                            }}>

                                                <span className="add-title">Add Safety Tips</span>
                                                <AddCircleIcon className="add-icon" fontSize="medium" onClick={handleClickOpen} />
                                            </div>
                                        </Link>
                                    </TableCell>
                                    <TablePagination
                                        align="right"
                                        rowsPerPageOptions={perPages}
                                        colSpan={9}
                                        count={tips.length}
                                        rowsPerPage={perPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    // ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>

                    </TableContainer>
                </Box>
            }


            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={{ textAlign: 'center', mb: 8 }}>{action === "add" ? 'Add' : 'Update'} Safety Tip</DialogTitle>
                <DialogContent>
                    {/* <FormControl sx={{ my: 1, minWidth: "100%" }}>
                    <InputLabel id="demo-multiple-name-label">Company</InputLabel>
                        <Select
                            multiple
                            value={company}
                            onChange={(e) => setCompany(typeof value === 'string' ? e.target.value.split(',') : e.target.value,)}
                            displayEmpty
                            labelId="company-label"
                            id="company"
                            label="Select Company"
                        //   error={shift.companyError}
                        //   InputProps={{
                        //     classes: {
                        //       root: classes.inputRoot,
                        //     },
                        //   }}
                        >

                            {
                                companies.map((item, index) => (
                                    <MenuItem value={item.id} key={index} multiple>
                                        <div className="select_item" value={item.id}>{item?.label}</div>
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl> */}
                    {/* <FormControl sx={{ my: 1, minWidth: "100%" }}>
                    <InputLabel id="demo-multiple-name-label">Vendor</InputLabel>
                        <Select
                            multiple
                            value={vendor}
                            onChange={(e) => setVendor(typeof value === 'string' ? e.target.value.split(',') : e.target.value,)}
                            displayEmpty
                            labelId="vendor-label"
                            id="vendor"
                            label="Select Vendor"
                        >

                            {
                                vendors?.map((item, index) => (
                                    <MenuItem value={item.id} key={index} multiple>
                                        <div className="select_item" value={item?.id}>{item?.label}</div>
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl> */}
                    {/* <FormControl sx={{ my: 1, minWidth: "100%" }}>
                    <InputLabel id="demo-multiple-name-label">Site</InputLabel>
                        <Select
                            multiple
                            value={site}
                            onChange={(e) => setSite(typeof value === 'string' ? e.target.value.split(',') : e.target.value,)}
                            displayEmpty
                            labelId="company-label"
                            id="company"
                            label="Select Site"
                        >

                            {
                                sites?.map((item, index) => (
                                    <MenuItem value={item.id} key={index} multiple>
                                        <div className="select_item" value={item.id}>{item?.label}</div>
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl> */}
                    <TextField
                        autoFocus
                        margin="dense"
                        id="title"
                        label="Safety tip title"
                        type="text"
                        defaultValue={title}
                        onChange={(event) => { setTitle(event.target.value) }}
                        fullWidth
                        error={titleError}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        label="Description for safety tip"
                        type="text"
                        defaultValue={desc}
                        onChange={(event) => { setDesc(event.target.value) }}
                        fullWidth
                        multiline
                        rows={4}
                        error={descError}
                    />
                </DialogContent>
                <DialogActions sx={{ mx: 2, mb: 4 }}>
                    <Button onClick={handleSubmit} variant="contained" color="primary">{btnTxt}</Button>
                    <Button onClick={handleClose} variant="outlined">Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={show} onClose={handleClose} fullWidth={true}>
                <DialogTitle sx={{ mb: 4, textAlign: "center" }}>Delete Safety Tip</DialogTitle>

                <DialogContent>
                    <Box
                        component="form"
                        sx={{ '& .MuiTextField-root': { my: 2, width: '100%' }, }}
                        noValidate
                        autoComplete="off"
                    >
                        <h4 style={{ textAlign: 'left', fontWeight: 'bold' }}>Do you want's to delete this tip</h4>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ mb: 2, mx: 4 }}>
                    <Button onClick={handleDelete} variant="contained" color="error">Delete</Button>
                    <Button onClick={handleShowClose} variant="outlined">Cancel</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}


