import React, { useEffect, useState, useContext } from "react";
import { Box, FormControl, Grid, Link, FormLabel, Modal, Typography, Button } from "@mui/material";
import PageTitle from "../../common/PageTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Controller, useForm } from "react-hook-form";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import { getAPI, postAPI, patchAPI, deleteAPI, getExportPdfAPI, getExportAPI } from "../../network";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Select as Choice } from '@material-ui/core';
import Select from 'react-select'
import { useNavigate } from "react-router-dom";
import { checkAuthority, validation, tableHeader, tableData, tablebtn, formatDatePost } from "../../utils";
import Loader from "../../common/Loader";
import EmptyTable from "../../common/EmptyTable";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { CompanyContext } from "../../../context";
// import { InputLabel, MenuItem } from "@material-ui/core";
// import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
// import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { BASE_URL } from "../../../utils/axiosSetup";
// import LocalDateSelector from "../../common/LocalDateSelector";
// import SiteDetailCard from "../../components/sites/DetailCard";

export default function RiskAssessmentForms() {
  // const [companyId] = useContext(CompanyContext)
  let companyId = localStorage.getItem('companyIdLS')
  if (companyId === null) {
    companyId = undefined 
  } 
  const [open, setOpen] = useState(false);
  const [optionOpen, setOptionOpen] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [opencat, setOpencat] = useState(false);
  const [editId, setEditId] = useState('');
  const [action, setAction] = useState('');
  const [show, setShow] = useState(false);
  const [showcat, setShowcat] = useState(false);
  const [loader, setLoader] = useState(false);
  const [categoryOption, setCategoryOption] = useState([]);
  const [optionCategory, setOptioncategory] = useState([]);
  const [category, setCategory] = useState([]);
  const [option, setOptions] = useState([])
  const [sites, setSites] = useState([])
  const [sitesSave, setSitesSave] = useState([])
  const [optionD, setOptionD] = useState([])
  const [person, setPerson] = useState({
    name: '',
    sites: [],
    siteError: false,
    nameError: false,
  })

  const [optionval, setOptionval] = useState({
    name: '',
    option: [],
    nameError: false,
    optionError: false,

  })
  const [catOption, setCatOption] = useState({
    name: '',
    nameError: false,
  })
  const [page, setPage] = useState(0)
  const [menu, setMenu] = useState()
  const [perPages, setPerPages] = useState([10, 25, 50]);
  const [perPage, setPerPage] = useState(10)

  const [page2, setPage2] = useState(0)
  const [perPages2, setPerPages2] = useState([10, 25, 50]);
  const [perPage2, setPerPage2] = useState(10)

  const [page3, setPage3] = useState(0)
  const [perPages3, setPerPages3] = useState([10, 25, 50]);
  const [perPage3, setPerPage3] = useState(10)

  const [assreport, setAssReports] = useState([])
  const [users, setUsers] = useState([])
  const [companies, setCompanies] = useState([])

  const [report2, setReport2] = useState({
    reportId: 11,
    siteId: '',
    shiftId: '',
    startDate: null,
    endDate: null,
    companyId: "",
    userId: "",
    riskAssessmentType: "",
  })
  const navigate = useNavigate();


  useEffect(() => {
    // getRiskAssesmentCategory();
    // getRiskCategory()
    // getOptions()
    // getCategoryOptions()
    getSites()

    // getSites(companyId)
    // getUsers(companyId)
    // getCompanies()
    // getAssessmentReport(null, null, null, companyId)
    getRiskAssesmentCategory()
  }, []);

  const getSites = async () => {
    setLoader()
    let data = await getAPI(`/sites?companyId=${companyId}`)
    if (data) {
      let outputs = data.map((item) => ({
        id: item._id ? item._id : item.id,
        label: item.name,
        value: item._id ? item._id : item.id
      }))
      setSites(outputs)
    }
    setLoader()
  }
  const getRiskAssesmentCategory = async () => {
    setLoader(true)
    let data = await getAPI(`/risk-assessment/category?companyId=${companyId}`);
    if (data) {
      setCategory(data)
    }
    setLoader(false)
  }



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    // console.log(event.target.value)
    setPerPage(event.target.value)
    setPage(0);
  };


  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };
  const handleChangeRowsPerPage2 = (event) => {
    setPerPage2(event.target.value)
    setPage2(0);
  };


  const handleChangePage3 = (event, newPage) => {
    setPage3(newPage);
  };
  const handleChangeRowsPerPage3 = (event) => {
    setPerPage3(event.target.value)
    setPage3(0);
  };

  const addLicense = (e) => {
    e.preventDefault();
    setAction('add');
    setSitesSave([])
    clearAll();
    setOpen(true)
  }

  const editlicense = (e, id) => {

    e.preventDefault();
    setEditId(id)
    clearAll();
    let data = category.filter(item => item.id === id)[0];
    console.log(data, "data")
    setPerson(prevState => ({
      ...prevState,
      name: data?.name,
      sites: data?.site?.map(e => { return { 'label': e?.name, 'id': e?._id, 'value': e?._id } }),
      // sites: data?.sites.map(e=>{return {id:data.siteid,label:}})
      nameError: false,
    }))

    // console.log(person, 'person-->', data)

    // getCities(data.country)
    setAction('edit');
    setOpen(true)

  }

  const deleteCompany = (id) => {

    setEditId(id);
    clearAll();
    setShow(true);
  }

  const clearAll = () => {
    setPerson({
      name: '',
      nameError: false,
    })
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleShowClose = () => {
    setShow(false);
    setShowcat(false);
    setShowOption(false);
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setPerson(prevState => ({
      ...prevState,
      nameError: false,
    }))
    if (sitesSave.length === 0) {
      toast.error('Choose at Least one Site')
      return
    }

    if (validation(null, 'Name', person.name)) {
      setPerson(prevState => ({
        ...prevState,
        nameError: true,
      }))
      return;
    }
    var sites2 = sitesSave.map((item) => {
      return item.value
    });
    let payload = {
      name: person.name,
      site: person.sites.id ? person.sites.id : sites2,
      company:companyId
    }
    // console.log(person, 'payload');

    if (action === 'add') {
      setLoader(true)
      let data = await postAPI('/risk-assessment/category', payload)
      //  console.log(data,"data")
      if (data) {
        getRiskAssesmentCategory()
        setOpen(false)
      }
      setLoader(false)
    }
    else {
      setLoader(true)
      let data = await patchAPI(`/risk-assessment/category/${editId}`, payload)
      if (data) {
        setOpen(false)
      }
      getRiskAssesmentCategory()
      setLoader(false)
    }
  }

  const handleDelete = async () => {
    setLoader(true);
    let process = await deleteAPI(`/risk-assessment/category/${editId}`);
    setLoader(false);
    if (process) {
      getRiskAssesmentCategory();
      setShow(false)
    }
  }



  // -----Add delete update Category---------------------------------------------------------------
  const getOptions = async () => {
    setLoader()
    let data = await getAPI(`risk-assessment-choice-option?companyId=${companyId}`)
    if (data) {
      let outputs = data.map((item) => ({
        id: item.id,
        label: item.name,
        value: item.id

      }))
      setOptions(outputs)


    }
    setLoader()
  }
  const getRiskCategory = async () => {
    setLoader(true)
    let data = await getAPI(`/risk-assessment-question-choice?companyId=${companyId}`);
    if (data) {
      setCategoryOption(data)
    }
    setLoader(false)
  }

  const editCat = (e, id) => {
    e.preventDefault();
    setEditId(id)
    clearAll();
    let data = categoryOption.filter(item => item.id === id)[0];
    setOptionval(prevState => ({
      ...prevState,
      name: data?.name,
      option: data?.options.map(e => { return { 'label': e.name, 'id': e._id, 'value': e._id } }),
      nameError: false,
      optionError: false
    }))
    // console.log(optionval, 'aaa', data, option)
    // debugger

    // getCities(data.country)
    setAction('edit');
    setOpencat(true)
  }

  const clearAllcat = () => {
    setOptionval({
      name: '',
      option: [],
      nameError: false,
      optionError: false,
    })
  }

  const deleteCat = (id) => {
    setEditId(id);
    clearAllcat();
    setShowcat(true);
  }
  const Closecat = () => {
    setOpencat(false)
  }
  const addCat = (e) => {
    e.preventDefault();
    setAction('add');
    clearAllcat();
    setOpencat(true)
  }

  const SubmitCat = async (e) => {
    e.preventDefault();


    // console.log(optionD, "::::::::::::::optionD")
    if (optionD.length === 0) {
      toast.error('Choose at Least one Option')
      return
    }
    setOptionval(prevState => ({
      ...prevState,
      nameError: false,
      optionError: false
    }))

    if (validation('empty', 'Name', -optionval.name)) {
      setOptionval(prevState => ({
        ...prevState,
        nameError: true,
      }))
      return;
    }

    else if (validation('array', 'Options', optionD)) {
      setOptionval(prevState => ({
        ...prevState,
        optionError: true,
      }))
      return;
    }
    var ids = optionD.map((item) => {
      return item.value
    });


    let payload = {
      name: optionval.name,
      options: ids,
    }
    // console.log(payload, "++++++++Payload for choices")


    if (action === 'add') {
      setLoader(true)
      let data = await postAPI('/risk-assessment-question-choice', payload)
      //  console.log(data,"data")
      if (data) {
        getRiskCategory()
        setOpencat(false)
      }
      setLoader(false)
    }
    else {
      setLoader(true)
      let data = await patchAPI(`/risk-assessment-question-choice/${editId}`, payload)
      if (data) {
        getRiskCategory()
        setOpencat(false)
      }
      setLoader(false)
    }
  }

  const handleDeletecat = async () => {
    setLoader(true);
    let process = await deleteAPI(`/risk-assessment-question-choice/${editId}`);
    setLoader(false);
    if (process) {
      getRiskCategory();
      setShowcat(false)
    }
  }

  // -----Add delete update Category Options---------------------------------------------------------------
  const getCategoryOptions = async () => {
    setLoader(true)
    let data = await getAPI(`/risk-assessment-choice-option?companyId=${companyId}`);
    if (data) {
      setOptioncategory(data)
    }
    setLoader(false)
  }

  const clearAllOption = () => {
    setCatOption({
      name: '',
      nameError: false,

    })
  }
  const addOption = (e) => {
    e.preventDefault();
    setAction('add');
    clearAllOption();
    setOptionOpen(true)
  }

  const editOption = (e, id) => {

    e.preventDefault();
    setEditId(id)
    clearAllOption();
    let data = optionCategory.filter(item => item.id === id)[0];
    setCatOption(prevState => ({
      ...prevState,
      name: data?.name,
      nameError: false,
    }))

    // console.log(person)

    // getCities(data.country)
    setAction('edit');
    setOptionOpen(true)

  }

  const deleteOption = (id) => {
    setEditId(id);
    clearAllOption();
    setShowOption(true);
  }


  const CloseOption = () => {
    setOptionOpen(false)
  }



  const SubmitOption = async (e) => {
    e.preventDefault();
    setCatOption(prevState => ({
      ...prevState,
      nameError: false,
    }))

    if (validation('empty', 'Name', catOption.name)) {
      setCatOption(prevState => ({
        ...prevState,
        nameError: true,
      }))
      return;
    }

    let payload = {
      name: catOption.name,
    }


    if (action === 'add') {
      setLoader(true)
      let data = await postAPI('/risk-assessment-choice-option', payload)
      //  console.log(data,"data")
      if (data) {
        getCategoryOptions()
        setOptionOpen(false)
      }
      setLoader(false)
    }
    else {
      setLoader(true)
      let data = await patchAPI(`/risk-assessment-choice-option/${editId}`, payload)
      if (data) {
        getCategoryOptions()
        setOptionOpen(false)
      }
      setLoader(false)
    }
  }

  const DeleteOption = async () => {
    setLoader(true);
    let process = await deleteAPI(`/risk-assessment-choice-option/${editId}`);
    setLoader(false);
    if (process) {
      getCategoryOptions();
      setShowOption(false)
    }
  }


  const handleNavigateForm = (ids) => {
    navigate(`${window.location.pathname}/assessment-question/${ids}`);
  }

  const handleNavigateSubmitForm = (formId) => {
    navigate(`${window.location.pathname}/${formId}/formSubmit`)
  }
  const getAssessmentReport2 = async () => {
    setLoader(true)




    var url = `/risk-assessment-report?companyId=${companyId}`

    let data = await getAPI(url);
    if (data) {
      setAssReports(data)

    }
    setLoader(false)


  }
  const clearFilter2 = () => {

    setLoader(true)

    setReport2({
      siteId: '',
      shiftId: '',
      companyId: '',
      userId: '',
      startDate: null,
      endDate: null,
      riskAssessmentType: '',
    })
    getAssessmentReport2()



    // if (id === 11) {
    //   getAssessmentReport()
    // }

    setLoader(false)

  }
  const exportDocRisk = async () => {
    // var url = generateUrl(report.companyId, report.siteId, report.startDate, report.endDate);

    setLoader(true)


    let data = await getExportPdfAPI(`${BASE_URL}/risk-assessment-report/exportpdf`);


    // let data = await getExportPdfAPI(`${BASE_URL}/export/file/${exportfile.trim()}` + url);

    if (data) {
      // alert(`${BASE_URL}/${exportfile}/export/file${url}`)
    }
    setLoader(false)

  }
  const getSites2 = async (cid) => {
    setLoader(true)
    let userType = localStorage.getItem('userType')
    if (companyId) {
      let data = await getAPI(userType === 'admin' ? `/sites?companyId=${companyId}` : '/company/sites');
      if (data) {
        setSites(data)
      }
    }
    if (cid) {
      let data = await getAPI(userType === 'admin' ? `/sites?companyId=${cid}` : '/company/sites');
      if (data) {
        setSites(data)
      }
    }
    else {
      let data = await getAPI(userType === 'admin' ? `/sites` : '/company/sites');
      if (data) {
        setSites(data)
      }
    }
    setLoader(false)
  }

  const getUsers = async (cId, sId) => {

    setLoader(true)
    if (sId) {
      const users = await getAPI(`/users/app?companyId=${cId ? cId : companyId}&siteId=${sId}`);
      setLoader(false)
      setUsers(users);
    } else {
      const users = await getAPI(`/users/app?companyId=${cId ? cId : companyId}`);
      setLoader(false)
      setUsers(users);
    }

  }
  const getCompanies = async () => {
    setLoader(true)

    let data = await getAPI(`/companies`);
    if (data) {
      setCompanies(data)
    }
    setLoader(false)
  }
  const generateUrl = (cId = null, sId = null, sDate = null, eDate = null, uId = null, rtId = null, rAt = null) => {
    var additional_url = '';
    var isFirst = true;
    if (sId !== null && sId !== '') {
      additional_url += isFirst ? `?siteId=${sId}` : `&siteId=${sId}`;
      isFirst = false;
    }
    if (sDate !== null && sDate !== '') {
      additional_url += isFirst ? `?startDate=${formatDatePost(sDate)}` : `&startDate=${formatDatePost(sDate)}`;
      isFirst = false;
    }

    if (eDate !== null && eDate !== '') {
      additional_url += isFirst ? `?endDate=${formatDatePost(eDate)}` : `&endDate=${formatDatePost(eDate)}`;
      isFirst = false;
    }
    if (cId !== null && cId !== '') {
      additional_url += isFirst ? `?companyId=${cId}` : `&companyId=${cId}`;
      isFirst = false;
    }
    if (uId !== null && uId !== '') {
      additional_url += isFirst ? `?userId=${uId}` : `&userId=${uId}`;
      isFirst = false;
    }
    if (rtId !== null && rtId !== '') {
      additional_url += isFirst ? `?reportType=${rtId}` : `&reportType=${rtId}`;
      isFirst = false;
    }
    if (rAt !== null && rAt !== '') {
      additional_url += isFirst ? `?riskAssessmentCategoryId=${rAt}` : `&riskAssessmentCategoryId=${rAt}`;
      isFirst = false;
    }

    return additional_url;
  }
  const getAssessmentReport = async (sId = null, sDate = null, eDate = null, cId = null, uId = null, rAt = null) => {
    setLoader(true)
    // setTimeout(() => { 

    // }, 1000);
    setLoader(false)

    let dCId = cId ? cId : companyId;
    let dUId = uId ? uId : report2.userId;
    let dSId = sId ? sId : report2.siteId;
    let dSDate = sDate ? sDate : report2.startDate;
    let dEDate = eDate ? eDate : report2.endDate;
    let dRat = rAt ? rAt : report2.riskAssessmentType;
    setLoader(true)

    var url = `/risk-assessment-report`

    let data = await getAPI(url + generateUrl(dCId, dSId, dSDate, dEDate, dUId, null, dRat));

    if (data) {
      setAssReports(data)
      // console.log("data from APi*******************",data)
    }
    setLoader(false)

    // setLoader(true)
    // let data = await getAPI(`/risk-assessment-report`)
    // if (data) {
    //   setAssReports(data)
    // }
    // setLoader(false)

  }

  const changeCompany2 = (event) => {
    let id = event.target.value;

    getSites(id)
    getUsers(id)
    getAssessmentReport(null, null, null, id)

    setReport2(prevState => ({
      ...prevState,
      companyId: id
    }))
  };

  const changeSite2 = (event) => {
    let id = event.target.value;
    let reportId = report2.reportId
    getUsers(null, id)
    getAssessmentReport(id)

    setReport2(prevState => ({
      ...prevState,
      siteId: id
    }))
  };

  const changeRiskAssessmentType2 = (event) => {
    let id = event.target.value;

    getAssessmentReport(null, null, null, null, null, id)

    setReport2(prevState => ({
      ...prevState,
      riskAssessmentType: id
    }))
  };

  const changeStartDate2 = (event) => {
    let id = event
    let reportId = report2.reportId


    // if (reportId === 11) {
    getAssessmentReport(null, id)
    // }
    // setreportstartDate(id)

    setReport2({
      ...report2,
      startDate: id
    })
  }

  const changeEndDate2 = (event) => {
    let id = event
    let reportId = report2.reportId
    // if (reportId === 11) {
    getAssessmentReport(null, null, id)
    // }

    // setreportendDate(id)
    setReport2({
      ...report2,
      endDate: id
    })
  }
  const AssessExp = async (id) => {
    setLoader(true)
    let data = await getExportAPI(`${BASE_URL}/risk-assessment-report/export?reportId=${id}`,`Risk Assessment Forms`)
    setLoader(false)
  }

  const [permissionList, setPermissionList] = useState([]);

  // Load the permission list from localStorage
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permisionlist")) || [];
    setPermissionList(permissions);
  }, []);

  const actionPermissions = permissionList[0]?.actionPermissions || [];

  // Check if 'Delete' and 'Edit' subActions exist
  
  const canAdd = actionPermissions.some(
    (permission) => permission.action === "Add"
  );
  const canEdit = actionPermissions.some(
    (permission) => permission.action === "Edit"
  );
  
  const canDelete = actionPermissions.some(
    (permission) => permission.action === "Delete"
  );
  // If actionPermissions is empty, show both buttons
  const showBothButtons = actionPermissions.length === 0;
  return (
    <Box sx={{ height: "inherit" }}>
      <ToastContainer />
      <Loader loader={loader} />
      {/* <Box display="flex">
        <div className="" style={{
          textAlign: 'center', fontWeight: 'bold', border: '1px solid black', width: '20rem', height: '2rem', margin: '3rem', backgroundColor: menu === 0 ? 'lightblue' : 'white', cursor: 'pointer'
        }} onClick={() => setMenu(0)}>
          Risk Assessment Forms
        </div>
        <div className="" style={{
          textAlign: 'center', fontWeight: 'bold', border: '1px solid black', width: '20rem', height: '2rem', margin: '3rem', backgroundColor: menu === 1 ? 'lightblue' : 'white', cursor: 'pointer'
        }} onClick={() => setMenu(1)}>
          Risk Assessment Options
        </div>
        <div className="" style={{
          textAlign: 'center', fontWeight: 'bold', border: '1px solid black', width: '20rem', height: '2rem', margin: '3rem', backgroundColor: menu === 2 ? 'lightblue' : 'white', cursor: 'pointer'
        }} onClick={() => setMenu(2)}>
          Risk Assessment Reports
        </div>
      </Box> */}

{/* 
      <Grid item xs={3} sx={{ mb: 2 }}>
        <FormControl style={{ width: "25%" }} >
          <InputLabel id="company-label">Please Select Report Type</InputLabel>
          <Choice
            labelId="company-label"
            id="company-select"
            value={menu}
            label="Select "
            onChange={(e) => setMenu(e.target.value)}
          >

            <MenuItem value={0}>Risk Assessment Forms
            </MenuItem>
            <MenuItem value={1}>Risk Assessment Options
            </MenuItem>
            <MenuItem value={2}>Risk Assessment Reports
            </MenuItem>

          </Choice>
        </FormControl>
      </Grid> */}

      
        <Box>


          <PageTitle title="Risk Assessment Forms" subTitle="" />


          {
            checkAuthority('ADD_ASSESMENT_CATEGORY') &&
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
              sx={{ mx: 2 }}
            >
               {(showBothButtons || canAdd) && (
              <Button variant="contained" style={{ backgroundColor: "#75859D" }} sx={{ height: 50 }} onClick={addLicense}>
                <AddCircleIcon /> &nbsp; &nbsp;
                Add Risk Assessment Form
              </Button>
               )}
            </Box>
          }

          <Box display="flex" sx={{ my: "2rem" }}>
            <TableContainer component={Paper} sx={{ mx: "0.8rem" }}>
              <Table
                sx={{ minWidth: "auto" }}
                aria-label="custom pagination table"
                className="responsive-table"
              >
                <TableHead>
                  <TableRow className="table-header" align="center">
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Risk Assessment Form
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Sites
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Action
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader} >
                      Create Form
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader} >
                      Submit Form
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {category.slice(page * perPage, page * perPage + perPage).map((item, index) => (
                    <TableRow key={index}>
                      <TableCell align="left" sx={tableData}>
                        <Link to="#" underline="none" className="link-hover">
                          {item.name}
                        </Link>
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.site?.map((e, index) => (
                          <span key={index} style={{ border: "1px solid gray", padding: "2px", margin: "2px" }}>
                            {e?.name}
                          </span>
                        ))}

                      </TableCell>


                      <TableCell align="center" sx={tablebtn}>
                      {(showBothButtons || canEdit) && (
                        <Button variant="outlined" className="btn-div" color="info" sx={{ mx: 1 }} onClick={(e) => editlicense(e, item?.id)}>
                          <EditIcon className="" />
                        </Button>
                      )}
                      {(showBothButtons || canDelete) && (
                        <Button variant="outlined" className="btn-div" color="error" onClick={() => deleteCompany(item?.id)}>
                          <DeleteIcon className="" />
                        </Button>
                      )}
                      </TableCell>
                      {
                        checkAuthority('ADD_ASSESSMENT_FORM') ?
                          <TableCell align="center" className="t-body-cell font-bold" onClick={() => handleNavigateForm(item?.id)}>
                            <span className="formBtn">{'Create Form'}</span>
                          </TableCell>
                          :
                          <TableCell align="center" className="t-body-cell font-bold">
                            <span className="formBtn"></span>
                          </TableCell>
                      }

                      {
                        checkAuthority('SUBMIT_ASSESSMENT_FORM') ?
                          <TableCell align="center" className="t-body-cell font-bold" onClick={() => handleNavigateSubmitForm(item?.id)}>
                            <span className="formBtn">{'Submit Form'}</span>
                          </TableCell>
                          :
                          <TableCell align="center" className="t-body-cell font-bold">
                            <span className="formBtn"></span>
                          </TableCell>
                      }

                    </TableRow>
                  ))}
                  {
                    category.length === 0 &&
                    <EmptyTable colSpan={7} />
                  }


                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      align="right"
                      rowsPerPageOptions={perPages}
                      colSpan={9}
                      count={category.length}
                      rowsPerPage={perPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    // ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
        </Box>
     



      <Dialog open={optionOpen} sx={{ height: '500px' }} onClose={CloseOption} fullWidth={true}>
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>{action === 'add' ? 'Add' : 'Edit'} Option</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { my: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >


            <FormControl sx={{ minWidth: '97%', mx: 0, px: 0 }}>
              <TextField
                id="name"
                label="Option Name"
                variant="outlined"
                type="text"
                value={catOption.name}
                error={catOption.nameError}
                onChange={(data) => {
                  setCatOption(prevState => ({
                    ...prevState,
                    name: data.target.value,
                  }))
                }}
                fullWidth
                sx={{ m: 0 }}
              />
            </FormControl>

          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={SubmitOption} variant="contained">{action === 'add' ? 'Submit' : 'Update'}</Button>
          <Button onClick={CloseOption} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showOption} onClose={handleShowClose} fullWidth={true}>
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>Delete Risk Assessment Option</DialogTitle>

        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { my: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >

            <h3 style={{ textAlign: 'left', fontWeight: 'bold' }}>Do you want's to delete this  Option</h3>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={DeleteOption} variant="contained" color="error">Delete</Button>
          <Button onClick={handleShowClose} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>



      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>{action === 'add' ? 'Add' : 'Edit'} Risk Assessment Form</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{ height: '100%' }}
            noValidate
            autoComplete="off"
          >
            <FormControl sx={{ minWidth: '97%', mx: 0, px: 0, my: 1 }}>
              &nbsp;
              <TextField
                id="name"
                label="Risk Category Form"
                variant="outlined"
                type="text"
                value={person.name}
                error={person.nameError}
                onChange={(data) => {
                  setPerson(prevState => ({
                    ...prevState,
                    name: data.target.value,
                  }))
                }}
                fullWidth
                sx={{ m: 0 }}
              />
            </FormControl>

            <FormControl sx={{ minWidth: '97%', mt: 2, mb: 50 }}>
              <span style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5 }}>Sites</span>
              <Select
                value={person.sites}
                onChange={(data) => {
                  setPerson(prevState => ({
                    ...prevState,
                    sites: data,
                  }))
                  setSitesSave(data)
                }}
                // onChange={(data) => {
                //   setSitesSave(data)
                // }}
                isMulti
                name="permissions"
                className="basic-multi-select"
                classNamePrefix="select"
                error={person.siteError}
                options={sites}
                style={{ zIndex: 900 }}
              />
            </FormControl>


          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={handleSubmit} variant="contained">{action === 'add' ? 'Submit' : 'Update'}</Button>
          <Button onClick={handleClose} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>
      {/* delete Modal */}
      <Dialog open={show} onClose={handleShowClose} fullWidth={true}>
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>Delete Risk Assessment Form</DialogTitle>

        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { my: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >

            <h3 style={{ textAlign: 'left', fontWeight: 'bold' }}>Do you want's to delete this Risk Category </h3>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={handleDelete} variant="contained" color="error">Delete</Button>
          <Button onClick={handleShowClose} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>


      <Dialog open={opencat} onClose={Closecat} fullWidth={true} >
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>{action === 'add' ? 'Add' : 'Edit'} Risk Assessment Option</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { my: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl sx={{ minWidth: '97%', mx: 0, px: 0 }}>
              <TextField
                id="name"
                label="Risk Category Name"
                variant="outlined"
                type="text"
                value={optionval.name}
                error={optionval.nameError}
                onChange={(data) => {
                  setOptionval(prevState => ({
                    ...prevState,
                    name: data.target.value,
                  }))
                }}
                fullWidth
                sx={{ m: 0 }}
              />
            </FormControl>
            <FormControl sx={{ minWidth: '97%', mt: 2, mb: 50 }}>
              <span style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5 }}>Options</span>
              <span style={{ fontSize: 8, marginBottom: 5 }}>Select all the Options you want to add</span>

              <Select
                // value={optionval.option}
                onChange={(data) => {
                  setOptionD(data)
                }}
                isMulti
                name="permissions"
                className="basic-multi-select"
                classNamePrefix="select"
                error={optionval.optionError}
                options={option}
                style={{ zIndex: 900 }}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={SubmitCat} variant="contained">{action === 'add' ? 'Submit' : 'Update'}</Button>
          <Button onClick={Closecat} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showcat} onClose={handleShowClose} fullWidth={true}>
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>Delete Risk Assessment Option</DialogTitle>

        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { my: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >

            <h3 style={{ textAlign: 'left', fontWeight: 'bold' }}>Do you want's to delete this Risk Assessment Option</h3>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={handleDeletecat} variant="contained" color="error">Delete</Button>
          <Button onClick={handleShowClose} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
