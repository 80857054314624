import { toast } from "react-toastify";
// import { postAPI } from "../network";
import NoRecordImg from '../../assets/images/NoRecordFound.png'
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const permissions = JSON.parse(localStorage.getItem('permissions'));
const userType = localStorage.getItem('userType');
const toastObj = { position: toast.POSITION.TOP_RIGHT };


export const tableHeader = {
    fontSize: '0.9rem !important',
    fontWeight: 500,
    color: '#45445F',
    px: 3,
    paddingLeft: '5px',
    paddingRight: '5px',
    padding: '12px'
}

export const tableData = {
    fontSize: '0.9rem !important',
    fontWeight: 400,
    color: '#45445F',
    px: 3,
    paddingLeft: '5px',
    paddingRight: '5px',
    padding: '12px'
}




export const tablebtn = {

    fontSize: ' 1rem !important',
    width: '18px!important',
    height: '18px !important',

}

export const durationCalc = (from = null, to = null) => {
    let newFrom = new Date(from)
    let newTo = new Date(to)
    var seconds = parseInt((newTo - newFrom) / 1000);
    let minutes = Math.floor(seconds / 60);
    let extraSeconds = seconds % 60;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
    return `${minutes} Minutes, ${extraSeconds} Seconds`;
}

export const setTitle = (title) => {
    document.title = title ?? '';
}

export const apiErrors = (errors) => {
    if (Array.isArray(errors)) {
        for (var i = 0; i < errors.length; i++) {
            toast.error(errors[i].messages[0], toastObj);
        }
    }
    else {
        toast.error(errors, toastObj)
    }

}

export const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}
export const getDateFromStringDateAndTime = (date, time) => {
    function convertTo24Hour(timeStr) {
        const [time, modifier] = timeStr.match(/(\d+:\d+)(AM|PM)/).slice(1);
        let [hours, minutes] = time.split(':');

        if (modifier === 'PM' && hours !== '12') {
            hours = parseInt(hours, 10) + 12;
        } else if (modifier === 'AM' && hours === '12') {
            hours = '00';
        }

        return `${hours}:${minutes}`;
    }

    const convertedTime = convertTo24Hour(time); // Convert time to 24-hour format
    const dateTimeString = `${date} ${convertedTime}`; // Combine date and time
    const timestamp = new Date(dateTimeString) // Convert to timestamp
    return timestamp;
}

export const formatDatePost = (timestamp) => {
    if (!timestamp) {
        return null
    }
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export const getSetTime = (rowTime) => {
    let date = '2022-01-01';
    let am = rowTime.substring(5, 7).toLowerCase();
    let time = rowTime.substring(0, 5);
    date += ` ${time} ${am}`;
    return new Date(date);
}
export const getTimeFromDate = (timestamp) => {
    var date = new Date(timestamp * 1000);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    var time = new Date();
    return time.setHours(hours, minutes, seconds);
}
export function getTimeFromTimestamp(timestamp) {
    const dateObject = new Date(timestamp);

    const h = dateObject.getUTCHours();
    const m = dateObject.getUTCMinutes();
    const s = dateObject.getUTCSeconds();

    let time24 = `${h}:${m}:${s}`
    const [hours24, minutes, seconds] = time24.split(":");
    let hours12 = parseInt(hours24, 10);
    const ampm = hours12 >= 12 ? "PM" : "AM";

    if (hours12 === 0) {
        hours12 = 12;
    } else if (hours12 > 12) {
        hours12 -= 12;
    }
    return `${hours12}:${minutes}`;
}
export function getTimeFromTimestamp2(timestamp) {
    // Convert the timestamp to a Date object
    const date = new Date(timestamp);

    // Get the time in milliseconds since the Unix epoch
    const timeInMilliseconds = date.getTime();

    // Convert milliseconds to seconds
    const timeInSeconds = timeInMilliseconds / 1000;


    const internationalTimestamp = timeInSeconds;  // Replace this with your timestamp in seconds
    const intlDate = new Date(internationalTimestamp * 1000);  // Convert to milliseconds

    const istOptions = {
        timeZone: 'Asia/Kolkata',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };

    const istTime = intlDate.toLocaleString('en-US', istOptions);
    return (istTime.split(',')[1]);

}

export const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
}

export const uniqueArray = (arr) => {
    return arr.filter(onlyUnique);
}

export const timeFormat = (time) => {
    var date = new Date(time);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? `0${minutes}`.toString() : minutes;
    hours = hours < 10 ? `0${hours}`.toString() : hours;
    var strTime = hours + ':' + minutes + ampm;
    return strTime;
}

export const fullName = (item) => {
    return item.firstname + ' ' + item.lastname;
}

export const formatDateUTCtoLocal = (utcDateString) => {
    const date = new Date(utcDateString);
    return new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short'
    }).format(date);
};
export const differenceOf2DatesInMinutes = (date1, date2) => {
    date1=new Date(date1)
    date2=new Date(date2)
    // Calculate the difference in milliseconds
    const differenceInMilliseconds = date1 - date2;
    console.log(date1,date2,differenceInMilliseconds)
    // Convert the difference from milliseconds to minutes
    const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
    return differenceInMinutes;
};

export const noRecordFound = (records, colSpan) => {
    if (records.length === 0) {
        return (
            <TableRow>
                <TableCell align="center" sx={tableData} colSpan={colSpan}>
                    {/* <img src={NoRecordImg} style={{width : 350}}/> */}
                    NO RECORD FOUND
                </TableCell>
            </TableRow>
        )
    }
    else {
        return null;
    }
}

export const setParams = (obj) => {
    var keys = Object.keys(obj);
    if (keys > 0) {

    }
    else {
        return '';
    }
}

export const uniqueNumberId = () => {
    let n = 9999999999;
    return Math.floor(Math.random() * n) + 1;
}

export const checkImage = (url) => {
    var request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.send();
    request.onload = function () {
        var status = request.status;
        if (request.status === 200) //if(statusText == OK)
        {
            return true;
        } else {
            return false
        }
    }
}
export function correctStrToTime(timeStr) {
    // console.log("strrrrrrrrtime",timeStr)
    return `${timeStr.slice(0, 5)} ${timeStr.slice(-2)}`
}



export const validation = (type = null, key, value) => {
    var reg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    var phoneno = /^\d{10}$/;
    if (type === null) {
        if (value === '' || value.length === 0) {
            warningMsg(`${key} is required! at least 1 character long.`);
            return true;
        }
        else {
            return false;
        }
    }
    else if (type === 'long') {
        if (value === '' || value.length < 10) {
            warningMsg(`${key} is required! at least 10 character long.`);
            return true;
        }
        else {
            return false;
        }
    }
    else if (type === 'Desc') {
        if (value === '' || value.length > 500) {
            warningMsg(`${key} should be 500 character long only.`);
            return true;
        }
        else {
            return false;
        }
    }
    else if (type === 'empty') {
        if (value === '' || value === undefined || value === null || value.length === 0) {
            warningMsg(`${key} is required!`);
            return true;
        }
        else {
            return false;
        }
    }
    else if (type === 'boolean') {
        if (value === '' || (value !== true && value !== false)) {
            warningMsg(`${key} is required!`);
            return true;
        }
        else {
            return false;
        }
    }
    else if (type === 'date') {
        if (value === null || value === '') {
            warningMsg(`${key} is required!`);
            return true;
        }
        else {
            return false;
        }
    }
    else if (type === 'time') {
        if (value === null || value === '') {
            warningMsg(`${key} is required!`);
            return true;
        }
        else {
            return false;
        }
    }
    else if (type === 'email') {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (value === '') {
            warningMsg(`${key} is required!`);
            return true;
        }
        else if (!regex.test(value)) {
            warningMsg(`${key} is invalid!`);
            return true;
        }
        else {
            return false;
        }
    }
    else if (type === 'phone') {
        if (value === '') {
            warningMsg(`${key} is required!`);
            return true;
        }
        // else if (!value.match(phoneno)) {
        //     warningMsg(`${key} is invalid!`);
        //     return true;
        // }
        else {
            return false;
        }
    }

    else if (type === 'password') {
        if (value === '') {
            warningMsg(`${key} is required!`);
            return true;
        }
        else if (value.length < 6) {
            warningMsg(`${key} must be minimum 6 character long!`);
            return true;
        }
        else {
            return false;
        }
    }
    else if (type === 'postcode') {
        if (value === '') {
            warningMsg(`${key} is required!`);
            return true;
        }
        else if (value.length < 4) {
            warningMsg(`${key} must be minimum 4 character long!`);
            return true;
        }
        else {
            return false;
        }
    }
    else if (type === 'array') {
        console.log(value)
        if (value.length === 0 || value == undefined) {
            warningMsg(`At least one ${key} is required!`);
            return true;
        }
        else {
            return false
        }
    }
    else if (type === 'answer') {
        if (value.length > 0 || value != []) {
            warningMsg(`Please save the answer!`);
            return true;
        }
        else {
            return false
        }
    }
}

export const warningMsg = (msg) => {
    toast.warning(msg, toastObj);
}

export const checkPermission = (text) => {
    let permission = permissions.filter((item) => item === text);
    if (permission === 0) {
        // location.href = window.location.hostname + 'permission'
    }
}

export const checkAuthority = (text) => {
    // console.log('ALL PERMISSIONS @@@@@@@@@@@@@@@@@', permissions)

    // if(text === 'ADD_SITES'){
    //     console.log('ADD_SITES     call ------------permission')
    //     return true;
    // }
    if (userType === 'company') {
        // console.log('ENTERED ---------------------')
        console.log('PERMISSION -------', text)
        let permission = permissions.filter((item) => item.name === text);
        // console.log('permission -----------------', permission)
        if (permission == 0) {
            // console.log('FALSE')
            return false;
        }
        else {
            console.log('TRUE')
            return true;
        }
    }
    else {
        return true;
    }

}

export const calculateTimeDuration = (startTime, endTime) => {
    const [startHour, startMinute] = startTime.split(':');
    const [endHour, endMinute] = endTime.split(':');

    let durationHour = parseInt(endHour, 10) - parseInt(startHour, 10);
    let durationMinute = parseInt(endMinute, 10) - parseInt(startMinute, 10);

    if (durationMinute < 0) {
        durationHour--;
        durationMinute += 60;
    }

    if (durationHour < 0) {
        durationHour += 24;
    }

    const formattedHour = String(durationHour).padStart(2, '0');
    const formattedMinute = String(durationMinute).padStart(2, '0');

    return `${formattedHour}:${formattedMinute}`;
}