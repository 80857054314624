import React, { useEffect, useState, useContext } from "react";
import { Box, FormControl, Grid, Link, Modal, Typography, Button } from "@mui/material";
import PageTitle from "../../common/PageTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import { getAPI, postAPI, patchAPI, deleteAPI, getExportAPI } from "../../network";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LocalDateSelector from "../../common/LocalDateSelector";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { toast } from "react-toastify";
import CircularProgress from '@mui/material/CircularProgress';
import { tableHeader, tableData, fullName } from "../../utils";
import "./style.css";
import { formatDatePost, formatDate } from '../../utils'
import Loader from "../../common/Loader";
import { CompanyContext } from "../../../context";
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import { BASE_URL } from "../../../constant";
import EmailIcon from '@mui/icons-material/Email';

export default function SupportPage() {
  // const [companyId] = useContext(CompanyContext)
  let companyId = localStorage.getItem('companyIdLS')
  if (companyId === null) {
    companyId = undefined
  }
  const [supports, setSupports] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [user, setUser] = useState('');
  const [searchTicketNo, setSearchTicketNo] = useState('');
  const [searchSupportType, setSearchSupportType] = useState('');
  const [users, setUsers] = useState([]);
  const [status, setStatus] = useState('');
  const [supportType, setSupportType] = useState('');
  const [statuses, setStatuses] = useState([]);
  const [date, setDate] = useState(null);
  const [userError, setUserError] = useState(false);
  const [statusError, setStatusError] = useState(false);
  const [issue, setIssue] = useState('');
  const [emailReply, setEmailResp] = useState('');
  const [issueError, setIssueError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [dialogTile, setDialogTitle] = useState('')
  const [editId, setEditId] = useState('');
  const [btnTxt, setBtnTxt] = useState('');
  const [ticket, setTicket] = useState('');
  const [action, setAction] = useState('');
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);

  const [filter, setFilter] = useState({
    startDate: null, endDate: null, statusId: null, userId: null
  })

  const [pagination, setPagination] = useState({
    rows: 10,
    data: [],
    page: 1,
    noOfPages: 0
  })

  const [perPages, setPerPages] = useState([10, 25, 50, 70]);
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(0)

  useEffect(() => {
    getSupports();
    getUsers();
    getStatuses();
  }, []);

  const getSupports = async (startDate = null, endDate = null, userId = null, statusId = null, searchF = null, searchReportTypeF = null) => {
    // var url = ''
    let newStartDate = startDate !== null ? startDate : filter?.startDate
    let newEndDate = endDate !== null ? endDate : filter?.endDate
    let newUserId = userId ? userId : filter?.userId
    let newStatusId = statusId ? statusId : filter?.statusId
    let newsearchF = searchF ? searchF : searchTicketNo
    let newsearchSF = searchReportTypeF ? searchReportTypeF : searchSupportType

    var additional_url = '';
    var isFirst = true;
    if (newStartDate !== null && newStartDate !== '') {
      additional_url += isFirst ? `?startDate=${formatDatePost(newStartDate)}` : `&startDate=${formatDatePost(newStartDate)}`;
      isFirst = false;
    }

    if (newEndDate !== null && newEndDate !== '') {
      additional_url += isFirst ? `?endDate=${formatDatePost(newEndDate)}` : `&endDate=${formatDatePost(newEndDate)}`;
      isFirst = false;
    }
    if (companyId !== null && companyId !== '') {
      additional_url += isFirst ? `?companyId=${companyId}` : `&companyId=${companyId}`;
      isFirst = false;
    }
    if (newUserId !== null && newUserId !== '') {
      additional_url += isFirst ? `?userId=${newUserId}` : `&userId=${newUserId}`;
      isFirst = false;
    }
    if (newStatusId !== null && newStatusId !== '') {
      additional_url += isFirst ? `?statusId=${newStatusId}` : `&statusId=${newStatusId}`;
      isFirst = false;
    }
    if (newsearchF !== null && newsearchF !== '') {
      additional_url += isFirst ? `?ticketId=${newsearchF}` : `&ticketId=${newsearchF}`;
      isFirst = false;
    }
    if (newsearchSF !== null && newsearchSF !== '') {
      additional_url += isFirst ? `?type=${newsearchSF}` : `&type=${newsearchSF}`;
      isFirst = false;
    }


    setLoader(true)
    console.log('URL ----', `/support${additional_url}`)
    const supports = await getAPI(`/support${additional_url}`);
    setLoader(false);
    // getPagination(supports)
    setSupports(supports);
  }


  // const getPagination = async(data = [] ,page = null, row = null) => {
  //   let newData = data.length !== 0 ? data : supports
  //   let newRow = row !== null ? row : pagination.rows
  //   let newPage = page !== null ? page : pagination.page
  //   console.log('newData', newData)
  //   console.log('newRow', newRow)
  //   console.log('newPage', newPage)
  //   if(newData.length > 0){
  //     console.log(newPage === 1 ? 0 :((newPage * newRow) - 1), ((newPage  * newRow) - 1) < newData.length ? ((newPage * newRow) - 1) : newData.length)
  //     let records = await newData.slice(newPage === 1 ? 0 :((newPage * newRow) - 1), ((newPage  * newRow) - 1) < newData.length ? ((newPage * newRow) - 1) : newData.length)
  //     console.log('RECORDS', records)
  //     await setPagination({
  //       ...filter,
  //       data:records,
  //       page : newPage,
  //       rows : newRow,
  //       noOfPages : Math.ceil(newData.length / newRow)
  //      })
  //   }
  //   else{
  //     await setPagination({
  //       ...filter,
  //       data:[],
  //       page : newPage,
  //       rows : newRow,
  //       noOfPages : 0
  //      })
  //   }

  // }

  const getUsers = async () => {
    setLoader(true)
    const users = await getAPI(`/users/app?companyId=${companyId}`);
    setLoader(false)
    setUsers(users);
  }

  const getStatuses = async () => {
    setLoader(true)
    const statuses = await getAPI(`/support-statuses?companyId=${companyId}`);
    setLoader(false)
    setStatuses(statuses);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPerPage(event.target.value)
    setPage(0);
  };



  const addSupport = (e) => {
    e.preventDefault();
    setDialogTitle('Add Support Issue');
    setBtnTxt('Submit');
    setAction('add');
    clearAll();
    setOpen(true)
  }

  const editSupport = (e, id) => {
    e.preventDefault();
    setEditId(id)
    setDialogTitle('Edit Support Issue');
    setBtnTxt('Edit');
    clearAll();
    let support = supports?.filter(item => item._id === id)[0];
    setStatus(support?.statusId?._id);
    setUser(support?.userId._id);
    setSupportType(support?.type);
    setIssue(support?.issue);
    setTitle(support?.title);
    setTicket(support?.ticketId)
    setDate(support?.closeDate)
    setAction('edit');
    setOpen(true)
  }

  const openMail = (e, id) => {
    e.preventDefault();
    setEditId(id)
    let support = supports?.filter(item => item._id === id)[0];
    setTicket(support?.ticketId)
    setOpenEmail(true)
  }

  const deleteSupport = (id) => {
    setEditId(id);
    clearAll();
    let support = supports?.filter(item => item._id === id)[0];
    setTicket(support?.ticketId)
    setShow(true);
  }

  const clearAll = () => {
    setUser('');
    setIssue('');
    setStatus('');
    setDate(null);
    setTitle('');
    setSupportType('');
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleShowClose = () => {
    setShow(false);
  }


  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
  const toastObj = { position: toast.POSITION.TOP_RIGHT };


  const handleSubmit = async (e) => {
    const currentDate = new Date();

    e.preventDefault();
    setUserError(false);
    setIssueError(false);
    setStatusError(false);
    setDateError(false);
    setTitleError(false);

    if (user === '') {
      toast.warning('User is required!', toastObj);
      setUserError(true);
      return;
    }
    else if (title === '') {
      toast.warning('Title is required!', toastObj);
      setTitleError(true);
      return;
    }
    else if (supportType === '') {
      toast.warning('Support Type is required!', toastObj);
      // setTitleError(true);
      return;
    }
    else if (issue === '' || issue.length < 10) {
      toast.warning('Description is required! and at least 10 character long.', toastObj);
      setIssueError(true);
      return;
    }
    // else if (date === null) {
    //   toast.warning('Date is required!', toastObj);
    //   setDateError(true);
    //   return;
    // }
    else if (status === '') {
      toast.warning('Status is required!', toastObj);
      setStatusError(true);
      return;
    }


    if (action === 'add') {
      let payload = {
        userId: user,
        statusId: status,
        title: title,
        issue: issue,
        issueDate: currentDate,
        closeDate: date,
        type: supportType
      };
      setLoader(true)
      var saveStatus = await postAPI('/support', payload);
      setLoader(false)
      if (saveStatus) {
        getSupports();
        setOpen(false)
      }
    }
    else if (action === 'edit') {
      let payload = {
        userId: user,
        statusId: status,
        title: title,
        issue: issue,
        issueDate: currentDate,
        closeDate: date,
        type: supportType
      };
      let url = `/support/${editId}`;
      setLoader(true)
      var saveStatus = await patchAPI(url, payload);
      setLoader(false)
      if (saveStatus) {
        getSupports();
        setOpen(false)
      }
    }
  }
  const handleSubmitEmail = async (e) => {
    e.preventDefault();


    if (emailReply === '') {
      toast.warning('Response is required!', toastObj);
      // setUserError(true);
      return;
    }




    let payload = {
      desc: emailReply,
      supportId: editId
    };
    setLoader(true)
    var saveStatus = await postAPI(`/support/sendemail`, payload);
    setLoader(false)
    if (saveStatus) {
      // getSupports();
      setOpenEmail(false)
    }


  }
  const changeSearch = (search) => {
    setSearchTicketNo(search.toLowerCase())
    getSupports(null, null, null, null, search.toLowerCase())
  }
  const changeSearchReportType = (search) => {
    setSearchSupportType(search)
    getSupports(null, null, null, null, null, search)
  }

  const handleDelete = async () => {
    setLoader(true);
    let process = await deleteAPI(`/support/${editId}`);
    setLoader(false);
    if (process) {
      getSupports();
      setShow(false)
    }
  }
  const AssessExp2 = async () => {
    setLoader(true)

    var url = ''
    var start = true;
    if (companyId) {
      url += (start ? '?' : '&') + `companyId=${companyId}`
      start = false;
    }
    if (filter.endDate !== '' && filter.endDate !== null) {
      url += (start ? '?' : '&') + `endDate=${formatDatePost(filter.endDate)}`
      start = false;
    }
    if (filter.startDate !== '' && filter.startDate !== null) {
      url += (start ? '?' : '&') + `startDate=${formatDatePost(filter.startDate)}`
      start = false;
    }
    // if (filter.statusId) {
    //   url += (start ? '?' : '&') + `status=${filter.statusId}`
    //   start = false;
    // }
    if (filter.statusId) {
      url += (start ? '?' : '&') + `statusId=${filter.statusId}`
      start = false;
    }
    if (searchTicketNo) {
      url += (start ? '?' : '&') + `ticketId=${searchTicketNo}`
      start = false;
    }
    if (searchSupportType) {
      url += (start ? '?' : '&') + `type=${searchSupportType}`
      start = false;
    }

    // if (search) {
    //   url += (start ? '?' : '&') + `search=${search}`
    //   start = false;
    // }
    let data = await getExportAPI(`${BASE_URL}/support/export` + url,`Support`)
    setLoader(false)
  }

  const [permissionList, setPermissionList] = useState([]);

  // Load the permission list from localStorage
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permisionlist")) || [];
    setPermissionList(permissions);
  }, []);

  const submenuPermissions = permissionList[0]?.submenuPermissions || [];

  // Check if 'Delete' and 'Edit' subActions exist
  
  const canDelete = submenuPermissions.some(
    (permission) => permission.submenu === "Delete"
  );
  const canEdit = submenuPermissions.some(
    (permission) => permission.submenu === "Edit"
  );
  const canAdd = submenuPermissions.some(
    (permission) => permission.submenu === "Add"
  );
  const canMail = submenuPermissions.some(
    (permission) => permission.submenu === "Mail"
  );

  // If submenuPermissions is empty, show both buttons
  const showBothButtons = submenuPermissions.length === 0;
  return (
    <Box sx={{ height: "inherit" }}>
      {
        loader &&
        <Loader loader={loader} />
      }
      <PageTitle title="Support" />

      <Grid
        spacing={2}
        container
        className="sort-box"
        sx={{ display:"flex", paddingLeft:"18px"}}

      >
        <Grid item xs={6} md={6} lg={3}>
          <FormControl style={{ backgroundColor: 'white' }}>
            <LocalDateSelector
              title="Start Date"
              value={filter.startDate}
              onChange={(value) => {
                setFilter({
                  ...filter,
                  startDate: value
                })
                getSupports(value)
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} md={6} lg={3}>
          <FormControl style={{ backgroundColor: 'white', borderRadius: '0px' }}>
            <LocalDateSelector
              title="End Date"
              value={filter.endDate}
              onChange={(value) => {
                setFilter({
                  ...filter,
                  endDate: value
                })
                getSupports(null, value)
              }}

            />
          </FormControl>
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <FormControl sx={{ width: "16rem" }} style={{ backgroundColor: 'white' }}>
            <InputLabel id="demo-simple-select-helper-label">User</InputLabel>
            <Select
              label="User"
              fullWidth
              value={filter.userId}
              style={{height:"61px", display:"flex", justifyContent:"center", alignItems:"center"}}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  userId: e.target.value
                })
                getSupports(null, null, e.target.value)
              }}
            >
              {
                users.map((item, index) => (
                  <MenuItem value={item._id} key={index}>{item.firstname} {item.lastname}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={6} lg={2} style={{ marginLeft: '4rem' }}>
          <FormControl sx={{ width: "16rem" }} style={{ backgroundColor: 'white' }}>
            <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
            <Select
              label="Status"
              fullWidth
              value={filter.statusId}
              style={{height:"61px", display:"flex", justifyContent:"center", alignItems:"center"}}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  statusId: e.target.value
                })
                getSupports(null, null, null, e.target.value)
              }}
            >

              <MenuItem value={'62c79b6a75125e6d08b6dac8'} >In Process</MenuItem>
              <MenuItem value={'62c79ba8775e494440db0fe9'} >Resolved</MenuItem>

            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={6} lg={3}>
          <TextField id="outlined-basic" label="Search via Ticket-Number" variant="outlined" onChange={(e) => changeSearch(e.target.value)} value={searchTicketNo} style={{ marginLeft: "0px", background: "white", marginTop: "16px", width: '19.5vw', marginRight: "0px" }} />
        </Grid>
        {/* <Grid item xs={6} md={6} lg={3}>
          <TextField id="outlined-basic" label="Search via Support Type" variant="outlined" onChange={(e) => changeSearchReportType(e.target.value)} value={searchSupportType} style={{ marginLeft: "5px", background: "white", marginTop: "16px", width: '19.5vw', marginRight: "0px" }} />
        </Grid> */}
        <Grid item xs={6} md={6} lg={3}>
          <FormControl sx={{ width: "18.5rem" }} style={{ backgroundColor: 'white', marginTop: "15px" }}>
            <InputLabel id="demo-simple-select-helper-label">Support Type</InputLabel>
            <Select
              label="Support Type"
              fullWidth
              value={searchSupportType}
              onChange={(e) => changeSearchReportType(e.target.value)}
            >

              <MenuItem value="">
                <div className="selectitem">Select Support Type</div>
              </MenuItem>
              <MenuItem value={'Admin Support'}>Admin Support</MenuItem>
              <MenuItem value={'Invoicing Support'}>Invoicing Support</MenuItem>
              <MenuItem value={'Tech support'}>Tech support</MenuItem>
              <MenuItem value={'Integration support'}>Integration support</MenuItem>
              <MenuItem value={'App Support'}>App Support</MenuItem>
              <MenuItem value={'Portal Support'}>Portal Support</MenuItem>
              <MenuItem value={'Hardware Support'}>Hardware Support</MenuItem>
              <MenuItem value={'User Support'}>User Support</MenuItem>
              <MenuItem value={'Other'}>Other</MenuItem>

            </Select>
          </FormControl>
        </Grid>


        {/* <Grid item xs={3}></Grid> */}
        <Button variant="contained" style={{ backgroundColor: "#75859D", marginTop: '30px', marginLeft:"18px" }} sx={{ height: 55, m: 2, width: '16rem' }} onClick={() => AssessExp2()}>
          <GetAppRoundedIcon /> &nbsp; &nbsp;
          Export
        </Button>
        <Grid item xs={3}></Grid>
      </Grid>
      <Box display="flex" paddingLeft={"6px"} sx={{ my: "2rem" }}>
        <TableContainer component={Paper} sx={{ mx: "0.8rem" }}>
          {/* <div style={{width: 'auto', overflowX: 'scroll'}}> */}
          <Table
            sx={{ minWidth: "auto" }}
            aria-label="custom pagination table"
            className="responsive-table"
          >
            <TableHead>
              <TableRow className="table-header" align="center">
                <TableCell align="center" component="th" sx={tableHeader}>
                  Ticket#
                </TableCell>
                <TableCell align="center" component="th" sx={tableHeader}>
                  User
                </TableCell>
                <TableCell align="center" component="th" sx={tableHeader}>
                  Date Logged
                </TableCell>
                <TableCell align="center" component="th" sx={tableHeader}>
                  Date Closed
                </TableCell>
                <TableCell align="center" component="th" sx={tableHeader}>
                  Title
                </TableCell>
                <TableCell align="center" component="th" sx={tableHeader}>
                  Issue
                </TableCell>
                <TableCell align="center" component="th" sx={tableHeader}>
                  Support Type
                </TableCell>
                <TableCell align="center" component="th" sx={tableHeader}>
                  Status
                </TableCell>
                <TableCell align="center" component="th" sx={tableHeader} >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {console.log("SUPPORTS",supports)} */}
              {supports?.slice(page * perPage, page * perPage + perPage).map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="center" sx={tableData}>
                    {item?.ticketId.toUpperCase()}
                  </TableCell>
                  <TableCell align="center" sx={tableData}>
                    {item?.userId?.firstname} {item?.userId?.lastname}
                  </TableCell>
                  <TableCell align="center" sx={tableData}>
                    {formatDate(item?.createdAt)}
                  </TableCell>
                  <TableCell align="center" sx={tableData}>
                    {item?.closeDate ? formatDate(item?.closeDate) : 'NA'}
                  </TableCell>
                  <TableCell align="left" sx={tableData}>
                    {item?.title}
                  </TableCell>
                  <TableCell align="left" sx={tableData}>
                    {item?.issue}
                  </TableCell>
                  <TableCell align="center" sx={tableData}>
                    {item?.type}
                  </TableCell>
                  <TableCell align="center" sx={tableData}>
                    {item?.statusId?.name}
                  </TableCell>
                  <TableCell align="center" sx={{ textAlign: 'center',display: "flex", gap:"10px", tableData }}>
                  {(showBothButtons || canEdit) && (
                    <Button className="btn-div" variant="outlined" color="info"  onClick={(e) => editSupport(e, item?._id)}>
                      <EditIcon className="" />
                    </Button>
                  )}
                    {/* {`https://outlook.office.com/mail/deeplink/compose?subject=${item.ticketId}&to=${item?.userId?.email}`} */}
                    <a target="_blank" href={`mailto:${item?.userId?.email}?subject=${encodeURIComponent(item.ticketId) || ''}`} >
                    {(showBothButtons || canMail) && (
                      <Button className="btn-div" variant="outlined" color="info"  >
                        <EmailIcon />
                      </Button>
                    )}
                    </a>
                    {(showBothButtons || canDelete) && (
                    <Button className="btn-div" variant="outlined" color="error" onClick={() => deleteSupport(item?._id)}>
                      <DeleteIcon className="" />
                    </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {
                supports.length === 0 &&
                <TableRow>
                  <TableCell align="center" sx={tableData} colSpan={5}>
                    No records found
                  </TableCell>

                </TableRow>
              }


            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell sx={tableData} direction="column" justifycontent="center" colSpan={2}>
                {(showBothButtons || canAdd) && (
                  <Link href="#" underline="none" onClick={addSupport}>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between'

                    }}>
                      <span className="add-title">Add Support Query</span>
                      <AddCircleIcon className="add-icon" fontSize="medium" />
                    </div>
                  </Link>
                )}
                </TableCell>
                {/* <TablePagination
                  align="right"
                  rowsPerPageOptions={[ 10, 25,50, { label: "All", value: -1 }]}
                  colSpan={5}
                  count={supports.length}
                  rowsPerPage={pagination.rows}
                  page={pagination.noOfPages}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows/page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                /> */}
                <TablePagination
                  align="right"
                  rowsPerPageOptions={perPages}
                  colSpan={9}
                  count={supports.length}
                  rowsPerPage={perPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                // ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
          {/* </div> */}
        </TableContainer>
      </Box>
      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>{dialogTile}</DialogTitle>
        <h4 style={{ textAlign: 'center' }}>TICKET : {ticket}</h4>
        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { my: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >


            <FormControl sx={{ minWidth: '97%' }}>
              <InputLabel id="demo-simple-select-helper-label">User</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={user}
                label="User"
                fullWidth
                onChange={(e) => { setUser(e.target.value) }}
              >
                {
                  users.map((item, index) => (
                    <MenuItem value={item._id} key={index}>{item.firstname} {item.lastname}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: '97%', mx: 0, px: 0 }}>
              <TextField
                id="title"
                label="Title"
                variant="outlined"
                type="text"
                defaultValue={title}
                onChange={(event) => {
                  setTitle(event.target.value)
                }}
                fullWidth
                sx={{ m: 0 }}
              />
            </FormControl>

            <FormControl sx={{ minWidth: '97%' }}>
              <TextField
                id="issue"
                label="Issue Description"
                variant="outlined"
                type="text"
                defaultValue={issue}
                onChange={(event) => {
                  setIssue(event.target.value)
                }}
                fullWidth
              />
            </FormControl>

            <FormControl sx={{ minWidth: '97%' }}>
              <InputLabel id="demo-select-standard-label">Support Type</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-select-standard"
                value={supportType}
                label="Select Support Type"
                fullWidth
                onChange={(e) => { setSupportType(e.target.value) }}
              >

                <MenuItem value={'Admin Support'}>Admin Support</MenuItem>
                <MenuItem value={'Invoicing Support'}>Invoicing Support</MenuItem>
                <MenuItem value={'Tech support'}>Tech support</MenuItem>
                <MenuItem value={'Integration support'}>Integration support</MenuItem>
                <MenuItem value={'App Support'}>App Support</MenuItem>
                <MenuItem value={'Portal Support'}>Portal Support</MenuItem>
                <MenuItem value={'Hardware Support'}>Hardware Support</MenuItem>
                <MenuItem value={'User Support'}>User Support</MenuItem>
                <MenuItem value={'Other'}>Other</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: '97%' }}>
              {action === 'add' ?
                null :
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker

                    label="Date Closed"
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue);
                    }}
                    fullWidth
                    renderInput={(params) => <TextField {...params} />}
                    inputFormat="YYYY-MM-DD"
                  />
                </LocalizationProvider>
              }
            </FormControl>


            <FormControl sx={{ minWidth: '97%' }}>
              <InputLabel id="demo-select-standard-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-select-standard"
                value={status}
                label="Select Status"
                fullWidth
                onChange={(e) => { handleStatusChange(e) }}
              >
                {
                  statuses.map((item, index) => (
                    <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={handleSubmit} variant="contained">{btnTxt}</Button>
          <Button onClick={handleClose} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEmail} onClose={handleClose} fullWidth={true}>
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>Send Support Mail</DialogTitle>
        {action === 'add'
          ? null
          : <h4 style={{ textAlign: 'center' }}>TICKET : {ticket}</h4>
        }

        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { my: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl sx={{ minWidth: '97%' }}>
              <TextField
                id="issue"
                label="Response"
                variant="outlined"
                type="text"
                defaultValue={issue}
                onChange={(event) => {
                  setEmailResp(event.target.value)
                }}
                fullWidth
              />
            </FormControl>



          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={handleSubmitEmail} variant="contained">Send</Button>
          <Button onClick={handleClose} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>


      {/* delete Modal */}
      <Dialog open={show} onClose={handleClose} fullWidth={true} mt-10 sx={{ "height": "20rem" }}>
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>Delete Support Query</DialogTitle>

        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { my: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >

            <h3 style={{ textAlign: 'left', fontWeight: 'bold' }}>Do you want's to delete support query ticket {ticket}</h3>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={handleDelete} variant="contained" color="error">Delete</Button>
          <Button onClick={handleShowClose} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
