import React from "react";
import { Box, createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import PageRoutes from "./app/routes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#42505C', // Define your primary color here
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ToastContainer />
        <PageRoutes />
      </Box>
    </ThemeProvider>
  );
}

export default App;
