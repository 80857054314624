import React, {useState ,useEffect} from 'react'
import { Box, Grid } from "@mui/material";
import PageTitle from '../../common/PageTitle';
import SiteDetailCard from '../../components/sites/DetailCard';
const TaskNav = () => {
  const [permissionList, setPermissionList] = useState([]);

  // Load the permission list from localStorage
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permisionlist")) || [];
    setPermissionList(permissions);
  }, []);

  const submenuPermissions = permissionList[0]?.submenuPermissions || [];

  // Check if 'Delete' and 'Edit' subActions exist
  
  const canCreate = submenuPermissions.some(
    (permission) => permission.submenu === "Create"
  );
  const canView = submenuPermissions.some(
    (permission) => permission.submenu === "View"
  );
 

  // If submenuPermissions is empty, show both buttons
  const showBothButtons = submenuPermissions.length === 0;
  return (
    <Box sx={{ height: "inherit" }}>
      <PageTitle title="Tasks" />
      <Box display="flex">
        <Grid
          container
          rowSpacing={7}
          columnSpacing={{ xs: 1 }}
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          mt="1%"
        >
{(showBothButtons || canCreate) && (
          <SiteDetailCard large title={'Create Task '} url={'/tasks/create'} />
)}
{(showBothButtons || canView) && (
          <SiteDetailCard large title={'View Task'} url={'/tasks/view'} />
)}
        </Grid>
      </Box>
    </Box>
  )
}

export default TaskNav