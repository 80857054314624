import React, { useEffect, useState, useContext } from "react";
import { Box, Button, Grid, InputLabel, Link } from "@mui/material";
import PageTitle from "../../../common/PageTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import LocalDateSelector from "../../../common/LocalDateSelector";
import { getAPI, getExportAPI, getExportPdfAPI } from "../../../network";
import { formatDate, tableHeader, tableData, formatDatePost, fullName, getTimeFromTimestamp } from "../../../utils";
import Loader from "../../../common/Loader";
import "./style.css";
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
// import {  Modal} from "@mui/material";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import { AllInbox } from "@material-ui/icons";
import { BASE_URL } from "../../../../constant";
// import { CompanyContext } from '../../../../context';


export default function CustomReportPage() {
  // const [companyId] = useContext(CompanyContext)
  let companyId = localStorage.getItem('companyIdLS')
  if (companyId === null) {
    companyId = undefined
  }
  const [reportsiteId, setreportsiteId] = useState("");
  const [reportstartDate, setreportstartDate] = useState("");
  const [reportendDate, setreportendDate] = useState("");
  const [logs, setLogs] = useState([]);
  const [shiftLogs, setShiftLogs] = useState([]);
  const [roasterLogs, setRoasterLogs] = useState([])
  const [lateLogs, setLateLogs] = useState([])
  const [failLogs, setFailLogs] = useState([])
  const [taskLogs, setTaskLogs] = useState([])
  const [supportLogs, setSupportLogs] = useState([])
  const [show, setShow] = useState(false);
  const [shifts, setShifts] = useState([])
  const [alarmLogs, setAlarmLogs] = useState([])
  const [sites, setSites] = useState([])
  const [users, setUsers] = useState([])
  const [category, setCategory] = useState([])
  const [companies, setCompanies] = useState([])
  const [reportType, setReportType] = useState([])
  const [all, setAll] = useState([])
  const [questions, setQuestions] = useState([])
  const [loader, setLoader] = useState(false)
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10)
  const [assreport, setAssReports] = useState([])
  const [perPages, setPerPages] = useState([5, 10, 25, 50]);
  const [cmp, setCmp] = useState('')
  const [sitee, setSitee] = useState('')
  const [report, setReport] = useState({
    reportId: 6,
    companyId: '',
    siteId: '',
    reportTypeId: '',
    shiftId: '',
    startDate: null,
    endDate: null,
  })
  const [report2, setReport2] = useState({
    reportId: 11,
    siteId: '',
    shiftId: '',
    startDate: null,
    endDate: null,
    companyId: "",
    userId: "",
    riskAssessmentType: "",
  })
  const [reportData, setReportData] = useState([]);


  const clearFilter = () => {
    getSites(companyId)
    setReport({
      ...report,
      reportId: 6,
      companyId: '',
      siteId: '',
      reportTypeId: '',
      shiftId: '',
      startDate: null,
      endDate: null,
    })

    let id = report.reportId;

    if (id === 1) {
      getLogs(companyId)
    }
    if (id === 2) {
      getCasualShifts(companyId)
    }
    if (id === 3) {
      getRoasterShifts(companyId)
    }
    if (id === 4) {
      getLateClockIn(companyId)
    }
    if (id === 5) {
      getFailClock(companyId)
    }
    if (id === 6) {
      getAllReports(companyId)
    }
    if (id === 7) {
      getTask(companyId)
    }
    if (id === 8) {
      getSupport(companyId)
    }
    if (id === 9) {
      getAlarm(companyId)
    }
    if (id === 10) {
      getShifts(companyId)
    }
    if (id === 11) {
      getAssessmentReport(null, null, null, companyId)
    }


  }
  const clearFilter2 = () => {

    setLoader(true)

    setReport2({
      siteId: '',
      shiftId: '',
      companyId: '',
      userId: '',
      startDate: null,
      endDate: null,
      riskAssessmentType: '',
      reportTypeId: ''
    })
    getAssessmentReport2()

    let id = report.reportId;


    // if (id === 11) {
    //   getAssessmentReport()
    // }

    setLoader(false)

  }

  useEffect(() => {
    // getAllReports(companyId)
    // getAssessmentReport()
    // getLogs()
    getSites(companyId)
    getUsers(companyId)
    // getRoasterShifts()
    // getLateClockIn()
    // getFailClock()
    // getTask()
    // getSupport()
    getAlarm(companyId)
    // getShifts()
    getPageData()
    // getReports()
    getCompanies()
    // getAssessmentReport(null, null, null, companyId)
    // getRiskAssesmentCategory()
  }, [])


  const handleShowClose = () => {
    setShow(false);
  }
  const getAllReports = async (cId = null, sId = null, rtId = null, sDate = null, eDate = null) => {
    let dcId = cId ? cId : report.companyId;
    let dSId = sId === null ? report.siteId : sId;
    let dSDate = sDate === null ? report.startDate : sDate;
    let dEDate = eDate === null ? report.endDate : eDate;
    let drtId = rtId === null ? report.reportTypeId : rtId;

    setLoader(true)

    var url = `/user/reports`

    let data = await getAPI(url + generateUrlAllReports(cId, dSId, dSDate, dEDate, null, drtId));
    // console.log('URL LINK','/user/reports' + generateUrl(dSId, dSDate, dEDate))

    if (data) {

      setAll(data)
      // console.log("data from APi*******************",data)
    }
    setLoader(false)
  }


  const getShifts = async (cId = null, sId = null, rtId = null, sDate = null, eDate = null) => {
    let dSId = sId === null ? report.siteId : sId;
    let dSDate = sDate === null ? report.startDate : sDate;
    let dEDate = eDate === null ? report.endDate : eDate;
    setLoader(true)
    if (!companyId) {

      let data = await getAPI(`/shift-logs` + generateUrl(cId, dSId, dSDate, dEDate));
      // console.log('SHIFT LOGS', data)

      if (data) {
        setShifts(data)
      }


    }
    if (companyId) {
      let data = await getAPI(`/shift-logs` + generateUrl(companyId, dSId, dSDate, dEDate));
      // console.log('SHIFT LOGS', data)

      if (data) {
        setShifts(data)
      }
    }
    setLoader(false)
  }

  const generateUrlAllReports = (cId = null, sId = null, sDate, eDate, uId = null, rtId = null) => {
    var additional_url = '';
    var isFirst = true;
    if (sId !== null && sId !== '') {
      additional_url += isFirst ? `?siteId=${sId}` : `&siteId=${sId}`;
      isFirst = false;
    }
    if (sDate !== null && sDate !== '') {
      additional_url += isFirst ? `?startDate=${formatDatePost(sDate)}` : `&startDate=${formatDatePost(sDate)}`;
      isFirst = false;
    }

    if (eDate !== null && eDate !== '') {
      additional_url += isFirst ? `?endDate=${formatDatePost(eDate)}` : `&endDate=${formatDatePost(eDate)}`;
      isFirst = false;
    }
    if (cId !== null && cId !== '') {
      additional_url += isFirst ? `?company=${cId}` : `&company=${cId}`;
      isFirst = false;
    }
    if (uId !== null && uId !== '') {
      additional_url += isFirst ? `?userId=${uId}` : `&userId=${uId}`;
      isFirst = false;
    }
    if (rtId !== null && rtId !== '') {
      additional_url += isFirst ? `?reportType=${rtId}` : `&reportType=${rtId}`;
      isFirst = false;
    }

    return additional_url;
  }
  const generateUrl = (cId = null, sId = null, sDate = null, eDate = null, uId = null, rtId = null, rAt = null) => {
    var additional_url = '';
    var isFirst = true;
    if (sId !== null && sId !== '') {
      additional_url += isFirst ? `?siteId=${sId}` : `&siteId=${sId}`;
      isFirst = false;
    }
    if (sDate !== null && sDate !== '') {
      additional_url += isFirst ? `?startDate=${formatDatePost(sDate)}` : `&startDate=${formatDatePost(sDate)}`;
      isFirst = false;
    }

    if (eDate !== null && eDate !== '') {
      additional_url += isFirst ? `?endDate=${formatDatePost(eDate)}` : `&endDate=${formatDatePost(eDate)}`;
      isFirst = false;
    }
    if (cId !== null && cId !== '') {
      additional_url += isFirst ? `?companyId=${cId}` : `&companyId=${cId}`;
      isFirst = false;
    }
    if (uId !== null && uId !== '') {
      additional_url += isFirst ? `?userId=${uId}` : `&userId=${uId}`;
      isFirst = false;
    }
    if (rtId !== null && rtId !== '') {
      additional_url += isFirst ? `?reportType=${rtId}` : `&reportType=${rtId}`;
      isFirst = false;
    }
    if (rAt !== null && rAt !== '') {
      additional_url += isFirst ? `?riskAssessmentCategoryId=${rAt}` : `&riskAssessmentCategoryId=${rAt}`;
      isFirst = false;
    }

    return additional_url;
  }

  const getLogs = async (cId, sId = null, rtId = null, sDate = null, eDate = null) => {

    let dSId = sId === null ? report.siteId : sId;
    let dSDate = sDate === null ? report.startDate : sDate;
    let dEDate = eDate === null ? report.endDate : eDate;
    let drtId = rtId ? rtId : report.reportTypeId;
    setLoader(true)
    if (!companyId) {

      let data = await getAPI(`/tenancy-check/fetchList` + generateUrl(cId, dSId, dSDate, dEDate, null, drtId));
      if (data) {
        setLogs(data)
      }
    }
    if (companyId) {

      let data = await getAPI(`/tenancy-check/fetchList` + generateUrl(companyId, dSId, dSDate, dEDate, null, drtId));
      if (data) {
        setLogs(data)
      }
    }
    setLoader(false)
  }

  const getCasualShifts = async (cId, sId = null, rtId = null, sDate = null, eDate = null) => {
    let dSId = sId === null ? report.siteId : sId;
    let dSDate = sDate === null ? report.startDate : sDate;
    let dEDate = eDate === null ? report.endDate : eDate;
    let drtId = rtId === null ? report.reportTypeId : rtId;
    setLoader(true)
    if (!companyId) {
      let data = await getAPI(`/company/shifts/list` + generateUrl(cId, dSId, dSDate, dEDate, null, drtId));
      if (data) {
        setShiftLogs(data)
      }
    }
    if (companyId) {
      let data = await getAPI(`/company/shifts/list` + generateUrl(companyId, dSId, dSDate, dEDate, null, drtId));
      if (data) {
        setShiftLogs(data)
      }
    }
    setLoader(false)
  }

  const getRoasterShifts = async (cId, sId = null, rtId = null, sDate = null, eDate = null) => {
    let dSId = sId === null ? report.siteId : sId;
    let dSDate = sDate === null ? report.startDate : sDate;
    let dEDate = eDate === null ? report.endDate : eDate;
    let drtId = rtId === null ? report.reportTypeId : rtId;

    setLoader(true)
    if (!companyId) {
      let data = await getAPI(`/company/roster/shift/list` + generateUrl(cId, dSId, dSDate, dEDate, null, drtId));
      if (data) {
        setRoasterLogs(data)
      }
    }
    if (companyId) {
      let data = await getAPI(`/company/roster/shift/list` + generateUrl(companyId, dSId, dSDate, dEDate, null, drtId));
      if (data) {
        setRoasterLogs(data)
      }
    }
    setLoader(false)
  }



  const getLateClockIn = async (cId, sId = null, rtId = null, sDate = null, eDate = null) => {
    let dSId = sId === null ? report.siteId : sId;
    let dSDate = sDate === null ? report.startDate : sDate;
    let dEDate = eDate === null ? report.endDate : eDate;
    let drtId = rtId === null ? report.reportTypeId : rtId;

    setLoader(true)
    if (!companyId) {
      let data = await getAPI(`/sites/list/clockinout` + generateUrl(cId, dSId, dSDate, dEDate, null, drtId));
      if (data) {
        setLateLogs(data)
      }
    }
    if (companyId) {
      let data = await getAPI(`/sites/list/clockinout` + generateUrl(companyId, dSId, dSDate, dEDate, null, drtId));
      if (data) {
        setLateLogs(data)
      }
    }
    setLoader(false)
  }

  const getFailClock = async (cId, sId = null, rtId, sDate = null, eDate = null) => {
    let dSId = sId === null ? report.siteId : sId;
    let dSDate = sDate === null ? report.startDate : sDate;
    let dEDate = eDate === null ? report.endDate : eDate;
    let drtId = rtId === null ? report.reportTypeId : rtId;

    setLoader(true)
    if (!companyId) {
      let data = await getAPI(`/sites/list/failedclock` + generateUrl(cId, dSId, dSDate, dEDate, null, drtId));
      if (data) {
        setFailLogs(data)
      }
    }
    if (companyId) {
      let data = await getAPI(`/sites/list/failedclock` + generateUrl(companyId, dSId, dSDate, dEDate, null, drtId));
      if (data) {
        setFailLogs(data)
      }
    }
    setLoader(false)
  }

  const getTask = async (cId, sId = null, rtId = null, sDate = null, eDate = null) => {
    let dSId = sId === null ? report.siteId : sId;
    let dSDate = sDate === null ? report.startDate : sDate;
    let dEDate = eDate === null ? report.endDate : eDate;
    let drtId = rtId ? rtId : report.reportTypeId;

    setLoader(true)
    if (!companyId) {
      if (cId) {
        let data = await getAPI(`/tasks` + generateUrl(cId, dSId, dSDate, dEDate, null, drtId).replace("startDate", "dueDate"));
        if (data) {
          setTaskLogs(data)
        }
      }
      if (cmp) {
        let data = await getAPI(`/tasks` + generateUrl(cmp, dSId, dSDate, dEDate, null, drtId).replace("startDate", "dueDate"));
        if (data) {
          setTaskLogs(data)
        }
      } else {
        let data = await getAPI(`/tasks` + generateUrl(report.companyId, dSId, dSDate, dEDate, null, drtId).replace("startDate", "dueDate"));
        if (data) {
          setTaskLogs(data)
        }

      }

    }
    if (companyId) {
      let data = await getAPI(`/tasks` + generateUrl(companyId, dSId, dSDate, dEDate, null, drtId).replace("startDate", "dueDate"));
      if (data) {
        setTaskLogs(data)
      }
    }
    setLoader(false)
  }

  const taskData = []
  for (let j = 0; j < taskLogs.length; j++) {
    taskData.push(taskLogs[j].tasks)
  }


  const taskD = []
  for (let i = 0; i < taskData.length; i++) {
    taskD.push(taskData[i][0])
  }

  const getAlarm = async (cId, sId = null, rtId, sDate = null, eDate = null) => {
    let dSId = sId === null ? report.siteId : sId;
    let dSDate = sDate === null ? report.startDate : sDate;
    let dEDate = eDate === null ? report.endDate : eDate;
    let drtId = rtId === null ? report.reportTypeId : rtId;

    setLoader(true)
    if (!companyId) {
      if (cId) {
        let data = await getAPI(`/alarm` + generateUrl(cId, dSId, dSDate, dEDate, null, drtId));
        if (data) {
          setAlarmLogs(data)
        }
      }

    }
    if (companyId) {
      let data = await getAPI(`/alarm` + generateUrl(companyId, dSId, dSDate, dEDate, null, drtId));
      if (data) {
        setAlarmLogs(data)
      }
    }
    setLoader(false)
  }

  const getSupport = async (cId, sId = null, rtId = null, sDate = null, eDate = null) => {
    let dSId = sId === null ? report.siteId : sId;
    let dSDate = sDate === null ? report.startDate : sDate;
    let dEDate = eDate === null ? report.endDate : eDate;
    let drtId = rtId === null ? report.reportTypeId : rtId;

    setLoader(true)

    // console.log('SUPPORT URL #####', generateUrl(dSId, dSDate, dEDate).replace('startDate','issueDate').replace('endDate','closeDate'))
    // generateUrl(dSId, dSDate, dEDate).replace('startDate','issueDate').replace('endDate','closeDate'))
    if (!companyId) {
      let data = await getAPI(`/support` + generateUrl(cId ? cId : report.companyId, dSId, dSDate, dEDate, null, drtId));
      if (data) {

        // console.log('Support', data)
        setSupportLogs(data)
      }
    }
    if (companyId) {
      let data = await getAPI(`/support` + generateUrl(companyId, dSId, dSDate, dEDate, null, drtId));
      if (data) {

        // console.log('Support', data)
        setSupportLogs(data)
      }
    }
    setLoader(false)
  }
  const getReports = async (siteId) => {
    var sId
    if (siteId) {
      sId = siteId
    }
    if (report.siteId && !siteId) {
      sId = report.siteId
    }
    if (sId) {
      var data = await getAPI(`report-types?siteId=${sId}`);
      // setLoader(false)
      if (data) {
        setReportData(data)
        console.log(data, 1122)
        //   navigateTo("/tasks/list")
      }
    }
    if (!siteId && !report.siteId) {
      var data = await getAPI(`report-types`);
      if (data) {
        setReportData(data)
        // console.log(data, 1122)
      }
    }
  }

  const getSites = async (cid) => {
    setLoader(true)
    let userType = localStorage.getItem('userType')
    if (companyId) {
      let data = await getAPI(userType === 'admin' ? `/sites?companyId=${companyId}` : '/company/sites');
      if (data) {
        setSites(data)
      }
    }
    if (cid) {
      let data = await getAPI(userType === 'admin' ? `/sites?companyId=${cid}` : '/company/sites');
      if (data) {
        setSites(data)
      }
    }

    setLoader(false)
  }

  const getUsers = async (cId, sId) => {

    setLoader(true)
    if (sId) {
      const users = await getAPI(`/users/app?companyId=${cId ? cId : companyId}&siteId=${sId}`);
      setLoader(false)
      setUsers(users);
    } else {
      const users = await getAPI(`/users/app?companyId=${cId ? cId : companyId}`);
      setLoader(false)
      setUsers(users);
    }

  }
  const getCompanies = async () => {
    setLoader(true)

    let data = await getAPI(`/companies`);
    if (data) {
      setCompanies(data)
    }
    setLoader(false)
  }
  const getRiskAssesmentCategory = async () => {
    setLoader(true)
    let data = await getAPI(`/risk-assessment/category?companyId=${companyId}`);
    if (data) {
      setCategory(data)
    }
    setLoader(false)
  }

  const reports = [
    // { id:11, name:"Custom Report"},
    { id: 6, name: "Custom Report" },
    { id: 7, name: "Task Report" },
    { id: 8, name: "Support Report" },
    { id: 9, name: "Scheduler(Alarm) Report" },
    { id: 10, name: "Shift logs Report" },
    { id: 1, name: "Tendency Check Report" },
    { id: 2, name: "Casual Shifts Advertised Incl. Report" },
    { id: 3, name: "Rostered Shifts Incl. Report" },
    { id: 4, name: "late In / Out Clock Report" },
    { id: 5, name: "Failed Clock ins Incl." },

  ];

  const changeSite = (event) => {

    let id = event.target.value;
    let reportId = report.reportId
    // getReports(id)
    if (reportId === 1) {
      getLogs(cmp, id)
    }
    if (reportId === 2) {
      getCasualShifts(cmp, id)
    }
    if (reportId === 3) {
      getRoasterShifts(cmp, id)
    }
    if (reportId === 4) {
      getLateClockIn(cmp, id)
    }
    if (reportId === 5) {
      getFailClock(cmp, id)
    }
    if (reportId === 6) {
      getAllReports(cmp, id)
    }
    if (reportId === 7) {
      getTask(cmp, id)
    }
    if (reportId === 8) {
      getSupport(cmp, id)
    }
    if (reportId === 9) {
      getAlarm(cmp, id)
    }
    if (reportId === 10) {
      getShifts(cmp, id)
    }
    setSitee(id)
    setReport(prevState => ({
      ...prevState,
      siteId: id
    }))
  };
  const changeCompany = (event) => {
    let cid = event.target.value;
    let reportId = report.reportId
    if (reportId === 1) {
      getLogs(cid)
    }
    if (reportId === 2) {
      getCasualShifts(cid)
    }
    if (reportId === 3) {
      getRoasterShifts(cid)
    }
    if (reportId === 4) {
      getLateClockIn(cid)
    }
    if (reportId === 5) {
      getFailClock(cid)
    }
    if (reportId === 6) {
      getAllReports(cid)
    }
    if (reportId === 7) {
      getTask(cid)
    }
    if (reportId === 8) {
      getSupport(cid)
    }
    if (reportId === 9) {
      getAlarm(cid)
    }
    if (reportId === 10) {
      getShifts(cid)
    }

    setReport(prevState => ({
      ...prevState,
      companyId: cid
    }))
    setCmp(cid)
    setReport(prevState => ({
      ...prevState,
      siteId: '',
      reportTypeId: ''
    }))
    getSites(cid)
  };
  const changeReportType = (event) => {
    let rtId = event.target.value;
    let reportId = report.reportId
    if (reportId === 1) {
      getLogs(cmp, sitee, rtId)
    }
    if (reportId === 2) {
      getCasualShifts(cmp, sitee, rtId)
    }
    if (reportId === 3) {
      getRoasterShifts(cmp, sitee, rtId)
    }
    if (reportId === 4) {
      getLateClockIn(cmp, sitee, rtId)
    }
    if (reportId === 5) {
      getFailClock(cmp, sitee, rtId)
    }
    if (reportId === 6) {
      getAllReports(cmp, sitee, rtId)
    }
    if (reportId === 7) {
      getTask(cmp, sitee, rtId)
    }
    if (reportId === 8) {
      getSupport(cmp, sitee, rtId)
    }
    if (reportId === 9) {
      getAlarm(cmp, sitee, rtId)
    }
    if (reportId === 10) {
      getShifts(cmp, sitee, rtId)
    }

    setReport(prevState => ({
      ...prevState,
      reportTypeId: rtId
    }))

  };
  const changeCompany2 = (event) => {
    let id = event.target.value;

    getSites(id)
    getUsers(id)
    getAssessmentReport(null, null, null, id)

    setReport2(prevState => ({
      ...prevState,
      companyId: id
    }))
  };

  const changeSite2 = (event) => {
    let id = event.target.value;
    let reportId = report2.reportId
    getUsers(null, id)
    getAssessmentReport(id)

    setReport2(prevState => ({
      ...prevState,
      siteId: id
    }))
  };
  const changeUser2 = (event) => {
    let id = event.target.value;

    getAssessmentReport(null, null, null, null, id)

    setReport2(prevState => ({
      ...prevState,
      userId: id
    }))
  };
  const changeRiskAssessmentType2 = (event) => {
    let id = event.target.value;

    getAssessmentReport(null, null, null, null, null, id)

    setReport2(prevState => ({
      ...prevState,
      riskAssessmentType: id
    }))
  };


  const changeShift = (event) => {
    let id = event.target.value;
    let reportId = report.reportId
    if (reportId === 1) {
      getLogs(id)
    }
    if (reportId === 2) {
      getCasualShifts(id)
    }
    if (reportId === 3) {
      getRoasterShifts(id)
    }
    if (reportId === 4) {
      getLateClockIn(id)
    }
    if (reportId === 5) {
      getFailClock(id)
    }
    if (reportId === 6) {
      getAllReports(id)
    }
    if (reportId === 7) {
      getTask(id)
    }
    if (reportId === 8) {
      getSupport(id)
    }
    if (reportId === 9) {
      getAlarm(id)
    }
    if (reportId === 10) {
      getShifts(id)
    }
    setReport(prevState => ({
      ...prevState,
      shiftId: id
    }))

  };
  // Filtring the options
  const getPageData = () => {
    const data = localStorage.getItem('reportDropdown');
    let id
    if (data === "Scheduled Tasks" || data === "Completed Tasks" || data === "Missed Tasks") {
      console.log(data, 121)
      getTask(companyId)
      id = 7
      setReport(prevState => ({
        ...prevState,
        reportId: id
      }))

      setReport({
        ...report,
        reportId: id
      })
    }
    if (data === "Rostered Shifts Late Clock Ins" || data === "Casual Shifts Late Clock Ins" || data === "Alarm Response Late Check Ins") {
      console.log("late", 12)
      getLateClockIn(companyId)
      id = 4
      setReport(prevState => ({
        ...prevState,
        reportId: id
      }))

      setReport({
        ...report,
        reportId: id
      })
    }
    if (data === "Shift Logs Received") {
      getShifts(companyId)
      id = 10
      setReport(prevState => ({
        ...prevState,
        reportId: id
      }))

      setReport({
        ...report,
        reportId: id
      })
    }
    if (!data) {
      // getAllReports()
      id = 6
      setReport(prevState => ({
        ...prevState,
        reportId: id
      }))

      setReport({
        ...report,
        reportId: id
      })
    }
    if (data === 1) {
      getLogs(companyId)
    }
    if (data === 2) {
      getCasualShifts()
    }
    if (data === 3) {
      getRoasterShifts()
    }
    if (data === 4) {
      getLateClockIn(companyId)
    }
    if (data === 5) {
      getFailClock(companyId)
    }
    if (data === 6) {
      getAllReports(companyId)
    }
    if (data === 7) {
      getTask(companyId)
    }
    if (data === 8) {
      getSupport(companyId)
    }
    if (data === 9) {
      getAlarm(companyId)
    }
    if (data === 10) {
      getShifts(companyId)
    }

  }



  const changeReport = (event) => {
    let id = event.target.value;
    if (id === 1) {
      getLogs(null, null, id)
    }
    if (id === 2) {
      getCasualShifts(null, null, id)
    }
    if (id === 3) {
      getRoasterShifts(null, null, id)
    }
    if (id === 4) {
      getLateClockIn(null, null, id)
    }
    if (id === 5) {
      getFailClock(null, null, id)
    }
    if (id === 6) {
      getAllReports(null, null, id)
    }
    if (id === 7) {
      getTask(null, null, id)
    }
    if (id === 8) {
      getSupport(null, null, id)
    }
    if (id === 9) {
      getAlarm(null, null, id)
    }
    if (id === 10) {
      getShifts(null, null, id)
    }
    setReport(prevState => ({
      ...prevState,
      reportId: id
    }))

    setReport({
      ...report,
      reportId: id
    })
  };

  const changeStartDate = (event) => {
    let id = event
    let reportId = report.reportId
    if (reportId === 1) {
      getLogs(null, null, null, id)
    }
    if (reportId === 2) {
      getCasualShifts(null, null, null, id)
    }
    if (reportId === 3) {
      getRoasterShifts(null, null, null, id)
    }
    if (reportId === 4) {
      getLateClockIn(null, null, null, id)
    }
    if (reportId === 5) {
      getFailClock(null, null, null, id)
    }
    if (reportId === 6) {
      getAllReports(null, null, null, id)
    }
    if (reportId === 7) {
      getTask(null, null, null, id)
    }
    if (reportId === 8) {
      getSupport(null, null, null, id)
    }
    if (reportId === 9) {
      getAlarm(null, null, null, id)
    }
    if (reportId === 10) {
      getShifts(null, null, null, id)
    }

    setReport({
      ...report,
      startDate: id
    })
  }
  const changeStartDate2 = (event) => {
    let id = event
    let reportId = report2.reportId


    // if (reportId === 11) {
    getAssessmentReport(null, id)
    // }
    // setreportstartDate(id)

    setReport2({
      ...report2,
      startDate: id
    })
  }

  const changeEndDate = (event) => {
    let id = event
    let reportId = report.reportId
    if (reportId === 1) {
      getLogs(null, null, null, null, id)
    }
    if (reportId === 2) {
      getCasualShifts(null, null, null, null, id)
    }
    if (reportId === 3) {
      getRoasterShifts(null, null, null, null, id)
    }
    if (reportId === 4) {
      getLateClockIn(null, null, null, null, id)
    }
    if (reportId === 5) {
      getFailClock(null, null, null, null, id)
    }
    if (reportId === 6) {
      getAllReports(null, null, null, null, id)
    }
    if (reportId === 7) {
      getTask(null, null, null, null, id)
    }
    if (reportId === 8) {
      getSupport(null, null, null, null, id)
    }
    if (reportId === 9) {
      getAlarm(null, null, null, null, id)
    }
    if (reportId === 10) {
      getShifts(null, null, null, null, id)
    }

    setReport({
      ...report,
      endDate: id
    })
  }
  const changeEndDate2 = (event) => {
    let id = event
    let reportId = report2.reportId
    // if (reportId === 11) {
    getAssessmentReport(null, null, id)
    // }

    // setreportendDate(id)
    setReport2({
      ...report2,
      endDate: id
    })
  }

  const rowCount = () => {
    if (report.reportId === 1) {
      return logs.length;
    }
    else if (report.reportId === 2) {
      return shiftLogs.length;
    }
    else if (report.reportId === 3) {
      return roasterLogs.length;
    }
    else if (report.reportId === 4) {
      return lateLogs.length;
    }
    else if (report.reportId === 5) {
      return failLogs.length;
    }
    else if (report.reportId === 6) {
      return all.length;
    }
    else if (report.reportId === 7) {
      return taskLogs.length;
    }
    else if (report.reportId === 8) {
      return supportLogs.length;
    }
    else if (report.reportId === 9) {
      return alarmLogs.length;
    }
    else if (report.reportId === 10) {
      return shifts.length;
    }
  }

  const getColSpan = () => {
    if (report.reportId === 1) {
      return 4;
    }
    else if (report.reportId === 2) {
      return 17;
    }
    else if (report.reportId === 3) {
      return 10;
    }
    else if (report.reportId === 4) {
      return 9;
    }
    else if (report.reportId === 5) {
      return 12;
    }
    else if (report.reportId === 6) {
      return 6;
    }
    else if (report.reportId === 7) {
      return 9;
    }
    else if (report.reportId === 8) {
      return 8;
    }
    else if (report.reportId === 9) {
      return 11;
    }
    else if (report.reportId === 10) {
      return 6;
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TablePaginationActions = () => { };


  const exportDoc = async () => {
    var url = generateUrl(report.companyId, report.siteId, report.startDate, report.endDate);
    let reportId = report.reportId
    var exportfile = ""
    console.log(url)
    if (reportId === 6) {
      let data = await getExportAPI(`${BASE_URL}/user/reports/export`, `Custom Reports`);
      if (data) {
        return
      }
      return
    }
    else if (reportId === 1) {
      exportfile = "tenancy-check";
    }
    else if (reportId === 2) {
      exportfile = "casual-shifts";
    }
    else if (reportId === 3) {
      exportfile = "roster";
    }
    else if (reportId === 4) {
      exportfile = "clockinout";
    }
    else if (reportId === 5) {
      exportfile = "failedclock";
    }

    else if (reportId === 7) {
      exportfile = "tasks";
    }
    else if (reportId === 8) {
      exportfile = "support";
    }
    else if (reportId === 9) {
      exportfile = "alarms";
    }
    else if (reportId === 10) {
      exportfile = "shift-logs";
    }
    // else{
    //   exportfile="complete-report";
    // }

    // exportfile = (exportfile == '' || exportfile == null) ? "complete-report" : exportfile;
    setLoader(true)
    let data = await getExportPdfAPI(`${BASE_URL}/export/file/${exportfile.trim()}` + url);

    if (data) {
      // alert(`${BASE_URL}/${exportfile}/export/file${url}`)
    }
    setLoader(false)

  }
  const exportDocRisk = async () => {
    // var url = generateUrl(report.companyId, report.siteId, report.startDate, report.endDate);

    setLoader(true)


    let data = await getExportPdfAPI(`${BASE_URL}/risk-assessment-report/exportpdf`);


    // let data = await getExportPdfAPI(`${BASE_URL}/export/file/${exportfile.trim()}` + url);

    if (data) {
      // alert(`${BASE_URL}/${exportfile}/export/file${url}`)
    }
    setLoader(false)

  }
  const exportDoc2 = async () => {
    var url = generateUrl(report.companyId, report.siteId, report.startDate, report.endDate);
    let reportId = report.reportId
    var exportfile = ""
    console.log(url)
    if (reportId === 6) {
      let data = await getExportPdfAPI(`${BASE_URL}/user/reports/export`);
      if (data) {
        return
      }
      return
    }
    else if (reportId === 11) {
      exportfile = "risk-assessment-reports";
    }


    setLoader(true)
    let data = await getExportPdfAPI(`${BASE_URL}/export/file/${exportfile.trim()}` + url);

    if (data) {
      // alert(`${BASE_URL}/${exportfile}/export/file${url}`)
    }
    setLoader(false)

  }

  const viewQuestionsModel = (item) => {
    const question = item.questions
    setQuestions(question)
    setShow(true)
  }


  const exportQue = async (id, sId, uId) => {

    let data = await getExportPdfAPI(`${BASE_URL}/user/reports/exportpdf?reportTypeId=${id}&userId=${uId}&siteId=${sId}`);
    console.log(data, 'data');
    if (data) {

    }
    setLoader(false)
  }

  const AssessExp = async (id) => {
    setLoader(true)
    let data = await getExportAPI(`${BASE_URL}/risk-assessment-report/export?reportId=${id}`, `Risk Assessment Reports`)
    setLoader(false)
  }
  const AssessExpPdf = async (id) => {
    setLoader(true)
    let data = await getExportPdfAPI(`${BASE_URL}/risk-assessment-report/exportpdf?reportId=${id}`)
    setLoader(false)
  }


  const ShiftExport = async (id) => {
    setLoader(true)
    let data = await getExportPdfAPI(`${BASE_URL}/export/file/shift-logs?reportId=${id}`)
    setLoader(false)
  }
  const taskExport = async (id) => {
    setLoader(true)
    await getExportPdfAPI(`${BASE_URL}/export/file/visitor-tasks?taskId=${id}`)
    setLoader(false)
  }


  const getAssessmentReport = async (sId = null, sDate = null, eDate = null, cId = null, uId = null, rAt = null) => {
    setLoader(true)
    // setTimeout(() => { 

    // }, 1000);
    setLoader(false)

    let dCId = cId ? cId : companyId;
    let dUId = uId ? uId : report2.userId;
    let dSId = sId ? sId : report2.siteId;
    let dSDate = sDate ? sDate : report2.startDate;
    let dEDate = eDate ? eDate : report2.endDate;
    let dRat = rAt ? rAt : report2.riskAssessmentType;
    setLoader(true)

    var url = `/risk-assessment-report`

    let data = await getAPI(url + generateUrl(dCId, dSId, dSDate, dEDate, dUId, null, dRat));

    if (data) {
      setAssReports(data)
      // console.log("data from APi*******************",data)
    }
    setLoader(false)

    // setLoader(true)
    // let data = await getAPI(`/risk-assessment-report`)
    // if (data) {
    //   setAssReports(data)
    // }
    // setLoader(false)

  }
  const getAssessmentReport2 = async () => {
    setLoader(true)




    var url = `/risk-assessment-report`

    let data = await getAPI(url);
    if (data) {
      setAssReports(data)

    }
    setLoader(false)


  }
  const refresh = () => {
    getAllReports()
    getAssessmentReport()
    getLogs()
    getSites(companyId)
    getRoasterShifts()
    getLateClockIn()
    getFailClock()
    getTask()
    getSupport()
    getAlarm()
    getShifts()
  }

  return (
    <Box sx={{ height: "inherit" }}>
      <Loader loader={loader} />
      <PageTitle title="Reports" subTitle="Custom Reports" />
      <Box
        display="flex"
        width={"100%"}
        justifyContent="flex-end"
        alignItems="flex-end"
        // sx={{ mx: 6, width: " 54rem" }}
      >
        <Button variant="contained" style={{ backgroundColor: "#75859D" }} sx={{ height: 50, mx: 2 }} onClick={clearFilter}>
          <FilterAltOffIcon /> &nbsp; &nbsp;
          Clear Filter
        </Button>

        <Button variant="contained" style={{ backgroundColor: "#75859D" }} sx={{ height: 50 }} onClick={exportDoc}>
          <GetAppRoundedIcon /> &nbsp; &nbsp;
          Export Report
        </Button>
      </Box>

      <Grid
        container
        className=""
        sx={{ mx: "0.4rem", mt: "1rem", pr: "2rem", }}
      >

        {/* <Grid item xs={3}>
          <FormControl sx={{ m: 1, flexBasis: '10%', width: "90%", backgroundColor: "white" }}>
            <Select
              value={report.reportId}
              onChange={(event) => {
                changeReport(event)
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              style={{
                borderRadius: 10,
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                borderColor: "#707070",
              }}
            >
              <MenuItem value="">
                <div className="selectitem">Select Report Type</div>
              </MenuItem>
              {reports.map((item, index) => (
                <MenuItem value={item.id} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}

        {/* {
          (report.reportId !== 8 && report.reportId !== 10 && report.reportId !== 1 && report.reportId !== 2) && */}
        {companyId ? null :
          <Grid item xs={3} >
            <FormControl sx={{ m: 1, flexBasis: '10%', width: "100%", backgroundColor: "white" }}>
              <Select
                value={report.companyId}
                onChange={(event) => {
                  setPage(0)
                  changeCompany(event)

                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                style={{
                  borderRadius: 5,
                  // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  borderColor: "#707070",
                  height:"61px"
                }}
              >
                <MenuItem value="">
                  <div className="selectitem">Select Company</div>
                </MenuItem>
                {companies.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        }
        <Grid item xs={3}  >

          <FormControl sx={{ m: 1, flexBasis: '10%', width: "18rem", backgroundColor: "white" }}>
            <Select
              value={report.siteId}
              onChange={(event) => {
                setPage(0)
                changeSite(event)
                getReports(event.target.value)
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              style={{
                borderRadius: 5,
                height:"61px",
                // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                borderColor: "#707070",
                // height:"61px"
              }}
            >
              <MenuItem value="">
                <div className="selectitem">Select Site*</div>
              </MenuItem>
              {sites.map((item, index) => (
                <MenuItem value={item._id} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} style={{marginLeft: "80px"}}>
          <FormControl sx={{ m: 1, flexBasis: '10%',  width: "18rem", backgroundColor: "white" }}>
            <Select
              value={report.reportTypeId}
              onChange={(event) => {
                setPage(0)
                changeReportType(event)

              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              style={{
                borderRadius: 5,
                 height:"61px",
                // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                borderColor: "#707070",
              }}
            >
              <MenuItem value="">
                <div className="selectitem">Select Report Type</div>
              </MenuItem>
              {reportData.map((item, index) => (
                <MenuItem value={item?._id} key={index}>{item?.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* } */}

        <Grid item xs={{ maxWidth: '37%' }} style={{marginLeft: "80px",   height:"61px"}}>
          <FormControl sx={{ m: 1, width: "18rem", flexBasis: '10%', }}>
            <LocalDateSelector title={report.reportId === 7 ? "Due Date" : (report.reportId === 8 ? "Start Date" : "Start Date")} value={report.startDate} onChange={(event) => {
              setPage(0)
              changeStartDate(event)

            }} />
          </FormControl>
        </Grid>
        {
          report.reportId !== 7 &&
          <Grid item xs={{ maxWidth: '37%' ,    height:"61px"}}>
            <FormControl sx={{ m: 1, width: "18rem", flexBasis: '10%', }}>
              <LocalDateSelector title={report.reportId === 8 ? "End Date" : "End Date"} value={report.endDate} onChange={(event) => {
                setPage(0)
                changeEndDate(event)

              }} />
            </FormControl>
          </Grid>
        }
      </Grid>
      <div style={{ marginLeft: "1rem" }}>
        {report.siteId ? null : "Please Select Site to see reports"}
      </div>
      <Box display="flex" sx={{ my: "2rem" }}>
        {
          (report.reportId === 1 && logs.length > 0) &&
          <TableContainer component={Paper} sx={{ mx: "0.8rem" }}>
            <Table
              sx={{ minWidth: "auto" }}
              aria-label="custom pagination table"
              className="responsive-table"
            >
              <TableHead>
                <TableRow className="table-header">
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Shift
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Floor
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Status
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Created At
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {logs.slice(page * perPage, page * perPage + perPage).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" sx={tableData}>
                      <table className="table">
                        <tr>
                          <th>Shift Type</th>
                          <th>Type</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>Clock In</th>
                          <th>Clock Out</th>
                        </tr>
                        <tr>
                          <td>{item.shiftId?.shiftType}</td>
                          <td>{item.shiftId?.inOut}</td>
                          <td>{item.shiftId?.startTime}</td>
                          <td>{item.shiftId?.endTime}</td>
                          <td>{item.shiftId?.clockInTime ? formatDate(item.shiftId?.clockInTime) : 'NA'}</td>
                          <td>{item.shiftId?.clockOutTime ? formatDate(item.shiftId?.clockOutTime) : 'NA'}</td>
                        </tr>

                      </table>
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {
                        Array.isArray(item.floors) &&
                        item.floors.length > 0 &&
                        <Table className="table">
                          <tr>
                            <th>Floor</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Note</th>
                            <th>Created</th>
                          </tr>
                          {
                            item.floors.map((floor, index) => (
                              <tr>
                                <td>{floor.floor}</td>
                                <td>{getTimeFromTimestamp(floor?.startTime)}</td>
                                <td>{floor.endTime != null ? getTimeFromTimestamp(floor.endTime) : 'NA'}</td>
                                <td>{floor.note}</td>
                                <td>{formatDate(floor.createdAt)}</td>
                              </tr>
                            ))
                          }
                        </Table>
                      }

                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {item.shiftStatus}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {formatDate(item.createdAt)}
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    align="right"
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={getColSpan()}
                    count={rowCount()}
                    rowsPerPage={perPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        }
        {
          (report.reportId === 2 && shiftLogs.length > 0) &&
          <TableContainer component={Paper} sx={{ mx: "0.8rem" }} style={{ overflowX: 'auto' }}>
            <Table
              sx={{ minWidth: "auto" }}
              aria-label="custom pagination table"
              className="responsive-table"
            >
              {/* {console.log('REPORT 2', shiftLogs)} */}
              <TableHead>
                <TableRow className="table-header">
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Work Order
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Shift Code
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader} width="5%">
                    Site
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Shift Type
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Break
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Parking
                  </TableCell>
                  {/* <TableCell align="center" component="th" sx={tableHeader}>
                    Recurrence
                  </TableCell> */}
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Start Time
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    End Time
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Start Date
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    End Date
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Created By
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Assign To
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Interested Users
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Total Hours
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Status
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Created At
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shiftLogs.slice(page * perPage, page * perPage + perPage).map((item, index) => (
                  <TableRow key={index} >
                    <TableCell align="left" sx={tableData}>
                      {item.woNumber}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item.shiftCode}
                    </TableCell>
                    <TableCell align="center" sx={tableData} style={{ whiteSpace: 'nowrap' }}>
                      {item.siteId?.name}
                    </TableCell>
                    <TableCell align="center" sx={tableData} style={{ whiteSpace: 'nowrap' }}>
                      {item.shiftType?.name}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {item.allowedBreaks === null ? 'NA' : (item.allowedBreaks ? item.allowedBreaks : 0)}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {item?.parkingRequired === null ? 'NA' : (item?.parkingRequired === true ? 'YES' : 'NO')}
                    </TableCell>
                    {/* <TableCell align="center" sx={tableData}>
                      {item.shiftRecurrence.map((item)=>(
                        <div>{item}</div>
                      ))}
                    </TableCell> */}
                    <TableCell align="center" sx={tableData}>
                      {item.startTime}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {item.endTime}
                    </TableCell>
                    <TableCell align="center" sx={tableData} style={{ whiteSpace: 'nowrap' }}>
                      {formatDate(item.startDate)}
                    </TableCell>
                    <TableCell align="center" sx={tableData} style={{ whiteSpace: 'nowrap' }}>
                      {formatDate(item.endDate)}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {item.createdBy?.firstname} {' '}  {item.createdBy?.lastname}
                    </TableCell>

                    <TableCell align="center" sx={tableData}>
                      {item.assignedUser?.firstname} {' '} {item.assignedUser?.lastname}
                    </TableCell>
                    <TableCell align="center" sx={tableData} style={{ width: '20% !important' }}>
                      {
                        Array.isArray(item.intrestedUsers) ?
                          item.intrestedUsers.map((item, index) => (
                            <div style={{ backgroundColor: 'blue', padding: 2, borderRadius: 10, margin: 5, color: 'white', display: 'inline-block', width: '100%', fontSize: 10, whiteSpace: 'nowrap' }} key={index}>{item?.firstname} {' '} {item?.lastname}</div>
                          ))
                          : null
                      }
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {item.totalHours}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {item.status}
                    </TableCell>
                    <TableCell align="center" sx={tableData} style={{ whiteSpace: 'nowrap' }}>
                      {formatDate(item.createdAt)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow >
                  <TablePagination
                    align="right"
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={getColSpan()}
                    count={rowCount()}
                    rowsPerPage={perPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        }
        {
          (report.reportId === 3 && roasterLogs.length > 0) &&
          <TableContainer component={Paper} sx={{ mx: "0.8rem" }}>
            <Table
              sx={{ minWidth: "auto" }}
              aria-label="custom pagination table"
              className="responsive-table"
            >
              <TableHead>
                <TableRow className="table-header">
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Shift Code
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Role
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Site
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Assigned User
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Start Time
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    End Time
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Start Date
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    End Date
                  </TableCell>
                  {/* <TableCell align="center" component="th" sx={tableHeader}>
                  Created By
                </TableCell> */}
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Total Hours
                  </TableCell>
                  {/* <TableCell align="center" component="th" sx={tableHeader}>
                  Created At
                </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {roasterLogs.slice(page * perPage, page * perPage + perPage).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" sx={tableData}>
                      {item.shiftCode}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item?.assignedUser?.role}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {item.siteId?.name}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {item.assignedUser?.firstname} {' '}  {item.assignedUser?.firstname}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {item.startTime}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {item.endTime}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {formatDate(item.startDate)}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {formatDate(item.endDate)}
                    </TableCell>
                    {/* <TableCell align="center" sx={tableData}>
                  {item.userId?.firstname} {' '}  {item.userId?.firstname}
                  </TableCell> */}
                    <TableCell align="center" sx={tableData}>
                      {item.totalHours}
                    </TableCell>

                    {/* <TableCell align="center" sx={tableData}>
                    {formatDate(item.createdAt)}
                  </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    align="right"
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={getColSpan()}
                    count={rowCount()}
                    rowsPerPage={perPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />

                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        }

        {
          (report.reportId === 4 && lateLogs.length > 0) &&
          <TableContainer component={Paper} sx={{ mx: "0.8rem" }}>
            <Table
              sx={{ minWidth: "auto" }}
              aria-label="custom pagination table"
              className="responsive-table"
            >
              <TableHead>
                <TableRow className="table-header">
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Shift
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Site
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    User
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Type
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Start Date
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    End Date
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Break Duration
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Status
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Created At
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {lateLogs.slice(page * perPage, page * perPage + perPage).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" sx={tableData}>
                      {item.shiftId}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item.siteId?.name}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item.userId?.firstname}  {' '} {item.userId?.lastname}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {item.type}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {formatDate(item.startDate)}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {formatDate(item.endDate)}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {item.breakDuration}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {item.status}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {formatDate(item.createdAt)}
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    align="right"
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={getColSpan()}
                    count={rowCount()}
                    rowsPerPage={perPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        }

        {
          (report.reportId === 5 && failLogs.length > 0) &&
          <TableContainer component={Paper} sx={{ mx: "0.8rem" }}>
            <Table
              sx={{ minWidth: "auto" }}
              aria-label="custom pagination table"
              className="responsive-table"
            >
              <TableHead>
                <TableRow className="table-header">
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Shift Code
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Shift Type
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Site
                  </TableCell>
                  {/* <TableCell align="center" component="th" sx={tableHeader}>
                  Roaster
                </TableCell> */}


                  <TableCell align="center" component="th" sx={tableHeader}>
                    Shift Date
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Start Date
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    End Date
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Start Time
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    End Time
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Clock In Time
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Clock Out Time
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Failed Date
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Created At
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {failLogs.slice(page * perPage, page * perPage + perPage).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" sx={tableData}>
                      {item.shift.shiftCode}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item.shift.shiftType}
                    </TableCell>
                    <TableCell align="left" sx={tableData} className=" no-wrap">
                      {item.shift.siteId?.name}
                    </TableCell>
                    {/* <TableCell align="center" className="t-body-cell no-wrap">
                    <table className="table">
                      <tr>
                        <th>Shift</th>
                        <th>Site</th>
                        <th>Assigned User</th>
                        <th>Date</th>
                        <th>Time</th>
                      </tr>
                      <tr>
                        <td>{item.shift.rosterId?.shiftCode}</td>
                        <td>{item.shift.rosterId?.siteId?.name}</td>
                        <td>{item.shift.rosterId?.assignedUser?.firstname}{' '}{item.shift.rosterId?.assignedUser?.lastname}</td>
                        <td>
                          {formatDate(item.shift.rosterId?.startDate) + ' - ' + formatDate(item.shift.rosterId?.endDate)}
                        </td>
                        <td>
                          {item.shift.rosterId?.startTime + ' - ' + item.shift.rosterId?.endTime}
                        </td>
                      </tr>
                    </table>
                  </TableCell> */}
                    <TableCell align="center" sx={tableData} className="no-wrap">
                      {formatDate(item.shift.shiftDate)}
                    </TableCell>
                    <TableCell align="center" sx={tableData} className="no-wrap">
                      {formatDate(item.shift.startDate)}
                    </TableCell>
                    <TableCell align="center" sx={tableData} className="no-wrap">
                      {formatDate(item.shift.endDate)}
                    </TableCell>
                    <TableCell align="center" sx={tableData} className="no-wrap">
                      {item.shift.startTime}
                    </TableCell>
                    <TableCell align="center" sx={tableData} className=" no-wrap">
                      {item.shift.endTime}
                    </TableCell>
                    <TableCell align="center" sx={tableData} className="no-wrap">
                      {item.shift.clockInTime !== null ? formatDate(item.shift.clockInTime) : 'NA'}
                    </TableCell>
                    <TableCell align="center" sx={tableData} className="no-wrap">
                      {item.shift.clockOutTime !== null ? formatDate(item.shift.clockOutTime) : 'NA'}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {
                        item.failedDate.map((item, index) => (
                          <span style={{ backgroundColor: '#0069D9', borderRadius: 10, margin: 5, color: 'white', display: 'inline-block', fontSize: 10, whiteSpace: 'nowrap', paddingHorizontal: 60 }} key={index}>&nbsp;&nbsp;{item}&nbsp;&nbsp;</span>
                        ))
                      }
                    </TableCell>
                    <TableCell align="center" sx={tableData} className="no-wrap">
                      {formatDate(item.shift.createdAt)}
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    align="right"
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={getColSpan()}
                    count={rowCount()}
                    rowsPerPage={perPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        }


        {
          (report.reportId === 6 && all.length > 0) &&
          <TableContainer component={Paper} sx={{ mx: "0.8rem" }}>
            <Table
              sx={{ minWidth: "auto" }}
              aria-label="custom pagination table"
              className="responsive-table"
            >
              <TableHead>
                <TableRow className="table-header">
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Report Type
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Site
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Report Date
                  </TableCell>

                  <TableCell align="center" component="th" sx={tableHeader}>
                    Notes
                  </TableCell>

                  <TableCell align="center" component="th" sx={tableHeader}>
                    Export
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {/* {console.log("Alll Data Of Report----", all)} */}
                {all.slice(page * perPage, page * perPage + perPage).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" sx={tableData}>
                      {item?.reportTypeId?.name}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item?.siteId?.name}
                    </TableCell>

                    <TableCell align="center" sx={tableData} className="no-wrap">
                      {formatDate(item.createdAt)}
                    </TableCell>

                    <TableCell align="center" sx={tableData} className="wrap">
                      {item?.note}
                    </TableCell>
                    {/* <TableCell align="center" sx={tableData}>
                  <button className="pointer" onClick={()=>{viewQuestionsModel(item)}}>View</button>
                  </TableCell> */}
                    <TableCell align="center" sx={tableData}>
                      <div className="pointer" >
                        <Link onClick={() => exportQue(item?.reportTypeId?._id, item?.siteId?._id, item?.userId?._id)}> Export File</Link>
                      </div>
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    align="right"
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={getColSpan()}
                    count={rowCount()}
                    rowsPerPage={perPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />

                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        }

        {
          (report.reportId === 7 && taskLogs.length > 0) &&
          <TableContainer component={Paper} sx={{ mx: "0.8rem" }}>
            <Table
              sx={{ minWidth: "auto" }}
              aria-label="custom pagination table"
              className="responsive-table"
            >
              <TableHead>
                <TableRow className="table-header">
                  <TableCell align="left" component="th" sx={tableHeader}>
                    Title
                  </TableCell>
                  <TableCell align="left" component="th" sx={tableHeader}>
                    Site
                  </TableCell>
                  <TableCell align="left" component="th" sx={tableHeader}>
                    Start Date
                  </TableCell>
                  <TableCell align="left" component="th" sx={tableHeader}>
                    End Date
                  </TableCell>
                  <TableCell align="left" component="th" sx={tableHeader}>
                    Due Time
                  </TableCell>
                  <TableCell align="left" component="th" sx={tableHeader}>
                    Description
                  </TableCell>
                  <TableCell align="left" component="th" sx={tableHeader}>
                    Export
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>


                {taskD.slice(page * perPage, page * perPage + perPage).map((task, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" sx={tableData}>
                      {/* {console.log(item,"====item=====")} */}
                      {task?.title}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>

                      {console.log(task.siteId, 1122)}
                      {
                        task.siteId.length ?

                          task.siteId.map((i, index) => (
                            <div className="" style={{ margin: "10px" }} key={index}>{i.name}</div>

                          ))
                          : null
                      }
                    </TableCell>
                    {/* <TableCell align="left"sx={tableData}>
                        {(task?.user?.firstname !== undefined && task?.user?.firstname !== '') ? `${task?.user?.firstname} ${task?.user?.lastname}` : 'NA'}
                      </TableCell> */}
                    <TableCell align="left" sx={tableData}>
                      {new Date(task?.startDate).toLocaleDateString("en-uk", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                      })}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {new Date(task?.endDate).toLocaleDateString("en-uk", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                      })}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {task?.timeDue}
                    </TableCell>
                    <TableCell align="left" sx={{ tableData, width: "40%" }}>
                      {task?.description.slice(0, 120)}...
                    </TableCell>
                    <TableCell align="left" sx={{ tableData, width: "40%" }} onClick={() => taskExport(task?._id)}>
                      <div className=""  >Download PDF</div>
                    </TableCell>

                  </TableRow>

                ))}
              </TableBody>
              <TableFooter>
                <TableRow >

                  <TablePagination
                    align="right"
                    rowsPerPageOptions={perPages}
                    colSpan={7}
                    count={taskD.length}
                    rowsPerPage={perPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  // ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        }

        {
          (report.reportId === 8 && supportLogs.length > 0) &&
          <TableContainer component={Paper} sx={{ mx: "0.8rem" }}>
            <Table
              sx={{ minWidth: "auto" }}
              aria-label="custom pagination table"
              className="responsive-table"
            >
              <TableHead>
                <TableRow className="table-header">
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Ticket Id
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    User
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Title
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Issue
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Issue Date
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Close Date
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader} >
                    Status
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Created At
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {supportLogs.slice(page * perPage, page * perPage + perPage).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" sx={tableData}>
                      {item?.ticketId}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item?.userId?.firstname ? `${item?.userId?.firstname} ${item?.userId?.lastname}` : 'NA'}
                    </TableCell>
                    <TableCell align="center" sx={tableData} className="no-wrap">
                      {item?.title}
                    </TableCell>
                    <TableCell align="center" sx={tableData} className=" no-wrap">
                      {item?.issue}
                    </TableCell>
                    <TableCell align="center" sx={tableData} className="no-wrap">
                      {formatDate(item?.issueDate)}
                    </TableCell>
                    <TableCell align="center" sx={tableData} className=" no-wrap" >
                      {item?.closeDate !== null ? formatDate(item?.closeDate) : 'NA'}
                    </TableCell>
                    <TableCell align="center" sx={tableData} className=" no-wrap">
                      {item?.statusId?.name}
                    </TableCell>
                    <TableCell align="center" className="t-body-cell no-wrap">
                      {formatDate(item.createdAt)}
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    align="right"
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={getColSpan()}
                    count={rowCount()}
                    rowsPerPage={perPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        }

        {
          (report.reportId === 9 && alarmLogs.length > 0) &&
          <TableContainer component={Paper} sx={{ mx: "0.8rem" }}>
            {/* {console.log('ALARM RESPONSE', alarmLogs)} */}
            <Table
              sx={{ minWidth: "auto" }}
              aria-label="custom pagination table"
              className="responsive-table"
            >
              <TableHead>
                <TableRow className="table-header">
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Company
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    User
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Mobile
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Site
                  </TableCell>
                  {/* <TableCell align="center" component="th" sx={tableHeader}>
                    Shift Recurrence
                  </TableCell> */}
                  {/* <TableCell align="center" component="th" sx={tableHeader}>
                    Start Date
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    End Date
                  </TableCell> */}
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Start Time
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    End Time
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader} >
                    Status
                  </TableCell>

                  <TableCell align="center" component="th" sx={tableHeader}>
                    Created At
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {alarmLogs.slice(page * perPage, page * perPage + perPage).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" sx={tableData}>
                      {item?.company?.name}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {(item?.user?.firstname !== undefined && item?.user?.firstname !== null) ? `${item?.user?.firstname} ${item?.user?.lastname}` : 'NA'}
                    </TableCell>
                    <TableCell align="center" sx={tableData} className="no-wrap">
                      {item?.user?.phone}
                    </TableCell>
                    <TableCell align="center" sx={tableData} className="no-wrap">
                      {
                        item.sites.length > 0 ?
                          item.sites.map((item, index) => (
                            <div key={index}>
                              {item?.name}
                            </div>
                          ))
                          :
                          'NA'
                      }
                    </TableCell>

                    {/* <TableCell align="center" sx={tableData} className="no-wrap">
                      {formatDate(item?.startDate)}
                    </TableCell>
                    <TableCell align="center" sx={tableData} className=" no-wrap">
                      {formatDate(item?.endDate)}
                    </TableCell> */}
                    <TableCell align="center" sx={tableData} className="no-wrap" >
                      {item?.startTime}
                    </TableCell>
                    <TableCell align="center" sx={tableData} className="no-wrap" >
                      {item?.endTime}
                    </TableCell>
                    <TableCell align="center" className="t-body-cell no-wrap">
                      {item?.status}
                    </TableCell>
                    <TableCell align="center" className="t-body-cell no-wrap">
                      {formatDate(item.createdAt)}
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    align="right"
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={getColSpan()}
                    count={rowCount()}
                    rowsPerPage={perPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        }


        {
          (report.reportId === 10 && shifts.length > 0) &&
          <TableContainer component={Paper} sx={{ mx: "0.8rem" }}>
            <Table
              sx={{ minWidth: "auto" }}
              aria-label="custom pagination table"
              className="responsive-table"
            >
              <TableHead>
                <TableRow className="table-header">
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Shift Code
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Media
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Log
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Created At
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Export
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shifts.slice(page * perPage, page * perPage + perPage).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" sx={tableData}>
                      {item?.shiftId?.shiftCode}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {
                        item.media !== null ?
                          <img src={item.media} style={{ width: 120, height: 90 }} />
                          :
                          null
                      }
                    </TableCell>
                    <TableCell align="center" sx={tableData} className="wrap">
                      <div style={{ wordWrap: 'break-word !important', width: 400 }}>
                        {item?.log.slice(0, 100)}
                      </div>
                    </TableCell>
                    <TableCell align="center" sx={tableData} className="no-wrap">
                      {formatDate(item.createdAt)}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      <div className="pointer" >
                        <Link onClick={() => ShiftExport(item.id)}> Export File</Link>
                      </div>

                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    align="right"
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={getColSpan()}
                    count={rowCount()}
                    rowsPerPage={perPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        }

        {
          rowCount() <= 0 &&
          <TableContainer style={{ backgroundColor: '#FFFFFF', marginLeft: "10px" }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <h2 variant="h1" align="center">No Record Found</h2>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        }



        {/* View Modal */}
        <Dialog open={show} onClose={handleShowClose} fullWidth={true}>
          <DialogTitle sx={{ mb: 6, textAlign: "center" }}>Questions</DialogTitle>

          <DialogContent>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 5, p: 5, width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >
              <table className="table">
                <tr>
                  <th>Question</th>
                  <th>Answer</th>
                  <th>Comment</th>
                </tr>
                {console.log("Quqq*****", questions)}
                {
                  questions.map((item, index) => (
                    <tr>
                      <td key={item.index}>{item?.questionId?.question}</td>
                      <td key={item.index}>{item?.answer}</td>
                      <td>{item?.comment}</td>
                    </tr>
                  ))

                }
              </table>

            </Box>
          </DialogContent>

        </Dialog>
      </Box>


    </Box>
  );
}
