import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { getV2API, getV2ExportAPI } from '../../../network/index.v2'
import EmptyTable from '../../../common/EmptyTable'
import { tableData } from '../../../utils'
import PageTitle from '../../../common/PageTitle'
import { getExportAPI } from '../../../network'


export const tableHeader = {
    fontSize: '0.9rem !important',
    fontWeight: 500,
    color: '#45445F',
    px: 3,
    paddingLeft: '5px',
    paddingRight: '5px',
    padding: '12px'
}



const ViewProperty = () => {
    const [propertyList, setPropertyList] = useState([])

    // -------------------------------------------- Table States and Static Data Manipulation Start --------------------------------------


    const [page, setPage] = useState(0)
    const [perPages, setPerPages] = useState([10, 25, 50]);
    const [perPage, setPerPage] = useState(10)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        console.log(event.target.value)
        setPerPage(event.target.value)
        setPage(0);
    };


    // -------------------------------------------- Table States and Static Data Manipulation Ends --------------------------------------

    const getProperty = async () => {

        try {
            const response = await getV2API("/maintenance/property")
            if (response) {
                setPropertyList(response)
            }
        } catch (error) {
            console.log(error, "error from api");
        }
    }

    const handleExport = async(e) => {
        e.preventDefault()

        const response = await getV2ExportAPI("/maintenance/property/exports", "Properties")

    }

    useEffect(() => {
        getProperty()
    }, [])
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Grid>
                <PageTitle title={"View Property"} />
                <Button variant="contained" style={{ backgroundColor: "#75859D", float: "right" }} sx={{ height: 52, mx: 2 }} onClick={handleExport}>
                    {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                   Export
                </Button>

            </Grid>
            <Grid>
                <Box display="flex" sx={{ my: "3rem" }}>
                    <TableContainer component={Paper}>

                        <Table
                            sx={{ minWidth: "auto" }}
                            aria-label="custom pagination table"
                            className="responsive-table"
                        >
                            <TableHead>
                                <TableRow className="table-header">
                                <TableCell align="left" component="th" sx={tableHeader}>
                                        Property Name
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Property Type
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Property Category
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Property Id
                                    </TableCell>
                                   
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Address
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Lat long
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Contact
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        State
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Suburb
                                    </TableCell>
                                    {/* <TableCell align="center" component="th" sx={tableHeader}>
                                    QR Code
                                </TableCell> */}
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Status
                                    </TableCell>

                                    {/* <TableCell align="center" component="th" sx={tableHeader}>
                                    Action
                                </TableCell> */}
                                    {/* <TableCell align="center" component="th" sx={tableHeader}>
                                        Task Status
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Action
                                    </TableCell> */}

                                </TableRow>
                            </TableHead>
                            <TableBody>


                                {propertyList.slice(page * perPage, page * perPage + perPage).map((task, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.name}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.propertytypeId?.title}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.propertycategoryId?.title}

                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.propertyId}
                                        </TableCell>
                                        
                                        <TableCell align="left" sx={tableData}>
                                            {task?.address}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.latLong}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.phoneNumber}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.state}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.suburb}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.status}
                                        </TableCell>
                                        {/* <TableCell align="left" sx={tableData}>

                                        {task?.isMatch === true ?
                                            <Button style={{ color: 'white', backgroundColor: 'green' }}> Matched</Button> :
                                            <Button style={{ color: 'white', backgroundColor: 'red' }} onClick={() => handleMapData(task)}>Mismatch</Button>
                                        }
                                        <i>
                                            {task?.isMatch ? null : `${task?.distance} km away`}
                                        </i>
                                    </TableCell> */}
                                        {/* <TableCell align="left" sx={tableData}>
                                        No
                                    </TableCell>

                                    <TableCell align="left" sx={tableData}>
                                        Non Critical
                                    </TableCell>
                                    <TableCell align="left" sx={tableData}>
                                        Pending
                                    </TableCell> */}
                                        {/* <TableCell align="left" sx={tableData}>
                                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'green' }} onClick={() => setOpenCheckout(true)}>Checked Out</Button>

                                            <Button variant="outlined" color="error" onClick={() => setOpenCheckout(true)}>Forced Check Out</Button>

                                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'orange' }} onClick={() => setOpenCheckout(true)}>Check Out</Button>
                                        </TableCell> */}

                                    </TableRow>

                                ))}

                                {
                                    propertyList.length === 0 &&
                                    <EmptyTable colSpan={6} />
                                }
                            </TableBody>
                            <TableFooter>
                                <TableRow >

                                    <TablePagination
                                        align="right"
                                        rowsPerPageOptions={perPages}
                                        colSpan={7}
                                        count={propertyList.length}
                                        rowsPerPage={perPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    // ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>
        </div>
    )
}

export default ViewProperty