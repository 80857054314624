import React, { useEffect, useState, useContext } from "react";
import { Box, FormControl, Button, MenuItem } from "@mui/material";
import PageTitle from "../../common/PageTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { getAPI, postAPI, deleteAPI, putAPI, getExportAPI, patchAPI } from "../../network";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import "./style.css";
import { timeFormat, formatDate, formatDatePost, getSetTime, validation, tableHeader, tableData } from "../../utils";
import Loader from "../../common/Loader";
import EmptyTable from "../../common/EmptyTable";
import { Card, CardContent, Typography, Grid, Avatar } from '@mui/material';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import { BASE_URL } from "../../../utils/axiosSetup";
import { makeStyles } from '@mui/styles';
import { MapContainer, Marker, Popup, TileLayer, Polyline } from 'react-leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import BasicSelector from "../../common/Selector";
const useStyles = makeStyles((theme) => ({
  card: {
    margin: 2,
    // width: '100%',
    height: '100%',
    overflow: 'auto',

  },
  media: {
    height: 140,
  },
  image: {
    width: '50%',
    height: 'auto',
    marginBottom: 2,
  },
}));

export default function ViewAlarmResponse() {
  // const [companyId] = useContext(CompanyContext)
  const classes = useStyles();
  let companyId = localStorage.getItem('companyIdLS')
  if (companyId === null) {
    companyId = undefined
  }
  const [open, setOpen] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const [openD, setOpenD] = useState(false);
  const [name1, setName] = useState('');
  const [email1, setEmail] = useState('');
  const [siteFilter, setSiteFilter] = useState('');
  const [user, setUser] = useState('');
  const [editId, setEditId] = useState('');
  const [action, setAction] = useState('');
  const [show, setShow] = useState(false);
  const [id, setId] = useState();
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [alarm, setalarm] = useState([]);
  const [countries, setCountries] = useState([])
  const [cities, setCities] = useState([])
  const [roles, setRoles] = useState([])
  const [sites, setSites] = useState([])
  const [companies, setCompanies] = useState([]);
  const [TimeError, setEndTimeError] = useState(false)
  const [DateError, setStartDateError] = useState(false)

  const [page, setPage] = useState(0);
  const [isVerified, setisVerified] = useState(false)


  const Status = [
    { label: "Open", value: "Open" },
    { label: "Closed", value: "Closed" },
    { label: "Deferred", value: "Deferred" },
    { label: "Opened", value: "Opened" },
  ]

  const [person, setPerson] = useState({
    title: '',
    user: '',
    company: '',
    status: '',
    site: '',
    description: '',
    dueDate: null,
    startTime: null,
    endTime: null,
    responseTime: new Date(),
    UserError: false,
    companyError: false,
    descriptionError: false,
    userError: false,
    startTimeError: false,
    dueDateError: false,
    dueTimeError: false,
    responseTimeError: false
  })
  const [perPages, setPerPages] = useState([10, 25, 50]);
  const [perPage, setPerPage] = useState(10)

  useEffect(() => {
    getalarm();
    getCompanies();
    getSites();
    UserLists()
  }, []);

  const ExcelExport = async (id) => {
    setLoader(true)
    let data = await getExportAPI(`${BASE_URL}/alarm/export`, `Alarm`)
    setLoader(false)
  }

  const getalarm = async (sId = null, name = null, email = null) => {
    setLoader(true)
    if (name == null) name = name1
    if (email == null) email = email1
    if (sId == null) sId = siteFilter
    let queryParams
    if (sId) {
      queryParams = new URLSearchParams({
        siteId: sId,
        name,
        email,
        companyId,
      }).toString();
    } else {
      queryParams = new URLSearchParams({
        name,
        email,
        companyId,
      }).toString();
    }

    let data = await getAPI(`/alarm?${queryParams}`);
    if (data) {
      setalarm(data)
    }

    // if (companyId) {
    //   let data = await getAPI(`/alarm?companyId=${companyId}`);
    //   if (data) {
    //     setalarm(data)
    //   }
    // }
    // if (companyId && name && !email) {
    //   let data = await getAPI(`/alarm?companyId=${companyId}&name=${name}`);
    //   if (data) {
    //     setalarm(data)
    //   }
    // }
    // if (companyId && name && email) {
    //   let data = await getAPI(`/alarm?companyId=${companyId}&name=${name}&email=${email}`);
    //   if (data) {
    //     setalarm(data)
    //   }
    // }
    // if (companyId && !name && email) {
    //   let data = await getAPI(`/alarm?companyId=${companyId}&email=${email}`);
    //   if (data) {
    //     setalarm(data)
    //   }
    // }
    // if (!companyId && !name && !email) {
    //   let data = await getAPI(`/alarm`);
    //   if (data) {
    //     setalarm(data)
    //   }
    // }
    setLoader(false)
  }

  const UserLists = async () => {
    let process = await getAPI(`/admin/guards?companyId=${companyId}`);

    let outputs = process.map((item) => ({
      id: item._id,
      label: item.firstname + ' ' + item.lastname,
      value: item._id
    }))
    setUsers(outputs)
  }



  const getSites = async () => {
    setLoader()
    let data = await getAPI(`/sites?companyId=${companyId}`)
    if (data) {
      let outputs = data.map((item) => ({
        id: item._id,
        label: item.name,
        value: item._id
      }))
      setSites(outputs)
    }
    setLoader()
  }
  const getCompanies = async () => {
    let process = await getAPI(`/companies`);
    if (process) {
      var companies = [];
      for (var i = 0; i < process.length; i++) {
        companies.push({ label: process[i].name, value: process[i].id })
      }
      setCompanies(companies);
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setPerPage(event.target.value)
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const addUser = (e) => {
    e.preventDefault();
    setAction('add');
    clearAll();
    setOpen(true)
  }

  const editUser = (e, id) => {
    e.preventDefault();
    setEditId(id)
    clearAll();
    let data = alarm.filter(item => item._id === id)[0];

    setPerson(prevState => ({
      ...prevState,

      title: data?.title,
      site: data?.site?._id,
      company: data?.company._id,
      status: data?.status,
      dueDate: data?.dueDate,
      startTime: getSetTime(data?.startTime),
      endTime: getSetTime(data?.endTime),
      responseTime: data.responseTime ? getSetTime(data?.responseTime) : new Date(),
      user: data?.user._id,
      description: data?.description,
      userError: false,
      companyError: false,
      dueDateError: false,
      dueTimeError: false,
      sitesError: false
      // roleError : false
    }))

    // getCities(data.countryId._id)
    setAction('edit');
    setOpen(true)
  }
  const cardSet = (e, id) => {
    e.preventDefault();
    setEditId(id)
    clearAll();
    let data = alarm.filter(item => item._id === id)[0];

    setPerson(prevState => ({
      ...prevState,

      title: data?.title,
      site: data?.site?.name,
      company: data?.company.name,
      status: data?.status,
      dueDate: data?.dueDate,
      startTime: data?.startTime,
      endTime: data?.endTime,
      timeTaken: data?.timeTaken,
      distanceCovered: data?.distanceCovered,
      responseTime: data.responseTime ? data?.responseTime : new Date(),
      user: data?.user?.firstname + " " + data?.user?.lastname,
      email: data?.user?.email,
      phone: data?.user?.phone,
      description: data?.description,
      notes: data?.notes,
      signature: data?.signature,
      media: data?.media,
      userError: false,
      companyError: false,
      dueDateError: false,
      dueTimeError: false,
      sitesError: false
      // roleError : false
    }))

    setOpenCard(true)
  }

  const deleteUser = (id) => {

    // setOpenD(true)
    setId(id)
    setShow(true)
    clearAll();
  }
  const clearAll = () => {
    setPerson({
      title: '',
      user: '',
      company: '',
      status: '',
      dueDate: null,
      startTime: null,
      endTime: null,
      responseTime: null,
      site: '',
      description: '',
      userError: false,
      companyError: false,
      dueDateError: false,
      dueTimeError: false,
      sitesError: false
    })
  }

  const handleClose = () => {
    setOpen(false)
    setOpenCard(false)
    setOpenMap(false);
  }
  const handleCloseD = () => {
    setOpenD(false)
  }

  const handleShowClose = () => {
    setShow(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPerson(prevState => ({
      ...prevState,
      userError: false,
      companyError: false,
      dueDateError: false,
      dueTimeError: false,
      sitesError: false
    }))


    if (validation('empty', 'Title', person.title)) {
      setPerson(prevState => ({
        ...prevState,
        titleError: true,
      }))
      return;
    }

    // else if (validation('empty', 'Company', person.company)) {
    //   setPerson(prevState => ({
    //     ...prevState,
    //     companyError: true,
    //   }))
    //   return;
    // }
    else if (validation('empty', 'user', person.user)) {
      setPerson(prevState => ({
        ...prevState,
        userError: true,
      }))
      return;
    }

    else if (validation('empty', 'Duedate', person.dueDate)) {
      setPerson(prevState => ({
        ...prevState,
        dueDateError: true,
      }))
      return;
    }
    else if (validation('empty', 'StartTime', person.startTime)) {
      setPerson(prevState => ({
        ...prevState,
        dueTimeError: true,
      }))
      return;
    }
    else if (validation('empty', 'EndTime', person.endTime)) {
      setPerson(prevState => ({
        ...prevState,
        dueTimeError: true,
      }))
      return;
    }

    else if (validation('empty', 'Site', person.site)) {
      setPerson(prevState => ({
        ...prevState,
        sitesError: true,
      }))
      return;
    }



    let formData = new FormData();
    formData.append('title', person.title);
    formData.append('user', person.user);
    formData.append('company', companyId);
    formData.append('status', person.status);
    formData.append('dueDate', formatDatePost(person.dueDate));
    formData.append('startTime', timeFormat(person.startTime));
    formData.append('endTime', timeFormat(person.endTime));
    formData.append('responseTime', timeFormat(person.responseTime));
    formData.append('description', person.description);
    formData.append(`sites`, person.site)


    if (action === 'add') {
      setLoader(true)
      let data = await postAPI('/alarm', formData)
      if (data) {
        getalarm()
        setOpen(false)
      }
      setLoader(false)
    }
    else {
      setLoader(true)
      let data = await putAPI(`/alarm/${editId}`, formData)
      if (data) {
        getalarm()
        setOpen(false)
      }
      setLoader(false)
    }
  }

  const handleDelete = async () => {
    setLoader(true);
    let data = await deleteAPI(`/alarm/${id}`)
    setLoader(false);
    if (data) {
      // getUsers();
      getalarm();
      setShow(false)
    }
  }
  const resendEmail = async (alarmId) => {
    setLoader(true);
    await patchAPI(`/alarm/resend/${alarmId}`)
    setLoader(false);

  }
  const [position1, setPosition1] = useState([-33.93493568576685, 151.06907095273533]);
  const [position2, setPosition2] = useState([-33.935, 151.07]);
  const [markers, setMarkers] = useState([]); // Custom markers
  const [siteMarkers, setSiteMarkers] = useState([]); // Custom markers
  const [openMap, setOpenMap] = useState(false)

  const handleMapData = (alarm) => {

    setMarkers([
      {
        "coordinates": [
          alarm.lat,
          alarm.lng
        ],
        "name": alarm.user.firstname + ' ' + alarm.user.lastname,
        "email": alarm.user.email
      }
    ]);

    setSiteMarkers([
      {
        "coordinates": [
          alarm.site.latitude,
          alarm.site.longitude
        ],
        "name": alarm.site.name,
        "email": alarm.site.address
      }
    ]);

    setPosition1([alarm.lat, alarm.lng])
    setPosition2([alarm.site.latitude, alarm.site.longitude])

    setOpenMap(true);

  }

  const [permissionList, setPermissionList] = useState([]);

  // Load the permission list from localStorage
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permisionlist")) || [];
    setPermissionList(permissions);
  }, []);

  const subactionPermissions = permissionList[0]?.subactionPermissions || [];

  // Check if 'Delete' and 'Edit' subActions exist
  
  const canDelete = subactionPermissions.some(
    (permission) => permission.subAction === "Delete"
  );
  const canEdit = subactionPermissions.some(
    (permission) => permission.subAction === "Edit"
  );
  const canResend = subactionPermissions.some(
    (permission) => permission.subAction === "Resend"
  );
  const canView = subactionPermissions.some(
    (permission) => permission.subAction === "View"
  );

  // If subactionPermissions is empty, show both buttons
  const showBothButtons = subactionPermissions.length === 0;

  return (
    <Box sx={{ height: "inherit" }}>
      <Loader loader={loader} />
      <PageTitle title="Secuber" subTitle="View Alarm Response" />
      <Box style={{ display: "flex" }}>
        <Grid>
          <BasicSelector
            disableAll={true}
            options={sites}
            selectorHight={"61px"}
            value={siteFilter}
            name={"Select Site"}
            selectorWidth={"260px"}
            style={{ marginLeft: "18px" }}
            onChange={(e) => {
              setSiteFilter(e.target.value)
              getalarm(e.target.value)
            }}
          />
        </Grid>
        <Grid>
          <TextField id="outlined-basic" label="Search via Name" variant="outlined" onChange={(e) => {
            getalarm(null, e.target.value)
            setName(e.target.value)
          }} style={{ marginLeft: "18px" }} />
        </Grid>
        <Grid>
          <TextField id="outlined-basic" label="Search via Email" variant="outlined" onChange={(e) => {
            getalarm(null, null, e.target.value)
            setEmail(e.target.value)
          }} style={{ marginLeft: "20px" }} />
        </Grid>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={{ mx: 1 }}
      >
        <Button variant="contained" style={{ backgroundColor: "#75859D", marginRight: "10px" }} sx={{ height: 50 }} onClick={() => ExcelExport()}>
          <GetAppRoundedIcon /> &nbsp; &nbsp;
          Export
        </Button>
      </Box>
      <Box display="flex" sx={{ my: "1rem" }}>

        <TableContainer component={Paper} sx={{ mx: "1rem", msOverflowX: "scroll" }}>
          <Table
            sx={{ minWidth: "auto" }}
            aria-label="custom pagination table"
            className="responsive-table"
          >
            <TableHead>
              <TableRow className="table-header" align="center">
                <TableCell align="left" component="th" sx={tableHeader}>
                  Title
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Description
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  User
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Phone
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Email
                </TableCell>
                <TableCell align="center" component="th" sx={tableHeader}>
                  Site
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  DueDate
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  StartTime
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  EndTime
                </TableCell>
                <TableCell align="center" component="th" sx={tableHeader}>
                  Status
                </TableCell>
                <TableCell align="center" component="th" sx={tableHeader}>
                  Location
                </TableCell>
                {/* <TableCell align="center" component="th" sx={tableHeader}>
                  Photo
                </TableCell>
                <TableCell align="center" component="th" sx={tableHeader}>
                  Signature
                </TableCell> */}
                <TableCell align="center" component="th" sx={tableHeader}>
                  Action
                </TableCell>

                {/* <TableCell align="center" component="th" sx={tableHeader}>
                  Created At
                </TableCell> */}

              </TableRow>
            </TableHead>
            <TableBody>
              {alarm.slice(page * perPage, page * perPage + perPage).map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="left" sx={tableData}>
                    {item?.title}
                  </TableCell>
                  <TableCell align="left" sx={tableData}>
                    {item?.description}
                  </TableCell>
                  <TableCell align="left" sx={tableData}>
                    {item?.user?.firstname} {' '} {item?.user?.lastname}
                  </TableCell>
                  <TableCell align="left" sx={tableData}>
                    {item?.user?.phone}
                  </TableCell>
                  <TableCell align="left" sx={tableData}>
                    {item?.user?.email}
                  </TableCell>
                  <TableCell align="center" sx={tableData}>
                    {item?.site?.name}
                    {/* {item?.sites?.map((item, index) => (
                      <div className="" key={index}>{item.name}</div>
                    ))} */}
                  </TableCell>

                  <TableCell align="left" sx={tableData}>
                    {formatDate(item?.dueDate)}
                  </TableCell>
                  <TableCell align="left" sx={tableData}>
                    {item?.startTime}
                  </TableCell>
                  <TableCell align="left" sx={tableData}>
                    {item?.endTime}
                  </TableCell>
                  <TableCell align="left" sx={tableData}>
                    {item?.status}
                  </TableCell>
                  {
                    item.signature ?
                      <TableCell align="left" sx={tableData}>

                        {item?.isMatch === true ?
                          <Button style={{ color: 'white', backgroundColor: 'green' }}> Matched</Button> :
                          <Button style={{ color: 'white', backgroundColor: 'red' }} onClick={() => handleMapData(item)}>Mismatch</Button>
                        }
                        <i>
                          {item?.isMatch === true ? null : `${item?.distance} km away`}
                        </i>
                      </TableCell>
                      :
                      <TableCell align="left" sx={tableData}>
                        <i>Response not submitted yet</i>
                      </TableCell>
                  }

                  {/* <TableCell align="left" sx={tableData}>
                    <img style={{ width: '60px' }} src={item?.media} alt="empty" />
                  </TableCell>
                  <TableCell align="left" sx={tableData}>
                    <img style={{ width: '60px' }} src={item?.signature} alt="empty" />
                  </TableCell> */}

                  <TableCell align="center" className="action-div" sx={{ textAlign: 'center' }}>
                  {(showBothButtons || canEdit) && (
                    <Button size="small" className="btn-div" variant="outlined" color="info" onClick={(e) => editUser(e, item?._id)}>
                      <EditIcon className="" />
                    </Button>
                  )}
                  {(showBothButtons || canDelete) && (
                    <Button size="small" className="btn-div" variant="outlined" color="error" onClick={(e) => deleteUser(item?._id)}>
                      <DeleteIcon className="" />
                    </Button>
                  )}
                  {(showBothButtons || canResend) && (
                    <Button size="small" className="btn-div" variant="outlined" color="success" onClick={() => resendEmail(item?._id)}>
                      <ReplayIcon className="" />
                    </Button>
                  )}
                  {(showBothButtons || canView) && (
                    <Button size="small" className="btn-div" variant="outlined" color="secondary" onClick={(e) => cardSet(e, item?._id)}>
                      <VisibilityIcon className="" />
                    </Button>
                  )}

                  </TableCell>



                </TableRow>
              ))}
              {
                alarm.length === 0 &&
                <EmptyTable colSpan={9} />
              }
            </TableBody>
            <TableFooter>
              <TableRow >

                <TablePagination
                  align="right"
                  rowsPerPageOptions={perPages}
                  colSpan={7}
                  count={users.length}
                  rowsPerPage={perPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                // ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>{action === 'add' ? 'Add' : 'Edit'} Alarm Response</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { my: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl style={{ width: "100%" }}>
              <TextField
                id="title"
                label="Title"
                variant="outlined"
                type="text"
                value={person.title}
                error={person.titleError}
                style={{ width: "100%" }}
                onChange={(data) => {
                  setPerson(prevState => ({
                    ...prevState,
                    title: data.target.value,
                  }))
                }}
                fullWidth
                sx={{ m: 0 }}
              />
            </FormControl>

            <TextField
              select
              label="User"
              value={person.user}
              onChange={(data) => {
                setPerson(prevState => ({
                  ...prevState,
                  user: data.target.value
                }))
              }}
              variant="outlined"
              fullWidth
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.value}>
                  {user.label}
                </MenuItem>
              ))}
            </TextField>

            <FormControl style={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  className="modal-component"
                  label="Due Date"
                  style={{ width: "100%" }}
                  inputFormat="dd/MM/yyyy"
                  value={person.dueDate}
                  error={person.dueDateError}
                  onChange={(data) => {
                    setPerson(prevState => ({
                      ...prevState,
                      dueDate: data,
                    }))
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  // error={startDateError}
                  fullWidth
                />
              </LocalizationProvider>
            </FormControl>

            <div className="" style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: "start", gap: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    label="Start Time"
                    value={person.startTime}
                    error={person.startTimeError}
                    style={{ width: "100%" }}
                    onChange={(data) => {
                      setPerson(prevState => ({
                        ...prevState,
                        startTime: data,
                      }))
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl style={{ width: "100%" }} >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    label="End Time"
                    value={person.endTime}
                    error={person.dueTimeError}
                    style={{ width: "100%" }}
                    onChange={(data) => {
                      setPerson(prevState => ({
                        ...prevState,
                        endTime: data,
                      }))
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />

                </LocalizationProvider>
              </FormControl>
            </div>

            <FormControl style={{ width: "100%" }}>
              <TextField
                id="description"
                label="Description"
                variant="outlined"
                type="text"
                style={{ width: "100%" }}
                multiline
                error={person.descriptionError}
                value={person.description}
                onChange={(data) => {
                  setPerson(prevState => ({
                    ...prevState,
                    description: data.target.value,
                  }))
                }}
                fullWidth
              />
            </FormControl>

            <TextField
              select
              label="Site"
              value={person.site}
              onChange={(data) => {
                setPerson(prevState => ({
                  ...prevState,
                  site: data.target.value
                }))
              }}
              variant="outlined"
              fullWidth
            >
              {sites.map((site) => (
                <MenuItem key={site.id} value={site.value}>
                  {site.label}
                </MenuItem>
              ))}
            </TextField>


            <TextField
              select
              label="Status"
              value={person.status}
              onChange={(data) => {
                setPerson(prevState => ({
                  ...prevState,
                  status: data.target.value,
                }))
              }}
              variant="outlined"
              fullWidth
            >
              {Status.map((status, index) => (
                <MenuItem key={index} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </TextField>
            <FormControl style={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  label="Response Time"
                  value={person.responseTime}
                  error={person.responseTimeError}
                  style={{ width: "100%" }}
                  onChange={(data) => {
                    setPerson(prevState => ({
                      ...prevState,
                      responseTime: data,
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>

          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={handleSubmit} variant="contained">{action === 'add' ? 'Submit' : 'Update'}</Button>
          <Button onClick={handleClose} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openD} onClose={handleCloseD} fullWidth={true}>
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>Are you sure want to delete Alarm Response ?</DialogTitle>
        <DialogContent></DialogContent>

      </Dialog>
      {/* delete Modal */}
      <Dialog open={show} onClose={handleShowClose} fullWidth={true}>
        {/* <DialogTitle sx={{ mb: 4 , textAlign :"center" }}>Admin User</DialogTitle> */}
        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { my: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>Do you want to delete? </h3>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={handleDelete} variant="contained" color="error">Yes</Button>
          <Button onClick={handleShowClose} variant="outlined">No</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openCard} onClose={handleClose} fullWidth={true}>
        <Card className={classes.card}>
          <CardContent>

            <Typography variant="h5" component="div">
              {person?.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {person?.description}
            </Typography>
            <Grid container spacing={2} style={{ marginTop: 16 }}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1"><strong>User:</strong> {person?.user}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1"><strong>Phone:</strong> {person.phone}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1"><strong>Email:</strong> {person.email}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1"><strong>Site:</strong> {person.site}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1"><strong>Due Date:</strong> {person.dueDate}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1"><strong>Start Time:</strong> {person.startTime}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1"><strong>End Time:</strong> {person.endTime}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1"><strong>Status:</strong> {person.status}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1"><strong>Time Taken :</strong> {person?.timeTaken} minutes</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1"><strong>Distance Covered:</strong> {person.distanceCovered} kilometers</Typography>
              </Grid>

              <Grid container spacing={2} style={{ marginTop: 16 }}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Picture</Typography>
                  <img src={person.media} alt="empty" className={classes.image} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Signature</Typography>
                  <img src={person.signature} alt="empty" className={classes.image} />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Dialog>
      <Dialog open={openMap} onClose={handleClose}>
        {/* <DialogTitle align="center" >Visitor Location</DialogTitle> */}

        <MapContainer center={position2} zoom={22} style={{ height: '80vh', width: '120vh', marginButton: '10px' }}>
          <TileLayer
            // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          />
          {markers.map((marker, index) => (
            <Marker key={index} position={marker?.coordinates}>
              <Popup>{marker.name}
                <br />
                {marker?.email}
              </Popup>
            </Marker>
          ))}
          {siteMarkers.map((marker, index) => (
            <Marker key={index} position={marker?.coordinates}>
              <Popup>{marker.name}
                <br />
                {marker?.email}
              </Popup>
            </Marker>
          ))}
          {/* <Polyline positions={[siteMarkers.coordinates, markers.coordinates]} color="red" /> */}
          <Polyline positions={[position1, position2]} color="red" />

        </MapContainer>

      </Dialog>
    </Box >
  );
}
