
import { useLocation } from "react-router-dom";
import { HOST_URL } from "./axiosSetup";
import { useEffect } from "react";


const HtmlErrorPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const htmlContent = decodeURIComponent(queryParams.get("content"));

    useEffect(() => {
        document.title = "Error: Something went wrong";
    }, []);

    try {
        const parsedContent = JSON.parse(htmlContent); // Try to parse JSON
        return (
            <pre>{JSON.stringify(parsedContent, null, 2)}</pre>
        );
    } catch (e) {
        // If parsing fails, assume it's HTML content
        return (
            <div className="h-screen font-roboto">
                <div
                    style={{ padding: "20px" }}
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                />
                <a
                    href={HOST_URL}
                    style={{ padding: "20px" }}
                    className="text-blue-500 underline cursor-pointer"
                >
                    Go Back
                </a>
            </div>
        );
    }
};

export default HtmlErrorPage
