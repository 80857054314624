import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../../../common/PageTitle";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { tableData, tableHeader } from "../../../utils";
import {
  deleteAPI,
  getAPI,
  getExportAPI,
  getExportPdfAPI,
  postAPI,
  putAPI,
} from "../../../network";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EmptyTable from "../../../common/EmptyTable";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { BASE_URL } from "../../../../utils/axiosSetup";
import issueFaultData from "./Fsrs.json";
import { useLocation } from "react-router-dom";

const FSR = () => {
  let companyId = localStorage.getItem("companyIdLS");
  if (companyId === null) {
    companyId = undefined;
  }

  const location = useLocation();
  const receivedData = location.state;
  const [loader, setLoader] = useState(false);
  const [fsrList, setFsrList] = useState([]);
  const [buildingTypeList, setBuildingTypeList] = useState([]);
  const [buildingNameList, setBuildingNameList] = useState([]);
  const [buildingFloorList, setBuildingFloorList] = useState([]);
  const [buildingSpaceList, setBuildingSpaceList] = useState([]);
  const [buildingSpaceIdList, setBuildingSpaceIdList] = useState([]);
  const [buildingAssetIdList, setBuildingAssetIdList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [assignee, setAssignee] = useState([]);
  const [urlF, setUrlF] = useState("");
  const [selectedIssueType, setSelectedIssueType] = useState("");
  const [issueTypes, setIssueTypes] = useState([]);
  const [faultTypes, setFaultTypes] = useState([]);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [inputs, setInputs] = useState({
    companyId: companyId,
    areaId: "",
    buildingTypes: "",
    buildingName: "",
    buildingFloor: "",
    buildingSpaceType: "",
    issueType: "",
    faultType: "",
    priorityLevel: "",
    issueDescription: "",
    loggedBy: "",
  });
  const [buildingInputs, setBuildingInputs] = useState({
    buildingSpaceId: "",
    buildingTypes: "",
    buildingName: "",
    buildingFloor: "",
    buildingSpaceType: "",
    issueType: "",
    faultType: "",
    priorityLevel: "",
    issueDescription: "",
    assignee: "",
    buildingVendor: "",
    status: "",
    woNumber: "",
    actionTaken: "",
    cost: 0,
    jobAttachment: "",
    _id: "",
  });
  const [editModal, setEditModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [filter, setFilter] = useState({
    bType: "",
    bName: "",
    bFloor: "",
    bSpace: "",
    bSpaceId: "",
    bIssueType: "",
    bFaultType: "",
    bStatus: "",
  });

  const [permissionList, setPermissionList] = useState([]);

  // Load the permission list from localStorage
  // useEffect(() => {
  //   const permissions = JSON.parse(localStorage.getItem("permisionlist")) || [];
  //   setPermissionList(permissions);
  // }, []);

  const subActionPermissions = permissionList[0]?.subactionPermissions || [];

  ///my code
  // const canPerformAction = (actionName, submenuName, permissionsData) => {
  //   // Check if permissionsData and required arrays exist
  //   console.log("PERMISSION" + permissionsData[0].menuPermissions);
  //   if (
  //     !permissionsData ||
  //     !permissionsData.submenuPermissions ||
  //     !permissionsData.actionPermissions
  //   ) {
  //     console.error("Invalid permissions data structure");
  //     return false;
  //   }

  //   // Find the submenu by name
  //   const submenuPermission = permissionsData.submenuPermissions.find(
  //     (submenu) => submenu.submenu === submenuName
  //   );

  //   if (!submenuPermission) {
  //     console.warn(`Submenu '${submenuName}' not found in permissions`);
  //     return false;
  //   }

  //   const submenuId = submenuPermission._id;

  //   // Check if the action is allowed for the specific submenu
  //   return permissionsData.actionPermissions.some(
  //     (permission) =>
  //       permission.action === actionName && permission.submenu === submenuId
  //   );
  // };

  // const canAdd = canPerformAction(
  //   "Add",
  //   "Facility Service Requests",
  //   permissionList
  // );
  // console.log("CANADD" + canAdd);
  // Check if 'Delete' and 'Edit' subActions exist

  // If subActionPermissions is empty, show both buttons
  // const showBothButtons = subActionPermissions.length === 0;

  // -------------------------------------------- Table States and Static Data Manipulation Start --------------------------------------

  const [page, setPage] = useState(0);
  const [perPages, setPerPages] = useState([10, 25, 50]);
  const [perPage, setPerPage] = useState(10);

  const fetchIssueTypes = async () => {
    if (companyId) {
      try {
        const response = await getAPI(
          `/building/get-issuetype?companyId=${companyId}`
        );
        setIssueTypes(response);
      } catch (error) {
        console.error("Error fetching issue types:", error);
      }
    }
  };
  const fetchFaultTypes = async (issueType) => {
    if (companyId) {
      try {
        const response = await getAPI(
          `/building/get-fault?companyId=${companyId}&issueType=${issueType}`
        );
        setFaultTypes(response);
      } catch (error) {
        console.error("Error fetching issue types:", error);
      }
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value);
    setPerPage(event.target.value);
    setPage(0);
  };

  const onChange = async (e) => {
    e.preventDefault();

    setInputs({ ...inputs, [e.target.name]: e.target.value });

    if (e.target.name == "issueType") {
      setSelectedIssueType(e.target.value);

      fetchFaultTypes(e.target.value)

    }
    if (e.target.name == "faultType") {
      setSelectedFaultType(e.target.value);
    }
  };

  const editChange = async (e) => {
    e.preventDefault();

    setBuildingInputs({ ...buildingInputs, [e.target.name]: e.target.value });

    if (e.target.name == "issueType") {
      setSelectedIssueType(e.target.value);
      // Find the corresponding fault types
      // const selectedData = issueFaultData.data.find(
      //   (item) => item.issueType === e.target.value
      // );
      fetchFaultTypes(e.target.value)
      // setFaultTypes(selectedData ? selectedData.faultType : []);
      // setSelectedFaultType(""); // Reset fault type selection when issue type changes
    }
    if (e.target.name == "faultType") {
      setSelectedFaultType(e.target.value);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (editMode) {
      editBuildingTypee();
    } else {
      postBuildingType();
    }
  };

  const editFSrType = async (e, _id) => {
    e.preventDefault();

    setEditModal(true);
    setEditMode(true);

    let data = fsrList?.filter((item) => item?._id === _id)[0];

    setBuildingInputs(data);
    setBuildingInputs({
      ...data,
      buildingTypes: data.buildingTypes._id,
      buildingName: data.buildingName._id,
      buildingFloor: data.buildingFloor._id,
      buildingSpaceType: data?.buildingSpaceType?._id,
      buildingSpaceId: data?.buildingSpaceId?._id,
      priorityLevel: data.priorityLevel,
      buildingVendor: data?.buildingVendor?._id,
      assignee: data?.assignee?._id,
      issueType: data?.issueType?._id,
      faultType: data.faultType?._id,
      cost: data?.cost,
      _id: data._id,
    });
    fetchFaultTypes(data?.issueType?._id)
    let selectedData = issueFaultData.data.find(
      (item) => item.issueType === data?.issueType
    );
    setFaultTypes(selectedData ? selectedData.faultType : []);
    setSelectedFaultType("");
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  // -------------------------------------------- Table States and Static Data Manipulation Ends --------------------------------------

  const getVendor = async () => {
    setLoader(true);
    const response = await getAPI(`/building/vendor?companyId=${companyId}`);
    if (response) {
      setVendor(response);
    }
    setLoader(false);
  };
  const getAssignee = async () => {
    setLoader(true);
    const response = await getAPI(`/building/Assignee?companyId=${companyId}`);
    if (response) {
      setAssignee(response);
    }
    setLoader(false);
  };
  const getBuildingType = async () => {
    setLoader(true);
    const response = await getAPI(`/building/type?companyId=${companyId}`);
    if (response) {
      setBuildingTypeList(response);
    }
    setLoader(false);
  };

  const getBuildingName = async () => {
    setLoader(true);
    const response = await getAPI(`/building/name?companyId=${companyId}`);
    if (response) {
      setBuildingNameList(response);
    }
    setLoader(false);
  };

  const getBuildingFloor = async () => {
    setLoader(true);
    const response = await getAPI(`/building/floor?companyId=${companyId}`);
    if (response) {
      setBuildingFloorList(response);
    }
    setLoader(false);
  };

  const getBuildingSpace = async () => {
    setLoader(true);
    const response = await getAPI(`/building/SpaceType?companyId=${companyId}`);
    if (response) {
      setBuildingSpaceList(response);
    }
    setLoader(false);
  };

  const getBuildingSpaceId = async () => {
    setLoader(true);
    const response = await getAPI(`/building/SpaceId?companyId=${companyId}`);
    if (response) {
      setBuildingSpaceIdList(response);
    }
    setLoader(false);
  };
  const getAssetId = async () => {
    setLoader(true);
    const response = await getAPI(`/building/AssetId?companyId=${companyId}`);
    if (response) {
      setBuildingAssetIdList(response);
    }
    setLoader(false);
  };
  const [fileName, setFileName] = useState("Select 16- Job Attachment File");
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click(); // Trigger the file input click
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      setFile(selectedFile);
      // setBuildingInputs({ ...buildingInputs, jobAttachment: selectedFile })
    } else {
      setFileName("Select 16- Job Attachment File");
    }
  };

  // ------------------------------------------------------ FSR CRUD Start ----------------------------------------------------------

  const postBuildingType = async () => {
    setLoader(true);

    const response = await postAPI(`/building/facilitiesRequest`, inputs);
    if (response) {
      getFacilityServices();
      setModalOpen(false);
      setEditMode(false);
      setInputs((prevInputs) => ({
        ...prevInputs,

        areaId: "",
        buildingTypes: "",
        buildingName: "",
        buildingFloor: "",
        buildingSpaceType: "",
        issueType: "",
        faultType: "",
        priorityLevel: "",
        issueDescription: "",
      }));
    }
    setLoader(false);
  };

  const getFacilityServices = async (
    bType = null,
    bName = null,
    bFloor = null,
    bSpace = null,
    bSpaceId = null,
    bIssueType = null,
    bFaultType = null,
    bStatus = null
  ) => {

    const queryParams = new URLSearchParams({
      buildingTypes: bType ? bType : filter.bType,
      buildingName: bName ? bName : filter.bName,
      buildingFloor: bFloor ? bFloor : filter.bFloor,
      buildingSpaceType: bSpace ? bSpace : filter.bSpace,
      buildingSpaceId: bSpaceId ? bSpaceId : filter.bSpaceId,
      buildingIssue: bIssueType ? bIssueType : filter.bIssueType,
      buildingFault: bFaultType ? bFaultType : filter.bFaultType,

      buildingStatus: bStatus ? bStatus : filter.bStatus,
      companyId: companyId ? companyId : filter.companyId,
    });

    setLoader(true);
    const response = await getAPI(
      `/building/facilitiesRequest?${queryParams.toString()}`
    );
    if (response) {
      setFsrList(response);
    }
    setLoader(false);
  };

  // console.log(urlF);

  const editBuildingTypee = async () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("picture", file);

    Object.keys(buildingInputs).forEach((key) => {
      // Convert null values to empty string or any other placeholder if necessary
      const value = buildingInputs[key] === null ? "" : buildingInputs[key];
      formData.append(key, value);
      console.log(key, value);
    });
    const response = await putAPI(
      `/building/facilitiesRequest/${buildingInputs?._id}`,
      formData
    );
    if (response) {
      getFacilityServices();
      setEditModal(false);
    }
    setLoader(false);
  };

  const deleteBuildingType = async (_id) => {
    console.log(_id);
    setLoader(true);
    const response = await deleteAPI(`/building/facilitiesRequest/${_id}`);
    if (response) {
      getFacilityServices();
    }
    setLoader(false);
  };
  // console.log(fsrList);

  // ------------------------------------------------------ FSR CRUD End --------------------------------------------------------------

  const getExportToExcel = async () => {
    setLoader(true);
    let data = await getExportAPI(
      `${BASE_URL}/building/facilitiesRequest/export${urlF}`,
      `Facility services and requests`
    );
    setLoader(false);
  };
  const getExportPdf = async () => {
    setLoader(true);
    let data = await getExportPdfAPI(
      `${BASE_URL}/building/facilitiesRequest/pdfexport/${buildingInputs._id}`,
      `Facility services and requests`
    );
    setLoader(false);
  };

  // useEffect(() => {
  //     const findFaultType = DropdownJson?.data?.filter((item) => item?.issueType === selectedIssueType) || []
  //     console.log(findFaultType, selectedIssueType);
  // }, [selectedIssueType])

  console.log(buildingSpaceList);
  useEffect(() => {
    getFacilityServices();
    getBuildingSpaceId();
    getBuildingSpace();
    getBuildingFloor();
    getBuildingName();
    getBuildingType();
    fetchIssueTypes();
    getVendor();
    getAssignee();
    getAssetId()
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user.email) {
      setInputs((prevInputs) => ({ ...prevInputs, loggedBy: user.email }));
    }
  }, []);

  const [selectedFaultType, setSelectedFaultType] = useState("");

  const handleIssueTypeChange = (event) => {
    const issueType = event.target.value;
    setSelectedIssueType(issueType);

    // Find the corresponding fault types
    const selectedData = issueFaultData.data.find(
      (item) => item.issueType === issueType
    );
    setFaultTypes(selectedData ? selectedData.faultType : []);
    setSelectedFaultType("");
  };


  // const [permissionList, setPermissionList] = useState([]);

  // Load the permission list from localStorage
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permisionlist")) || [];
    setPermissionList(permissions);
    if (receivedData) {
      getFacilityServices(null, null, null, null, null, receivedData);
      fetchFaultTypes(receivedData);
      setFilter({ ...filter, bIssueType: receivedData });
    }

  }, []);

  const actionPermissions = permissionList[0]?.actionPermissions || [];
  const submenuPermissions = permissionList[0]?.submenuPermissions || [];

  // Check if the "Add" action exists and matches a submenuPermissions _id
  const canAdd = actionPermissions.some((permission) => {
    if (permission.action === "Add") {
      return submenuPermissions.some(
        (submenu) => submenu._id === permission.submenu
      );
    }
    return false;
  });
  const canDelete = actionPermissions.some((permission) => {
    if (permission.action === "Delete") {
      return submenuPermissions.some(
        (submenu) => submenu._id === permission.submenu
      );
    }
    return false;
  });
  const canEdit = actionPermissions.some((permission) => {
    if (permission.action === "Edit") {
      return submenuPermissions.some(
        (submenu) => submenu._id === permission.submenu
      );
    }
    return false;
  });
  // If actionPermissions is empty, show both buttons
  const showBothButtons = actionPermissions.length === 0;

  return (
    <div>
      <PageTitle title={"Digital Front Door"} />

      <p style={{ marginLeft: "12px", width: "70%", paddingBottom: "20px" }}>
        <b style={{ fontWeight: 600 }}>Note: </b>
        {
          "Please use below filters to shortlist the existing issues before logging in new request. If a request already exist and is 'open', you need to log another request. Alternatively, please find proceed to log a new tenancy service request."
        }
      </p>

      <div style={{ width: "78%", paddingBottom: "20px", marginTop: "60px" }}>
        <Button
          variant="contained"
          style={{ backgroundColor: "#75859D", float: "right", width: "170px" }}
          sx={{ height: 52, mx: 2 }}
          onClick={() => getExportToExcel()}
        >
          {/* <AddCircleIcon /> &nbsp; &nbsp; */}
          Export
        </Button>
        {(showBothButtons || canAdd) && (
          <Button
            onClick={() => setModalOpen(true)}
            variant="contained"
            style={{
              backgroundColor: "#75859D",
              float: "right",
              width: "170px",
            }}
            sx={{ height: 52, mx: 2 }}
          >
            <AddCircleIcon /> &nbsp; &nbsp; Add FSR
          </Button>
        )}
      </div>

      <Grid
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: "10px",
          width: "70%",
          marginTop: "60px",
        }}
      >
        <FormControl fullWidth style={{ width: "100%", marginLeft: "10px" }}>
          <InputLabel id="demo-simple-select-label">
            Select Building Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="bType"
            name="bType"
            value={filter?.bType}
            label="Select Building Type"
            onChange={(e) => {
              setFilter({
                ...filter,
                bType: e.target.value,
              });
              getFacilityServices(e.target.value);
            }}
          >
            {buildingTypeList?.map((item, index) => {
              return (
                <MenuItem key={index} value={item?._id}>
                  {item?.title}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ width: "100%", marginLeft: "10px" }}>
          <InputLabel id="demo-simple-select-label">
            Select Building Name
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="bName"
            name="bName"
            value={filter?.bName}
            label="Select Building Name"
            onChange={(e) => {
              setFilter({
                ...filter,
                bName: e.target.value,
              });
              getFacilityServices(null, e.target.value);
            }}
          >
            {buildingNameList
              ?.filter((name) => name?.buildingTypes?._id === filter?.bType)
              ?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?._id}>
                    {item?.title}
                  </MenuItem>
                );
              })}
            {/* <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem> */}
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ width: "100%", marginLeft: "10px" }}>
          <InputLabel id="demo-simple-select-label">
            Select Building Floor
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="bFloor"
            name="bFloor"
            value={filter?.bFloor}
            label="Select Building Floor"
            onChange={(e) => {
              setFilter({
                ...filter,
                bFloor: e.target.value,
              });
              getFacilityServices(null, null, e.target.value);
            }}
          >
            {buildingFloorList
              ?.filter((floor) => floor?.buildingName?._id === filter?.bName)
              ?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?._id}>
                    {item?.title}
                  </MenuItem>
                );
              })}
            {/* <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem> */}
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ width: "100%", marginLeft: "10px" }}>
          <InputLabel id="demo-simple-select-label">
            Select Space Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="bSpace"
            name="bSpace"
            value={filter?.bSpace}
            label="Select Space Type"
            onChange={(e) => {
              setFilter({
                ...filter,
                bSpace: e.target.value,
              });
              getFacilityServices(null, null, null, e.target.value);
            }}
          >
            {buildingSpaceList
              ?.filter((space) => space?.buildingFloor?._id === filter?.bFloor)
              ?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?._id}>
                    {item?.title}
                  </MenuItem>
                );
              })}
            {/* <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem> */}
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ width: "100%", marginLeft: "10px" }}>
          <InputLabel id="demo-simple-select-label">Select Space ID</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="bSpaceId"
            name="areaId"
            value={filter?.bSpaceId}
            label="Select Space ID"
            onChange={(e) => {
              setFilter({
                ...filter,
                bSpaceId: e.target.value,
              });
              getFacilityServices(null, null, null, null, e.target.value);
            }}
          >
            {buildingSpaceIdList
              ?.filter(
                (spaceId) => spaceId?.buildingSpace?._id === filter?.bSpace
              )
              ?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?._id}>
                    {item?.areaId}
                  </MenuItem>
                );
              })}
            {/* <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem> */}
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ width: "100%", marginLeft: "10px" }}>
          <InputLabel id="issue-type-select-label">
            Select Issue Type
          </InputLabel>
          <Select
            labelId="issue-type-select-label"
            id="bIssueType"
            name="bIssueType"
            value={filter?.bIssueType}
            label="Select Issue Type"
            onOpen={fetchIssueTypes} // Fetch issue types when dropdown is opened
            onClose={() => setDropdownOpened(false)} // Reset dropdown state on close
            onChange={(e) => {
              setFilter({
                ...filter,
                bIssueType: e.target.value,
              });
              fetchFaultTypes(e.target.value);
              getFacilityServices(null, null, null, null, null, e.target.value);
            }}
          >
            {issueTypes.length > 0 ? (
              issueTypes.map((issueType) => (
                <MenuItem key={issueType._id} value={issueType._id}>
                  {issueType.title}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No issue types available</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl fullWidth style={{ width: "100%", marginLeft: "10px" }}>
          <InputLabel id="fault-type-select-label">
            Select Fault Type
          </InputLabel>
          <Select
            labelId="fault-type-select-label"
            id="bFaultType"
            name="bFaultType"
            value={filter?.bFaultType}
            label="Select Fault Type"
            onClose={() => setDropdownOpened(false)} // Reset dropdown state on close
            onChange={(e) => {
              setFilter({
                ...filter,
                bFaultType: e.target.value,
              });
              getFacilityServices(null, null, null, null, null, null, e.target.value);
            }}
          >
            {faultTypes.length > 0 ? (
              faultTypes.map((faultType) => (
                <MenuItem key={faultType._id} value={faultType._id}>
                  {faultType.title}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No fault types available</MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ width: "100%", marginLeft: "10px" }}>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="bStatus"
            name="bStatus"
            value={filter?.bStatus}
            label="Status"
            onChange={(e) => {
              setFilter({
                ...filter,
                bStatus: e.target.value,
              });
              getFacilityServices(
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                e.target.value
              );
            }}
          >
            <MenuItem value={"Active"}>Active</MenuItem>
            <MenuItem value={"Inactive"}>Inactive</MenuItem>{" "}
            <MenuItem value={"Open"}>Open</MenuItem>
            <MenuItem value={"Assigned"}>Assigned</MenuItem>
            <MenuItem value={"Deferred"}>Deferred</MenuItem>
            <MenuItem value={"On Hold"}>On Hold</MenuItem>
            <MenuItem value={"Completed"}>Completed</MenuItem>
            <MenuItem value={"Duplicate"}>Duplicate</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <div style={{ marginTop: "50px" }}>
        <TableContainer
          component={Paper}
          sx={{ mx: "0.8rem", width: "100%", my: "6rem" }}
        >
          {/* <div style={{width: 'auto', overflowX: 'scroll'}}> */}

          <Table
            sx={{ minWidth: "auto" }}
            aria-label="custom pagination table"
            className="responsive-table"
          >
            <TableHead>
              <TableRow className="table-header">
                <TableCell align="left" component="th" sx={tableHeader}>
                  Request ID
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Date Logged
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Building Type
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Building Name
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Building Floor
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Space Type
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Space Id
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Priority Level
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Service Type
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Fault Type
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Issue Description
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Logged By
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Assignee
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Vendor
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  WO Number
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Status
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Action Taken
                </TableCell>

                <TableCell align="left" component="th" sx={tableHeader}>
                  Cost
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Date Closed
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...fsrList]
                ?.reverse()
                .slice(page * perPage, page * perPage + perPage)
                .map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align="left" sx={tableData}>
                        {item?.requestId}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.createdAt.split("T")[0]}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.buildingTypes?.title}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.buildingName?.title}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.buildingFloor?.title}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.buildingSpaceType?.title}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.buildingSpaceId?.areaId}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.priorityLevel}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.issueType?.title}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.faultType?.title}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.issueDescription}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.loggedBy}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.buildingVendor?.vendorName}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.assignee?.name}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.woNumber}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.status}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.actionTaken}
                      </TableCell>

                      <TableCell align="left" sx={tableData}>
                        {item?.cost}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.dateClosed}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={tableData}
                        style={{
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {(showBothButtons || canEdit) && (
                          <Button
                            size="small"
                            className="btn-div"
                            variant="outlined"
                            color="info"
                            onClick={(e) => editFSrType(e, item?._id)}
                          >
                            <EditIcon className="" />
                          </Button>
                        )}
                        {(showBothButtons || canDelete) && (
                          <Button
                            size="small"
                            className="btn-div"
                            variant="outlined"
                            color="error"
                            onClick={() => deleteBuildingType(item?._id)}
                          >
                            <DeleteIcon className="" />
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {!fsrList && <EmptyTable colSpan={9} />}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  align="right"
                  rowsPerPageOptions={perPages}
                  colSpan={9}
                  count={1}
                  rowsPerPage={perPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
          {/* </div> */}
        </TableContainer>
      </div>

      <div>
        <Modal open={modalOpen}>
          <Box sx={style}>
            <PageTitle title={"Add FSR"} />

            <Grid
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(1, 1fr)",
                gap: "10px",
              }}
            >
              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Building Type 1*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="buildingTypes"
                  value={inputs?.buildingTypes}
                  label="Select Building Type 1"
                  onChange={onChange}
                >
                  {buildingTypeList?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item?._id}>
                        {item?.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Building Name 2*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="buildingName"
                  value={inputs?.buildingName}
                  label="Select Building Name 2"
                  onChange={onChange}
                >
                  {buildingNameList
                    ?.filter(
                      (name) =>
                        name?.buildingTypes?._id === inputs?.buildingTypes
                    )
                    ?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?._id}>
                          {item?.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Building Floor 3*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="buildingFloor"
                  value={inputs?.buildingFloor}
                  label="Select Building Floor 3"
                  onChange={onChange}
                >
                  {buildingFloorList
                    ?.filter(
                      (floor) =>
                        floor?.buildingName?._id === inputs?.buildingName
                    )
                    ?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?._id}>
                          {item?.title}
                        </MenuItem>
                      );
                    })}
                  {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Building Space 4
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="buildingSpaceType"
                  value={inputs?.buildingSpaceType}
                  label="Select Building Space 4"
                  onChange={onChange}
                >
                  {buildingSpaceList
                    ?.filter(
                      (space) =>
                        space?.buildingFloor?._id === inputs?.buildingFloor
                    )
                    ?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?._id}>
                          {item?.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Space ID 5
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="areaId"
                  value={inputs?.areaId}
                  label="Space ID 5"
                  onChange={onChange}
                >
                  {buildingSpaceIdList
                    ?.filter(
                      (spaceId) =>
                        spaceId?.buildingSpaceType?._id ===
                        inputs?.buildingSpaceType
                    )
                    ?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?._id}>
                          {item?.areaId}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Priority Level 6
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="priorityLevel"
                  value={inputs?.priorityLevel}
                  label="Priority Level 6"
                  onChange={onChange}
                >
                  <MenuItem value="Emergency">Emergency</MenuItem>
                  <MenuItem value="Urgent">Urgent</MenuItem>
                  <MenuItem value="Priority 1 (24 Hours)">
                    Priority 1 (24 Hours)
                  </MenuItem>
                  <MenuItem value="Priority 2 (48 Hours)">
                    Priority 2 (48 Hours)
                  </MenuItem>
                  <MenuItem value="Priority 3 (72 Hours)">
                    Priority 3 (72 Hours)
                  </MenuItem>
                  <MenuItem value="Priority 4 (72+ Hours)">
                    Priority 3 (72+ Hours)
                  </MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Issue Type 7
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="issueType"
                  value={inputs?.issueType}
                  label="Priority Level 7"
                  onChange={onChange}
                >
                  {issueTypes.length > 0 ? (
                    issueTypes.map((issueType) => (
                      <MenuItem key={issueType._id} value={issueType._id}>
                        {issueType.title}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No issue types available</MenuItem>
                  )}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Fault Type 8
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="faultType"
                  value={inputs?.faultType}
                  label="Fault Type 8"
                  onChange={onChange}
                >
                  {faultTypes.length > 0 ? (
                    faultTypes.map((faultType) => (
                      <MenuItem key={faultType._id} value={faultType._id}>
                        {faultType.title}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No fault available</MenuItem>
                  )}
                </Select>
              </FormControl>

              <TextField
                label="Issue Description 9"
                value={inputs?.issueDescription}
                name="issueDescription"
                id="title"
                variant="outlined"
                style={{ width: "100%", marginLeft: "10px" }}
                onChange={onChange}
              />
            </Grid>
            <Grid
              style={{
                marginTop: "15px",
                width: "100%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "10px",
                marginLeft: "10px",
              }}
            >
              <Button
                variant="contained"
                onClick={onSubmit}
                style={{ float: "right" }}
                sx={{ height: 40 }}
              >
                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                Submit
              </Button>

              <Button
                onClick={() => {
                  setModalOpen(false);
                  // setEditMode(false)
                }}
                variant="outlined"
                style={{ float: "right" }}
                sx={{ height: 40 }}
              >
                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                Cancel
              </Button>
            </Grid>

            <div></div>
          </Box>
        </Modal>
      </div>

      <div>
        <Modal open={editModal}>
          <Box sx={style}>
            <PageTitle title={"Edit FSR"} />

            <Grid
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "10px",
              }}
            >
              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Building Type 1*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="buildingTypes"
                  value={buildingInputs?.buildingTypes}
                  label="Select Building Type 1"
                  onChange={editChange}
                >
                  {buildingTypeList?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item?._id}>
                        {item?.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Building Name 2*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="buildingName"
                  value={buildingInputs?.buildingName}
                  label="Select Building Name 2"
                  onChange={editChange}
                >
                  {buildingNameList
                    ?.filter(
                      (name) =>
                        name?.buildingTypes?._id ===
                        buildingInputs?.buildingTypes
                    )
                    ?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?._id}>
                          {item?.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Building Floor 3*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="buildingFloor"
                  value={buildingInputs?.buildingFloor}
                  label="Select Building Floor 3"
                  onChange={editChange}
                >
                  {buildingFloorList
                    ?.filter(
                      (floor) =>
                        floor?.buildingName?._id ===
                        buildingInputs?.buildingName
                    )
                    ?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?._id}>
                          {item?.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Building Space 4
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="buildingSpaceType"
                  value={buildingInputs?.buildingSpaceType}
                  label="Select Building Space 4"
                  onChange={editChange}
                >
                  {buildingSpaceList
                    ?.filter(
                      (space) =>
                        space?.buildingFloor?._id ===
                        buildingInputs?.buildingFloor
                    )
                    ?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?._id}>
                          {item?.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Space ID 5
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="buildingSpaceId"
                  value={buildingInputs?.buildingSpaceId}
                  label="Space ID 5"
                  onChange={editChange}
                >
                  {buildingSpaceIdList
                    ?.filter(
                      (spaceId) =>
                        spaceId?.buildingSpaceType?._id ===
                        buildingInputs?.buildingSpaceType
                    )
                    ?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?._id}>
                          {item?.areaId}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Priority Level 6
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="priorityLevel"
                  value={buildingInputs?.priorityLevel}
                  label="Priority Level 6"
                  onChange={editChange}
                >
                  <MenuItem value="Emergency">Emergency</MenuItem>
                  <MenuItem value="Urgent">Urgent</MenuItem>
                  <MenuItem value="Priority 1 (24 Hours)">
                    Priority 1 (24 Hours)
                  </MenuItem>
                  <MenuItem value="Priority 2 (48 Hours)">
                    Priority 2 (48 Hours)
                  </MenuItem>
                  <MenuItem value="Priority 3 (72 Hours)">
                    Priority 3 (72 Hours)
                  </MenuItem>
                  <MenuItem value="Priority 4 (72+ Hours)">
                    Priority 3 (72+ Hours)
                  </MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Issue Type 7
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="issueType"
                  value={buildingInputs?.issueType}
                  onOpen={fetchIssueTypes} // Fetch issue types when dropdown is opened
                  label="Priority Level 7"
                  onChange={editChange}
                >
                  {issueTypes.length > 0 ? (
                    issueTypes.map((issueType) => (
                      <MenuItem key={issueType._id} value={issueType._id}>
                        {issueType.title}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No issue types available</MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Fault Type 8
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="faultType"
                  value={buildingInputs?.faultType}
                  label="Fault Type 8"
                  onChange={editChange}
                >
                  {faultTypes.length > 0 ? (
                    faultTypes.map((faultType) => (
                      <MenuItem key={faultType._id} value={faultType._id}>
                        {faultType.title}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No fault available</MenuItem>
                  )}
                </Select>
              </FormControl>

              <TextField
                label="Issue Description 9"
                value={buildingInputs?.issueDescription}
                name="issueDescription"
                id="title"
                variant="outlined"
                style={{ width: "100%", marginLeft: "10px" }}
                onChange={editChange}
              />

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Assignee 10
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="assignee"
                  value={buildingInputs?.assignee}
                  label="Assignee 10"
                  onChange={editChange}
                >
                  {assignee
                    ?.filter(
                      (name) =>
                        name?.buildingName?._id === buildingInputs?.buildingName
                    )
                    ?.map((assignee, index) => (
                      <MenuItem key={index} value={assignee._id}>
                        {assignee.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">Vendor 11</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="buildingVendor"
                  value={buildingInputs?.buildingVendor}
                  label="Vendor 11"
                  onChange={editChange}
                >
                  {vendor
                    ?.filter(
                      (name) =>
                        name?.buildingName?._id === buildingInputs?.buildingName
                    )
                    ?.map((vendor, index) => (
                      <MenuItem key={index} value={vendor._id}>
                        {vendor.vendorName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <TextField
                label="WO Number 12"
                value={buildingInputs?.woNumber}
                name="woNumber"
                id="title"
                variant="outlined"
                style={{ width: "100%", marginLeft: "10px" }}
                onChange={editChange}
              />
              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">Status 13</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={buildingInputs?.status}
                  label="Status 13"
                  onChange={editChange}
                >
                  <MenuItem value={"Open"}>Open</MenuItem>
                  <MenuItem value={"Assigned"}>Assigned</MenuItem>
                  <MenuItem value={"Deferred"}>Deferred</MenuItem>
                  <MenuItem value={"On Hold"}>On Hold</MenuItem>
                  <MenuItem value={"Completed"}>Completed</MenuItem>
                  <MenuItem value={"Duplicate"}>Duplicate</MenuItem>
                </Select>
              </FormControl>

              <TextField
                label="Action Taken 14"
                value={buildingInputs?.actionTaken}
                name="actionTaken"
                id="title"
                variant="outlined"
                style={{ width: "100%", marginLeft: "10px" }}
                onChange={editChange}
              />

              <TextField
                label="Cost 15"
                value={buildingInputs?.cost}
                name="cost"
                type="number"
                id="title"
                variant="outlined"
                style={{ width: "100%", marginLeft: "10px" }}
                onChange={editChange}
              />
              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">Asset Id 16</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="buildingVendor"
                  value={buildingInputs?.buildingVendor}
                  label="Asset Id 16"
                  onChange={editChange}
                >
                  {buildingAssetIdList
                    ?.filter(
                      (name) =>
                        name?.buildingFloor?._id === buildingInputs?.buildingFloor
                    )
                    ?.map((asset, index) => (
                      <MenuItem key={index} value={asset._id}>
                        {asset.assetId}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>


              {/* <InputLabel fullWidth style={{ marginLeft: "14px" }}>16- Job Attachment</InputLabel> */}
              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <button type="button" onClick={handleClick}>
                  {fileName}
                </button>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  accept=".pdf, image/*"
                />
              </FormControl>
            </Grid>

            <Grid
              style={{
                marginTop: "15px",
                width: "100%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "10px",
                marginLeft: "10px",
              }}
            >
              <Button
                variant="contained"
                onClick={onSubmit}
                style={{ float: "right" }}
                sx={{ height: 40 }}
              >
                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                Update
              </Button>

              <Button
                onClick={() => {
                  setEditModal(false);
                  // setEditMode(false)
                }}
                variant="outlined"
                style={{ float: "right" }}
                sx={{ height: 40 }}
              >
                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "#75859D" }}
                sx={{ height: 40, mx: 2 }}
                onClick={() => getExportPdf()}
              >
                Export PDF
              </Button>
              {buildingInputs.jobAttachment && (
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#c8f0b1" }}
                  sx={{ height: 40, mx: 2 }}
                >
                  <a
                    href={buildingInputs.jobAttachment}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Attachment
                  </a>
                </Button>
              )}
            </Grid>

            <div></div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default FSR;
