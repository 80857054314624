/* eslint-disable no-unused-vars */

import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
  Grid,
  FormControl,
  Skeleton,
  Button,
  Alert
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BasicSelector from "../../common/Selector";
import TableFooter from "@mui/material/TableFooter";
import LocalDateSelector from "../../common/LocalDateSelector";
import PageTitle from "../../common/PageTitle";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader"
import { deleteAPI, getAPI, getExportPdfAPI } from "../../network";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CompanyContext } from "../../../context";
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EmptyTable from "../../common/EmptyTable";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthority, tableHeader, tableData, formatDate, timeFormat, formatDatePost } from "../../utils";
import "./style.css";
import { FilterList } from "@mui/icons-material";
import { getCompanies } from "../../../features/company/companyAPI";
import { axiosInstance } from "../../../utils/axiosSetup";



export default function TaskManagement() {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  // const [companyId] = useContext(CompanyContext)
  let companyId = localStorage.getItem('companyIdLS')
  if (companyId === null) {
    companyId = undefined
  }
  const [deleteId, setDeleteId] = useState('');
  const [open, setOpen] = useState(false)
  const [loader, setLoader] = useState(false)
  const [tasks, setTasks] = useState([])
  const [sites, setSites] = useState([])
  const [companies, setCompanies] = useState([])

  const [page, setPage] = useState(0);
  const [perPages, setPerPages] = useState([5, 10, 25, 50]);
  const [perPage, setPerPage] = useState(10)
  const [taskCounting, setTaskCounting] = useState({})


  const [permissionList, setPermissionList] = useState([]);

  // Load the permission list from localStorage
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permisionlist")) || [];
    setPermissionList(permissions);
  }, []);

  const actionPermissions = permissionList[0]?.actionPermissions || [];

  // Check if 'Delete' and 'Edit' subActions exist
  const canAdd = actionPermissions.some(
    (permission) => permission.action === "Add"
  );
 

  // If actionPermissions is empty, show both buttons
  const showBothButtons = actionPermissions.length === 0;


  const [filter, setFilter] = useState({
    companyId: '',
    siteId: '',
    startDate: null,
    endDate: null
  })
  const taskData = []
  for (let j = 0; j < tasks.length; j++) {
    taskData.push(tasks[j].tasks)
  }


  const taskD = []
  for (let i = 0; i < taskData.length; i++) {
    taskD.push(taskData[i][0])
  }

  useEffect(() => {
    taskCount()
    getTasks(companyId)
    getSites(companyId)
    getCompanies()
  }, []);

  const userType = localStorage.getItem('userType')


  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value)
    setPerPage(event.target.value)
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };  


  // {console.log( checkAuthority('ADD_TASK') ? 'TRUE ---------' : "FALSE --------")}
  const getTasks = async (cId = null, sId = null, sDate = null, eDate = null) => {

    cId = cId ? cId : filter.companyId;
    sId = sId !== null ? sId : filter.siteId;
    eDate = eDate !== null ? eDate : filter.endDate;
    sDate = sDate !== null ? sDate : filter.startDate;

    var url = ''
    var start = true;
    if (cId !== '' && cId !== null) {
      url += (start ? '?' : '&') + `companyId=${cId}`
      start = false;
    }
    if (sId !== '' && sId !== null) {
      url += (start ? '?' : '&') + `siteId=${sId}`
      start = false;
    }
    if (eDate !== '' && eDate !== null) {
      url += (start ? '?' : '&') + `endDate=${formatDatePost(eDate)}`
      start = false;
    }
    if (sDate !== '' && sDate !== null) {
      url += (start ? '?' : '&') + `startDate=${formatDatePost(sDate)}`
      start = false;
    }
    setLoader(true)

    let data = await getAPI('/visitor-tasks' + url)
    if (data) {
      setTasks(data)
    }
    setLoader(false)
  }

  const getSites = async (cId) => {
    setLoader(true)
    if (cId) {
      let data = await getAPI(`/sites?companyId=${cId}`)
      if (data) {
        var arr = []
        for (var i = 0; i < data.length; i++) {
          var obj = data[i]
          obj['label'] = obj.name
          obj['value'] = obj._id
          arr.push(obj)
        }
        setSites(arr)
      }
    } else {
      let data = await getAPI('/sites')
      if (data) {
        var arr = []
        for (var i = 0; i < data.length; i++) {
          var obj = data[i]
          obj['label'] = obj.name
          obj['value'] = obj._id
          arr.push(obj)
        }
        setSites(arr)
      }
    }
    setLoader(false)
  }

  const getCompanies = async () => {
    setLoader(true)
    const data = await getAPI('/companies');
    if (data) {
      var arr = []
      for (var i = 0; i < data.length; i++) {
        var obj = data[i]
        obj['label'] = obj.name
        obj['value'] = obj.id
        arr.push(obj)
      }
      setCompanies(arr)
    }
    setLoader(false)
  }


  const deleteClick = (id) => {
    setDeleteId(id);
    setOpen(true)
  }

  const handleDelete = async (siteId = null) => {

    setLoader(true);
    let empty = await deleteAPI(`/tasks/${deleteId}`);
    if (empty) {
      setOpen(false);
      getTasks()

    }
    setLoader(false);
  }
  const getPdf = async (id) => {

    setLoader(true);
    let data = await getExportPdfAPI(`/export/file/visitor-tasks?taskId=${id}`);

    setLoader(false);
  }
  const getAllPdf = async (id) => {

    setLoader(true);
    let data = await getExportPdfAPI(`/export/file/visitor-tasks`);

    setLoader(false);
  }
  const taskCount = async () => {
    setLoader(true);
    const data = await axiosInstance.get('/visitor-tasks/count');
    if (data) {

      setTaskCounting(data.data.count[0])
    }
    setLoader(false);

  }
  return (
    <Box>
      <Loader loader={loader} />
      <div>
        <PageTitle title="Visitor Task Management" />
      </div>

      <Box sx={{ marginLeft: "20px" }}>
        <FormControl
          sx={{
            width: "100%",
          }}
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ width: "100%" }}
          >
            <div><b>Current Date Task Overview</b></div>


          </Box>
          <Grid sx={{ my: "1rem", width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px 0px" }} spacing={3}>
            <Grid sx={{ width: '22%', height: '80px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '10px !important', m: '10', fontWeight: '500', borderRadius: '5px !important', boxShadow: '0px 2px 4px 3px rgba(0, 0, 0, 0.08)', backgroundColor: "green", color: "white" }}>
              <PeopleAltIcon /> {taskCounting?.totalTasks ? taskCounting?.totalTasks : 0} Total Tasks Breakdown
            </Grid>
            <Grid sx={{ width: '22%', height: '80px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '10px !important', m: '10', fontWeight: '500', borderRadius: '5px !important', boxShadow: '0px 2px 4px 3px rgba(0, 0, 0, 0.08)', backgroundColor: "lightgreen" }}>
              <PeopleAltIcon /> {taskCounting?.completedTasks ? taskCounting?.completedTasks : 0} Tasks Completed
            </Grid>
            <Grid sx={{ width: '22%', height: '80px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '10px !important', m: '10', fontWeight: '500', borderRadius: '5px !important', boxShadow: '0px 2px 4px 3px rgba(0, 0, 0, 0.08)', backgroundColor: "orange" }}>
              <PeopleAltIcon /> {taskCounting?.reportableIssues ? taskCounting?.reportableIssues : 0} Reportable Issues
            </Grid>
            <Grid sx={{ width: '22%', height: '80px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '10px !important', m: '10', fontWeight: '500', borderRadius: '5px !important', boxShadow: '0px 2px 4px 3px rgba(0, 0, 0, 0.08)', backgroundColor: "red", color: 'white' }}>
              <PeopleAltIcon /> {taskCounting?.injuriesSuffered ? taskCounting?.injuriesSuffered : 0} Injuries Reported
            </Grid>
          </Grid>

          {/* 
          <Grid sx={{ my: "1rem", justifyContent: 'space-between !important', alignItems: 'center' }} container spacing={3} width={"100%"}>
            <Grid>
              <b>Task Completed</b>
            </Grid>
            {
              checkAuthority('ADD_TASK') && userType == 'company' ?

                <Grid item xs={6} md={6} lg={4} sx={{ my: 2 }}>
                  <Link
                    to="/company/tasks/create"
                    style={{
                      backgroundColor: "grey",
                      color: "white",
                      padding: 15,
                      fontWeight: 600,
                      borderRadius: 5,
                      textDecoration: "none",
                    }}
                  >
                    Create Task
                  </Link>
                </Grid> :
                <Grid item xs={6} md={6} lg={4} sx={{ my: 2 }}>
                  <Link
                    to="/tasks/create"
                    style={{
                      backgroundColor: "grey",
                      color: "white",
                      padding: 15,
                      fontWeight: 600,
                      borderRadius: 5,
                      textDecoration: "none",
                    }}
                  >
                    Add Task
                  </Link>
                </Grid>
            }

          </Grid> */}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%", mt: '1rem' }}
          >
            <div><b>Task Completed</b></div>

            <div style={{ display: "flex", gap: "10px", padding: "5px" }}>
            {(showBothButtons || canAdd) && (
              <Button variant="contained" style={{ backgroundColor: "#75859D" }} sx={{ height: 50 }} onClick={() => navigateTo("/visitor_task")}>
                <AddCircleIcon /> &nbsp; &nbsp;
                Add Task
              </Button>
            )}
              <Button variant="contained" style={{ backgroundColor: "#75859D" }} sx={{ height: 50 }} onClick={() => getAllPdf()}>
                <GetAppRoundedIcon /> &nbsp; &nbsp;
                Export Report
              </Button>
            </div>
          </Box>


          <Grid sx={{ my: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", gap: "30px" }} spacing={3} width={"100%"}>


            {
              checkAuthority('VIEW_TASKS') &&
              <>

                <Grid item xs={6} md={6} lg={2} sx={{ width: "100%" }}>

                  <BasicSelector
                    disableAll={true}
                    options={sites}
                    selectorHight={"62px"}
                    value={filter.siteId}
                    name={"Select Site"}
                    selectorWidth={"100%"}
                    onChange={(e) => {

                      setFilter({
                        ...filter,
                        siteId: e.target.value
                      })
                      getTasks(null, e.target.value)
                    }}
                  />
                </Grid>

                <Grid item xs={6} md={6} lg={4} sx={{ width: "100%" }}>
                  <LocalDateSelector
                    title="Start Date "
                    value={filter.startDate}

                    onChange={(date) => {
                      setFilter({
                        ...filter,
                        startDate: date
                      })
                      getTasks(null, null, date, null)
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={4} sx={{ width: "100%" }}>
                  <LocalDateSelector
                    title="End Date "
                    value={filter.endDate}

                    onChange={(date) => {
                      setFilter({
                        ...filter,
                        endDate: date
                      })
                      getTasks(null, null, null, date)
                    }}
                  />
                </Grid>
                {/* <Grid item xs={6} md={6} lg={2}>

                  <BasicSelector
                    disableAll={true}
                    options={companies}
                    selectorHight={"53px"}
                    value={filter.email}
                    name={"Email"}
                    selectorWidth={"100%"}
                    onChange={(e) => {

                      setFilter({
                        ...filter,
                        email: e.target.value
                      })
                      getTasks(e.target.value)
                    }}
                  />
                </Grid> */}

              </>
            }

          </Grid>
        </FormControl>
        {
          checkAuthority('VIEW_TASKS') &&
          <Box display="flex" sx={{ my: "3rem" }}>
            <TableContainer component={Paper}>

              <Table
                sx={{ minWidth: "auto" }}
                aria-label="custom pagination table"
                className="responsive-table"
              >
                <TableHead>
                  <TableRow className="table-header">
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Visitor Email
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Title
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Description
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Site
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Start Date
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      End Date
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Due Time
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      View Details                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Export
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>


                  {taskD.slice(page * perPage, page * perPage + perPage).map((task, index) => (
                    <TableRow key={index}>
                      <TableCell align="left" sx={tableData}>
                        {task?.assignedUser?.email}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.title}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.description}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.siteId?.name}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {new Date(task?.startDate).toLocaleDateString("en-uk", {
                          year: "numeric",
                          month: "short",
                          day: "2-digit",
                        })}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {new Date(task?.endDate).toLocaleDateString("en-uk", {
                          year: "numeric",
                          month: "short",
                          day: "2-digit",
                        })}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.timeDue}
                      </TableCell>
                      <TableCell align="center" sx={{ textAlign: 'center', tableData }}>
                        {
                          checkAuthority('VIEW_TASKS') && userType == 'admin' ?
                            <Button className="btn-div" variant="outlined" color="info" sx={{ mx: 1 }}
                              onClick={(e) => {
                                navigateTo('/visitor-tasks/details', { state: task.groupId })
                              }}>
                              View
                            </Button> :
                            <Button className="btn-div" variant="outlined" color="info" sx={{ mx: 1 }}
                              onClick={(e) => {
                                navigateTo('/company/visitor-tasks/details', { state: task.groupId })
                              }}>
                              View
                            </Button>
                        }
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        <Button onClick={() => getPdf(task.id ? task.id : task._id)}>Open File</Button>
                      </TableCell>

                    </TableRow>

                  ))}

                  {
                    tasks.length === 0 &&
                    <EmptyTable colSpan={6} />
                  }
                </TableBody>
                <TableFooter>
                  <TableRow >

                    <TablePagination
                      align="right"
                      rowsPerPageOptions={perPages}
                      colSpan={7}
                      count={taskD.length}
                      rowsPerPage={perPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    // ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
        }

      </Box>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true}>
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>Delete Task</DialogTitle>

        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { my: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >

            <h3 style={{ textAlign: 'left', fontWeight: 'bold' }}>Do you want's to delete this task</h3>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={handleDelete} variant="contained" color="error">Delete</Button>
          <Button onClick={() => setOpen(false)} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
