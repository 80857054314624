import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, FormControl, Select, MenuItem, TextField } from "@mui/material";
import PageTitle from "../../common/PageTitle";
import Dropdown from "../../common/Dropdown";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MapContainer, TileLayer, Marker, Popup, useMap, Polyline } from 'react-leaflet';
import { getAPI } from "../../network";
import L from 'leaflet';
import { CompanyContext } from "../../../context";
import 'leaflet/dist/leaflet.css';
import siteImage from './site.png';
import userImage from './user.png';

// let DefaultIcon = L.icon({
//   iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-icon.png',
//   shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-shadow.png'
// });
// L.Marker.prototype.options.icon = DefaultIcon;

const userIcon = new L.Icon({
  iconUrl: userImage,
  iconSize: [40, 80], // size of the icon
  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-shadow.png',
  shadowSize: [41, 41]
});
const siteIcon = new L.Icon({
  iconUrl: siteImage,
  iconSize: [40, 80], // size of the icon
  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-shadow.png',
  shadowSize: [41, 41]
});

function UpdateMapCenter({ center, zoom }) {
  const map = useMap();
  useEffect(() => {
    map.setView(center, zoom);
  }, [center, zoom, map]);
  return null;
}

export default function TrackerPage() {
  const mapRef = useRef(null);

  const [center, setCenter] = useState([23.850000, 89.949997]); // Default center coordinates
  const [zoom, setZoom] = useState(2); // Default zoom level
  const [markers, setMarkers] = useState(); // Custom markers
  const [sites, setSites] = useState([]);
  const [resources, setResources] = useState([]);
  const [users, setUsers] = useState([]);
  const [userMarker, setUserMarker] = useState(null); // User marker
  let companyId = localStorage.getItem('companyIdLS');
  if (companyId === null) {
    companyId = undefined;
  }

  const [selectedSite, setSelectedSite] = useState('');
  const [selectedGuard, setSelectedGuard] = useState('');
  const [activeToggleButton, setActiveToggleButton] = useState('live');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const changeSite = (event) => {
    const siteId = event.target.value;
    setSelectedSite(siteId);

    let siteData = sites.find(site => site._id === siteId);

    if (siteData) {
      let locate = {
        coordinates: [siteData.latitude, siteData.longitude],
        name: siteData.name,
        email: siteData.companyId.email
      };
      setMarkers(locate);
      setCenter(locate.coordinates);
      setZoom(25); // Set zoom to 25 when a site is selected
      setUserMarker(null); // Reset user marker when a new site is selected
      setSelectedGuard("")
    } else {
      setCenter([23.850000, 89.949997]); // Reset to default center if no site is selected
      setZoom(2); // Reset zoom to default level
      setUserMarker(null); // Reset user marker
      setSelectedGuard(null); // Reset user marker
    }
    usersLists(event.target.value); // Fetch user data

  };

  const changeGuard = (event) => {
    const userId = event.target.value;
    setSelectedGuard(userId);

    console.log(userId)
    let userData = users.find(user => user._id === userId);
    console.log(userData)
    if (userData) {
      let locate = {
        coordinates: [userData.latitude, userData.longitude],
        name: `${userData.firstname} ${userData.lastname}`,
        email: userData.email
      };
      setUserMarker(locate);
      setZoom(16);
    } else {
      setUserMarker(null); // Reset user marker if no user is selected
      setSelectedGuard(null); // Reset user marker if no user is selected
    }
  };

  const sitesLists = async () => {
    if (companyId) {
      let res = await getAPI(`/sites?companyId=${companyId}`);
      if (res) {
        setSites(res);
      }
    } else {
      let res = await getAPI(`/sites`);
      if (res) {
        setSites(res);
      }
    }
  };


  const usersLists = async (sId) => {
    let res = await getAPI(`/trackers?siteId=${sId ? sId : selectedSite}`);
    if (res) {
      setUsers(res);
    }
    console.log(res)
  };

  useEffect(() => {
    sitesLists();
  }, [companyId]);


  return (
    <Box sx={{ height: "inherit" }}>
      <PageTitle title="GPS Trackers" />
      <Grid container className="sort-box" sx={{ mx: "0rem", mt: "5rem", pr: "2rem" }}>
        <Grid item xs={3}>
          <Dropdown label={'Select Site'} data={sites} selectedItem={selectedSite} changeItem={changeSite} />
        </Grid>
        <Grid item xs={3}>
          <Dropdown label={'Select Resource'} data={users} selectedItem={selectedGuard} changeItem={changeGuard} />
        </Grid>

      </Grid>
      <Box>
        <MapContainer center={center} zoom={zoom} style={{ height: '80vh', width: '100%' }} ref={mapRef}>
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          />
          {selectedSite && markers && (
            <>
              <UpdateMapCenter center={markers.coordinates} zoom={zoom} />
              <Marker position={markers.coordinates} icon={siteIcon}>
                <Popup>
                  {markers.name}
                  <br />
                  {markers.email}
                </Popup>
              </Marker>
            </>
          )}
          {userMarker && (
            <>
              <Marker position={userMarker.coordinates} icon={userIcon}>
                <Popup>
                  {userMarker.name}
                  <br />
                  {userMarker.email}
                </Popup>
              </Marker>
              <Polyline positions={[markers.coordinates, userMarker.coordinates]} color="blue" />
            </>
          )}
        </MapContainer>
      </Box>
      <div style={{ height: 50 }}></div>
    </Box>
  );
}
