import React, { useEffect, useState, useContext } from "react";
import PageTitle from "../../common/PageTitle";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { getAPI, postAPI } from "../../network";

import TextField from '@mui/material/TextField';


import "./style.css";
import { timeFormat, formatDatePost, validation } from "../../utils";
import Loader from "../../common/Loader";
import { MenuItem, FormControl, Box, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";




export default function CompanyAllUsers() {
  // const [companyId] = useContext(CompanyContext)
  let companyId = localStorage.getItem('companyIdLS')
  if (companyId === null) {
    companyId = undefined
  }

  const [action, setAction] = useState('add');

  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);

  const [sites, setSites] = useState([])

  const navigate = useNavigate()


  const [person, setPerson] = useState({
    title: '',
    user: '',
    company: '',
    status: '',
    responseTime: null,
    site: '',
    description: '',
    dueDate: null,
    startTime: null,
    endTime: null,
    UserError: false,
    companyError: false,
    descriptionError: false,
    userError: false,
    startTimeError: false,

    dueDateError: false,
    dueTimeError: false,
    responseTimeError: false
  })


  useEffect(() => {
    getSites();
    UserLists()
  }, []);



  const UserLists = async () => {
    let process = await getAPI(`/admin/guards?companyId=${companyId}`);
    let outputs = process.map((item) => ({
      id: item._id,
      label: item.firstname + ' ' + item.lastname,
      value: item._id
    }))
    setUsers(outputs)
  }

  const getSites = async () => {
    setLoader()
    let data = await getAPI(`/sites?companyId=${companyId}`)
    if (data) {
      let outputs = data.map((item) => ({
        id: item._id,
        label: item.name,
        value: item._id
      }))
      setSites(outputs)
    }
    setLoader()
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPerson(prevState => ({
      ...prevState,
      userError: false,
      companyError: false,
      dueDateError: false,
      dueTimeError: false,
      sitesError: false
    }))


    if (validation('empty', 'Title', person.title)) {
      setPerson(prevState => ({
        ...prevState,
        titleError: true,
      }))
      return;
    }
    else if (validation('empty', 'user', person.user)) {
      setPerson(prevState => ({
        ...prevState,
        userError: true,
      }))
      return;
    }

    else if (validation('empty', 'Duedate', person.dueDate)) {
      setPerson(prevState => ({
        ...prevState,
        dueDateError: true,
      }))
      return;
    }
    else if (validation('empty', 'StartTime', person.startTime)) {
      setPerson(prevState => ({
        ...prevState,
        dueTimeError: true,
      }))
      return;
    }
    else if (validation('empty', 'EndTime', person.endTime)) {
      setPerson(prevState => ({
        ...prevState,
        dueTimeError: true,
      }))
      return;
    }

    else if (validation('empty', 'Site', person.site)) {
      setPerson(prevState => ({
        ...prevState,
        siteError: true,
      }))
      return;
    }


    // console.log("person*******",person)
    // var ids = person.sites.map((item) => {
    //   return item.value
    // });

    let formData = new FormData();
    formData.append('title', person.title);
    formData.append('user', person.user);
    formData.append('company', companyId);
    formData.append('status', person.status);
    formData.append('dueDate', formatDatePost(person.dueDate));
    formData.append('startTime', timeFormat(person.startTime));
    formData.append('endTime', timeFormat(person.endTime));
    formData.append('responseTime', timeFormat(person.responseTime));
    formData.append('description', person.description);
    formData.append(`site`, person.site)
    // for (let i = 0; i < ids.length; i++) {
    //   formData.append(`sites[${i}]`, ids[i])
    // }

    if (action === 'add') {
      setLoader(true)
      let data = await postAPI('/alarm', formData)
      if (data) {
        navigate('/scheduler/view-alarm-response')
      }
      setLoader(false)
    }

  }


  return (
    <Box>
      <Loader loader={loader} />
      <PageTitle title="Secuber" subTitle="Create Alarm Response" />

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="start"
        alignItems="start"
        height="100vh"
        width="90%"
        gap="20px"
        marginLeft="20px"
      >
        <FormControl style={{ width: "30%" }}>
          <TextField
            id="title"
            label="Title"
            variant="outlined"
            type="text"
            style={{ width: "100%" }}
            value={person.title}
            error={person.titleError}
            onChange={(data) => {
              setPerson(prevState => ({
                ...prevState,
                title: data.target.value,
              }))
            }}
            fullWidth
            sx={{ m: 0 }}
          />
        </FormControl>

        <TextField
          select
          label="User"
          value={person.user}
          style={{ width: "30%" }}
          onChange={(data) => {
            setPerson(prevState => ({
              ...prevState,
              user: data.target.value
            }))
          }}
          variant="outlined"
          fullWidth
        >
          {users.map((user) => (
            <MenuItem key={user.id} value={user.value}>
              {user.label}
            </MenuItem>
          ))}
        </TextField>

        <div style={{ width: "30%", display: "flex", justifyContent: "start", alignItems: "center", gap: "10px" }}>
          <FormControl style={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                className="modal-component"
                label="Due Date"
                style={{ width: "100%" }}
                inputFormat="dd/MM/yyyy"
                value={person.dueDate}
                error={person.dueDateError}
                onChange={(data) => {
                  setPerson(prevState => ({
                    ...prevState,
                    dueDate: data,
                  }))
                }}
                renderInput={(params) => <TextField {...params} />}
                // error={startDateError}
                fullWidth
              />
            </LocalizationProvider>
          </FormControl>
        </div>

        <div className="" style={{ width: "30%", display: "flex", justifyContent: "start", alignItems: "start", gap: "10px" }}>


          <FormControl style={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                label="Start Time"
                value={person.startTime}
                error={person.startTimeError}
                style={{ width: "100%" }}
                onChange={(data) => {
                  setPerson(prevState => ({
                    ...prevState,
                    startTime: data,
                  }))
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

          </FormControl>
          <FormControl style={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                label="End Time"
                style={{ width: "100%" }}
                value={person.endTime}
                error={person.dueTimeError}
                onChange={(data) => {
                  setPerson(prevState => ({
                    ...prevState,
                    endTime: data,
                  }))
                }}
                renderInput={(params) => <TextField {...params} />}
              />

            </LocalizationProvider>
          </FormControl>
        </div>

        <FormControl style={{ width: "30%" }}>
          <TextField
            id="description"
            label="Description"
            variant="outlined"
            type="text"
            style={{ width: "100%" }}
            multiline
            error={person.descriptionError}
            value={person.description}
            onChange={(data) => {
              setPerson(prevState => ({
                ...prevState,
                description: data.target.value,
              }))
            }}
            fullWidth
          />
        </FormControl>

        <TextField
          select
          label="Site"
          value={person.site}
          style={{ width: "30%" }}
          onChange={(data) => {
            setPerson(prevState => ({
              ...prevState,
              site: data.target.value
            }))
          }}
          variant="outlined"
          fullWidth
        >
          {sites.map((site) => (
            <MenuItem key={site.id} value={site.value}>
              {site.label}
            </MenuItem>
          ))}
        </TextField>

        <div style={{ width: "100%" }}><Button style={{ float: "start" }} onClick={handleSubmit} variant="contained">{action === 'add' ? 'Submit' : 'Update'}</Button></div>
      </Box>

    </Box>
  );
}
