import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Select, MenuItem, TextField, InputLabel, FormControl, InputAdornment, Box, Typography } from '@material-ui/core';
import { BASE_URL } from '../../../../utils/axiosSetup';
import PageTitle from '../../../common/PageTitle';
import './dashboard.css';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [issueTypes, setIssueTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const authToken = localStorage.getItem('token');
  const navigate = useNavigate();
  let companyId = localStorage.getItem('companyIdLS')
  if (companyId === null) {
    companyId = undefined
  }
  const filters = {
    compnayId: companyId,
    // status: 'Deferred',
    fromDate,
    toDate,
    status: selectedStatus
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${BASE_URL}/building/issuetype`, {
          params: filters,
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        });

        if (response.data.code === 200) {
          setIssueTypes(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching issue types:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [fromDate, toDate, selectedStatus]);

  const handleIssueClick = (issueType) => {
    navigate(`/fsr/facilities`, { state: issueType });
  };
  return (
    <div>
      <PageTitle title="Dashboard" />

      {/* Filter UI */}
      <div style={{ marginBottom: '20px' }}>
        <FormControl variant="outlined" style={{ marginRight: '20px', width: '250px' }}>
          <InputLabel id="bType-label">Status </InputLabel>
          <Select
            labelId="bType-label"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            label="Issue Type"
            MenuProps={{ disableScrollLock: true }}
          >
            <MenuItem value="">Select Status</MenuItem>
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Open">Open</MenuItem>
            <MenuItem value="Assigned">Assigned</MenuItem>
            <MenuItem value="Deferred">Deferred</MenuItem>
            <MenuItem value="On Hold">On Hold</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="Duplicate">Duplicate</MenuItem>

          </Select>
        </FormControl>

        {/* Date Pickers */}
        <TextField
          type="date"
          label="From Date"
          variant="outlined"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ marginRight: '20px' }}
        />
        <TextField
          type="date"
          label="To Date"
          variant="outlined"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div class="fsr-container">
        <div class="fsr-d-flex">
          {loading ? (
            <div>Loading...</div>
          ) : (
            issueTypes.map((issue) => (

              //   <div
              //   className="fsr-dashboard-box col"
              //   style={{ cursor: 'pointer' }}
              //   onClick={() => handleIssueClick(issue.issueType)} 
              //   bis_skin_checked="1"
              // >
              //   <h5>{issue.issueType}</h5>
              //   <h4>{issue.count}</h4>
              // </div>     
              <Grid item xs={12} md={4} lg={4} px={2} key={issue.issueType}>
                <Grid container >
                  <Grid item xs={12}>
                    <Box

                      onClick={() => handleIssueClick(issue?.issueType?._id)}
                      sx={{
                        px: 4,
                        py: 4,
                        display: "flex",
                        // boxShadow: "0px 2px 4px 3px rgba(0, 0, 0, 0.08)",
                        boxShadow: " 0 3px 6px rgb(0 0 0 / 0%), 0 3px 6px rgb(0 0 0 / 16%) !important",
                        borderRadius: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        color: "#202E43",
                        backgroundColor: "#DAEEEF",
                        rowGap: "25px",
                        cursor: "pointer ",
                        "&:hover": {
                          backgroundColor: "#2A4556",
                          color: "white",
                          fontWeight: "medium",
                        },
                        height: "auto",
                        width: "300px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          textTransform: "capitalize",
                          lineHeight: "1.5rem",
                          fontWeight: "500",
                          fontSize: "1.1rem",
                        }}
                      >
                        {issue?.issueType?.title}
                      </Typography>

                      <Typography
                        variant="h4"
                        sx={{
                          textTransform: "capitalize",
                          fontWeight: "600",
                          lineHeight: "10px",
                          fontSize: "1.7rem",

                        }}
                      >
                        {issue.count}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
