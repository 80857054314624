import React from 'react'
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import { Button } from '@material-ui/core';


import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
// import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';

const ViewSchedule = () => {
    return (
        <div>
            <div>
                <h1>View Maintainance Schedule</h1>
            </div>
            <div>
                <div className='export-button' >
                    <div style={{ display: "flex", gap: "10px" }}>
                        <p className='titles'>Site</p>
                        <p className='titles'>Work Type</p>
                        <p className='titles'>Supplier Name</p>
                        <p className='titles'>WO Status</p>
                        <p className='titles'>Work Permit Status</p>
                        <p className='titles'>Quotations Status</p>
                        <p className='titles'>Service Categ 1</p>
                        <p className='titles'>Service Categ 2</p>
                        <p className='titles'>Service Categ 3</p>
                        <p className='titles'>Selected Date Range</p>
                    </div>
                    <Button variant="contained" style={{ backgroundColor: "grey" }} sx={{ height: 30, m: 2 }}>
                        <GetAppRoundedIcon /> &nbsp; &nbsp;
                        Export
                    </Button>
                </div>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {/* <DemoContainer components={['DateRangeCalendar']}> */}
                            <DateCalendar/>
                        {/* </DemoContainer> */}
                    </LocalizationProvider>
                </div>
            </div>
        </div>
    )
}

export default ViewSchedule