import React, { useEffect, useState } from "react";
import PageTitle from "../../common/PageTitle";
import { checkAuthority } from "../../utils";
import SiteDetailCard from "../../components/sites/DetailCard";
import "./Fsr.css";

const Fsr = () => {
  const [fsrList, setFsrList] = useState([]);
  const segments = window.location?.pathname;

  // Fetch and parse permissionList from localStorage
  const permissionList =
    JSON.parse(localStorage.getItem("permisionlist")) || [];
  // console.log("permissionList:", permissionList);

  // Check if permissionList has submenuPermissions in the first item
  const submenuPermissions = permissionList[0]?.submenuPermissions || [];
  // console.log("submenuPermissions:", submenuPermissions);
  //   console.log("fsr submenupermissions" + submenuPermissions.length);
  const getManagementDetails = () => {
    let arr = [];
    let url = segments?.split("/")[1] === "company" ? "/company" : "";

    // Function to check if a submenu is allowed
    const hasPermission = (title) => {
      console.log("hasPermission" + title);
      return submenuPermissions.some((perm) => perm.submenu === title);
    };

    // Check if submenuPermissions is empty; if so, add both items
    if (submenuPermissions.length === 0) {
      console.log("IF");
      arr.push(
        {
          id: 1,
          title: "Facility Service Requests",
          url: `${url}/fsr/facilities`,
        },
        {
          id: 2,
          title: "Facility Settings",
          url: `${url}/fsr/settings`,
        },
        {
          id: 3,
          title: "FSR Dashboard",
          url: `${url}/fsr/dashboard`,
        }
      );
    } else {
      console.log("ELSE");
      // Conditionally add items to arr based on permissions
      if (
        checkAuthority("VIEW_FACILITY_SERVICE_REQUESTS") &&
        hasPermission("Facility Service Requests")
      ) {
        arr.push({
          id: 1,
          title: "Facility Service Requests",
          url: `${url}/fsr/facilities`,
        });
      }

      if (
        checkAuthority("VIEW_FACILITY_SETTINGS") &&
        hasPermission("Facility Settings")
      ) {
        arr.push({
          id: 2,
          title: "FSR Settings",
          url: `${url}/fsr/settings`,
        });
      }

      // Check for FSR Dashboard permission
      if (
        checkAuthority("VIEW_FACILITY_SERVICE_REQUESTS") &&
        hasPermission("FSR Dashboard")
      ) {
        arr.push({
          id: 3,
          title: "FSR Dashboard",
          url: `${url}/fsr/dashboard`,
        });
      }
    }

    // Set the fsrList state
    setFsrList(arr);
  };

  useEffect(() => {
    getManagementDetails();
  }, [segments]); // Ensure segments is a dependency

  return (
    <div>
      <PageTitle title={"FSR"} />
      <div className="datacard">
        {fsrList.map((fsr) => (
          <SiteDetailCard key={fsr.id} title={fsr.title} url={fsr.url} />
        ))}
      </div>
    </div>
  );
};

export default Fsr;
