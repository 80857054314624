import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core'

import React, { useEffect, useState } from 'react'
import { getV2API, postV2API } from '../../../network/index.v2'
import { tableData } from '../../../utils'
import EmptyTable from '../../../common/EmptyTable'

export const tableHeader = {
  fontSize: '0.9rem !important',
  fontWeight: 500,
  color: '#45445F',
  px: 3,
  paddingLeft: '5px',
  paddingRight: '5px',
  padding: '12px'
}

const SubmitQuotation = () => {


  const [submitQuotationList, setSubmitQuotationList] = useState([])



  const [newMaterialRows, setNewMaterialRows] = useState([]);
  const [newRows, setNewRows] = useState([]);
  const [newThirdPartyRows, setNewThirdPartyRows] = useState([]);


  const handleAddMaterialRow = () => {
    setNewMaterialRows([...newMaterialRows, {
      materialType: "",
      materialName: "",
      description: "",
      units: "",
      price: "",
      gst: "",
      priceIncludingGst: ""
    }]);
  };

  const handleAddRow = () => {
    setNewRows([...newRows, {
      labourType: "",
      rateType: "",
      rate: "",
      units: "",
      price: "",
      gst: "",
      priceIncludingGst: ""
    }]);
  };


  const handleAddThirdPartyRows = () => {
    setNewThirdPartyRows([...newThirdPartyRows, {
      contractorName: "",
      type: "",
      // rate: "",
      units: "",
      price: "",
      gst: "",
      priceIncludingGst: ""
    }]);
  };

  const handleMaterialChange = (index, field, value) => {
    const updatedRows = newMaterialRows.map((row, i) =>
      i === index ? { ...row, [field]: value } : row
    );
    setNewMaterialRows(updatedRows);
  };

  const handleChange = (index, field, value) => {
    const updatedRows = newRows.map((row, i) =>
      i === index ? { ...row, [field]: value } : row
    );
    setNewRows(updatedRows);
  };


  const handleThirdPartyChange = (index, field, value) => {
    const updatedRows = newThirdPartyRows.map((row, i) =>
      i === index ? { ...row, [field]: value } : row
    );
    setNewThirdPartyRows(updatedRows);
  };


  // -------------------------------------------- Table States and Static Data Manipulation Start --------------------------------------


  const [page, setPage] = useState(0)
  const [perPages, setPerPages] = useState([10, 25, 50]);
  const [perPage, setPerPage] = useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value)
    setPerPage(event.target.value)
    setPage(0);
  };


  // -------------------------------------------- Table States and Static Data Manipulation Ends --------------------------------------

  const submitQuotation = async () => {

    // const formData = new FormData()
    // formData.append("inputs")

    const formData = {
      materials: newMaterialRows,
      labour: newRows,
      thirdPartyCosts: newThirdPartyRows
    }

    try {
      const response = await postV2API("/maintenance/quotation", formData)
      getQuotationsList()
      // if(respons)
    } catch (error) {
      console.log(error);
    }


  }

  const getQuotationsList = async () => {
    try {
      const response = await getV2API("/maintenance/quotation")
      if (response) {
        setSubmitQuotationList(response)
      }
    } catch (error) {
      console.log(error);
    }
  }



  useEffect(() => {
    getQuotationsList()
  }, [])
  return (
    <div style={{ fontWeight: "bolder" }}>
      <div></div>
      <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
        <div >
          <h1 style={{ fontSize: "20px" }}>MATERIAL</h1>
          <Box display="flex" sx={{ my: "3rem" }}>
            <TableContainer component={Paper}>

              <Table
                sx={{ minWidth: "auto" }}
                aria-label="custom pagination table"
                className="responsive-table"
              >
                <TableHead>
                  <TableRow className="table-header">
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Material Type
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      MaterialName
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Description
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Units
                    </TableCell>
                    {/* <TableCell align="left" component="th" sx={tableHeader}>
                      WO Status
                    </TableCell> */}
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Price
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      GST
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Price Incl.GST
                    </TableCell>


                  </TableRow>
                </TableHead>
                <TableBody>


                  {
                    submitQuotationList.slice(page * perPage, page * perPage + perPage).map((task, index) => {
                      return task?.materials?.map((item) => {
                        return (
                          <TableRow key={index}>
                            <TableCell align="left" sx={tableData}>
                              {item?.materialType}
                            </TableCell>
                            <TableCell align="left" sx={tableData}>
                              {item?.materialName}
                            </TableCell>
                            <TableCell align="left" sx={tableData}>
                              {item?.description}
                            </TableCell>
                            <TableCell align="left" sx={tableData}>
                              {item?.units}
                            </TableCell>
                            <TableCell align="left" sx={tableData}>
                              {item?.price}
                            </TableCell>
                            <TableCell align="left" sx={tableData}>
                              {item?.gst}
                            </TableCell>
                            <TableCell align="left" sx={tableData}>
                              {item?.priceIncludingGst}
                            </TableCell>
                          </TableRow>
                        )
                      })
                    })
                  }


                  {
                    submitQuotationList.length - 1 && (
                      <TableRow>
                        <TableCell colSpan={7} align="right">
                          <Button onClick={handleAddMaterialRow} variant="contained" color="primary">
                            Add
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  }

                  {newMaterialRows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">
                        <TextField
                          type="text"
                          value={row.labourType}
                          onChange={(e) => handleMaterialChange(index, 'materialType', e.target.value)}
                          sx={{ width: "100%" }}
                          label="Material Type"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          type="text"
                          value={row.rateType}
                          onChange={(e) => handleMaterialChange(index, 'materialName', e.target.value)}
                          sx={{ width: "100%" }}
                          label="Material Name"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          type="text"
                          value={row.rate}
                          onChange={(e) => handleMaterialChange(index, 'description', e.target.value)}
                          sx={{ width: "100%" }}
                          label="Description"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          type="text"
                          value={row.units}
                          onChange={(e) => handleMaterialChange(index, 'units', e.target.value)}
                          sx={{ width: "100%" }}
                          label="Units"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          type="text"
                          value={row.price}
                          onChange={(e) => handleMaterialChange(index, 'price', e.target.value)}
                          sx={{ width: "100%" }}
                          label="Price"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          type="text"
                          value={row.gst}
                          onChange={(e) => handleMaterialChange(index, 'gst', e.target.value)}
                          sx={{ width: "100%" }}
                          label="Gst"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          type="text"
                          value={row.priceIncludingGst}
                          onChange={(e) => handleMaterialChange(index, 'priceIncludingGst', e.target.value)}
                          sx={{ width: "100%" }}
                          label="Price Incl.Gst"
                        />
                      </TableCell>
                    </TableRow>
                  ))}

                  {
                    submitQuotationList.length === 0 &&
                    <EmptyTable colSpan={6} />
                  }
                </TableBody>
                <TableFooter>
                  <TableRow >

                    <TablePagination
                      align="right"
                      rowsPerPageOptions={perPages}
                      colSpan={7}
                      count={submitQuotationList.length}
                      rowsPerPage={perPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    // ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
        </div>

        <div >
          <h1 style={{ fontSize: "20px" }}>LABOUR</h1>
          <Box display="flex" sx={{ my: "3rem" }}>
            <TableContainer component={Paper}>

              <Table
                sx={{ minWidth: "auto" }}
                aria-label="custom pagination table"
                className="responsive-table"
              >
                <TableHead>
                  <TableRow className="table-header">
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Labour Type
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Rate Type
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Rate
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Units
                    </TableCell>
                    {/* <TableCell align="left" component="th" sx={tableHeader}>
                      WO Status
                    </TableCell> */}
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Price
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      GST
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Price Incl.GST
                    </TableCell>


                  </TableRow>
                </TableHead>
                <TableBody>


                  {
                    submitQuotationList.slice(page * perPage, page * perPage + perPage).map((task, index) => {
                      return task?.labour?.map((item) => {
                        return (
                          <TableRow key={index}>
                            <TableCell align="left" sx={tableData}>
                              {item?.labourType}
                            </TableCell>
                            <TableCell align="left" sx={tableData}>
                              {item?.rateType}
                            </TableCell>
                            <TableCell align="left" sx={tableData}>
                              {item?.rate}
                            </TableCell>
                            <TableCell align="left" sx={tableData}>
                              {item?.units}
                            </TableCell>
                            <TableCell align="left" sx={tableData}>
                              {item?.price}
                            </TableCell>
                            <TableCell align="left" sx={tableData}>
                              {item?.gst}
                            </TableCell>
                            <TableCell align="left" sx={tableData}>
                              {item?.priceIncludingGst}
                            </TableCell>
                          </TableRow>
                        )
                      })
                    })}

                  {
                    submitQuotationList.length - 1 && (
                      <TableRow>
                        <TableCell colSpan={7} align="right">
                          <Button onClick={handleAddRow} variant="contained" color="primary">
                            Add
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  }

                  {newRows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">
                        <TextField
                          type="text"
                          value={row.labourType}
                          onChange={(e) => handleChange(index, 'labourType', e.target.value)}
                          sx={{ width: "100%" }}
                          label="Labour Type"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          type="text"
                          value={row.rateType}
                          onChange={(e) => handleChange(index, 'rateType', e.target.value)}
                          sx={{ width: "100%" }}
                          label="Rate Type"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          type="text"
                          value={row.rate}
                          onChange={(e) => handleChange(index, 'rate', e.target.value)}
                          sx={{ width: "100%" }}
                          label="Rate"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          type="text"
                          value={row.units}
                          onChange={(e) => handleChange(index, 'units', e.target.value)}
                          sx={{ width: "100%" }}
                          label="Units"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          type="text"
                          value={row.price}
                          onChange={(e) => handleChange(index, 'price', e.target.value)}
                          sx={{ width: "100%" }}
                          label="Price"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          type="text"
                          value={row.gst}
                          onChange={(e) => handleChange(index, 'gst', e.target.value)}
                          sx={{ width: "100%" }}
                          label="Gst"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          type="text"
                          value={row.priceIncludingGst}
                          onChange={(e) => handleChange(index, 'priceIncludingGst', e.target.value)}
                          sx={{ width: "100%" }}
                          label="Price Incl.Gst"
                        />
                      </TableCell>
                    </TableRow>
                  ))}

                  {/* {newRows.length > 0 && (
                    <TableRow>
                      <TableCell colSpan={7} align="right">
                        <Button onClick={""} variant="contained" color="primary">
                          Save
                        </Button>
                      </TableCell>
                    </TableRow>
                  )} */}

                  {
                    submitQuotationList.length === 0 &&
                    <EmptyTable colSpan={6} />
                  }
                </TableBody>
                <TableFooter>
                  <TableRow >

                    <TablePagination
                      align="right"
                      rowsPerPageOptions={perPages}
                      colSpan={7}
                      count={submitQuotationList.length}
                      rowsPerPage={perPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    // ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
        </div>

        <div >
          <h1 style={{ fontSize: "20px" }}>PASS THOROUGH / THIRD PARTY COSTS</h1>
          <Box display="flex" sx={{ my: "3rem" }}>
            <TableContainer component={Paper}>

              <Table
                sx={{ minWidth: "auto" }}
                aria-label="custom pagination table"
                className="responsive-table"
              >
                <TableHead>
                  <TableRow className="table-header">
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Subcontractor Name
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Material/Labour
                    </TableCell>
                    {/* <TableCell align="left" component="th" sx={tableHeader}>
                      Description
                    </TableCell> */}
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Units
                    </TableCell>
                    {/* <TableCell align="left" component="th" sx={tableHeader}>
                      WO Status
                    </TableCell> */}
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Price
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      GST
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Price Incl.GST
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>


                  {
                    submitQuotationList.slice(page * perPage, page * perPage + perPage).map((task, index) => {
                      return task?.thirdPartyCosts?.map((item) => {
                        return (
                          <TableRow key={index}>
                            <TableCell align="left" sx={tableData}>
                              {item?.contractorName}
                            </TableCell>
                            <TableCell align="left" sx={tableData}>
                              {item?.type}
                            </TableCell>
                            <TableCell align="left" sx={tableData}>
                              {item?.units}
                            </TableCell>
                            {/* <TableCell align="left" sx={tableData}>
                              {item?.units}
                            </TableCell> */}
                            <TableCell align="left" sx={tableData}>
                              {item?.price}
                            </TableCell>
                            <TableCell align="left" sx={tableData}>
                              {item?.gst}
                            </TableCell>
                            <TableCell align="left" sx={tableData}>
                              {item?.priceIncludingGst}
                            </TableCell>
                          </TableRow>
                        )
                      })
                    })}

                  {
                    submitQuotationList.length - 1 && (
                      <TableRow>
                        <TableCell colSpan={7} align="right">
                          <Button onClick={handleAddThirdPartyRows} variant="contained" color="primary">
                            Add
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  }

                  {newThirdPartyRows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">
                        <TextField
                          type="text"
                          value={row.labourType}
                          onChange={(e) => handleThirdPartyChange(index, 'contractorName', e.target.value)}
                          sx={{ width: "100%" }}
                          label="Subcontractor Name"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          type="text"
                          value={row.rateType}
                          onChange={(e) => handleThirdPartyChange(index, 'type', e.target.value)}
                          sx={{ width: "100%" }}
                          label="Material/Labour"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          type="text"
                          value={row.rate}
                          onChange={(e) => handleThirdPartyChange(index, 'units', e.target.value)}
                          sx={{ width: "100%" }}
                          label="Units"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          type="text"
                          value={row.units}
                          onChange={(e) => handleThirdPartyChange(index, 'price', e.target.value)}
                          sx={{ width: "100%" }}
                          label="Price"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          type="text"
                          value={row.gst}
                          onChange={(e) => handleThirdPartyChange(index, 'gst', e.target.value)}
                          sx={{ width: "100%" }}
                          label="Gst"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          type="text"
                          value={row.priceIncludingGst}
                          onChange={(e) => handleThirdPartyChange(index, 'priceIncludingGst', e.target.value)}
                          sx={{ width: "100%" }}
                          label="Price Incl.Gst"
                        />
                      </TableCell>

                    </TableRow>
                  ))}

                  {
                    submitQuotationList.length === 0 &&
                    <EmptyTable colSpan={6} />
                  }
                </TableBody>
                <TableFooter>
                  <TableRow >

                    <TablePagination
                      align="right"
                      rowsPerPageOptions={perPages}
                      colSpan={7}
                      count={submitQuotationList.length}
                      rowsPerPage={perPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    // ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
          

        </div>

        <div>
            <Button sx={{ float: "right" }} variant='contained' type='button' onClick={submitQuotation}>Submit All Forms</Button>
          </div>


        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start", gap: "20px" }}>
          <div style={{ width: "50%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Button variant='contained'>Attachments</Button>
            <TextField variant='outlined' label="Notes" />
          </div>
          <div style={{ width: "50%" }}>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: "auto" }}
                aria-label="custom pagination table"
                className="responsive-table"
              >
                <TableHead>
                  <TableRow className="table-header">
                    <TableCell align="left" component="th" sx={tableHeader}>Sub Total ex GST</TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>Gst</TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>


                  {
                    submitQuotationList.slice(page * perPage, page * perPage + perPage).map((task, index) => {
                      return task?.thirdPartyCosts?.map((item) => {
                        return (
                          <TableRow key={index}>
                            <TableCell align="left" sx={tableData}>
                              {task?.totalCost}
                            </TableCell>
                            <TableCell align="left" sx={tableData}>
                              {task?.totalGst}
                            </TableCell>
                            <TableCell align="left" sx={tableData}>
                              {task?.totalCostIncludingGst}
                            </TableCell>

                          </TableRow>
                        )
                      })
                    })}

                  {
                    submitQuotationList.length === 0 &&
                    <EmptyTable colSpan={6} />
                  }
                </TableBody>

              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <Grid style={{ marginTop: "10px" }}>
        <Button variant='contained' style={{ float: "right" }}>Submit For Approval</Button>
      </Grid>
    </div>
  )
}

export default SubmitQuotation