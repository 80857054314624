
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import { getV2API } from '../../../network/index.v2';
import { tableData } from '../../../utils';
import EmptyTable from '../../../common/EmptyTable';

export const tableHeader = {
    fontSize: '0.9rem !important',
    fontWeight: 500,
    color: '#45445F',
    px: 3,
    paddingLeft: '5px',
    paddingRight: '5px',
    padding: '12px'
}





const ViewTask = () => {





    const [maintenanceList, setMaintenanceList] = useState([])

    // -------------------------------------------- Table States and Static Data Manipulation Start --------------------------------------


    const [page, setPage] = useState(0)
    const [perPages, setPerPages] = useState([10, 25, 50]);
    const [perPage, setPerPage] = useState(10)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        console.log(event.target.value)
        setPerPage(event.target.value)
        setPage(0);
    };


    // -------------------------------------------- Table States and Static Data Manipulation Ends --------------------------------------


    const getMaintenanceData = async () => {
        try {
            const response = await getV2API("/maintenance/management")
            if (response) {
                setMaintenanceList(response)
            }
        } catch (error) {
            console.log(error);
        }
    }

    console.log(maintenanceList);

    useEffect(() => {
        getMaintenanceData()
    }, [])
    return (
        <div>
            <div className='export-button' >
                <div style={{ display: "flex", gap: "10px" }}>
                    <p className='titles'>Site</p>
                    <p className='titles'>Work Type</p>
                    <p className='titles'>Supplier Name</p>
                    <p className='titles'>WO Status</p>
                    <p className='titles'>Work Permit Status</p>
                    <p className='titles'>Quotations Status</p>
                    <p className='titles'>Service Categ 1</p>
                    <p className='titles'>Service Categ 2</p>
                    <p className='titles'>Service Categ 3</p>
                    <p className='titles'>Selected Period</p>
                </div>
                <Button variant="contained" style={{ backgroundColor: "grey" }} sx={{ height: 30, m: 2 }}>
                    <GetAppRoundedIcon /> &nbsp; &nbsp;
                    Export
                </Button>
            </div>
            <div >
                <Box display="flex" sx={{ my: "3rem" }}>
                    <TableContainer component={Paper}>

                        <Table
                            sx={{ minWidth: "auto" }}
                            aria-label="custom pagination table"
                            className="responsive-table"
                        >
                            <TableHead>
                                <TableRow className="table-header">
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Title
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Work Type
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Company Name
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Site Id
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Site Name
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        location Id
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Shift Recurrence
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Recurrence Day
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Vendor
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Service Category Level 1
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Service Category Level 2
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Service Category Level 3
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Due Time
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Date Completed
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Field Staff Name
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        WO Status
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Defects Identified
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        FieldStaff Check In Time
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        FieldStaff Check Out Time
                                    </TableCell>

                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Time On Site
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Force Check Out(yes/no)
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Quotation
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        On time Completion(yes/no)
                                    </TableCell>
                                    {/* <TableCell align="center" component="th" sx={tableHeader}>
                                        Quote Action
                                    </TableCell> */}

                                </TableRow>
                            </TableHead>
                            <TableBody>


                                {
                                    maintenanceList.slice(page * perPage, page * perPage + perPage).map((task, index) => {
                                        return (

                                            task?.tasks?.map((item) => {
                                                return <TableRow key={index}>
                                                    <TableCell align="left" sx={tableData}>
                                                        {item?.title}
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        {item?.workType}
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        {item?.companyId?.name}
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        {item?.siteId?._id}
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        {item?.siteId?.name}
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        {item?.locationId}
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        {item?.shiftRecurrence}
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        {item?.recurrenceDay?.map(it => it)}
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        {item?.vendorId}
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        {task?.checkIn?.split('T')[0]}
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        {task?.checkOut?.split('T')[0]}
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        {task?.purpose}
                                                    </TableCell>
                                                    {/* <TableCell align="left" sx={tableData}>

                                            {task?.isMatch === true ?
                                                <Button style={{ color: 'white', backgroundColor: 'green' }}> Matched</Button> :
                                                <Button style={{ color: 'white', backgroundColor: 'red' }} onClick={() => handleMapData(task)}>Mismatch</Button>
                                            }
                                            <i>
                                                {task?.isMatch ? null : `${task?.distance} km away`}
                                            </i>
                                        </TableCell> */}
                                                    <TableCell align="left" sx={tableData}>
                                                        {item?.timeDue}
                                                    </TableCell>

                                                    <TableCell align="left" sx={tableData}>
                                                        {item?.updatedAt?.split("T")[0]}
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        Pending
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        Pending
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        Pending
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        {item?.startDate?.split('T')[0]}
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        {item?.endDate?.split('T')[0]}
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        Pending
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        {item?.status}
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        Pending
                                                    </TableCell>
                                                    <TableCell align="left" sx={tableData}>
                                                        Pending
                                                    </TableCell>
                                                    {/* <TableCell align="left" sx={tableData}>
                                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'green' }} onClick={() => setOpenCheckout(true)}>Checked Out</Button>

                                            <Button variant="outlined" color="error" onClick={() => setOpenCheckout(true)}>Forced Check Out</Button>

                                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'orange' }} onClick={() => setOpenCheckout(true)}>Check Out</Button>
                                        </TableCell> */}

                                                </TableRow>
                                            })

                                        )
                                    })
                                }

                                {
                                    maintenanceList.length === 0 &&
                                    <EmptyTable colSpan={6} />
                                }
                            </TableBody>
                            <TableFooter>
                                <TableRow >

                                    <TablePagination
                                        align="right"
                                        rowsPerPageOptions={perPages}
                                        colSpan={7}
                                        count={maintenanceList.length}
                                        rowsPerPage={perPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    // ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Box>
            </div>
        </div>
    )
}

export default ViewTask